defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/PlayTogether/ClubInfo',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    var ClubInfo = function (controller, coupon) {
      var groupPlay = coupon.groupPlay;
      var clubName = coupon.clubName || '';

      if (!groupPlay) {
        return m('div.play-together', [
          m('p.play-together-club-name', controller.d('PlayTogether/ClubName', { CLUBNAME: clubName })),
        ]);
      }

      var rows = groupPlay.totalRowCount;
      var sharePercentage = groupPlay.playerSharePercentage ? groupPlay.playerSharePercentage.toString().replace('.', ',') : null;

      return m('div.play-together', [
        m('p.play-together-club-name', controller.d('PlayTogether/ClubName', { CLUBNAME: clubName })),
        m('div.play-together-rows', [
          m('span.play-together-label', controller.d('PlayTogether/RowsLabel') + ' '),
          m('span.play-together-value', controller.d('PlayTogether/RowsValue', { NUMBER: rows }))
        ]),
        sharePercentage ?
          m('div.play-together-amount', [
            m('span.play-together-label', controller.d('PlayTogether/AmountLabel') + ' '),
            m('span.play-together-value', controller.d('PlayTogether/AmountValue', { NUMBER: sharePercentage }))
          ])
          : null
      ]);
    };


    // Public functions:
    return ClubInfo;

  });
