defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/SimpleCubeLoader',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Template:
    var SimpleLoader = function () {
      var generateCubeFaces = function () {
        var cubeInnerFace = m('.cube-inner-face');
        var cubeFace = m('.cube-face', [
          m('.square'),
          m('.diamond'),
          m('.circle'),
          m('.triangle')
        ]);

        return [
          cubeFace,
          cubeFace,
          cubeFace,
          cubeFace,
          cubeFace,
          cubeFace,
          cubeInnerFace,
          cubeInnerFace,
          cubeInnerFace,
          cubeInnerFace,
          cubeInnerFace,
          cubeInnerFace
        ];
      };

      return m('.ds-cube-loader-wrapper',
        m('.ds-cube-wrapper',
          m('.ds-cube', generateCubeFaces())
        )
      );
    };

    // Public functions:
    return SimpleLoader;

  });
