defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/OptionDetailsModal', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/Shared/Modal',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/InfoContent',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/KeysContent',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/GuaranteeContent/GuaranteeContent',
  // eslint-disable-next-line no-unused-vars
], function (m, Modal, InfoContent, KeysContent, GuaranteeContent) {
  return function ({ controller, systemName }) {
    Object.assign(this, controller);
    this.modal = new Modal({ extraClassNames: 'theme-numbergames' });

    return {
      show: () => {
        this.modal.setContent(<div className='max-h-[52rem] overflow-auto text-center flex flex-col gap-24'>
          <InfoContent controller={controller} targetedSystemName={systemName} />
          <KeysContent controller={controller} targetedSystemName={systemName} />
          <GuaranteeContent controller={controller} targetedSystemName={systemName} />
        </div>);
        this.modal.show();
      }
    };
  };
});