defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Helpers/PackagesApi',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
  ],
  function (ApiRequest) {
    // Variables:
    const baseURL = '/dlo/scapi/danskespil/numbergames';

    const getPackages = (version) => {
      if (version === 'v2') {
        return ApiRequest({
          url: `${baseURL}/packages/v2/packagesInformation`
        });
      }

      return ApiRequest({
        url: `${baseURL}/packages/packagesinformation`
      });
    };

    const generateLightningRowsForPackageGames = (packageData) => {
      const payload = [];
      packageData.coupons.forEach((coupon) => {
        coupon.couponGames.forEach((game) => {
          const existingGamePayload = payload.find((payloadGame) => {
            return payloadGame.GameType === game.gameType;
          });

          if (existingGamePayload) {
            existingGamePayload.RowCount += game.rowCount;
          } else {
            payload.push({
              GameType: game.gameType,
              RowCount: game.rowCount
            });
          }
        });
      });

      return ApiRequest({
        url: `${baseURL}/packages/random`,
        method: 'POST',
        data: payload
      }).then((response) => {
        const rowsResponseData = [...response];
        const coupons = packageData.coupons.map((packageCoupon) => {
          const couponGames = packageCoupon.couponGames.map((couponGame) => {
            const indexOfRowsForGame = rowsResponseData.findIndex((rowsData) => {
              return rowsData.gameType === couponGame.gameType;
            });

            if (indexOfRowsForGame !== -1 &&
              rowsResponseData[indexOfRowsForGame].rows?.length >= couponGame.rowCount) {
              const rows = rowsResponseData[indexOfRowsForGame].rows.slice(0, couponGame.rowCount);
              rowsResponseData[indexOfRowsForGame].rows.splice(0, rows.length);
              couponGame = { ...couponGame, rows };
            } else {
              console.error('Rows data for package is not available for:', packageCoupon.gameType);
            }

            return couponGame;
          });

          return { ...packageCoupon, couponGames };
        });

        return { ...packageData, coupons };
      });
    };

    return {
      getPackages,
      generateLightningRowsForPackageGames
    };
  });