defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/RowNumber',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Template:
    var RowNumber = function (number, className, config) {

      // Variables:
      className = className || '';

      // Functions:
      function selectElement(e) {
        var $element = $DS(e.currentTarget);

        $DS($element).on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function (event) {
          if ($DS(event.target).hasClass('deselect-number-animation')) {
            $element.removeClass('flip-animation-in-progress select-number-animation deselect-number-animation');
            $element.find('.shine').remove();
          }
          if ($DS(event.target).hasClass('shine')) {
            $DS(event.target).remove();
            $element.removeClass('flip-animation-in-progress select-number-animation deselect-number-animation');
          }
        });
      }

      // Return:
      return m('div', { class: 'cell cell-number-' + number + className, config: config, onclick: selectElement }, [
        m('div', { class: 'cell-container' }, [
          m('span', { class: 'front-face' }, [
            m('span', { class: 'number' }, number),
          ]),
          m('span', { class: 'back-face' }, [
            m('span', { class: 'number' }, number)
          ])
        ])
      ]);

    };

    // Public functions:
    return RowNumber;

  });
