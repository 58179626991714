defineDs('DanskeSpil/Framework/NumberGames/Scripts/Templates/CouponOverlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Context:
    var CouponOverlay = function (className, couponId, dictionary) {

      var printCoupon = function () {
        return function () {
          document.getElementsByClassName('coupon-iframe')[0].contentWindow.focus();
          document.getElementsByClassName('coupon-iframe')[0].contentWindow.print();
        };
      };

      // Return:
      return {
        class: m.prop(className),

        render: function (dismiss) {
          return m('div', { class: this.class() }, [
            m('div', { class: 'pannel' }, [
              m('div', { class: 'coupon-iframe-wrapper' }, [
                m('iframe', {
                  class: 'coupon-iframe',
                  src: window.location.origin + '/' + window.location.pathname.split('/')[1] + '/vis-kupon?couponId=' + couponId,
                  frameborder: '0',
                  allowfullscreen: '0'
                })
              ]),
              m('div', { class: 'buttons' }, [
                m('button', { class: 'btn cta', onclick: printCoupon() }, dictionary.printButton),
                m('button', { class: 'btn cta', onclick: dismiss }, dictionary.closeButton)
              ])
            ])
          ]);
        }
      };
    };

    // Public functions:
    return CouponOverlay;

  });
