defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PurchaseFlow/Confirm/GameCouponItem', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/DateUtils'
], function (m, DateUtils) {
  const GameCouponItem = {
    controller: function ({ controller, coupon, drawIndex }) {
      Object.assign(this, controller);

      this.couponGameType = this.gameTypes[coupon.gameType];
      this.mainGame = m.prop(null);
      this.mainGameDraw = m.prop(null);
      this.jokerGames = m.prop([]);

      coupon.couponGames.forEach((couponGame) => {
        if (['JokerSaturday', 'JokerWednesday'].includes(couponGame.gameType)) {
          this.jokerGames().push(couponGame);
        } else {
          this.mainGame(couponGame);
          if (drawIndex) {
            this.mainGameDraw(couponGame.draws[drawIndex]);
          } else {
            this.mainGameDraw(couponGame.openDraw);
          }
        }
      });
    },
    view: function (controller) {
      const mainDrawDate = DateUtils.parseISO8601(controller.mainGameDraw().closingTime);
      const detailsRow = ({ icon, label, value, collapsibleContent }) => {
        return m('.multi-packages-confirm-game-card__details-row', [
          m('.multi-packages-confirm-game-card__details-header', {
            class: collapsibleContent ? 'multi-packages-confirm-game-card__details-header--collapsible' : '',
            onclick: (event) => {
              if (!collapsibleContent) return;

              const $trigger = event.currentTarget;
              const $section = $trigger.parentElement;
              const $gameRows = $section.querySelector('.multi-packages-confirm-game-card__details-game-rows'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
              const maxHeight = $section.querySelector('.multi-packages-confirm-game-card__details-game-rows-inner')?.clientHeight || 0; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
              const toggleState = !$section.classList.contains('multi-packages-confirm-game-card__details-row--opened');

              $section.classList.toggle('multi-packages-confirm-game-card__details-row--opened', toggleState);
              $gameRows.style.maxHeight = toggleState ? `${maxHeight}px` : '';
            }
          }, [
            m('.multi-packages-confirm-game-card__details-header-icon-cell', icon ? m('svg', m('use', {
              href: icon
            })) : null),
            m('.multi-packages-confirm-game-card__details-header-label-cell', label),
            collapsibleContent ? m('svg.multi-packages-confirm-game-card__details-header-collapsible-icon', m('use', {
              href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#arrow-down-icon'
            })) : null,
            m('.multi-packages-confirm-game-card__details-header-value-cell', value)
          ]),
          collapsibleContent || null
        ]);
      };

      const gameRowsStructure = (gameName, rows) => {
        return m('.multi-packages-confirm-game-card__details-game-rows',
          m('.multi-packages-confirm-game-card__details-game-rows-inner', rows.map((row, index) => {
            let rowContent = row.numbers.map((number) => (gameName !== 'joker' ? number.toString().padStart(2, '0') : number));
            if (row.starNumbers) {
              const _startNumbers = row.starNumbers.map((number) => number.toString().padStart(2, '0'));
              rowContent.push('+');
              rowContent = rowContent.concat(_startNumbers);
            }

            if (row.vikingNumber) {
              rowContent.push('+');
              rowContent.push(row.vikingNumber.toString().padStart(2, '0'));
            }

            return m('.multi-packages-confirm-game-card__details-game-row', [
              m('.multi-packages-confirm-game-card__details-game-row-count', index + 1),
              m('.multi-packages-confirm-game-card__details-game-row-numbers', rowContent.map((item) => {
                return m('span', item);
              }))
            ]);
          }))
        );
      };

      return m(`.multi-packages-confirm-game-card.multi-packages-confirm-game-card--${controller.couponGameType}`, [
        m('.multi-packages-confirm-game-card__header', [
          m('img.multi-packages-confirm-game-card__logo', {
            class: `multi-packages-confirm-game-card__logo--${controller.couponGameType}`,
            src: controller.gameLogos[controller.couponGameType]
          }),
          m('.multi-packages-confirm-game-card__day', mainDrawDate.dayName)
        ]),
        m('.multi-packages-confirm-game-card__details', [
          detailsRow({
            icon: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#coupon-draw',
            label: controller.confirmDictionary('GameCard/DrawLabel'),
            value: `${mainDrawDate.date}/${mainDrawDate.month} ${mainDrawDate.year}`
          }),
          detailsRow({
            icon: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#coupon-rows',
            label: controller.confirmDictionary('GameCard/RowsLabel'),
            value: controller.mainGame().rowCount,
            collapsibleContent: gameRowsStructure(controller.couponGameType, controller.mainGame().rows)
          })
        ]),
        controller.jokerGames().length > 0 ?
          controller.jokerGames()
            .filter((jokerGame) => {
              if (controller.couponGameType === 'eurojackpot') {
                const nextJokerName = mainDrawDate.dayName === 'fredag' ? 'JokerSaturday' : 'JokerWednesday';
                return jokerGame.gameType === nextJokerName;
              }
              return true;
            })
            .map((jokerGame) => {
              const mainDate = new Date(controller.mainGameDraw().closingTime);
              const jokerDate = new Date(jokerGame.openDraw.closingTime);
              let jokerDrawDate = DateUtils.parseISO8601(jokerGame.openDraw.closingTime);
              if (mainDate > jokerDate) {
                const nextWeekJokerDate = new Date(jokerDrawDate.dateObject);
                nextWeekJokerDate.setDate(nextWeekJokerDate.getDate() + 7);
                jokerDrawDate = DateUtils.parseISO8601(nextWeekJokerDate.toISOString());
              }

              return m('.multi-packages-confirm-game-card__joker', [
                m('.multi-packages-confirm-game-card__header', [
                  m('img.multi-packages-confirm-game-card__logo.multi-packages-confirm-game-card__logo--joker', {
                    src: controller.gameLogos.joker
                  }),
                  m('.multi-packages-confirm-game-card__day', jokerDrawDate.dayName)
                ]),
                m('.multi-packages-confirm-game-card__details', [
                  detailsRow({
                    icon: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#coupon-draw',
                    label: controller.confirmDictionary('GameCard/DrawLabel'),
                    value: `${jokerDrawDate.date}/${jokerDrawDate.month} ${jokerDrawDate.year}`
                  }),
                  detailsRow({
                    icon: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#coupon-rows',
                    label: controller.confirmDictionary('GameCard/RowsLabel'),
                    value: jokerGame.rowCount,
                    collapsibleContent: gameRowsStructure(controller.couponGameType, jokerGame.rows)
                  })
                ]),
              ]);
            }) : null
      ]);
    }
  };
  return GameCouponItem;
});
