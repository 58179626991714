defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/StandardPackage/StandardPackageItem', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Helpers/PackagesUtils',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/Shared/PackageCard',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/StandardPackage/GameCouponItem'
], function (m, Params, PackagesUtils, PackageCard, GameCouponItem) {
  // Component:
  var StandardPackageItem = {
    controller: function ({ controller, packageData }) {
      Object.assign(this, controller);

      this.packagePool = PackagesUtils.getPackagePool(packageData.coupons);

      this.selectPackage = () => {
        if (!packageData.isOpenForSale) {
          return;
        }

        Params.set('packageFlowStep=purchaseFlow');
        Params.set(`packageNo=${packageData.packageNo}`);
      };
    },

    view: function (controller, { packageData }) {
      const totalPoolSizeLabel = controller.packagePool.isMinimumPoolSize ? `min. ${controller.packagePool.totalPoolSize} MIO` : `${controller.packagePool.totalPoolSize} MIO`;
      const body = () => {
        return m('.multi-packages-package-card__coupons-list', packageData.coupons.map((coupon) => {
          return m(GameCouponItem, { controller, coupon });
        }));
      };
      const footer = () => {
        return m('.multi-packages-button', {
          class: packageData.isOpenForSale ? '' : 'btn--disabled',
          onclick: controller.selectPackage
        }, controller.standardPackageDictionary(packageData.isOpenForSale ? 'BuyButton' : 'SalesClosedButton', { price: packageData.price }));
      };
      return PackageCard({
        ribbon: packageData.ribbon,
        tagline: packageData.description,
        title: controller.packagePool.totalPoolSize > 0 ? totalPoolSizeLabel : 0,
        body: body(),
        footer: footer()
      });
    }
  };

  return StandardPackageItem;
});
