defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/MultiClient/LightningClient', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/Client/RotarySelector',
], function (m, Params, RotarySelector) {

  // Client:
  var GameClient = function () {

    // Component:
    var root = {
      controller: function (args) {
        Object.assign(this, args.controller);

        const gameInstanceIdInParams = Params.extract('gameInstanceId');
        if (gameInstanceIdInParams !== this.gameInstanceId()) {
          if (this.verticalType && ['plus', 'jackpot'].indexOf(this.verticalType()) !== -1) {
            // for `plus` and `jackpot` we set the gameInstance in localStorage under '`Subscription-Models-` + verticalType'
            return;
          }
          Params.set('gameInstanceId=' + this.game().id());
        }

        if (this.game() && !this.game().rowsToGenerate() > 0) {
          this.game().rowsToGenerate(this.getLightningOptions()()[0]);
        }
      },

      view: function (controller) {
        var label = controller.game().rowsToGenerate() === 1 ? controller.mcDictionary('game/lightningRow') : controller.mcDictionary('game/lightningRows');
        return m('.multi-client-rotary-selector__outer', RotarySelector(controller.getLightningOptions(), controller.game().rowsToGenerate, label, controller.game().save(), controller.gameType));
      }
    };

    return root;
  };

  // Public functions:
  return GameClient;

});
