defineDs('DanskeSpil/Domain/Tutorial/Scripts/Tutorial.js',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Tutorial/Scripts/Pages/TutorialFrontpage',
    'DanskeSpil/Domain/Tutorial/Scripts/Pages/TutorialSlidepage',
    'DanskeSpil/Domain/Tutorial/Scripts/Pages/TutorialSimplePage',
    'DanskeSpil/Domain/Tutorial/Scripts/Pages/TutorialAvalonPage',
    'DanskeSpil/Domain/Tutorial/Scripts/Pages/TutorialTipsSystemPage',
  ], function (Component, TutorialFrontpage, TutorialSlidepage, TutorialSimplePage, TutorialAvalonPage, TutorialTipsSystemPage) {

    Component('tutorial', function (m, ignoredRoute, settings, ignoredProperty, element) {

      var controller = function () {
        this.settings = settings;
        this.footerSettings = settings.footer;
        this.mostVisitedLabel = settings.mostVisitedLabel;
        this.searchQuery = m.prop('');
        this.filteredTutorials = m.prop([]);
        this.mostVisitedList = settings.mostVisitedList;
        this.titleCssStyle = settings.titleCssStyle;

        this.categories = settings.categories;
        this.slideTutorials = settings.slideTutorials;
        this.avalonTutorials = settings.avalonTutorials;
        this.tipsSystemTutorial = settings.tipsSystemTutorial;
        this.richTextTutorials = settings.richTextTutorials;

        this.jointList = this.categories.concat(this.slideTutorials).concat(this.richTextTutorials).concat(this.avalonTutorials).concat(this.tipsSystemTutorial).sort(function (a, b) {
          return a.order - b.order;
        });
        this.allTutorials = this.categories.reduce(function (accumulator, category) {
          accumulator = accumulator.concat(category.slideTutorials).concat(category.richTextTutorials).concat(category.avalonTutorials).concat(category.tipsSystemTutorial);
          return accumulator;
        }, []).concat(this.slideTutorials).concat(this.avalonTutorials).concat(this.tipsSystemTutorial).concat(this.richTextTutorials);

        // Populate avalon-tutorials with content from DOM
        this.allTutorials.forEach(function (tutorial) {
          if (tutorial.urlPrefix !== '/avalon') return;

          var content = document.getElementById(tutorial.id);

          if (content) {
            tutorial.content = content.cloneNode(true);
            content.id = content.id + '-added-to-js';
            tutorial.content.classList.remove('u-hidden');
          }
        });

        this.getTextWithHighligt = function (text) {
          var finalText = text;
          if (!this.searchQuery()) return finalText;

          var openingTags = Array.from(text.matchAll(new RegExp('<style>|<script', 'gi'))).map(function (match) {
            return match.index;
          });
          var closingTags = Array.from(text.matchAll(new RegExp('</style>|</script>', 'gi'))).map(function (match) {
            return match.index;
          });

          if (openingTags.length === 0) openingTags.push(-1);
          if (closingTags.length === 0) closingTags.push(-1);

          var searchRegexPrep = this.searchQuery().split(' ').join('|');
          var searchRegex = new RegExp(searchRegexPrep, 'gmi');

          var match;
          var indexOffset = 0;
          while ((match = searchRegex.exec(text)) !== null) {

            var closestPreviousOpeningTag = openingTags.reduce(function (prev, current, index) {
              var closest = Math.abs(current - match.index) < Math.abs(prev.val - match.index) && current <= match.index ? current : prev.val;
              var isNew = closest !== prev.val;
              return { val: closest, index: isNew ? index : prev.index };
            }, { val: -1, index: -1 });

            if ((match.index < closestPreviousOpeningTag.val || match.index > closingTags[closestPreviousOpeningTag.index]) || closestPreviousOpeningTag.val === -1) {
              finalText = finalText.slice(0, match.index + indexOffset) + '<span class="tutorial__highlight">' + match[0] + '</span>' + finalText.slice(match.index + match[0].length + indexOffset);
              indexOffset += '<span class="tutorial__highlight"></span>'.length;
            }
          }

          return finalText;
        }.bind(this);
      };

      var rootctrl = new controller();

      m.route.mode = 'hash';
      m.route(element, '/', {
        '/': m.component(TutorialFrontpage, rootctrl),
        '/slide/:tutorial': m.component(TutorialSlidepage, rootctrl),
        '/richtext/:tutorial': m.component(TutorialSimplePage, rootctrl),
        '/avalon/:tutorial': m.component(TutorialAvalonPage, rootctrl),
        '/tipssystem/:ignore': m.component(TutorialTipsSystemPage, rootctrl),
      });
    });
  });
