defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PurchaseFlow/Receipt', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Coupon',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Helpers/PackagesUtils',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/Shared/PackagesTabs',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PurchaseFlow/Receipt/GamesCountdowns'
], function (m, Utils, Coupon, PackagesUtils, PackagesTabs, GamesCountdowns) {
  // Component:
  var Receipt = {
    controller: function ({ controller }) {
      Object.assign(this, controller);

      this.componentKey = 'multi-packages-receipt';
      this.showBuyAsSubscriptionButtonOnReceipt = this.settings.buyAsSubscriptionOnReceipt;

      this.headlineText(null);
      this.receiptDictionary = this.dictionary.bind({ prefix: 'MultiPackages/Receipt/' });

      this.packagePool = PackagesUtils.getPackagePool(this.packageModel().information().coupons);
      this.isCouponLoaded = m.prop(false);
      this.packageDescription = this.packageModel().information().description;
      this.selectedCoupon = m.prop(this.packageModel()?.information?.()?.coupons[0]);
      this.afterLoadCoupon = () => {
        this.isCouponLoaded(true);
      };

      this.selectCoupon = (index) => {
        const _selectedCoupon = this.packageModel().information().coupons[index];
        if (this.selectedCoupon()?.gameType === _selectedCoupon?.gameType) return;
        this.selectedCoupon(null);
        setTimeout(() => {
          this.selectedCoupon(_selectedCoupon);
          m.redraw();
        }, 100);
        this.isCouponLoaded(false);
      };

      this.tabsOptions = this.packageModel().information().coupons.map((coupon) => {
        const couponGame = coupon.couponGames.find((game) => game.gameType === coupon.gameType);
        return {
          classModifier: this.gameTypes[couponGame.gameType],
          label: this.gameTypes[couponGame.gameType]
        };
      });

      this.packageModel().trackPurchase();
    },

    view: function (controller) {
      const onLottiePlayerRendered = function (element) {
        if (typeof element.play === 'function') {
          element.setAttribute('loop', 'true');
          element.setAttribute('count', '2');
          element.play();
        }
      };

      return m('.multi-packages-receipt', [
        // left panel
        m('.multi-packages-receipt__left', [
          m('.multi-packages-receipt__header', [
            m('.multi-packages-receipt__heading', controller.receiptDictionary('Heading')),
            m('.multi-packages-receipt__subheading', controller.receiptDictionary('Subheading', { minCouponPool: controller.packagePool.totalPoolSize }))
          ]),
          controller.showBuyAsSubscriptionButtonOnReceipt ? m('.multi-packages-receipt__buy-as-subscription', [
            m('.multi-packages-receipt__buy-as-subscription-description', controller.receiptDictionary('BuyAsSubscriptionDescription')),
            m('.multi-packages-button', {
              onclick: controller.showSubscriptionOptionsModal
            }, controller.receiptDictionary('BuyAsSubscriptionButton', { packageDescription: controller.packageDescription })
            )
          ]) : null,
          Utils.isMobile() ? null : m(GamesCountdowns, { controller })
        ]),
        // right panel
        m('.multi-packages-receipt__right', [
          customElements.get('lottie-player') && controller.isCouponLoaded() ?
            m('lottie-player[autoplay].fireworks-animation', {
              class: `fireworks-animation--${controller.selectedCoupon().gameType.toLowerCase()}`,
              src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/fireworks_animation.json',
              config: onLottiePlayerRendered
            }) : null,
          controller.packageModel().information().coupons.length > 1 ? m('.multi-packages-receipt__games-tabs', m(PackagesTabs, { controller })) : null,
          Utils.isMobile() ? m(GamesCountdowns, { controller }) : null,
          controller.selectedCoupon() ? m(Coupon, { controller: {
            ...controller,
            gameType: controller.selectedCoupon().gameType === 'EuroJackpot' ? 'Eurojackpot' : controller.selectedCoupon().gameType,
            game: m.prop({ couponId: m.prop(controller.selectedCoupon().couponId) })
          } }) : null
        ])
      ]);
    }
  };

  return Receipt;
});
