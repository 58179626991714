defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/StatisticsModule',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Shared/Framework/Mithril/Scripts/Helpers/Hammer',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/NumberGamesDictionary',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/StatisticsModuleApi',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/StatisticsModule/PeriodFilter',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/StatisticsModule/NumbersTypeSwitch',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/StatisticsModule/Graph',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/StatisticsModule/GraphOverview',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/StatisticsModule/SelectedNumbers'
  ],
  function (Component, Hammer, Render, Storage, NumberGamesDictionary, Utils, StatisticsModuleApi, PeriodFilter, NumbersTypeSwitch, Graph, GraphOverview, SelectedNumbers) {

    // Component:
    Component('statistics-module', [NumberGamesDictionary], function (m, route, settings, property) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.$graphViewFinder = m.prop();
          this.$largeBarsWrapper = m.prop();
          this.completedDrawDatesFeed = {};
          this.completedDrawMonths = [];
          this.completedDrawYears = [];
          this.d = NumberGamesDictionary.get.bind({ prefix: 'StatisticsModule/' });
          this.eurojackpotClassicGameClientLink = m.prop(settings.eurojackpotClassicGameClientLink);
          this.eurojackpotLuckyGameClientLink = m.prop(settings.eurojackpotLuckyGameClientLink);
          this.eurojackpotSystemGameClientLink = m.prop(settings.eurojackpotSystemGameClientLink);
          this.gameClients = ['Lotto', 'Vikinglotto', 'Eurojackpot', 'Keno'];
          this.kenoClassicGameClientLink = m.prop(settings.kenoClassicGameClientLink);
          this.kenoSystemGameClientLink = m.prop(settings.kenoSystemGameClientLink);
          this.isMobile = m.prop(Utils.isMobile() || (Utils.isTabletDevice() && (window.innerWidth < window.innerHeight)));
          this.loading = m.prop(true);
          this.lottoClassicGameClientLink = m.prop(settings.lottoClassicGameClientLink);
          this.lottoLuckyGameClientLink = m.prop(settings.lottoLuckyGameClientLink);
          this.lottoSystemGameClientLink = m.prop(settings.lottoSystemGameClientLink);
          this.property = property;
          this.render = m.prop(Render());
          this.selectedGameClient = m.prop('Eurojackpot');
          this.vikinglottoClassicGameClientLink = m.prop(settings.vikinglottoClassicGameClientLink);
          this.vikinglottoLuckyGameClientLink = m.prop(settings.vikinglottoClassicGameClientLink);
          this.vikinglottoSystemGameClientLink = m.prop(settings.vikinglottoClassicGameClientLink);
          this.windowWidth = document.documentElement.clientWidth;

          // Properties:
          this.property('currentPosition', 0);
          this.property('filterOrder', 'Number');
          this.property('filterToggle', true);
          this.property('filterZeroToggle', false);
          this.property('graphOverviewExpandToggle', true);
          this.property('largeBarsWrapperWidth');
          this.property('numbersType', 'numbers');
          this.property('selectedPoints', {});
          this.property('selectedMonths', []);
          this.property('selectedYears', []);

          // Context:
          var currentPosition = this.property('currentPosition');
          var delta = 0;
          var filteredStatisticsDataFeed = null;
          var numbersFromStorage = Storage.get(this.selectedGameClient() + '-StatisticsNumbers') || null;
          var statisticsDataFeed = m.prop();
          var url = location.pathname.toLowerCase();

          if (/\/keno/.test(url)) {
            this.selectedGameClient('Keno');
          } else if (/\/lotto/.test(url)) {
            this.selectedGameClient('Lotto');
          } else if (/\/vikinglotto/.test(url)) {
            this.selectedGameClient('Vikinglotto');
          }

          if (numbersFromStorage) {
            this.property('selectedPoints')[this.selectedGameClient()] = JSON.parse(numbersFromStorage);
          }

          // Functions:
          this.getStatisticsFeed = function () {
            var selectedDatesToFeed = { periods: [], orderProperty: this.property('filterOrder') };
            var selectedMonths = this.property('selectedMonths');
            var yearsToFeed = this.property('selectedYears');

            if (yearsToFeed.length === 0 && selectedMonths.length > 0) {
              yearsToFeed = this.completedDrawYears;
            }

            yearsToFeed.forEach(function (year) {
              selectedDatesToFeed.periods.push({ year: year, months: selectedMonths });
            });

            StatisticsModuleApi.statisticsFeed(this.selectedGameClient(), selectedDatesToFeed).then(function (response) {
              statisticsDataFeed(response);
              statisticsFeed();
            });
          }.bind(this);

          var statisticsFeed = function () {
            var numbersTypeFeed = null;

            filteredStatisticsDataFeed = statisticsDataFeed() || filteredStatisticsDataFeed;

            if (this.property('numbersType') === 'numbers' || this.selectedGameClient() === 'Keno') {
              numbersTypeFeed = filteredStatisticsDataFeed.mainNumbersStatistic;
            } else if (this.property('numbersType') === 'extraNumbers') {
              numbersTypeFeed = filteredStatisticsDataFeed.extraNumbersStatistic;
            }

            this.hasGameVersionConflict = numbersTypeFeed.Status === 'GameVersionConflict';
            this.leastDrawnCount = numbersTypeFeed.LeastDrawnCount;
            this.loading(false);
            this.mostDrawnCount = numbersTypeFeed.MostDrawnCount;
            this.statisticsNumbersFeed = numbersTypeFeed.drawCountDistribution;

            m.redraw();

            this.graphInit();
          }.bind(this);

          this.changeNumberType = function () {
            statisticsFeed();
          };

          this.getCompletedDrawDates = function () {
            var self = this;
            // resetting all fields when the game client changes
            self.completedDrawDatesFeed = {};
            self.completedDrawMonths = [];
            self.completedDrawYears = [];
            self.property('selectedPoints', {});
            self.property('selectedMonths', []);
            self.property('selectedYears', []);

            StatisticsModuleApi.drawDates(this.selectedGameClient()).then(function (data) {
              var dates = data.drawDates;

              if (self.selectedGameClient() === 'Eurojackpot') {
                dates = data.eurojackpot.drawDates;
              }

              dates.forEach(function (date) {
                var dateFormatted = date.split('-');

                if (!self.completedDrawDatesFeed[dateFormatted[0]]) {
                  self.completedDrawDatesFeed[dateFormatted[0]] = [];
                }

                if (self.completedDrawDatesFeed[dateFormatted[0]].indexOf(dateFormatted[1]) === -1) {
                  self.completedDrawDatesFeed[dateFormatted[0]].push(dateFormatted[1]);
                }
              });

              for (var year in self.completedDrawDatesFeed) {
                self.completedDrawYears.push(year);

                self.completedDrawDatesFeed[year].map(function (month) {
                  if (self.completedDrawMonths.indexOf(month) === -1) {
                    self.completedDrawMonths.push(month);
                  }
                });

                self.completedDrawMonths.sort(function (a, b) {
                  return a - b;
                });
              }
              m.redraw();
            });
          }.bind(this);

          this.graphInit = function () {
            var $graphViewFinder = this.$graphViewFinder();
            var $largeBarsWrapper = this.$largeBarsWrapper();

            if ($largeBarsWrapper) {
              var largeBarsWrapperWidth = $largeBarsWrapper.offsetWidth;

              this.property('largeBarsWrapperWidth', largeBarsWrapperWidth);

              if ($graphViewFinder) {
                $graphViewFinder.style.width = ((this.windowWidth / largeBarsWrapperWidth) * 100) + '%';

                this.property('graphViewFinderWidth', $graphViewFinder.offsetWidth);
              }

              this.property('viewsRatio', this.property('graphViewFinderWidth') / this.windowWidth);
              this.property('graphSlideToRight', largeBarsWrapperWidth > this.windowWidth ? true : false);

              sliderValue(1);

              graphPanAction($largeBarsWrapper);
            }

            if ($graphViewFinder) {
              graphPanAction($graphViewFinder);
            }

            setTimeout(function () {
              m.redraw();
            }, 100);
          }.bind(this);

          var sliderValue = function (deltaValue) {
            var $graphViewFinder = this.$graphViewFinder();

            if (deltaValue > 0) {
              currentPosition = 0;
              deltaValue = 0;
            } else if (deltaValue < (-this.property('largeBarsWrapperWidth') + this.windowWidth)) {
              deltaValue = -this.property('largeBarsWrapperWidth') + this.windowWidth;
              currentPosition = deltaValue;
            }

            this.property('currentPosition', deltaValue);

            if (!this.isMobile()) {
              this.$largeBarsWrapper().style.transform = 'translateX(' + (deltaValue) + 'px)';
            }

            if ($graphViewFinder) {
              $graphViewFinder.style.transform = 'translateX(' + (-deltaValue * this.property('viewsRatio')) + 'px)';
            }

            m.redraw();
          }.bind(this);

          var graphPanAction = function ($targetedGraph) {
            var hammer = new Hammer($targetedGraph);
            var movementDirection = 1;
            var self = this;

            if ($targetedGraph.className === 'zoom-finder') {
              movementDirection = -1 / this.property('viewsRatio');

              hammer.on('tap', function (event) {
                var positionAfterTap = (event.changedPointers[0].layerX - (event.changedPointers[0].target.offsetWidth / 2));
                var maxPosition = self.property('smallBarsWrapperWidth') - self.property('graphViewFinderWidth');

                if (positionAfterTap < 0) {
                  positionAfterTap = 0;
                } else if (positionAfterTap > maxPosition) {
                  positionAfterTap = maxPosition;
                }

                sliderValue(-positionAfterTap / self.property('viewsRatio'));

                m.redraw();
              });
            } else {
              hammer.on('panstart', function (event) {
                currentPosition = event.changedPointers[0].layerX - event.changedPointers[0].offsetX;
              });
            }

            hammer.on('panleft panright', function (event) {
              delta = (currentPosition + (event.deltaX * movementDirection));

              sliderValue(delta);
            });

            hammer.on('panend', function () {
              currentPosition = delta;
            });
          }.bind(this);

          this.slideGraphSlide = function (direction) {
            return function () {
              var width = this.windowWidth;

              if (direction === 'right') {
                currentPosition = (currentPosition - (width / 3));
              } else if (direction === 'left') {
                currentPosition = (currentPosition + (width / 3));
              }

              sliderValue(currentPosition);
            }.bind(this);
          };

          // Setup:
          this.getCompletedDrawDates();
          this.getStatisticsFeed();

          // Render:
          this.render().ready.then(function (nodes) {
            this.$graphViewFinder(nodes['graph-view-finder'].$element);

            if (nodes['large-bars-wrapper']) {
              this.$largeBarsWrapper(nodes['large-bars-wrapper'].$element);
            }

            if (!this.isMobile()) {
              this.property('largeBarsWrapperHeight', this.$graphViewFinder().offsetHeight);
            }
          }.bind(this));

        },
        view: function (controller) {
          if (!controller.loading()) {
            var render = controller.render();

            return m('div', { class: 'statistics-module', config: render.depend('statistics-module') }, [
              m('div', { class: 'statistics-module-wrapper ' + controller.selectedGameClient().toLowerCase() + '-statistics' }, [
                PeriodFilter(controller),
                NumbersTypeSwitch(controller),
                controller.isMobile() ? null : GraphOverview(controller),
                controller.hasGameVersionConflict ?
                  m('.statistics-module-gameversion-conflict', [
                    m('svg', { class: 'icon' }, [
                      m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#vikinglotto-calendar-white' })
                    ]),
                    m('.statistics-module-gameversion-conflict-message', m.trust(controller.d('GameVersionConflict/' + controller.selectedGameClient())))
                  ])
                  : Graph(controller)
              ]),
              controller.hasGameVersionConflict ? null : SelectedNumbers(controller)
            ]);
          }
        }
      };

      // Routes:
      route('/', root);

    });

  });
