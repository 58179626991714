defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/PrintWrapper',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (m, Render, Utils) {

    // Template:
    var PrintWrapper = function (controller, type, tab) {

      // Variables:
      var render = Render();
      var d = controller.d.bind({ prefix: { eurojackpot: 'EurojackpotSystem/', lotto: 'LottoSystem/', vikinglotto: 'VikingLottoSystem/' }[type] });
      var game = controller.game();
      var ejpv2 = type === 'eurojackpot' && game.gameVersionNo() === 2;
      var generatedRows = game.generatedRows();
      var isGameValid = game.isGameValid();
      var row = game.getRow(1);
      var numbers = row.numbers.length;

      // Functions:
      // onbeforeprint is called before the print window is opened
      // we want controller.print to be set back to false only after the print window is shown
      window.onbeforeprint = function () {
        controller.print(false);
      };

      render.ready.then(function (nodes) {
        var printContent = nodes['print-content'];
        var printContentContext = printContent.context;
        if (printContent.rendered) {
          if (!printContentContext.renderCount) printContentContext.renderCount = 0;
          printContentContext.renderCount = printContentContext.renderCount + 1;
          // IOS would open the print window multiple times but by adding the renderCount
          // to the node we can trigger it when it's rendered for the first time
          if (printContentContext.renderCount === 1) {
            window.print();
          }
        }
      });

      // View:
      return m('div', { class: 'number-games-print-wrapper' }, [
        m('div', { class: 'print-wrapper-header' }, [
          m('div', { class: 'print-wrapper-header-icon' }, [
            m('svg', { class: 'icon' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/' + { eurojackpot: 'Eurojackpot/Graphics/SpriteSheets/EurojackpotCommonIcons.svg#icon-eurojackpot_logo', lotto: 'Lotto/Graphics/SpriteSheets/LottoCommonIcons.svg#icon-lotto_logo_vindertal', vikinglotto: 'VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#icon-viking-lotto-logo' }[type] })
            ]),
            m('span', { class: 'text' }, d((tab === 'key' ? 'Key' : 'ProfitGuarantee') + 'Tab/PrintHeader'))
          ])
        ]),
        function () {
          if (controller.print && controller.print()) {
            return m('div', { config: render.depend('print-content') }, [
              m('div', { class: 'print-wrapper-subheader' }, [
                m('div', { class: 'row' }, game.systemName()),
                m('div', { class: 'row' }, Utils.formatISO8601(new Date().toISOString(), { includeTime: false, includeTimePrefix: false })),
                function () {
                  if (tab === 'key' && numbers > 0) {
                    return m('div', { class: 'row' }, [
                      d('KeyTab/PrintMyNumbers'),
                      m('span', { class: 'numbers' }, row.numbers.map(function (number) { return number.number; }).join(', ') + (type === 'eurojackpot' ? ' + ' + row.starNumbers.map(function (number) { return number.number; }).join(', ') : (type === 'vikinglotto' ? ' + ' + row.vikingNumber.number : '')))
                    ]);
                  }
                }(),
                function () {
                  if (tab === 'winnings') {
                    return m('div', { class: 'row' }, [
                      m('span', { class: 'description-text' }, d('ProfitGuaranteeTab/PrintWinningsExplanation'))
                    ]);
                  }
                }()
              ]),
              m('div', { class: 'print-wrapper-body' }, [

                // Keys:

                tab === 'key' ? [
                  function () {
                    var breakAt = 10;
                    var chunks = [];
                    var dom = [];
                    var isValidAndGenerated = (isGameValid && generatedRows.length > 0) || !controller.property('systemRows');
                    var rows = isValidAndGenerated ? game.getRowsSummary(true) : controller.property('systemRows').rows;

                    if ((type === 'eurojackpot' || type === 'vikinglotto') && isValidAndGenerated) {
                      var redone = [];

                      rows.map(function (row) {
                        redone.push(row.numbers);
                      });

                      rows = redone;
                    }

                    for (var i = 0; i < rows.length; i++) {
                      var chunkNumber = Math.floor(i / breakAt);

                      if (!chunks[chunkNumber]) {
                        chunks[chunkNumber] = [];
                      }

                      chunks[chunkNumber].push(rows[i]);
                    }

                    var emptyChunk = Array.apply(null, Array(chunks[0][0].length)).map(function () { return 0; });
                    var lastChunk = chunks[chunks.length - 1];
                    var difference = breakAt - lastChunk.length;

                    if (difference > 0) {
                      for (var k = 0; k < difference; k++) {
                        lastChunk.push(emptyChunk);
                      }
                    }

                    for (var j = 0; j < chunks.length; j++) {
                      var total = (j + 1) * breakAt;
                      var start = (total - breakAt) + 1;

                      if (total > rows.length) {
                        total = rows.length;
                      }

                      dom.push(m('div', { class: 'print-system-row-wrapper' }, [
                        m('div', { class: 'print-system-row-headline' }, d('KeyTab/PrintRow') + ' ' + start + '-' + total),
                        m('div', { class: 'print-system-row-body' }, [
                          function () {
                            var innerDom = [];

                            chunks[j].map(function (numbers) {
                              for (var q = 0; q < numbers.length; q++) {
                                if (!innerDom[q]) {
                                  innerDom.push([]);
                                }

                                var number = numbers[q];

                                innerDom[q].push(m('span', { class: 'print-system-row-cell' + (number === 0 ? ' hidden' : '') }, number));
                              }
                            });

                            return innerDom;
                          }()
                        ])
                      ]));
                    }

                    return dom;
                  }()

                  // Winnings:
                ] : [
                  function () {
                    var winningsProbabilities = controller.property('winningsProbabilities');

                    // Eurojackpot:
                    if (type === 'eurojackpot') {
                      var winningsGroups = ejpv2 ? ['5 + 2', '5 + 1', '5 + 0', '4 + 2', '4 + 1', '3 + 2', '4 + 0', '3 + 1', '2 + 2', '3 + 0', '1 + 2', '2 + 1'] : ['5 + 2', '5 + 1', '5 + 0', '4 + 2', '4 + 1', '4 + 0', '3 + 2', '3 + 1', '2 + 2', '3 + 0', '1 + 2', '2 + 1'];

                      var extract = function (column, mainNumbers, starNumbers) {
                        var value = column.rows.filter(function (row) {
                          if (row.mainNumbers == mainNumbers && row.starNumbers == starNumbers) {
                            return row.value;
                          }
                        });

                        if (value[0]) {
                          return value[0].value;
                        }

                        return '';
                      };

                      return m('table', { class: 'print-system-garanti-table' }, [
                        m('thead', { class: 'print-system-garanti-table-head' }, [
                          m('tr', [
                            m('th', d('ProfitGuaranteeTab/PrintNumberOfCrosses')),
                            m('th', d('ProfitGuaranteeTab/PrintNumberOfRows')),
                            m('th', { colspan: '2' }, d('ProfitGuaranteeTab/PrintNumberOfCorrectNumbers')),
                            m('th', { colspan: '12' }, d('ProfitGuaranteeTab/PrintNumberOfPrizesWithNumbers'))
                          ])
                        ]),
                        m('tbody', { class: 'print-system-garanti-table-body' }, [
                          m('tr', [
                            m('th', ''),
                            m('th', ''),
                            m('th', d('ProfitGuaranteeTab/PrintMainNumbers')),
                            m('th', d('ProfitGuaranteeTab/PrintStarNumbers')),
                            winningsGroups.map(function (group) {
                              return m('th', group);
                            })
                          ]),
                          winningsProbabilities.data.columns.map(function (column, index) {
                            var winningNumbers = winningsGroups[index].split(' + ');

                            return m('tr', [
                              m('th', index === 0 ? game.numbersPerRow() : null),
                              m('th', index === 0 ? game.systemName().split('-')[1] : null),
                              m('th', winningNumbers[0]),
                              m('th', winningNumbers[1]),
                              m('th', extract(column, 5, 2)),
                              m('th', extract(column, 5, 1)),
                              m('th', extract(column, 5, 0)),
                              m('th', extract(column, 4, 2)),
                              m('th', extract(column, 4, 1)),
                              m('th', extract(column, ejpv2 ? 3 : 4, ejpv2 ? 2 : 0)),
                              m('th', extract(column, ejpv2 ? 4 : 3, ejpv2 ? 0 : 2)),
                              m('th', extract(column, 3, 1)),
                              m('th', extract(column, 2, 2)),
                              m('th', extract(column, 3, 0)),
                              m('th', extract(column, 1, 2)),
                              m('th', extract(column, 2, 1))
                            ]);
                          })
                        ])
                      ]);

                    // C & R:
                    } else if (winningsProbabilities.systemType !== 'M') {
                      return winningsProbabilities.data.rows.map(function (row) {
                        return m('div', { class: 'print-system-garanti-section-wrapper' }, [
                          m('div', { class: 'print-system-garanti-section-header' }, d('ProfitGuaranteeTab/PrintCorrectNumbers', { number: row.number })),
                          row.columns.map(function (column) {
                            return m('div', { class: 'print-system-garanti-section-column' }, [
                              m('span', { class: 'print-system-garanti-section-cell' }, d('ProfitGuaranteeTab/PrintCorrectNumbers', { number: column.correct })),
                              column.values.map(function (value) {
                                return m('span', { class: 'print-system-garanti-section-cell' }, value);
                              })
                            ]);
                          }),
                          m('div', { class: 'print-system-garanti-section-column' }, [
                            m('span', { class: 'print-system-garanti-section-cell' }, d('ProfitGuaranteeTab/PrintFrequency')),
                            row.frequency.map(function (frequency) {
                              return m('span', { class: 'print-system-garanti-section-cell' }, frequency);
                            })
                          ])
                        ]);
                      });

                    // M:
                    } else {
                      var prizes = winningsProbabilities.data.prizes;

                      return m('table', { class: 'print-system-garanti-table' }, [
                        m('thead', { class: 'print-system-garanti-table-head' }, [
                          m('tr', [
                            m('th', { colspan: '2' }, d('ProfitGuaranteeTab/PrintNumberOfCorrectNumbers')),
                            m('th', { colspan: prizes.fifth ? '5' : '4' }, d('ProfitGuaranteeTab/PrintNumberOfPrizes'))
                          ])
                        ]),
                        m('tbody', { class: 'print-system-garanti-table-body' }, [
                          m('tr', [
                            m('th', d('ProfitGuaranteeTab/PrintWinningNumbers')),
                            m('th', d('ProfitGuaranteeTab/PrintAdditionalNumbers')),
                            m('th', d('ProfitGuaranteeTab/PrintFirstPrize')),
                            m('th', d('ProfitGuaranteeTab/PrintSecondPrize')),
                            m('th', d('ProfitGuaranteeTab/PrintThirdPrize')),
                            m('th', d('ProfitGuaranteeTab/PrintFourthPrize')),
                            prizes.fifth ? m('th', d('ProfitGuaranteeTab/PrintFifthPrize')) : null
                          ]),
                          winningsProbabilities.data.winningNumbers.map(function (number, index) {
                            return m('tr', [
                              m('td', number),
                              m('td', winningsProbabilities.data.additionalNumbers[index]),
                              m('td', prizes.first[index]),
                              m('td', prizes.second[index]),
                              m('td', prizes.third[index]),
                              m('td', prizes.fourth[index]),
                              prizes.fifth ? m('td', prizes.fifth[index]) : null
                            ]);
                          })
                        ])
                      ]);
                    }
                  }()

                ]
              ])
            ]);
          }
        }(),
        m('div', { class: 'print-wrapper-footer' }, [
          m('div', { class: 'footer-notes' }, [
            m('p', d((tab === 'key' ? 'Key' : 'ProfitGuarantee') + 'Tab/PrintFooter1')),
            m('p', d((tab === 'key' ? 'Key' : 'ProfitGuarantee') + 'Tab/PrintFooter2'))
          ]),
          m('div', { class: 'footer-bar' }, [
            m('svg', { class: 'icon-cube' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#danskespil-cube' })
            ]),
            m('svg', { class: 'icon-logo' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/' + { eurojackpot: 'Eurojackpot/Graphics/SpriteSheets/EurojackpotCommonIcons.svg#icon-eurojackpot_logo', lotto: 'Lotto/Graphics/SpriteSheets/LottoCommonIcons.svg#icon-lotto_logo_white', vikinglotto: 'VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#icon-viking-lotto-logo_white' }[type] })
            ])
          ])
        ])
      ]);

    };

    // Public functions:
    return PrintWrapper;

  });
