defineDs('DanskeSpil/Domain/NumberGames/Scripts/NonMithril/MultiClientTopSpot', [
  'Common/Framework/EventHandling/Scripts/Event',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
  'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
  'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
  'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
], (Event, Ensighten, ApiRequest, JackpotInfo, TimeUtils, EurojackpotInfo, LottoInfo, VikingLottoInfo) => {

  let prevDevice;

  const pushGaEvent = (label = 'Ingen tekst') => {
    const category = 'top_spot';
    const action = 'cta_click';

    Ensighten.pushGaEvent(category, action, label);
  };

  const getClosingTime = (region) => {
    if (region === 'eurojackpot') {
      return EurojackpotInfo.data()?.openDraw?.closingTime;
    } else if (region === 'lotto') {
      return LottoInfo.data()?.lottoSaturday?.openDraw?.closingTime;
    } else if (region === 'vikinglotto') {
      return VikingLottoInfo.data()?.openDraw?.closingTime;
    }
  };

  const getTimeUnit = ({ closingTime, unit, now }) => {
    const deadline = (new Date(closingTime)).getTime();
    const timeSpan = TimeUtils.getCompoundTimeObject(deadline - now);
    let timeUnit = timeSpan[unit].toString();
    if (timeUnit.length === 1) {
      timeUnit = '0' + timeUnit;
    }
    return timeUnit;
  };

  const setCountdown = ({ $topSpot, closingTime, previousCountdown }) => {
    const now = (new Date(TimeUtils.getServerDateTime())).getTime();
    const currentDays = getTimeUnit({ closingTime, unit: 'days', now });
    const currentHours = getTimeUnit({ closingTime, unit: 'hours', now });
    const currentMins = getTimeUnit({ closingTime, unit: 'mins', now });
    const currentSecs = getTimeUnit({ closingTime, unit: 'secs', now });

    if (currentDays !== previousCountdown.days) {
      previousCountdown.days = currentDays;
      $topSpot.querySelector('.js-multi-client-top-spot-days').innerText = currentDays; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    }

    if (currentHours !== previousCountdown.hours) {
      previousCountdown.hours = currentHours;
      $topSpot.querySelector('.js-multi-client-top-spot-hours').innerText = currentHours; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    }

    if (currentMins !== previousCountdown.mins) {
      previousCountdown.mins = currentMins;
      $topSpot.querySelector('.js-multi-client-top-spot-minutes').innerText = currentMins; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    }

    if (currentSecs !== previousCountdown.secs) {
      previousCountdown.secs = currentSecs;
      $topSpot.querySelector('.js-multi-client-top-spot-seconds').innerText = currentSecs; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    }
  };

  const canNotUseLayoutWithPoolSize = ({ poolSize, layout }) => !poolSize && layout === 'layout-1';

  const getDevice = () => {
    let device = 'mobile';
    if (window.matchMedia('(min-width: 1440px)').matches) {
      device = 'desktop';
    } else if (window.matchMedia('(min-width: 768px)').matches) {
      device = 'tablet';
    }
    return device;
  };

  const addLottie = async ({ region, mobileLottieSrc, tabletLottieSrc, desktopLottieSrc, lottiePoolValueReplacementString, poolSizes }) => {
    const device = getDevice();
    if (device === prevDevice) return;
    prevDevice = device;

    let src;
    const player = document.querySelector('.multi-client-top-spot__lottie lottie-player');

    if (device === 'desktop') src = desktopLottieSrc;
    if (device === 'tablet') src = tabletLottieSrc;
    if (device === 'mobile') src = mobileLottieSrc;

    if (src) {
      let strLottie = JSON.stringify(await ApiRequest({ url: src }));

      if (lottiePoolValueReplacementString) {
        const { lotto: lottoPoolSize, vikinglotto: vikinglottoPoolSize, eurojackpot: eurojackpotPoolSize } = poolSizes;
        let poolValue = '';
        if (region === 'eurojackpot') poolValue = eurojackpotPoolSize;
        if (region === 'lotto') poolValue = lottoPoolSize;
        if (region === 'vikinglotto') poolValue = vikinglottoPoolSize;
        strLottie = strLottie.replace(lottiePoolValueReplacementString, poolValue);
      }

      player.load(strLottie);
    } else {
      player.load({});
    }
  };

  const setupLottie = async () => {
    let settings = document.querySelector('.multi-client-top-spot__lottie')?.dataset.settings;
    if (settings) {
      const { region, mobileLottieSrc, tabletLottieSrc, desktopLottieSrc, lottiePoolValueReplacementString, poolSizes } = JSON.parse(settings);
      await addLottie({ region, mobileLottieSrc, tabletLottieSrc, desktopLottieSrc, lottiePoolValueReplacementString, poolSizes });
      window.addEventListener('resize', () => addLottie({ region, mobileLottieSrc, tabletLottieSrc, desktopLottieSrc, lottiePoolValueReplacementString, poolSizes }));
    }
  };

  const setupTopSpot = ({ $topSpot }) => {
    const previousCountdown = { days: 0, hours: 0, mins: 0, secs: 0 };
    let layout = $topSpot.dataset.preferredLayout;
    const region = $topSpot.dataset.region;
    const poolSize = JackpotInfo.getByGame(region)?.poolSize;
    const closingTime = getClosingTime(region);
    const $title = $topSpot.querySelector('.js-multi-client-top-spot__title'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    const titleContainsDayVariable = $title?.innerHTML?.toString().includes('{day}');

    if (titleContainsDayVariable) {
      let titleText = $title.innerHTML.toString();

      if (closingTime) {
        const dayDate = new Date(closingTime);
        const drawDay = dayDate.toLocaleString('da-dk', { weekday: 'long' });
        titleText = titleText.replace('{day}', drawDay);
      } else {
        titleText = '';
      }
      $title.innerHTML = titleText;
    }

    if (canNotUseLayoutWithPoolSize({ poolSize, layout })) {
      layout = 'layout-2';
      $topSpot.classList.remove('multi-client-top-spot--layout-1');
      $topSpot.classList.add('multi-client-top-spot--layout-2');
    }

    if (layout === 'layout-1') {
      document.querySelector('.js-multi-client-top-spot-pool-size').innerText = `${poolSize} mio`;
    }

    setCountdown({ $topSpot, closingTime, previousCountdown });
    setInterval(() => setCountdown({ $topSpot, closingTime, previousCountdown }), 200);

    $topSpot.querySelector('.multi-client-top-spot__cta').addEventListener('click', (ev) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      pushGaEvent(ev.target.innerHTML);
      Event.fire('ds.event.multiclient:topspot:buy', {
        region,
        rows: Number($topSpot.dataset.numberOfRowsToPurchase),
      });
    });

    setupLottie();
  };

  const initialize = () => {
    if (document.querySelector('.mode-edit')) return;

    Promise.all([
      JackpotInfo.ready,
      EurojackpotInfo.ready,
      LottoInfo.ready,
      VikingLottoInfo.ready,
    ]).then(() => {
      document.querySelectorAll('.js-multi-client-top-spot').forEach(($topSpot) => {
        setupTopSpot({ $topSpot });
      });
    });

  };

  initialize();

});
