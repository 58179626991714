defineDs('DanskeSpil/Domain/NumberGames/Scripts/NewsView', ['Shared/Framework/Mithril/Scripts/Helpers/Utils'], function (Utils) {

  var newsUrl = window.location.href;

  var getOptions = function () {
    var width = 575;
    var height = 400;
    var left = (window.innerHeight - width) / 2;
    var top = (window.innerHeight - height) / 2;
    return 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
  };

  if (document.getElementsByClassName('twitter-share')[0]) {
    document.getElementsByClassName('twitter-share')[0].addEventListener('click', function (e) {
      e.preventDefault();
      Utils.logDeprecated('NewsView.js - .twitter-share exist'); // Added by MIPE  (30/4-2024)
      var url = 'http://twitter.com/share?text=' + newsUrl;
      window.open(url, 'twitter', getOptions());
      return false;
    });
  }

  if (document.getElementsByClassName('facebook-share')[0]) {
    document.getElementsByClassName('facebook-share')[0].addEventListener('click', function (e) {
      e.preventDefault();
      Utils.logDeprecated('NewsView.js - .facebook exist'); // Added by MIPE  (30/4-2024)
      var url = 'https://www.facebook.com/sharer/sharer.php?u=' + newsUrl;
      window.open(url, 'facebook', getOptions());
      return false;
    });
  }

});
