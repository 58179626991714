defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/VikingLottoLightning',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoDictionary',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
    'DanskeSpil/Domain/VikingLotto/Scripts/Models/VikingLottoGame',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/PurchaseBar',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoLightning/RotarySelector',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/JokerOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/SubscriptionValidationOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay'
  ],
  function (Component, Params, VikingLottoDictionary, VikingLottoInfo, VikingLottoGame, PurchaseBar, RotarySelector, ErrorOverlay, JokerOverlay, SubscriptionValidationOverlay, Overlay) {

    // Component:
    Component('vikinglotto-lightning', [VikingLottoInfo, VikingLottoDictionary], function (m, route, settings, property) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.d = VikingLottoDictionary.get.bind();
          this.isFeedDown = m.prop(VikingLottoInfo.isFeedDown());
          this.property = property;

          if (!this.isFeedDown()) {
            this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
            this.game = m.prop(VikingLottoGame.setupGame({ gameInstanceId: this.gameInstanceId(), playType: 'Lightning', rowsToGenerate: VikingLottoInfo.getDefaultRowCount() }));
            this.errorOverlay = m.prop(new Overlay(ErrorOverlay('vikinglotto-lightning-game-overlay', { header: this.d('GenerateRowsErrorOverlay/Header'), body: this.d('GenerateRowsErrorOverlay/Body'), dismiss: this.d('GenerateRowsErrorOverlay/Dismiss') })));
            this.jokerOverlay = m.prop(new Overlay(JokerOverlay(this.game(), 'vikinglotto-lightning-game-overlay', this.d, VikingLottoInfo.jokerData())));
            this.subscriptionValidationOverlay = m.prop(new Overlay(SubscriptionValidationOverlay('vikinglotto-lightning-game-overlay subscription-validation-overlay', { header: this.d('SubscriptionValidationOverlay/Header'), body: this.d('SubscriptionValidationOverlay/Body'), dismiss: this.d('SubscriptionValidationOverlay/Dismiss') })));
            this.rows = this.game().rowsToGenerate;
            this.rowsOptions = m.prop(VikingLottoInfo.getLightningRowOptions());

            // Functions:
            this.purchase = function () {
              var game = this.game();
              var self = this;

              // Abort purchase and show subscription validation overlay, if subscription is chosen but not valid
              if (game.numberOfDraws() === 0 && !game.isSubscriptionValid()) {
                this.subscriptionValidationOverlay().show();
                return;
              }

              this.jokerOverlay().show(function () {
                game.prepareConfirm().then(function (confirmUrl) {
                  if (game.numberOfDraws() === 0) {
                    game.sendToSubscription();
                  } else {
                    location.href = confirmUrl;
                  }
                }, function () {
                  self.errorOverlay().show();
                });
              });
            }.bind(this);
          }
        },

        view: function (controller) {
          return m('div', { class: 'vikinglotto-section vikinglotto-lightning purchase-bar-container' }, [
            m('div', { class: 'vikinglotto-content-wrapper' }, [
              !VikingLottoInfo.noOpenDraw() ? [
                m('h2', { class: 'headline' }, controller.d('VikingLottoLightning/Headline')),
                m('h4', { class: 'subheadline' }, controller.d('VikingLottoLightning/ChooseRows')),
                RotarySelector(controller.rowsOptions, controller.rows, controller.d('VikingLottoLightning/Row' + (controller.rows() !== 1 ? 's' : ''))),
                PurchaseBar(controller)
              ] : [
                // Feed down or no open draw, content
                m('div', { class: 'game-not-available-wrapper' }, [
                  // ICON
                  settings.iconUrl.length > 0 ? m('img', { class: 'not-available-icon', src: settings.iconUrl }) : '',
                  !controller.isFeedDown() ? [
                    m('h2', { class: 'headline' }, controller.d('VikingLottoClosed/FrontpageLightning/NoOpenDrawHeader')),
                    m('h3', { class: 'subheadline' }, controller.d('VikingLottoClosed/FrontpageLightning/NoOpenDrawText_1')),
                    m('h3', { class: 'subheadline' }, controller.d('VikingLottoClosed/FrontpageLightning/NoOpenDrawText_2'))
                  ] : [
                    m('h2', { class: 'headline' }, controller.d('VikingLottoClosed/FrontpageLightning/GameNotAvailableHeader')),
                    m('h3', { class: 'subheadline' }, controller.d('VikingLottoClosed/FrontpageLightning/GameNotAvailableText_1')),
                    m('h3', { class: 'subheadline' }, controller.d('VikingLottoClosed/FrontpageLightning/GameNotAvailableText_2'))
                  ]
                ])
              ]
            ])
          ]);
        }
      };

      // Routes:
      route('/', root);

    });

  });

