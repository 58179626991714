defineDs('DanskeSpil/Domain/NumberGames/Scripts/Helpers/CouponHelpers',
  [
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/CampaignEngineApi'
  ],
  function (CampaignEngineApi) {
    // Functions:
    const findCampaignFromCampaignEngine = (campaignNumbers) => {
      return new Promise((resolve, reject) => {
        CampaignEngineApi.getAllCampaigns()
          .then((allCampaignsFromCampaignEngine) => {
            for (let i = 0; i < campaignNumbers.length; i++) {
              const campaignNumber = campaignNumbers[i];
              const campaignFromCampaignEngine = allCampaignsFromCampaignEngine.find((campaign) => {
                return campaign.campaignNumbers.includes(campaignNumber);
              });

              if (campaignFromCampaignEngine &&
                  !campaignFromCampaignEngine.campaignType?.toLowerCase().includes('extra')) {
                campaignFromCampaignEngine.couponCampaignNumber = campaignNumber;
                resolve(campaignFromCampaignEngine);
                break;
              }

              if (i + 1 === campaignNumbers.length) {
                resolve(null);
              }
            }
          })
          .catch(() => reject());
      });
    };
    return {
      findCampaignFromCampaignEngine
    };
  });
