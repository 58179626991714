defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/WinningNumbersLatest', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/WinningNumbersUtils',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/NumberGamesDictionary',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/WinningNumbers/DrawnBalls',
], function (Component, Utils, WinningNumbersUtils, Dictionary, DrawnBalls) {
  Component('winning-numbers-latest', [Dictionary], function (m, route, { textNewWinner, textNewWinners, textNewMillionaire, textNewMillionaires, gameType, ctaText, ctaUrl }, property) {

    const root = {
      controller: function () {
        const d = Dictionary.get.bind({ prefix: 'Components/WinningNumbersLatest/' });
        const completedDrawObjects = m.prop();
        const completedDrawsDates = m.prop([]);
        const selectedDrawDate = m.prop();
        const gameTypeSelected = m.prop(gameType);
        const winningNumbers = m.prop([]);
        const winningBonusNumbers = m.prop([]);
        const newMillionaires = m.prop(0);
        const newWinners = m.prop(0);

        const updateNumbers = ({ numbers, bonusNumbers, prizes }) => {
          if (numbers) winningNumbers(numbers);
          if (bonusNumbers) winningBonusNumbers(bonusNumbers);

          if (prizes) {
            const millionPrizeWinners = prizes.reduce((prev, curr) => {
              if (curr.amount >= 1000000) prev += curr.numberOfWinners;
              return prev;
            }, 0);
            newMillionaires(millionPrizeWinners);
          }

          if (!newMillionaires()) {
            const winners = prizes.reduce((prev, curr) => {
              if (curr.amount > 0) prev += curr.numberOfWinners;
              return prev;
            }, 0);
            newWinners(winners);
          }

          m.redraw();
        };

        const getWinningNumbers = (date) => {
          if (gameType === 'lotto') {
            WinningNumbersUtils.getLottoWinningNumbers(date).then(updateNumbers);
          }

          if (gameType === 'vikinglotto') {
            WinningNumbersUtils.getVikinglottoWinningNumbers(date).then(updateNumbers);
          }

          if (gameType === 'eurojackpot') {
            WinningNumbersUtils.getEurojackpotWinningNumbers(date, completedDrawObjects).then(updateNumbers);
          }
        };

        const selectDrawDate = (date) => {
          selectedDrawDate(date);
          getWinningNumbers(date);
          m.redraw();
        };

        const initialize = () => {
          if (gameType === 'lotto') {
            WinningNumbersUtils.getLottoDrawDates().then((drawDates) => {
              completedDrawsDates(drawDates);
              selectDrawDate(drawDates[0]);
            });
          }

          if (gameType === 'vikinglotto') {
            WinningNumbersUtils.getVikinglottoDrawDates().then((drawDates) => {
              completedDrawsDates(drawDates);
              selectDrawDate(drawDates[0]);
            });
          }

          if (gameType === 'eurojackpot') {
            WinningNumbersUtils.getEurojackpotDrawDates().then(({ eurojackpot }) => {
              completedDrawObjects({ eurojackpot });
              completedDrawsDates(eurojackpot.drawDates);
              selectDrawDate(eurojackpot.drawDates[0]);
            });
          }
        };

        const getFunText = () => {
          let text;
          if (newMillionaires() === 1) {
            text = textNewMillionaire;
          } else if (newMillionaires() > 1) {
            text = textNewMillionaires.replace('{numberOfWinners}', Utils.formatNumber(newMillionaires()));
          } else if (newWinners() === 1) {
            text = textNewWinner;
          } else if (newWinners() > 1) {
            text = textNewWinners.replace('{numberOfWinners}', Utils.formatNumber(newWinners()));
          }

          if (text) {
            return m('.winning-numbers-latest__fun-text', m.trust(text));
          }
        };

        initialize();

        return {
          d,
          ctaText,
          ctaUrl,
          getFunText,
          gameType,
          gameTypeSelected,
          property,
          selectedDrawDate,
          winningNumbers,
          winningBonusNumbers,
        };
      },

      view: (
        {
          d,
          ctaText,
          ctaUrl,
          getFunText,
          gameType,
          gameTypeSelected,
          property,
          selectedDrawDate,
          winningNumbers,
          winningBonusNumbers,
        }
      ) => {

        return m('.winning-numbers-latest', { class: `winning-numbers-latest--${gameType}` }, [
          m('.winning-numbers-latest__logo'),

          m('.winning-numbers-latest__draw-date', `d. ${Utils.formatDateDayMonthYear(selectedDrawDate())}`),

          getFunText(),

          DrawnBalls({
            gameType,
            numbers: winningNumbers(),
            label: d('Balls/WinningNumbers'),
            bonusNumbers: winningBonusNumbers(),
            bonusLabel: d(`Balls/BonusNumbers/${gameType}`),
            property,
            gameTypeSelected,
            selectedDrawDate,
          }),

          ctaText && ctaUrl
            ? m('.winning-numbers-latest__cta-wrapper', m('a.winning-numbers-latest__cta', {
              href: ctaUrl
            }, ctaText))
            : '',

        ]);
      }

    };

    route('/', root);
  });
});
