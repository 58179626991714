defineDs('DanskeSpil/Domain/NumberGames/Scripts/HowToPlayPopup', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Project/Design/Js/Global/HashChange',
  'DanskeSpil/Domain/NumberGames/Scripts/HowToPlayContent'
],
function (Component, HashChange, HowToPlayContent) {

  // Component:
  Component('how-to-play-popup', function (m, route, settings, property) {

    // Components:
    var root = {
      controller: function () {

        // Variables:
        var hash = 'how-to-play';
        var youtube = [];
        var youtubeIds = [];
        var vimeo = [];
        var vimeoIds = [];
        var slideshare = [];
        var slideshareIds = [];
        var requests = [];
        var $body = document.body;
        var $html = document.documentElement;
        var $popup = document.querySelector('.how-to-play-popup');

        this.property = property;

        if (!this.property('activated') && !this.property('loading')) {
          this.property('activated', false);
          this.property('loading', true);
        }

        // Functions:
        var popupOpen = function () {
          document.querySelector('.how-to-play-popup').classList.add('show');

          let bodyLockedEventTriggered = false;
          $popup.addEventListener('transitionend', () => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            if (!bodyLockedEventTriggered) {
              $body.classList.add('lock'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
              $html.classList.add('lock'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            }
            bodyLockedEventTriggered = true;
          });

          if (this.property('loading') && !this.property('activated')) {
            this.property('activated', true);

            settings.contents.forEach(function (value, index) {
              var id = value.contentId;

              value.html = '';
              value.order = index;
              value.playing = false;
              value.ready = false;

              // YouTube:
              if (value.provider === 'youtube' && youtubeIds.indexOf(id) === -1) {
                youtubeIds.push(id);
                youtube.push(value);

                // Vimeo:
              } else if (value.provider === 'vimeo' && vimeoIds.indexOf(id) === -1) {
                vimeo.push(value);
                vimeoIds.push(id);

                // SlideShare:
              } else if (value.provider === 'slideshare' && slideshareIds.indexOf(id) === -1) {
                var requestUrl = value.url + value.contentId + '&format=jsonp';

                requests.push(m.request({
                  dataType: 'jsonp',
                  url: requestUrl,
                  callbackKey: 'callback',
                  config: function (xhr) {
                    xhr.setRequestHeader('Content-Type', 'application/javascript');
                  }
                }).then(function (response) {
                  value.html = response.html;

                  slideshareIds.push(id);
                  slideshare.push(value);
                }, function (error) {
                  console.error('Error', error);
                }));
              }
            });

            m.sync(requests).then(function () {
              this.property('howToPlayData', {
                youtube: { ready: false, contents: youtube },
                vimeo: { ready: false, contents: vimeo },
                slideshare: { ready: false, contents: slideshare }
              });

              this.property('loading', false);

              m.redraw();
            }.bind(this));
          }

          m.redraw();
        }.bind(this);

        this.popupClose = function () {
          location.hash = '';

          document.querySelector('.how-to-play-popup').classList.remove('show');

          $body.classList.remove('lock');
          $html.classList.remove('lock');
        }.bind(this);

        this.getFrame = function (content, render) {
          if (content.provider === 'vimeo' || content.provider === 'youtube') {
            return m('iframe', {
              config: render.depend(content.provider + '-iframe-' + content.contentId),
              src: content.url,
              frameborder: '0',
              allowfullscreen: '0',
              id: content.contentId
            });
          } else if (content.provider === 'slideshare') {
            return m.trust(content.html);
          }
        };

        // Context:
        if (location.hash === '#' + hash) {
          popupOpen();
        }

        document.querySelector('[href="#' + hash + '"]')?.addEventListener('click', function (event) {
          event.preventDefault();
          popupOpen();  // To do: Fix this properly so it triggers on hash change
          history.pushState(null, null, '#' + hash);
        });

        HashChange.match(hash, function () {
          popupOpen();
        });

      },

      // View:
      view: function (controller) {
        return m('div', { class: 'how-to-play-section' }, [
          m('div', { class: 'how-to-play-content-wrapper' }, [
            m('div', { class: 'close-button-wrapper' }, [
              m('a', { class: 'btn close help-link', onclick: controller.popupClose }),
            ]),
            controller.property('activated') && !controller.property('loading') ? HowToPlayContent(controller, settings) : null
          ])
        ]);
      }
    };

    // Routes:
    route('/', root);

  });

});
