
defineDs('DanskeSpil/Framework/NumberGames/Scripts/Templates/RotateDeviceOverlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (m, Utils) {

    function setupEventListeners() {

      document.addEventListener('DOMContentLoaded', function () {

        document.body.addEventListener('click', function (event) {

          // If we clicked on an input field, add class to body, to prevent the RotateDeviceOverlay from showing
          // this is an issue on Android where the regular show media query is triggered when the keyboard is visible on Android devices
          if (event.target.matches('input[type=text]')) {
            document.body.classList.add('input-has-focus');
          } else {
            // We did not click on an input, so after a delay, where the android keyboard has time to close, we remove the class again
            setTimeout(function () {
              document.body.classList.remove('input-has-focus');
            }, 200);

          }
        });

      });

    }

    // Mobile check:
    if (Utils.isMobile()) {

      setupEventListeners();

      // Variables:
      var requireDictionary = 'DanskeSpil/Domain/';
      var url = location.pathname.toLowerCase();
      var isEurojackpot = /\/eurojackpot/.test(url);
      var isKeno = /\/keno/.test(url);
      var isVikingLotto = /\/vikinglotto/.test(url);

      if (isEurojackpot) {
        requireDictionary += 'Eurojackpot/Scripts/Helpers/EurojackpotDictionary';
      } else if (isKeno) {
        requireDictionary += 'Keno/Scripts/Helpers/KenoDictionary';
      } else if (isVikingLotto) {
        requireDictionary += 'VikingLotto/Scripts/Helpers/VikingLottoDictionary';
      } else {
        requireDictionary += 'Lotto/Scripts/Helpers/LottoDictionary';
      }

      // Require:
      requireDs(requireDictionary, function (Dictionary) {

        Dictionary.ready.then(function () {

          var dictionary = Dictionary.get;

          // Overlay:
          var overlay = m('div', { class: 'orientation-screen' }, [
            m('div', { class: 'orientation-screen-inner' }, [
              m('svg.orientation-screen-icon[id="orientation-screen-icon"][viewBox="0 0 400 400"][x="0px"][y="0px"]', { style: { 'enable-background': 'new 0 0 347 268' } }, [
                m('g.phone', [
                  m('path.phone-frame[d="m350.2,91.75l-298.3,0c-13.7,0 -24.9,11 -24.9,24.6l0,145.7c0,13.6 11.2,24.6 24.9,24.6l298.3,0c13.8,0 24.9,-11 24.9,-24.6l0,-145.7c0,-13.6 -11.2,-24.6 -24.9,-24.6zm-287.2,116.5c0,4.3 -3.7,7.9 -8.1,7.9c-4.4,0 -7.9,-3.5 -7.9,-7.9l0,-37.8c0,-4.3 3.6,-7.9 7.9,-7.9c4.4,0 8.1,3.5 8.1,7.9l0,37.8zm256.1,37.6c0,7.8 -6.4,14.2 -14.3,14.2l-207.7,0c-7.9,0 -14.3,-6.3 -14.3,-14.2l0,-112.8c0,-7.8 6.4,-14.2 14.3,-14.2l207.6,0c7.9,0 14.3,6.3 14.3,14.2l0,112.8l0.1,0zm26.8,-40.6c-8.9,0 -16.1,-7.1 -16.1,-15.9c0,-8.8 7.2,-15.9 16.1,-15.9c8.9,0 16.1,7.1 16.1,15.9c0,8.8 -7.2,15.9 -16.1,15.9z"]'),
                  m('g.lines', [
                    m('rect.line1[fill="#ffffff"][height="24"][stroke="null"][stroke-opacity="null"][stroke-width="null"][width="100"][x="149"][y="172"]'),
                    m('rect.line2[fill="#ffffff"][height="24"][stroke="null"][stroke-opacity="null"][stroke-width="null"][width="100"][x="149"][y="172"]')
                  ])
                ]),
                m('path.shadow[d="m201.050003,331.8c95.8,0 173.5,6.3 173.5,14.1s-77.7,14.1 -173.5,14.1s-173.5,-6.3 -173.5,-14.1s77.7,-14.1 173.5,-14.1z"]')
              ]),
              m('span', { class: 'orientation-screen-message' }, [
                dictionary('RotateScreen/Top'),
                m('hr'),
                dictionary('RotateScreen/Bottom')
              ]),
              m('div', { class: 'orientation-screen-logo' })
            ])
          ]);

          // Render:
          var $overlay = document.createElement('div');

          m.render($overlay, overlay);

          document.body.appendChild($overlay);

        });

      });

    }

  });
