defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoLucky/InfoTab',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Template:
    var InfoTab = function (controller) {

      // Variables:
      var d = controller.d;
      var game = controller.game();
      var system = game.systemName();
      var rows = game.rowsToGenerate(); // Rows to generate for VikingLottoLucky
      var numbers = game.getSystemNumberAmount();

      // View:
      return [m('div', { class: 'inner-wrapper' }, [
        m('h2', { class: 'tab-headline' }, d('VikingLottoLucky/InfoTab/Header', { rows: rows })),
        m('p', d('VikingLottoLucky/InfoTab/SystemDescription')),
        m('p', d('VikingLottoLucky/InfoTab/GameDescription', {
          system: system,
          numbers: numbers,
          rows: rows
        }))
      ])];
    };

    // Public functions:
    return InfoTab;

  });
