defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/DeleteRowModal',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/Shared/Modal'
  ],
  // eslint-disable-next-line no-unused-vars
  function (m, Modal) {
    return function ({ controller, row, useCompactView }) {
      Object.assign(this, controller);
      this.modal = new Modal({
        $parent: document.body,
        extraClassNames: 'theme-numbergames'
      });

      const deleteRow = (rowCount) => {
        return () => {
          if (useCompactView) {
            this.currentRow(null);
          } else {
            this.currentRow(rowCount || 1);
          }
          const cleanRows = this.gameInstance().rows().filter((r) => r.count !== rowCount);
          for (let i = 0; i < cleanRows.length; i++) {
            cleanRows[i].count = i;
          }
          this.gameInstance().rows(cleanRows);
          this.gameInstance().save();
          this.modal.close();
          this.showExtraOptions(false);
        };
      };

      const content = (
        <div data-uitest-id='delete-row-modal' className='md:p-16'>
          <div className='text-5xl text font-bold uppercase'>{controller.labelDictionary('classicNumbersPickerRemoveRowModalHeading')}</div>
          <div className='text-3xl mt-12 whitespace-pre-line'>{controller.labelDictionary('classicNumbersPickerRemoveRowModalText')}</div>
          <div className='flex gap-16 mt-32'>
            <div
              data-uitest-id='remove-row-confirm-button'
              className='text-14 leading-none bg-yellow-1 font-bold uppercase py-20 px-24 rounded-16 cursor-pointer'
              onclick={deleteRow(row.count)}
            >{controller.labelDictionary('classicNumbersPickerRemoveRowModalConfirmButton')}</div>
            <div
              data-uitest-id='remove-row-cancel-button'
              className='text-14 leading-none font-bold uppercase py-20 px-24 rounded-16 border-solid border cursor-pointer'
              onclick={() => {
                this.modal.close();
              }}
            >{controller.labelDictionary('classicNumbersPickerRemoveRowModalCancelButton')}</div>
          </div>
        </div>
      );

      return {
        show: () => {
          this.modal.setContent(content);
          this.modal.show();
        }
      };
    };
  }
);