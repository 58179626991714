defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PurchaseFlow/Confirm', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Confirm',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/DateUtils',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/SimpleCubeLoader',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PurchaseFlow/Confirm/GameCouponItem'
], function (m, Ensighten, DataLayer, ConfirmHelper, AccountIntegration, DateUtils, SimpleCubeLoader, GameCouponItem) {
  // Component:
  var Confirm = {
    controller: function ({ controller }) {
      Object.assign(this, controller);

      this.confirmDictionary = this.dictionary.bind({ prefix: 'MultiPackages/Confirm/' });
      this.headlineText(this.confirmDictionary('Heading', { packageName: this.packageModel()?.information().description }));
      this.checkBalance = m.prop(true);
      this.askForDeposit = m.prop(false);
      this.showBuyAsSubscriptionButtonOnConfirm = this.settings.buyAsSubscriptionOnConfirm;

      const now = new Date();
      const nowDate = DateUtils.parseISO8601(now.toISOString());
      this.basketLabel = `${nowDate.date}/${nowDate.month} ${nowDate.year}`;
      ConfirmHelper.checkAuthAndBalance(this.packageModel).then(() => {
        this.askForDeposit(false);
        this.checkBalance(false);
        m.redraw();
      }, (error) => {
        if (error === 'ASK_FOR_DEPOSIT') {
          this.askForDeposit(true);

          // Add ensighten virtualPage event:
          if (this.packageModel()) {
            Ensighten.pushVirtualPage('tank_op', DataLayer.categoryName(this.packageModel().description) + '/tank_op', 'notEnoughCreditPage');
          }
        }

        this.checkBalance(false);
        m.redraw();
      });

      this.buyCoupon = () => {
        if (this.processingPurchase()) return;

        this.processingPurchase('buyCoupon');
        this.packageModel().purchase().then(() => {
          AccountIntegration.triggerBalanceUpdate();
          this.processingPurchase(false);
          this.packageModel().purchasePackageComplete();
          m.redraw();
        }, (data) => {
          ConfirmHelper.handlePurchaseError(this.packageModel, (data && data.errorMessage) || null, this.confirmErrorOverlay);
          this.processingPurchase(false);
          m.redraw();
        });
      };

      this.buyAsSubscription = () => {
        if (this.processingPurchase()) return;
        this.showSubscriptionOptionsModal();
      };

      // Deposit flow:
      this.openDeposit = () => {
        AccountIntegration.openDeposit(this.packageModel().totalPrice(), () => {
          location.href = this.packageModel().cancelUrl();
        });
      };

      // Push products to datalayer
      this.packageModel().addPackageToCart();
    },

    view: function (controller) {
      const buttonState = (buttonName) => {
        if (controller.processingPurchase()) {
          if (controller.processingPurchase() === buttonName) {
            return 'btn--loading';
          } else {
            return 'btn--disabled';
          }
        }
      };

      if (controller.checkBalance()) {
        return m('.multi-packages-purchase-flow__loader',
          m('.multi-packages-purchase-flow__loader-inner', SimpleCubeLoader())
        );
      }

      if (controller.askForDeposit()) {
        return m('.multi-packages-purchase-flow__inner-section', [
          m('.multi-packages-purchase-flow__inner-section-heading', controller.confirmDictionary('AskForDeposit/Headline')),
          m('.multi-packages-purchase-flow__inner-section-text', controller.confirmDictionary('AskForDeposit/Text')),
          m('a.multi-packages-button', { onclick: controller.openDeposit }, controller.confirmDictionary('AskForDeposit/DepositButton'))
        ]);
      }

      return m('.multi-packages-confirm__content', [
        controller.property('purchaseFlow:onGoBack') ? m('.multi-packages-confirm__content__back-button', {
          onclick: controller.property('purchaseFlow:onGoBack')
        }, m('svg.multi-packages-confirm__content__back-button-icon',
          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#back-icon' })
        ),
        ) : null,
        m('.multi-packages-confirm__packages-wrapper', controller.packageModel().information().coupons.map((coupon) => {
          if (coupon.gameType === 'EuroJackpot') {
            return [
              m(GameCouponItem, { controller, coupon, drawIndex: 0 }),
              m(GameCouponItem, { controller, coupon, drawIndex: 1 })
            ];
          }

          return m(GameCouponItem, { controller, coupon });
        })
        ),
        m('.multi-packages-confirm__basket', m('.multi-packages-confirm__basket-inner',
          m('.multi-packages-confirm__basket-overview', [
            m('.multi-packages-confirm__basket-item', [
              m('svg.multi-packages-confirm__basket-item-icon', m('use', {
                href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#coupon-calendar'
              })),
              m('.multi-packages-confirm__basket-item-label', controller.confirmDictionary('Basket/DateLabel')),
              m('.multi-packages-confirm__basket-item-value', controller.basketLabel),
            ])
          ]),
          m('.multi-packages-confirm__basket-buttons', [
            m('.multi-packages-button', {
              class: buttonState('buyCoupon'),
              onclick: controller.buyCoupon
            }, controller.confirmDictionary('Basket/BuyButton', { price: controller.packageModel().information().price })
            ),
            controller.showBuyAsSubscriptionButtonOnConfirm ? m('.multi-packages-confirm__basket-subscription-option', [
              controller.settings.buyAsSubscriptionPlusIcon ? m('img.multi-packages-confirm__basket-subscription-icon', {
                src: controller.settings.buyAsSubscriptionPlusIcon
              }) : null,
              m('.multi-packages-confirm__basket-subscription-description', controller.confirmDictionary('Basket/BuyAsSubscriptionDescription')),
              m('.multi-packages-button.multi-packages-button--outlined', {
                class: buttonState(),
                onclick: controller.buyAsSubscription
              }, controller.confirmDictionary('Basket/BuyAsSubscriptionButton')
              )
            ]
            ) : null
          ])
        ))
      ]);
    }
  };

  return Confirm;
});
