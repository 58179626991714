defineDs('DanskeSpil/Domain/NumberGames/Scripts/NonMithril/CarouselSpot', [
  'Shared/Framework/Mithril/Scripts/Helpers/Hammer'
], function (Hammer) {

  // Return if no carouselspot in DOM
  const $carousel = document.querySelector('.carousel');
  if (!$carousel) {
    return;
  }

  if (window.sitecoreEnv) {
    return;
  }

  // Utils.logDeprecated('Domain/NumberGames/Scripts/NonMithril/CarouselSpot.js - .carousel'); // Used on /eurojackpot/pakke-kvittering (12/9-2022)

  let settings = $carousel.getAttribute('data-settings') || {}; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
  if (typeof settings === 'string') {
    settings = JSON.parse(settings);
  }

  var show;
  var started = false;
  var SlideShowConstructor = function (el) {


    var slideShow = {

      // vars
      el: el,
      timer: undefined,
      animFreq: 10,
      slideWidth: undefined,
      slideCount: 0,
      slideMargin: 0,
      currentSlide: 0,

      initialize: function () {
        var slides = el.children;
        // animation frequency

        this.animFreq = settings.animationInterval != -1 ? settings.animationInterval * 1000 : 2000;

        // slide width including margin
        var slideStyle = slides[0].currentStyle || window.getComputedStyle(slides[0]);

        this.slideWidth = slides[0].offsetWidth + parseInt(slideStyle.marginRight, 10);
        this.slideMargin = parseInt(slideStyle.marginRight, 10);

        // slide amount found inside ´el´
        this.slideCount = slides.length;
        // set slide track width
        // this.el.style = 'width: ' + (parseInt(this.slideCount * this.slideWidth, 10) + 10) + 'px';
        // remove listener for anchors

        Array.prototype.forEach.call(slides, function (el) {
          // el.childNodes[1].childNodes[0].addEventListener('click', function (e) {
          el.addEventListener('click', function (e) {
            e.preventDefault();
            return false;
          });
        });

        this._createNav();
        // this._setStartPos();
      },

      // start slideshow
      start: function () {
        this.stop();
        this.timer = setInterval(this.changeSlide.bind(this), this.animFreq);
      },

      // stop slideshow
      stop: function () {
        clearInterval(this.timer);
      },

      // change to slide fn(x)
      changeSlide: function (slide, e) {
        this.currentSlide = slide != undefined ? slide : this._getNextSlide();
        this._animateSlide(this.currentSlide * this.slideWidth, e);
      },

      // center the first slide
      // _setStartPos: function () {
      // var leftOffset = (window.innerWidth - (this.slideWidth - this.slideMargin)) / 2;
      // el.style.left = leftOffset + 'px';
      // },

      // return incremented slide or
      // ´0´ if we've reach the end
      _getNextSlide: function () {
        return this.currentSlide < this.slideCount - 1 ? this.currentSlide + 1 : 0;
      },

      // translate used as animation
      _animateSlide: function (x) {
        el.className += ' animate';
        el.style.transform = 'translateX(-' + x + 'px)';

        this.updateUI(this.currentSlide);
        this.animTime = setTimeout(function () {
          el.className = el.className.replace('animate', '').trim();
        }, 300);

      },

      updateUI: function (index) {
        var nodes = this.nav.childNodes;
        if (nodes[this.currentSlide]) {

          for (var i = 0; i < nodes.length; i++) {
            nodes[i].className = nodes[i].className.replace('active', '').trim();
          }
          nodes[index].className += ' active';
        }
      },

      _createNav: function () {
        this.nav = document.createElement('div');
        this.nav.className = 'carousel-nav';

        for (var i = 0; i < this.slideCount; i++) {
          var span = document.createElement('span');
          span.setAttribute('data-slide', i);
          if (i === 0) span.className += ' active';
          this.nav.appendChild(span);
        }
        this.el.parentNode.appendChild(this.nav);

        this._createNavListeners();
      },

      // hook click listener to nav
      _createNavListeners: function () {
        var els = el.parentElement.getElementsByClassName('carousel-nav')[0];
        var self = this;

        els.addEventListener('click', function (e) {
          self._clickSlideNav(e);
        });
      },

      // change slide based on ´data-slide´ value
      _clickSlideNav: function (e) {
        this.stop();
        this.changeSlide(parseInt(e.target.getAttribute('data-slide'), 10), e);
        this.start();
      }
    };

    return slideShow;

  };


  function HammerCarousel(slideshow) {
    this.slideshow = slideshow || {};
    this.container = slideshow.el;
    this.slideWidth = slideshow.slideWidth;

    this.hammer = new Hammer.Manager(this.container, {
      // domEvents: true,
      recognizers: [
        [Hammer.Tap],
        [Hammer.Pan]
      ]
    });
    this.hammer.on('tap panstart panmove panend pancancel', Hammer.bindFn(this.onPan, this));
    this.show(this.slideshow.currentSlide);
  }

  HammerCarousel.prototype = {

    show: function (showIndex, percent, animate) {
      var className = this.container.className;
      if (animate) {
        if (className.indexOf('animate') === -1) {
          this.container.className += ' animate';
        }
        this.slideshow.stop();
        this.slideshow.start();
        this.slideshow.updateUI(this.slideshow.currentSlide);
      } else {
        if (className.indexOf('animate') !== -1) {
          this.container.className = this.container.className.replace('animate', '').trim();
        }
      }

      var pos = (this.slideWidth / 100) * (((showIndex) * 100) + percent);
      if (pos > 2 * this.slideWidth) {
        return false;
      }

      var translate = 'translate(-' + pos + 'px)';

      this.container.style.transform = translate;
      this.container.style.mozTransform = translate;
      this.container.style.webkitTransform = translate;

      this.slideshow.currentSlide = showIndex;
    },

    onPan: function (ev) {

      var delta = ev.deltaX;
      var percent = (100 / this.slideWidth) * delta;
      var animate = false;
      // var lock = false; // not used according to lint

      if (ev.type == 'panend' || ev.type == 'pancancel') {
        if (Math.abs(percent) > 20 && ev.type == 'panend') {
          this.slideshow.currentSlide += (percent < 0) ? 1 : -1;
          if (this.slideshow.currentSlide < 0) {
            this.slideshow.currentSlide = 0;
          }
          if (this.slideshow.currentSlide > 2) {
            this.slideshow.currentSlide = 2;
          }
        }
        percent = 0;
        animate = true;
      }

      if (ev.type == 'tap') {

        var target = ev.target;
        if (target) {
          var href = target.tagName == 'A' ? target.href : target.closest('a')?.attributes['href'].nodeValue;
          if (href) {
            location.href = href;
          }
        }

      }

      this.show(this.slideshow.currentSlide, -percent, animate);
    }
  };

  var runSlideshow = function (els) {
    var array = [];

    if (show) {
      show.start();
    }

    for (var i = 0; i < els.length; i++) {
      if (!started) {

        show = new SlideShowConstructor(els[i], {});
        show.initialize();
        show.start();

        new HammerCarousel(show, Hammer.DIRECTION_HORIZONTAL);

        array.push(show);
      }

    }
    started = true;

    return array;
  };

  var stopSlideshow = function () {
    show.stop();
  };

  // find the elements that need slidshow capabilities
  var slideshowElements = [];
  Array.prototype.forEach.call(document.getElementsByClassName('carousel'), function (el) {
    slideshowElements.push(el);
  });

  if (window.innerWidth < 960) {
    // add slideshow capabilities to each el
    if (!started) {
      runSlideshow(slideshowElements);
    }
  }

  window.onresize = function () {
    var $nav = document.querySelector('.carousel-nav');
    if (window.innerWidth < 960) {
      $nav.style.display = 'inline-block';

      SlideShowConstructor();
      runSlideshow(slideshowElements);

    }
    if (window.innerWidth >= 960) {
      $nav.style.display = 'none';
      var carouselSpot = document.getElementsByClassName('carousel')[0];
      carouselSpot.removeAttribute('style');
      if (show) {
        // show.animFreq = 0;
        stopSlideshow();
      }
    }

  };
});
