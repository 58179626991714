defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/NotificationBox',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/NumberGames/Scripts/Framework/LottiePlayer',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/GenericOverlay'
  ],
  function (m, ignoreLottiePlayer, Overlay, GenericOverlay) {

    var NotificationBox = function (notificationKey, notifications, options) {
      if (!notifications) return null;
      var notification = notifications.filter(function (notification) {
        return notification.key === notificationKey;
      })[0];

      if (!notification || !(notification.image || notification.text)) return null;

      var useLottieFile = customElements.get('lottie-player') && notification.lottieUrl;

      var onRendered = function (element) {
        if (typeof element.play === 'function') {
          element.setAttribute('loop', 'true');
          element.play();
        }
      };
      var text = notification.text;
      if (options && options.replacements) {
        var keys = Object.keys(options.replacements);

        for (var i = 0; i < keys.length; i++) {
          var k = keys[i];
          var val = options.replacements[k];

          text = notification.text.replace(new RegExp('{' + k + '}', 'gi'), val);
        }
      }

      if (options && options.asOverlay) {
        var genericOverlayDictionary = {
          iconUrl: notification.iconUrl,
          lottieUrl: notification.lottieUrl,
          header: text,
          dismiss: options.dismissText
        };

        return new Overlay(GenericOverlay(genericOverlayDictionary));
      }

      return [
        m('.notification-box', [
          notification.iconUrl ? m('.notification-box__image-wrapper', [
            useLottieFile ? m('lottie-player[autoplay].notification-box__image', {
              src: notification.lottieUrl,
              config: onRendered
            }) : m('img.notification-box__image', { src: notification.iconUrl }),
          ]) : null,

          m('.notification-box__text', text)
        ])
      ];
    };

    return NotificationBox;
  });
