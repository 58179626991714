defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/StatisticsModule/SelectedNumbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage'
  ],
  function (m, Storage) {

    var SelectedNumbers = function (controller) {

      // Variables
      var d = controller.d;
      var gameType = controller.selectedGameClient();
      var options = {
        Lotto: ['System', 'Classic', 'Lucky'],
        Vikinglotto: ['System', 'Classic', 'Lucky'],
        Eurojackpot: ['System', 'Classic', 'Lucky'],
        Keno: ['Classic', 'System']
      }[gameType];
      var selectedPoints = controller.property('selectedPoints');

      // Functions:
      var removeSelectedNumbers = function () {
        delete selectedPoints[gameType];
      };

      var removeSelectedNumber = function (numberType, number) {
        return function () {
          delete selectedPoints[gameType][numberType][number];
        };
      };

      var saveSelectedNumbersAndRedirect = function (game) {
        return function () {
          Storage.set(gameType + '-StatisticsNumbers', JSON.stringify(selectedPoints[gameType]));

          window.location.href = controller[gameType.toLowerCase() + game + 'GameClientLink']();
        };
      };

      var removeSelectedNumbersFromStorage = function () {
        Storage.set(gameType + '-StatisticsNumbers', null);
      };

      var toggleMobileSelectedNumbers = function () {
        controller.property('selectedNumbersMobileToggle', !controller.property('selectedNumbersMobileToggle'));
      };

      // Return:
      return m('div', { class: 'statistics-module-selected-numbers' + (controller.property('selectedNumbersMobileToggle') ? ' opened' : '') }, [
        function () {
          var outerDOM = [];
          var innerDOM = [];

          for (var numberType in selectedPoints[gameType]) {
            for (var number in selectedPoints[gameType][numberType]) {
              innerDOM.push(m('div', { class: 'selected-number-box' + (numberType === 'extraNumbers' ? ' special' : '') }, [
                m('div', { class: 'selected-number-delete-button', onclick: removeSelectedNumber(numberType, number) }, [
                  m('svg', { class: 'icon' }, [
                    m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-x' })
                  ])
                ]),
                m('div', { class: 'number-cell' }, selectedPoints[gameType][numberType][number])
              ]));
            }
          }

          outerDOM.push(m('div', { class: 'selected-numbers-game-wrapper ' + gameType.toLocaleLowerCase() + '-numbers' }, [
            function () {
              if (innerDOM.length > 0) {
                return m('div', { class: 'selected-numbers-game-inner-wrapper' }, [
                  m('div', { class: 'selected-numbers-headline' }, [
                    d('GraphModule/SelectedNumbers/Headline'),
                    m('div', { class: 'selected-numbers-delete-button', onclick: removeSelectedNumbers }, [
                      d('GraphModule/SelectedNumbers/RemoveNumbers'),
                      m('svg', { class: 'icon' }, [
                        m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-rubbish' })
                      ])
                    ])
                  ]),
                  m('div', { class: 'selected-numbers-wrapper' }, innerDOM),
                  m('div', { class: 'selected-numbers-go-to-game-buttons-wrapper' }, [
                    m('div', { class: 'go-to-game-header' }, d('GraphModule/SelectedNumbers/ButtonsHeadline')),
                    m('div', { class: 'go-to-game-buttons' }, options.map(function (option) {
                      return m('div', { class: 'go-to-game-button', onclick: saveSelectedNumbersAndRedirect(option) }, d('GraphModule/SelectedNumbers/Buttons/' + gameType + '/' + option));
                    }))
                  ])
                ]);
              } else {
                removeSelectedNumbersFromStorage();

                return m('div', { class: 'no-selected-numbers' }, d('GraphModule/SelectedNumbers/NoSelectedNumbers'));
              }
            }()
          ]));

          if (innerDOM.length > 0) {
            outerDOM.push(m('span', { class: 'selected-numbers-mobile-toggle-button', onclick: toggleMobileSelectedNumbers }, [
              m('span', { class: 'count-wrapper' }, innerDOM.length),
              m('svg', { class: 'icon' }, [
                m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-rows-list' })
              ])
            ]));
          }

          return outerDOM;
        }()
      ]);
    };

    // Public functions:
    return SelectedNumbers;

  });
