defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/GameRows',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    var GameRows = function (controller, game) {

      // Variables
      var coupon = controller.model.data();
      var isJoker = game.gameType.toLowerCase().indexOf('joker') > -1;
      var isSystem = coupon.couponType === game.playType && coupon.playType === 'System';
      var isKeno = coupon.couponGame === 'Keno';
      var isAllOrNothing = coupon.couponGame === 'AllOrNothing';

      // Functions:
      var formatNumber = function (digit) {
        digit = '' + digit; // Turn into a string

        return (digit.length === 1 ? '0' : '') + digit;
      };

      // Format content of a single td cell:
      var formatCell = function (number, classPrefix) {
        return { content: formatNumber(number.number), class: 'number ' + (classPrefix || '') + (number.correct ? ' correct ' + number.correct : '') };
      };

      // Format all numbers in a row:
      var formatCells = function (row) {
        var cells = [];
        cells = cells.concat(row.numbers.map(function (number) {
          return formatCell(number, '');
        }));

        if (row.extraNumbers) {
          cells.push({ content: '+', class: 'divider' });
          cells = cells.concat(row.extraNumbers.map(function (number) {
            return formatCell(number, 'extranumber');
          }));
        }

        return cells;
      };

      // Show/hide the rows of the game, only in system games
      var toggleRows = function () {
        var $numberContainer = document.querySelector('.chosen-system-numbers');
        var $rowContainer = document.querySelector('.number-rows');
        var $numberRows = $rowContainer.querySelector('.number-table'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        var numberRowsHeight = $numberRows.getBoundingClientRect().height; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

        $numberContainer.classList.toggle('active');

        if ($numberContainer.classList.contains('active')) {
          $rowContainer.style.maxHeight = numberRowsHeight + 'px';
          $rowContainer.style.opacity = '1';
        } else {
          $rowContainer.style.maxHeight = '0';
          $rowContainer.style.opacity = '0';
        }

      };

      // View:
      if (isJoker) {
        // Special formatting for jokerRows
        return m('p', { class: 'jokernumbers' },
          game.rows.map(function (row) {
            return m('.line' + (row.hasPrize ? ' row-won' : ''), row.numbers.map(function (number) {
              return m('span', { class: 'number' + (number.correct ? ' correct ' + number.correct : '') }, number.number);
            }),
            m('span.won-indicator', [
              m('svg', { class: 'svg icon' }, [
                m('use', { class: 'icon', href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#icon-checkmark' })
              ])
            ]));
          })
        );
      }

      if (isSystem) {
        var systemNumbers = coupon.games[0].systemNumbers && coupon.games[0].systemNumbers.numbers;
        var systemExtraNumber = coupon.games[0].systemNumbers && coupon.games[0].systemNumbers.extraNumbers;

        return m('div', { class: 'chosen-system-numbers' }, [ // show the user-chosen numbers
          m('div', { class: 'chosen-numbers' }, [
            m('table', { class: 'number-table' }, [
              m('tr', { class: '' }, [
                systemNumbers.map(function (cell) {
                  return m('td', { class: 'number' + (cell.correct ? ' correct ' + cell.correct : '') }, cell.number);
                }),
                (systemExtraNumber ?
                  m('table', { class: 'ekstra-number-table' }, [
                    m('tr', { class: '' }, [
                      systemExtraNumber.map(function (cell) {
                        return m('span', { class: 'ekstra-number-container' }, [
                          m('td', { class: 'ekstra-number' + (cell.correct ? ' correct ' + cell.correct : '') }, cell.number),
                          m('svg', { class: 'svg icon' }, [
                            m('use', { class: 'icon', href: (coupon.couponGame === 'EuroJackpot' ? '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#icon-receipt_star' : '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#icon-receipt-viking-helmet') })
                          ])
                        ]);
                      })
                    ])
                  ]) : ''
                )
              ])
            ])
          ]),

          (coupon.systemName[0] !== 'M' && coupon.couponGame !== 'Keno' ? [

            m('div', { class: 'toggle-row' }, [
              m('span', { class: 'toggle-link open', onclick: toggleRows }, controller.d('ToggleRows/ShowRows')),
              m('span', { class: 'toggle-link close', onclick: toggleRows }, controller.d('ToggleRows/HideRows'))
            ]),

            m('div', { class: 'number-rows' }, [
              m('table', { class: 'number-table' }, [
                game.rows.map(function (row, i) {
                  var cells = formatCells(row, game);
                  return m('tr', { class: row.hasPrize ? 'row-won' : '' }, [
                    m('td.row-number', i + 1 + '.'),
                    cells.map(function (cell) {
                      return m('td', { class: cell.class }, cell.content);
                    }),
                    m('td.won-indicator', [
                      function () {
                        if (row.hasPrize) {
                          return m('svg', { class: 'svg icon' }, [
                            m('use', { class: 'icon', href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#icon-checkmark' })
                          ]);
                        }
                      }()
                    ])
                  ]);
                })
              ])
            ])
          ] : '')
        ]);
      } else {
        // This is classic numbers
        return m('.number-tables__wrapper', game.rows.map(function (row, i) {
          var cells = formatCells(row, game);
          return [
            m('.number-table__wrapper', { class: row.hasPrize ? 'row-won' : '' }, [
              isKeno ? m('table', { class: 'keno-title-table' },
                m('td', { class: 'keno-row-title' }, 'Række med ' + cells.length + ' tal')
              ) : '',
              isAllOrNothing ? m('table', { class: 'keno-title-table' },
                m('td', { class: 'keno-row-title' }, 'Række ' + (i + 1))
              ) : '',
              m('table.number-table',
                m('tr', { class: row.hasPrize ? 'row-won' : '' }, [
                  m('td.row-number', i + 1 + '.'),
                  cells.map(function (cell) {
                    return m('td', { class: cell.class }, cell.content);
                  }),
                  m('td.won-indicator' + (row.hasPrize ? '' : ' hide'), [
                    function () {
                      if (row.hasPrize) {
                        return m('svg', { class: 'svg icon' }, [
                          m('use', {
                            class: 'icon',
                            href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#icon-checkmark'
                          })
                        ]);
                      }
                    }()
                  ])
                ])
              ),
            ])
          ];
        }));
      }
    };


    // Public functions:
    return GameRows;

  });
