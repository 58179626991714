defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/RowVikingNumbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/RowVikingNumber'
  ],
  function (m, RowVikingNumber) {

    // Context:
    var RowVikingNumbers = function (controller, rowNumber) {

      // Variables:
      var currentRow = controller.property('currentRow');
      var dom = [];
      var game = controller.game();
      var vikingNumber = 0;
      var totalVikingNumbers = game.vikingNumbersIntervalMax();

      // Functions:
      var vikingNumberConfig = function (vikingNumber) {
        if (rowNumber + 1 === currentRow) {
          return function ($element, rendered, context) {
            if (rendered && context.rowNumber === rowNumber) {
              return false;
            }

            context.rowNumber = rowNumber;

            $element = $DS($element);

            $element.unbind('click touchend touchmove touchstart');

            var touchClick = false;
            var touchEnd = false;
            var touchMove = false;
            var touchTimer = null;

            $element.on('click', function () {
              if (!touchClick) {
                vikingNumberToggle(vikingNumber);
              }
            });

            $element.on('touchstart', function () {
              clearTimeout(touchTimer);

              touchClick = false;
              touchEnd = false;
              touchMove = false;

              touchTimer = setTimeout(function () {
                if (!touchEnd && !touchMove) {
                  touchClick = true;

                  $element.addClass('active');
                }
              }, 250);
            });

            $element.on('touchend', function () {
              touchEnd = true;

              if (touchClick) {
                $element.removeClass('active');

                vikingNumberToggle(vikingNumber);

                m.redraw();
              }
            });

            $element.on('touchmove', function () {
              touchMove = true;
            });

            $element.on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function () {
              controller.property('vikingNumberSelected' + vikingNumber, false);
            });
          };
        }
      };

      var vikingNumberToggle = function (vikingNumber) {
        var hasVikingNumber = game.hasVikingNumber(currentRow, vikingNumber);
        var nextRow = game.getRow(currentRow + 1);
        var rowVikingNumber = game.getRow(currentRow).vikingNumber;
        var totalNumbers = (rowVikingNumber ? 1 : 0) + game.getRow(currentRow).numbers.length;

        if (!rowVikingNumber && totalNumbers < game.totalNumbersPerRowMax() && !hasVikingNumber) {
          game.addVikingNumber(currentRow, vikingNumber);

          if (game.isRowValid(currentRow)) {
            controller.property('animate', 'row-completed');

            if (!nextRow) {
              game.addRow();
            }
          }
        } else if (hasVikingNumber) {
          game.removeVikingNumber(currentRow, vikingNumber);

          var lastRow = game.getRow(game.rows().length);

          if (nextRow.count === lastRow.count) {
            lastRow = null;
          }

          if (!game.isRowValid(currentRow) && nextRow && nextRow.numbers.length === 0 && nextRow.vikingNumber && lastRow && lastRow.numbers.length === 0 && lastRow.vikingNumber) {
            game.removeRow(currentRow + 2);
          }

          if (controller.property('animate') === 'row-completed') {
            controller.property('animate', '');
          }
        } else {
          controller.property('animate', 'shake' + (game.playType() === 'Lucky' ? '' : '-viking-numbers'));
        }

        if (controller.property('animate') !== 'shake' + (game.playType() === 'Lucky' ? '' : '-viking-numbers')) {
          controller.property('vikingNumberSelected' + vikingNumber, true);
        }

        m.redraw();
      };

      // View:
      while (vikingNumber < totalVikingNumbers) {
        vikingNumber++;

        var className = '';
        var hasVikingNumber = game.hasVikingNumber(rowNumber + 1, vikingNumber);

        if (hasVikingNumber) {
          className = className + ' selected';
        }

        if (controller.property('vikingNumberSelected' + vikingNumber) && rowNumber + 1 === currentRow) {
          className = className + ' flip-animation-in-progress ' + (hasVikingNumber ? '' : 'de') + 'select-number-animation';
        }

        dom.push(RowVikingNumber(vikingNumber, className, vikingNumberConfig(vikingNumber)));
      }

      return [
        m('div', { class: 'row-vikings-container clearfix' }, dom)
      ];
    };

    // Public functions:
    return RowVikingNumbers;

  });
