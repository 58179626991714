defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/Statistics/BiggestWins/BiggestWins', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/StatisticsModuleApi',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/SimpleCubeLoader',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Statistics/BiggestWins/InView',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Statistics/BiggestWins/Row',
], function (Component, StatisticsModuleApi, SimpleCubeLoader, InView, Row) {
  Component('biggest-wins', function (m, route, settings, ignoreProperty, $element) {

    const root = {
      controller: function () {
        // variable
        this.loading = m.prop(true);
        this.isInView = m.prop(false);
        this.count = 0;
        this.items = m.prop(null);
        this.itemsTemp = m.prop(null);
        this.game = settings.game;
        this.labelYear = settings.labelYear;
        this.labelAmount = settings.labelAmount;
        this.title = settings.title;
        this.bodyText = settings.text.replace(/\r\n/g, '<br/>');
        this.oldBigWins = settings.oldBigWins;
        this.callToAction = settings.callToAction;
        this.rowsToShow = 5;

        this.oldBigWins = settings.oldBigWins.map((item) => ({
          year: Number(item.year),
          amount: Number(item.amount)
        }));
        // functions
        const limitResults = (array, limit) => array.slice(0, limit);
        // init
        StatisticsModuleApi.biggestWins(this.game).then((response) => {

          let combinedArray = [...response, ...this.oldBigWins];
          this.items = combinedArray.sort((a, b) => b.amount - a.amount);
          const callbackInView = (value) => {
            this.isInView(value);
            if (!value) return;
            this.rowsControllers?.forEach((rowController) => {
              rowController.startInterval?.();
            });
          };

          this.items = limitResults(this.items, this.rowsToShow >= this.items.length ? this.items.length : this.rowsToShow);
          this.rowsControllers = this.items.map((item) => {
            return new Row.controller({ item });
          });

          this.loading = m.prop(false);
          InView.isInView($element, callbackInView, true);
          m.redraw();
        });
      },

      view: (controller) => {

        const content = () => {
          if (controller.loading() || !controller.isInView()) {
            return [
              Array.from({ length: controller.rowsToShow }, () => Row.view()),
              SimpleCubeLoader()
            ];
          }

          return controller.rowsControllers.map((rowController) => {
            return Row.view(rowController);
          });
        };

        return m('.biggest-wins-overview biggest-wins-overview--' + controller.game, [
          m('.biggest-wins-overview-table', [
            m('.biggest-wins-overview-table__header', [m('.biggest-wins-overview-table__header-cell', controller.labelYear), m('.biggest-wins-overview-table__header-cell', controller.labelAmount)]),
            m('.biggest-wins-overview-table__content',
              content()
            )
          ]),
          m('.biggest-wins-overview-text', [
            m('h2.title', controller.title),
            m('.biggest-wins-overview-text__content', m.trust(controller.bodyText)),
            m('.biggest-wins-overview-text__content-cta', m('a.biggest-wins-overview__btn-cta', { href: controller.callToAction.url }, controller.callToAction.title))
          ]),
          m('.biggest-wins-overview-cta', m('a.biggest-wins-overview__btn-cta', { href: controller.callToAction.url }, controller.callToAction.title))
        ]);
      }
    };

    route('/', root);
  });
});
