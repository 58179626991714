defineDs('DanskeSpil/Framework/NumberGames/Scripts/Helpers/Resize',
  function () {

    // Variables:
    var callbacks = [];

    // Functions:
    var add = function (callback) {
      callbacks.push(callback);
    };

    // Init:
    window.addEventListener('resize', function () {
      var amount = callbacks.length;

      for (var i = 0; i < amount; i++) {
        callbacks[i]();
      }
    });

    // Public functions:
    return add;

  });
