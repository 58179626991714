defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoLucky/Row',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/RowNumbers',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/RowNumbersFooter',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/RowVikingNumbers'
  ],
  function (m, RowNumbers, RowNumbersFooter, RowVikingNumbers) {

    // Template:
    var Row = function (controller) {

      // Variables:
      var d = controller.d;

      // Functions:
      var rowClass = function () {
        var className = 'row-container row-number-1 in-focus';

        if (controller.property('animate') === 'shake') {
          className += ' shake-state shake-viking-numbers-state';
        }

        return className;
      };

      var rowConfig = function ($element) {
        $element = $DS($element);

        $element.unbind();

        $element.on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function () {
          var animate = controller.property('animate');

          if (/shake/.test(animate) && !/shake-end/.test(animate)) {
            controller.property('animate', controller.property('animate') + ' shake-end');

            m.redraw();
          }
        });
      };

      // View:
      return m('div', { class: 'rows-container' }, [
        m('div', { class: rowClass(), config: rowConfig }, [
          m('div', { class: 'row-header' }, [
            m('div', { class: 'tab-game-rules-text' }, d('VikingLottoLucky/RowHeader'))
          ]),
          m('div', { class: 'number-picker-container' }, [
            m('div', { class: 'number-picker-wrapper' }, [
              RowNumbers(controller, 0),
              m('hr', { class: 'numbers-vikings-separator' }),
              RowVikingNumbers(controller, 0)
            ]),
            RowNumbersFooter(controller, 0)
          ])
        ])
      ]);

    };

    // Public functions:
    return Row;

  });
