defineDs('DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/WinningNumbersUtils', [
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/WinningNumbersApi',
], (Params, Utils, Api) => {


  const getDrawDateFromUrlParam = () => {
    return Params.extract('traekningsdato');
  };


  const getEurojackpotDrawDates = () => {
    return Api.getEurojackpotDrawDates().then(({ eurojackpot, joker }) => ({ eurojackpot, joker }));
  };


  const getEurojackpotDrawDetails = ({ date, isJoker, completedDrawObjects }) => {
    const { eurojackpot, joker } = completedDrawObjects();

    if (!isJoker) {
      const draw = eurojackpot.draws.filter((draw) => draw.date.startsWith(date));
      return { drawId: draw?.[0]?.drawNo };
    }

    if (isJoker) {
      const draw = joker.draws.filter((draw) => draw.date.startsWith(date));

      if (draw?.length) {
        return { drawId: draw[draw.length - 1]?.drawNo, jokerType: draw[draw.length - 1]?.jokerType };
      }
    }
  };


  const getEurojackpotWinningNumbers = (date, completedDrawObjects) => {
    const { drawId } = getEurojackpotDrawDetails({ date, completedDrawObjects });

    if (!drawId) {
      return {
        numbers: [],
        bonusNumbers: [],
        prizes: [],
        gameVersionNo: null,
      };
    }

    return Api.getEurojackpotWinningNumbers({ drawId }).then(({ winningNumbers, starNumbers, prizes, gameVersionNo }) => {
      return {
        numbers: winningNumbers,
        bonusNumbers: starNumbers,
        prizes: prizes,
        gameVersionNo: gameVersionNo,
      };
    });
  };


  const getEurojackpotJokerWinningNumbers = (date, completedDrawObjects) => {
    const { drawId, jokerType } = getEurojackpotDrawDetails({ date, isJoker: true, completedDrawObjects });

    if (!drawId) {
      return {
        jokerNumbers: [],
        jokerPrizes: [],
      };
    }

    return Api.getJokerWinningNumbers({ drawId, jokerType }).then(({ winningNumbers, prizes }) => {
      return {
        jokerNumbers: winningNumbers,
        jokerPrizes: prizes,
      };
    });
  };


  const getLottoDrawDates = () => {
    return Api.getLottoDrawDates().then((dates) => dates.sort().reverse());
  };


  const getLottoWinningNumbers = (date) => {
    return Api.getLottoWinningNumbers({ date }).then(({ lottoSaturday, jokerSaturday }) => {
      return {
        numbers: lottoSaturday.winningNumbers,
        bonusNumbers: [lottoSaturday.bonusNumber],
        prizes: lottoSaturday.prizes,
        gameVersionNo: lottoSaturday.gameVersionNo,
        jokerNumbers: jokerSaturday.winningNumbers,
        jokerPrizes: jokerSaturday.prizes
      };
    });
  };


  const getVikinglottoDrawDates = () => {
    return Api.getVikinglottoDrawDates().then((dates) => dates.sort().reverse());
  };


  const getVikinglottoWinningNumbers = (date) => {
    return Api.getVikinglottoWinningNumbers({ date }).then(({ vikinglotto, jokerWednesday }) => {
      return {
        numbers: vikinglotto.winningNumbers,
        bonusNumbers: [vikinglotto.vikingNumber],
        prizes: vikinglotto.prizes,
        gameVersionNo: vikinglotto.gameVersionNo,
        jokerNumbers: jokerWednesday.winningNumbers,
        jokerPrizes: jokerWednesday.prizes
      };
    });
  };


  const initializeFirstDrawDate = (dates) => {
    const dateFromUrlParam = getDrawDateFromUrlParam();

    if (dates.includes(dateFromUrlParam)) {
      return dateFromUrlParam;
    }

    return dates[0];
  };


  const setDrawDateToUrlParam = (date) => {
    if (!date) {
      Params.remove('traekningsdato');
    }

    Params.set(`traekningsdato=${Utils.formatDateYearMonthDay(date)}`);
  };


  return {
    getDrawDateFromUrlParam,
    getEurojackpotDrawDates,
    getEurojackpotWinningNumbers,
    getEurojackpotJokerWinningNumbers,
    getLottoDrawDates,
    getLottoWinningNumbers,
    getVikinglottoDrawDates,
    getVikinglottoWinningNumbers,
    initializeFirstDrawDate,
    setDrawDateToUrlParam,
  };
});

