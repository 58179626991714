defineDs('Shared/Framework/Mithril/Scripts/Core/Model',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Cleanup'
  ],
  function (m, Cleanup) {

    // Functions:
    var model = function (modelName, constructor) {
      modelName = 'Model-' + modelName;

      constructor.generateId = function () {
        return 'xxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          /* eslint-disable no-bitwise, no-mixed-operators, one-var */
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          /* eslint-enable no-bitwise, no-mixed-operators, one-var */
          return v.toString(16);
        });
      };

      constructor.cache = {};

      constructor.construct = function (content, state, save) {
        content = content || {};

        var record = new this(content);

        record.id = m.prop(content.id ? content.id : this.generateId());
        record.state = m.prop(state ? state : 'clean');
        record.timestamp = m.prop(content.timestamp || (new Date()).getTime());

        if (typeof record.init === 'function') {
          record.init(content);
        }

        if (typeof save === 'undefined' || save) {
          record.save();
        }

        return record;
      };

      constructor.prototype.save = function () {
        this.state('clean');
        this.timestamp((new Date()).getTime());

        var data = JSON.stringify(this.toJSON());

        try {
          localStorage.setItem(modelName + '-' + this.id(), data);
        } catch (e) {
          console.warn('Model.js Cannot write to localStorage');
        }

        constructor.cache[this.id()] = this;
      };

      constructor.update = function (record, content) {

        var id = record.id();

        content.id = id;

        var updatedRecord = this.construct(content, 'new');

        Object.keys(updatedRecord).forEach(function (key) {
          record[key] = updatedRecord[key];
        });

        return record;

      };

      constructor.get = function (query) {
        var type = typeof query;

        if (type === 'undefined') {
          return null;
        }

        // Get by ID:
        if (type === 'number' || type === 'string') {
          var cached = constructor.cache[query];

          if (cached) {
            return cached;
          }

          try {
            var val = localStorage.getItem(modelName + '-' + query);
          } catch (e) {
            console.warn('Model.js Cannot read from localStorage');
          }

          if (!val) {
            return null;
          }

          var json = JSON.parse(val);
          var record = this.construct(json);

          return record;
        }

        var bulk = [];
        var keys = Object.keys(constructor.cache);

        for (var i = 0; i < keys.length; i++) {
          bulk.push(constructor.cache[keys[i]]);
        }

        // Query:
        if (type === 'object') {
          keys = Object.keys(query);
          var length = keys.length;

          bulk = bulk.filter(function (record) {
            var match = true;

            for (var i = 0; i < length; i++) {
              var first = typeof first === 'function' ? query[keys[i]]() : query[keys[i]];
              var second = record[keys[i]]();

              if (/delete|remove/.test(record.state()) || first !== second) {
                match = false;

                break;
              }
            }

            return match;
          });

        }

        // Bulk:
        return bulk;

      };

      constructor.delete = function (query) {
        if (!query) {
          return;
        }

        try {
          localStorage.removeItem(modelName + '-' + query);
          delete constructor.cache[query];
        } catch (e) {
          console.warn('Model.js Cannot remove from localStorage');
        }
      };

      constructor.new = function (content, state, save) {
        return this.construct(content, state || 'new', save);
      };

      return constructor;
    };

    // Cleanup
    Cleanup();

    // Public functions:
    return model;

  });
