defineDs('DanskeSpil/Framework/NumberGames/Scripts/Templates/ReceiveWinnerMessage',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
  ],
  function (m) {

    // View:
    var ReceiveWinnerMessage = function (controller, styleClassPrefix, iconUrl) {

      var d = controller.d;


      // View:
      return m('div', { class: 'win-message-container' }, [

        // Icon
        iconUrl ? m('svg', { class: 'receive-winner-message-icon' },
          m('use', { class: 'receive-winner-message-icon', href: iconUrl })
        ) : null,

        m('div', { class: 'notice-step' }, [

          // Texts
          m('h2', { class: 'headline' }, controller.d('ConfirmStep/ReceiveWinnerMessage/Headline')),
          m('h3', { class: 'subheadline' }, controller.d('ConfirmStep/ReceiveWinnerMessage/Subheadline')),

          // Email checkbox
          m('div', { class: 'checkbox-container' }, [
            m('div', { class: styleClassPrefix + '-checkbox' }, [
              m('label', { class: 'ios-skin', for: 'email' }, [
                m('input', { type: 'checkbox', id: 'email', 'data-role': 'email', value: '1', onchange: m.withAttr('checked', controller.notifyPrizeEmail) }),
                m('span', { class: 'ios-skin_body' }, [
                  m('span', { class: 'ios-skin_switch' })
                ]),
                m('i', { class: 'checkmark' })
              ]),
              m('label', { class: 'media-label', for: 'email' }, [
                m('p', { class: 'media-text' }, d('ConfirmStep/ReceiveWinnerMessage/EmailText'))
              ])
            ]),

            // SMS checkbox
            m('div', { class: styleClassPrefix + '-checkbox' }, [
              m('label', { class: 'ios-skin', for: 'sms' }, [
                m('input', { type: 'checkbox', id: 'sms', 'data-role': 'sms', value: '1', onchange: m.withAttr('checked', controller.notifyPrizeSms) }),
                m('span', { class: 'ios-skin_body' }, [
                  m('span', { class: 'ios-skin_switch' })
                ]),
                m('i', { class: 'checkmark' })
              ]),
              m('label', { class: 'media-label', for: 'sms' }, [
                m('p', { class: 'media-text' }, d('ConfirmStep/ReceiveWinnerMessage/SmsText'))
              ])
            ])
          ])

        ])
      ]);

    };

    // Public functions:
    return ReceiveWinnerMessage;

  });
