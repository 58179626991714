defineDs('DanskeSpil/Framework/NumberGames/Scripts/Helpers/ViewportUtils',
  function () {

    // Functions:
    var viewportWidth = function () {
      return $DS('body').width();
    };

    var debounce = function (func, wait, immediate) {
      var timeout;

      return function () {
        var context = this;
        var args = arguments;

        var later = function () {
          timeout = null;

          if (!immediate) {
            func.apply(context, args);
          }
        };

        var callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
          func.apply(context, args);
        }
      };
    };

    /**
* @param {Number} ratio - Set the ratio of an element's height and width that needs to be visible for
* it to be considered in viewport. A threshold of 0.5 or 1 will require that half or all, respectively,
* of an element's height and width need to be visible. ratio must be a number between 0 and 1.
*/
    var isInViewport = function (elem, ratio) {
      // Copied from https://github.com/camwiegert/in-view/blob/master/src/viewport.js

      var bcr = elem.getBoundingClientRect();

      var intersection = {
        t: bcr.bottom,
        r: window.innerWidth - bcr.left,
        b: window.innerHeight - bcr.top,
        l: bcr.right
      };

      var threshold = {
        x: ratio * bcr.width,
        y: ratio * bcr.height
      };

      return intersection.t > threshold.y
        && intersection.r > threshold.x
        && intersection.b > threshold.y
        && intersection.l > threshold.x;
    };

    var onEnterViewportListener = function (callback) {
      var fn = null;
      var timeoutId = -1;

      var detach = function () {
        clearTimeout(timeoutId);

        if (fn !== null) {
          $DS(window).off('scroll', fn);
        }
      };

      return {
        attachTo: function (element, attachOptions) {
          attachOptions = attachOptions || { withDelay: 0 };

          // detach();

          fn = debounce(function () {
            if (isInViewport(element, .6)) {
              callback();
            }
          }, 500);

          if (attachOptions.withDelay) {
            timeoutId = setTimeout(function () {
              fn();

              $DS(window).on('scroll', fn);
            }, attachOptions.withDelay);
          } else {
            $DS(window).on('scroll', fn);
          }
        },

        detach: detach
      };
    };

    // Public functions:
    return {
      viewportWidth: viewportWidth,
      onEnterViewportListener: onEnterViewportListener
    };

  });

