defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/GuaranteeContent/GuaranteeContent',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Common/Framework/EventHandling/Scripts/Event',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/GuaranteeContent/MathematicalAccordions',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/GuaranteeContent/ReducedAndChanceAccordions',
  ], function (m, Event, MathematicalAccordions, ReducedAndChanceAccordions) {
    const GuaranteeContent = {
      controller: function ({ controller, targetedSystemName }) {
        Object.assign(this, controller);

        this.localSystemName = m.prop(null);
        const guaranteeListContentLoadingState = () => (<div className='rounded-16 border border-solid border-gray-2 overflow-clip'>
          <div className='bg-white text-black p-16 cursor-wait text-12 font-bold leading-16 uppercase'>{
            this.labelDictionary('systemGuaranteeTabAccordionLabelLoading', { systemName: targetedSystemName || this.gameInstance().systemName() })
          }</div>
        </div>);
        this.guaranteeListContent = m.prop(guaranteeListContentLoadingState());

        this.init = () => {
          this.systemName = targetedSystemName || this.gameInstance().systemName();
          this.systemType = this.systemName.slice(0, 1);
          const maxNumberOfNumbers = this.gameInstance().numbersPerRow();
          const totalRows = this.gameInstance().getSystemRowAmount();
          const dictionaryReplacementKeys = {
            systemName: this.systemName,
            maxNumberOfNumbers,
            totalRows,
          };

          this.heading = this.labelDictionary('systemGuaranteeTabHeading', dictionaryReplacementKeys);
          this.subheading = this.labelDictionary('systemGuaranteeTabSubheading', dictionaryReplacementKeys);
          this.description = this.labelDictionary('systemGuaranteeTabDescription', dictionaryReplacementKeys);
          this.explanation = this.labelDictionary('systemGuaranteeTabExplanation', dictionaryReplacementKeys);

          this.gameInstance().api().getSystemWinningProbabilities(this.systemName).then((response) => {
            switch (this.systemType) {
            case 'M':
              this.guaranteeListContent(<MathematicalAccordions controller={this} data={response} />);
              break;
            default:
              this.guaranteeListContent(<ReducedAndChanceAccordions controller={this} data={response} />);
              break;
            }

            m.redraw();
          });
          this.localSystemName(this.systemName);
        };

        const onSystemNameChange = () => {
          this.guaranteeListContent(guaranteeListContentLoadingState());
          m.redraw();
          // in order to make sure that the DOM is updated with the correct system data we need to show for a draw cycle the loading state
          setTimeout(() => {
            this.init();
          }, 100);
        };

        if (!targetedSystemName) {
          Event.subscribe('ds.event.numbersPicker:onSystemNameChange', onSystemNameChange);
        }

        this.onunload = () => {
          if (this.activeTab?.() !== 'guarantee') {
            Event.unsubscribe('ds.event.numbersPicker:onSystemNameChange', onSystemNameChange);
          }
        };

        this.init();
      },
      view: function (controller) {
        return (<div data-uitest-id='guarantee-content' className='flex flex-col gap-24'>
          <div className='min-w-18 w-content m-auto flex flex-col gap-6'>
            <div data-uitest-id='heading' className='text-16 font-bold uppercase'>{controller.heading}</div>
            <hr className='h-1 w-full border-none bg-white/80'/>
            <div data-uitest-id='subheading' className='text-12 font-bold opacity-80 uppercase'>{controller.subheading}</div>
          </div>
          <div data-uitest-id='description' className='text-14 leading-22 whitespace-pre-line'>{controller.description}</div>
          {controller.guaranteeListContent()}

          {controller.explanation ? <div data-uitest-id='explanation' className='px-36 text-14 leading-22 whitespace-pre-line'>{controller.explanation}</div> : null}
        </div>);
      }
    };
    return GuaranteeContent;
  });