defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/Shared/SkeletonCardLoader', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/Shared/PackageCard'
], function (m, PackageCard) {
  // Component:
  var SkeletonCardLoader = {
    view: function () {
      return m('.multi-packages-skeleton-loader__wrapper', PackageCard({
        tagline: m('.multi-packages-skeleton-loader__bar'),
        title: m('.multi-packages-skeleton-loader__bar'),
        body: m('.multi-packages-package-card__coupons-list', [
          m('.multi-packages-skeleton-loader__bar.multi-packages-package-card__coupon-card'),
          m('.multi-packages-skeleton-loader__bar.multi-packages-package-card__coupon-card')
        ]),
        footer: m('.multi-packages-skeleton-loader__bar')
      }));
    }
  };

  return SkeletonCardLoader;
});
