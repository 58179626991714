defineDs(
  'DanskeSpil/Domain/NumberGames/Scripts/NonMithril/MultiBannerSpot',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
    'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils',
    'Common/Framework/EventHandling/Scripts/Event',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/LottieUtils',
    'Shared/Framework/Ensighten/Scripts/Ensighten',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/Shared/Modal',
  ],
  (m, JackpotInfo, TimeUtils, Event, LottieUtils, Ensighten, Modal) => {
    const setupLottie = async ({ topSpotSettings, $topSpot }) => {
      const $lottieContainer = $topSpot.querySelector('.multi-banner-spot__lottie'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      await LottieUtils.initializeLottie({
        $lottieContainer,
        settings: {
          lottieMobile: topSpotSettings.mobileLottieSrc,
          lottieTablet: topSpotSettings.tabletLottieSrc,
          lottieDesktop: topSpotSettings.desktopLottieSrc,
          lottieReplacements: topSpotSettings.lottieReplacements,
        },
      });
    };

    const zeroPad = (num, places) => String(num).padStart(places, '0');
    const updateCountdownClock = ({ previousCountdown, currentCountdown, $days, $hours, $mins, $secs }) => {
      if (previousCountdown.days !== currentCountdown.days) $days.textContent = zeroPad(currentCountdown.days, 2);
      if (previousCountdown.hours !== currentCountdown.hours) $hours.textContent = zeroPad(currentCountdown.hours, 2);
      if (previousCountdown.mins !== currentCountdown.mins) $mins.textContent = zeroPad(currentCountdown.mins, 2);
      if (previousCountdown.secs !== currentCountdown.secs) $secs.textContent = zeroPad(currentCountdown.secs, 2);
    };

    const initializeCountdown = async ({ topSpotSettings, $topSpot }) => {
      return new Promise((resolve) => {
        const $countDown = $topSpot.querySelector('.multi-banner-spot__count-down'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        if (!$countDown) return;

        // Find the countdown label and check if it should be enabled
        const $countDownTimeWrapper = $countDown.querySelector('.multi-banner-spot__count-down-time'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        const closingTime = topSpotSettings.mainDrawClosingTime;

        // If the countdown shouldn't be enabled, stop here
        if (!$countDownTimeWrapper || !closingTime) return;

        // Find the countdown elements
        const $days = $countDown.querySelector('.js-multi-banner-spot__count-down-time__days'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        const $hours = $countDown.querySelector('.js-multi-banner-spot__count-down-time__hours'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        const $mins = $countDown.querySelector('.js-multi-banner-spot__count-down-time__minutes'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        const $secs = $countDown.querySelector('.js-multi-banner-spot__count-down-time__seconds'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

        if (!$days || !$hours || !$mins || !$secs) return;

        // Initialize countdown variables
        let firstRenderCycle = false;
        let previousCountdown = { days: null, hours: null, mins: null, secs: null };
        let elapsedSinceLastTick;

        // Define the function that updates the countdown
        const updateCountdown = (timestamp) => {
          if (elapsedSinceLastTick === undefined) {
            elapsedSinceLastTick = timestamp - 1000; // Ensure first tick run immediately
          }

          const elapsed = timestamp - elapsedSinceLastTick;

          if (elapsed >= 1000) {
            elapsedSinceLastTick = timestamp;
            const timeDiff = new Date(closingTime) - TimeUtils.getServerDateTime();
            const compoundTimeObj = TimeUtils.getCompoundTimeObject(timeDiff);

            updateCountdownClock({
              previousCountdown,
              currentCountdown: compoundTimeObj,
              $days,
              $hours,
              $mins,
              $secs,
            });

            if (!firstRenderCycle) {
              $countDownTimeWrapper.classList.remove('hidden');
              firstRenderCycle = true;
            }
            previousCountdown = compoundTimeObj;
          }
          resolve();
          window.requestAnimationFrame(updateCountdown);
        };
        // Start the countdown
        window.requestAnimationFrame(updateCountdown);
      });
    };

    const initializeVideoBanner = ({ $topSpot, topSpotSettings, variant }) => {
      if (variant === 'variant-7' || variant === 'variant-8') {
        const videoModal = new Modal({ extraClassNames: 'multi-banner-spot__modal' });

        let elPlayIcon = null;
        if (variant === 'variant-7') {
          elPlayIcon = $topSpot.querySelector('.multi-banner-spot__icon-gradient'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        } else {
          elPlayIcon = $topSpot.querySelector('.multi-banner-spot__secondary-call-to-action'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        }

        elPlayIcon.addEventListener('click', () => {
          let videoUrl = topSpotSettings[LottieUtils.getDevice() + 'VideoUrl'];
          videoModal.setContent(
            m('video.multi-banner-spot__video', { controls: true, autoplay: true, playsinline: '' }, [
              m('source', { src: videoUrl, type: 'video/mp4' }),
            ])
          );
          videoModal.show();
        });
      }
    };

    /**
     * Flashes text in the countdown element of a top spot.
     */
    const flashText = (flashText, $topSpot, variant) => {
      // Flashy text is only enabled on variant 1
      if (variant !== 'variant-1') return;

      const $countDown = $topSpot.querySelector('.multi-banner-spot__count-down'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      if (!flashText || !$countDown) return;

      const $countDownContentWrapper = $countDown.querySelector('.multi-banner-spot__count-down-content-wrapper'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      const $countDownFlashyText = $countDown.querySelector('.multi-banner-spot__count-down-flashy-text'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      if (!$countDownContentWrapper || !$countDownFlashyText) return;

      const intervalId = setInterval(() => {
        if (!$countDownContentWrapper.isConnected || !$countDownFlashyText.isConnected) {
          clearInterval(intervalId);
          return;
        }

        $countDownContentWrapper.classList.toggle('hidden');
        $countDownFlashyText.classList.toggle('hidden');
      }, 3000);
    };

    const initializeCallToAction = ({ $topSpot, topSpotSettings, variant }) => {
      const $callToAction = $topSpot.querySelector('.multi-banner-spot__cta'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      if (!$callToAction) return;

      $callToAction.addEventListener('click', (event) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        event.preventDefault();
        const gameType = topSpotSettings.gameType;
        const targetUrl = new URL(event.target.href);
        let rows = 0;
        try {
          rows = new URLSearchParams(targetUrl.search).get('rows');
        } catch (error) {
          console.error('Invalid url passed from CTA:', error);
        }
        const isSamePageLink = targetUrl.origin + targetUrl.pathname === location.origin + location.pathname;

        // Amount of rows that should be forwarded to the multiClient buy flow
        if (rows && isSamePageLink) {
          const timeoutId = setTimeout(() => {
            // If the timeout is not cleared, redirect the user to the href
            window.location.href = event.target.href;
          }, 20);

          const eventHandler = () => {
            clearTimeout(timeoutId);
            Event.unsubscribe('ds.event.multiclient:topspot:buy-event-handled', eventHandler);
          };

          Event.subscribe('ds.event.multiclient:topspot:buy-event-handled', eventHandler);
          Event.fire('ds.event.multiclient:topspot:buy', {
            region: gameType,
            rows: parseInt(rows, 10),
          });
          Ensighten.pushGaEvent('herospot', variant, event.target.href);
        } else {
          Ensighten.pushGaEventOnPageRedirect('herospot', variant, event.target.href);
          window.location.href = event.target.href;
        }
      });
    };

    // --- Initialize component ---
    const setupTopSpot = ({ $topSpot }) => {
      const topSpotSettingsString = $topSpot.dataset.settings;
      const topSpotSettings = topSpotSettingsString ? JSON.parse(topSpotSettingsString) : null;
      if (!topSpotSettings) return;
      const variant = $topSpot.dataset.variant;
      topSpotSettings.gameType = $topSpot.dataset.game;

      initializeCountdown({ topSpotSettings, $topSpot }).then(() =>
        flashText(topSpotSettings.flashText, $topSpot, variant)
      );
      setupLottie({ topSpotSettings, $topSpot });
      initializeCallToAction({ $topSpot, topSpotSettings, variant });
      initializeVideoBanner({ $topSpot, topSpotSettings, variant });
    };

    const initialize = () => {
      if (document.querySelector('.mode-edit')) {
        // Reveal countdown labels in edit mode
        document
          .querySelectorAll('.multi-banner-spot .multi-banner-spot__count-down-label')
          .forEach((elem) => elem.classList.remove('hidden'));

        return;
      }

      Promise.all([JackpotInfo.ready]).then(() => {
        document.querySelectorAll('.multi-banner-spot').forEach(($topSpot) => {
          setupTopSpot({ $topSpot });
        });
      });
    };

    initialize();
  }
);
