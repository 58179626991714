defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/CustomPackageCard', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Helpers/PackagesUtils',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/Shared/PackageCard',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/ItemGamePlaceholder',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/StandardPackage/GameCouponItem'
], function (m, Ensighten, DataLayer, PackagesUtils, PackageCard, ItemGamePlaceholder, GameCouponItem) {
  const CustomPackageCard = {
    controller: function ({ controller }) {
      Object.assign(this, controller);

      this.onAddGame = (gameType) => {
        this.selectedGameType(gameType);
        this.getGameModel(gameType).then((gameModel) => {
          this.selectedGameModel(gameModel);
          if (this.selectedGameType() === 'EuroJackpot') {
            this.selectedGameModel().setNumberOfDraws(2);
          }
          this.customPackageFlowStep('rowsSelection');
        });
        Ensighten.pushGaEvent('custom_package', 'add', this.gameTypes[gameType]);
        setTimeout(() => {
          m.redraw();
        }, 0);
      };

      this.onRemoveGame = (gameType) => {
        const informationCouponsCopy = [...this.customPackageModel().information().coupons];
        const existingCouponIndex = this.customPackageModel().information().coupons.findIndex((coupon) => {
          return coupon.gameType === gameType;
        });
        const removedCoupon = informationCouponsCopy[existingCouponIndex];
        informationCouponsCopy.splice(existingCouponIndex, 1);
        this.customPackageModel().setPackageInformation({
          description: this.customPackageModel().information().description,
          coupons: informationCouponsCopy
        });
        Ensighten.pushGaEvent('custom_package', 'remove', this.gameTypes[gameType]);
        DataLayer.removeFromCart(removedCoupon.couponGames.map((coupon) => {
          let couponGameType = this.gameTypes[coupon.gameType];
          let variantPlayType = removedCoupon.playType;
          if (couponGameType === 'vikinglotto') {
            couponGameType = 'viking';
          }
          if (['JokerWednesday', 'JokerSaturday'].includes(coupon.gameType)) {
            variantPlayType = coupon.gameType.replace('Joker', '').toLowerCase();
          }
          return {
            name: couponGameType,
            price: coupon.price,
            quantity: coupon.rowCount,
            variant: DataLayer.variantName(couponGameType, coupon.drawCount, variantPlayType),
            category: DataLayer.categoryName(removedCoupon.playType),
            coupon: 'custom package'
          };
        }));
      };

      this.onEditGame = (gameType) => {
        this.selectedGameType(gameType);
        const existingCouponIndex = this.customPackageModel().information().coupons.findIndex((coupon) => {
          return coupon.gameType === gameType;
        });
        const couponGames = this.customPackageModel().information().coupons[existingCouponIndex].couponGames;
        const mainGame = couponGames.find((couponGame) => couponGame.gameType === gameType);
        const hasWednesdayJoker = couponGames.find((couponGame) => couponGame.gameType === 'JokerWednesday');
        const hasSaturdayJoker = couponGames.find((couponGame) => couponGame.gameType === 'JokerSaturday');

        this.getGameModel(gameType).then((gameModel) => {
          this.selectedGameModel(gameModel);
          this.customPackageFlowStep('rowsSelection');
          this.selectedGameModel().rowsToGenerate(mainGame.rowCount);
          this.selectedGameModel().setNumberOfDraws(mainGame.drawCount);
          if (hasWednesdayJoker) {
            this.selectedGameModel().withJokerWednesday(true);
          }
          if (hasSaturdayJoker) {
            this.selectedGameModel().withJokerSaturday(true);
          }
          this.selectedGameModel().save();
        });
        Ensighten.pushGaEvent('custom_package', 'edit', this.gameTypes[gameType]);
      };
    },

    view: function (controller) {
      const packageCoupons = controller.customPackageModel().information().coupons;
      const packagePool = PackagesUtils.getPackagePool(packageCoupons);
      const isPackageEmpty = packageCoupons.length === 0;
      const actionsButtons = {
        onRemoveGame: controller.onRemoveGame,
        onEditGame: controller.onEditGame
      };
      const actionButtonClassName = () => {
        let className = '';
        if (isPackageEmpty) {
          className = 'btn--disabled';
        }
        if (controller.processingPurchase()) {
          className = 'btn--loading';
        }
        return className;
      };
      return PackageCard({
        tagline: packagePool.totalPoolSize === 0 ? controller.customPackageDictionary('CustomPackageFlow/PackageOverview/Header/EmptyPackageHeading') : controller.customPackageDictionary('CustomPackageFlow/PackageOverview/Header/ValidPackageHeading'),
        title: packagePool.totalPoolSize === 0 ? controller.customPackageDictionary('CustomPackageFlow/PackageOverview/Header/EmptyPackageSubheading') : (packagePool.isMinimumPoolSize ? 'min ' : '') + `${packagePool.totalPoolSize} MIO`,
        body: m('.custom-package__games-options', controller.customPackageGameOptions.map((gameName) => {
          const couponFromCustomPackage = packageCoupons.find((coupon) => coupon.gameType === gameName);
          if (couponFromCustomPackage) {
            return m(GameCouponItem, { controller, coupon: couponFromCustomPackage, actionsButtons });
          }
          return m(ItemGamePlaceholder, { controller, gameName, onAddGame: controller.onAddGame });
        })),
        footer: m('.multi-packages-button', {
          class: actionButtonClassName(),
          onclick: controller.generatePackage
        }, isPackageEmpty ? controller.customPackageDictionary('CustomPackageFlow/PackageOverview/BuyButtonEmptyPackage') : controller.customPackageDictionary('CustomPackageFlow/PackageOverview/BuyButtonValidPackage', { price: controller.totalPrice() }))
      });
    }
  };

  return CustomPackageCard;
});