defineDs('DanskeSpil/Domain/Tutorial/Scripts/Pages/TutorialAvalonPage',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialFooter',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialNavigation',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialHero'
  ], function (m, TutorialFooter, Navigation, Hero) {

    return {
      controller: function (args) {
        window.scrollTo(0, 0);

        this.safeTitle = decodeURI(m.route.param('tutorial'));
        this.mostVisitedLabel = args.mostVisitedLabel;
        this.mostVisitedList = args.mostVisitedList;
        this.footerSettings = args.footerSettings;
        this.titleCssStyle = args.titleCssStyle;
        this.getTextWithHighligt = args.getTextWithHighligt;
        this.tutorial = args.allTutorials.filter(function (tutorial) {
          return tutorial.safeTitle === this.safeTitle;
        }.bind(this))[0];
        this.avalonContent = null;

        this.onRender = function () {
          var contentWithoutComments = this.tutorial.content?.outerHTML?.replace(/<!--.*-->/g, '') ?? '';
          this.avalonContent = m.trust(this.getTextWithHighligt(contentWithoutComments));

          setTimeout(function () {
            m.redraw();
          }, 0);
        }.bind(this);
      },

      view: function (controller) {
        return m('.tutorial__avalon', [
          Navigation(controller), // eslint-disable-line compat/compat
          Hero({ title: controller.tutorial.title, style: controller.titleCssStyle, activate: controller.tutorial.activateHeader }),
          m('.tutorial__content-box.tutorial__avalon-content', {
            class: [
              controller.tutorial.useFullWidth ? 'tutorial__content-box--full-width' : '',
              controller.tutorial.useFullWidth && !controller.tutorial.activateHeader ? 'tutorial__content-box--no-radius' : ''
            ].join(' '),
            config: controller.onRender
          }, controller.avalonContent),
          controller.footerSettings ? TutorialFooter({ style: controller.titleCssStyle, activate: controller.tutorial.activateFooter, ...controller.footerSettings }) : null
        ]);
      }
    };
  });
