defineDs('DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params', [
  'Shared/Framework/Mithril/Scripts/Helpers/Params'
], function (MithrilParams) {

  // Functions:
  var destruct = function (params) {
    var segments = params.split('&');
    var properties = {};
    var singles = [];

    for (var i = 0; i < segments.length; i++) {
      var segment = segments[i];

      if (segment !== '') {
        var split = segment.split('=');

        if (split.length === 1) {
          singles.push(split[0]);
        } else {
          properties[split[0]] = split[1];
        }
      }
    }

    return { properties: properties, singles: singles };
  };

  var extract = function (param) {
    return MithrilParams.extract(param, null);
  };

  var set = function (params) {
    var additional = destruct(params);
    var existing = destruct(location.search.slice(1));
    var final = [];
    let hash = location.hash;

    // Maybe URL is constucted with hash, e.g. /roed-konto/mine-oplysninger#/cards?foo&bar.
    const hasParamsInHash = location.hash?.split('?');

    if (hasParamsInHash?.length > 1) {
      hash = hasParamsInHash[0];
      final.push(hasParamsInHash[1]);
    }

    Object.keys(additional.properties).map(function (key) {
      existing.properties[key] = additional.properties[key];
    });

    additional.singles.map(function (key) {
      if (existing.singles.indexOf(key) === -1) {
        existing.singles.push(key);
      }
    });

    Object.keys(existing.properties).map(function (property) {
      final.push(property + '=' + existing.properties[property]);
    });

    existing.singles.map(function (single) {
      final.push(single);
    });

    history.replaceState(
      null,
      document.title,
      location.pathname + '?' + final.join('&') + hash
    );
  };

  /**
   * Removes specified URL parameters from the current URL and updates the browser history.
   * @param {string} params - A string containing URL parameters to be removed (e.g., "param1&param2").
   */
  const remove = (params) => {
    // Split the parameters to be removed and the existing parameters into arrays.
    const removeParams = params.split('&');
    const existing = location.search.slice(1).split('&');

    // Filter and construct the URL parameters to keep.
    const urlParams = existing
      .filter((existingUrlParam) => !removeParams.includes(existingUrlParam.split('=')[0]))
      .filter((element) => element !== '')
      .join('&');

    // Create the final URL.
    const finalURL = urlParams.length ? `?${urlParams}` : '';

    // Update the browser history with the new URL.
    setTimeout(() => {
      history.replaceState(null, document.title, location.pathname + finalURL + location.hash);
    }, 0);
  };

  var uniqueParams = function (params) {
    var querySplit = params.split('?');

    if (querySplit.length !== 2) {
      return params;
    }

    var paramsSplit = querySplit[1].split('&').filter(function (param) {
      // remove empty items in array.
      return param;
    });
    var unique = [];

    for (var i = 0; i < paramsSplit.length; i++) {
      var param = paramsSplit[i];

      if (unique.indexOf(param) === -1) {
        unique.push(param);
      }
    }

    return querySplit[0] + '?' + unique.join('&');
  };

  // Public functions:
  return {
    detect: MithrilParams.detect,
    extract: extract,
    set: set,
    remove,
    uniqueParams: uniqueParams
  };

});
