defineDs('DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialNavigation',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
  ],
  function (m) {
    return function (settings) {
      var currentUrl = m.route.param('tutorial');
      return settings.mostVisitedList.length > 0 ? m('.tutorial-navbar', [
        m('.tutorial-navbar-label', settings.mostVisitedLabel),
        m('.tutorial-navbar-cave',
          m('.tutorial-navbar-cave-inner')
        ),
        m('.tutorial-navbar-container', {
          onscroll: function (event) {
            var container = event.target;
            var cave = document.querySelector('.tutorial-navbar-cave');
            if (container.scrollLeft) cave.classList.add('tutorial-navbar-cave--open');
            else cave.classList.remove('tutorial-navbar-cave--open');
          }
        }, [
          m('.tutorial-navbar-navs', settings.mostVisitedList.map(function (tutorial) {
            return m('a.tutorial-navbar-nav', {
              class: currentUrl === tutorial.safeTitle ? 'tutorial-navbar-nav--hide' : '',
              href: tutorial.urlPrefix + '/' + tutorial.safeTitle,
              config: m.route
            }, tutorial.title);
          }))
        ])
      ]) : null;
    };
  });
