defineDs('DanskeSpil/Framework/FlipClock/Scripts/FlipClock', [
  'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'Shared/Framework/Mithril/Scripts/Helpers/DOMUtils',
], function (TimeUtils, Utils, DOMUtils) {

  var resizeClocks = function () {
    if (document.querySelector('.js-flip-clock')) {
      Utils.logDeprecated('Framework/FlipClock/Scripts/FlipClock.js - .js-flip-clock found'); // Added by MIPE 2022-08-04
    }

    document.querySelectorAll('.js-flip-clock').forEach(function ($clock) {
      var breakPoints = $clock.dataset['breakPoints'];
      var parentWidth = $clock.parentNode.getBoundingClientRect().width;
      var invertColors = $clock.dataset['invertColor'];

      $clock.classList.remove('flip-clock--xsmall', 'flip-clock--small', 'flip-clock--default', 'flip-clock--large', 'flip-clock--xlarge', 'flip-clock--invert');

      if (invertColors === 'True') {
        $clock.classList.add('flip-clock--invert');
      }

      if (breakPoints.xlarge && parentWidth >= breakPoints.xlarge) {
        $clock.classList.add('flip-clock--xlarge');
      } else if (breakPoints.large && parentWidth >= breakPoints.large) {
        $clock.classList.add('flip-clock--large');
      } else if (breakPoints.default && parentWidth >= breakPoints.default) {
        $clock.classList.add('flip-clock--default');
      } else if (breakPoints.small && parentWidth >= breakPoints.small) {
        $clock.classList.add('flip-clock--small');
      } else {
        $clock.classList.add('flip-clock--xsmall');
      }
    });
  };

  var domCounter = function ($clock, counter, val) {
    var ul = $clock.querySelectorAll('ul')[counter]; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

    var beforeVal = ul.querySelector('.before .js-up')?.textContent ?? '';
    var valUp;
    var valDown = val;

    if (counter === 0) {
      // days
      if (val > 99) {
        valUp = 99;
        valDown = 99;
      } else {
        valUp = val === 99 ? 99 : val + 1;
      }
    } else if (counter === 1) {
      // hours
      valUp = val === 23 ? 0 : val + 1;
    } else {
      // mins and secs
      valUp = val === 59 ? 0 : val + 1;
    }

    // prefix with zero for value 0-9
    valUp = ('0' + valUp).slice(-2);
    valDown = ('0' + valDown).slice(-2);

    if (beforeVal !== valUp) {
      var $flipper = ul.querySelector('li.active');

      const $jsUpActive = ul.querySelector('.active .js-up');
      const $jsDownActive = ul.querySelector('.active .js-down');
      const $jsUpBefore = ul.querySelector('.before .js-up');
      const $jsDownBefore = ul.querySelector('.before .js-down');

      if ($jsUpActive && $jsDownActive && $jsUpBefore && $jsDownBefore) {
        $jsUpActive.textContent = valUp;
        $jsDownActive.textContent = valUp;
        $jsUpBefore.textContent = valDown;
        $jsDownBefore.textContent = valDown;
      }

      if (!$flipper?.innerHTML) {
        $flipper = ul.querySelectorAll('li')[0];
        $flipper.classList.add('before');
        $flipper.classList.remove('active');
        $flipper.nextElementSibling.classList.add('active');
      } else if ($flipper.nextElementSibling === null) {
        ul.querySelectorAll('li')?.forEach(($el) => {
          $el.classList.remove('before');
        });
        $flipper.classList.add('before');
        $flipper.classList.remove('active');
        $flipper = ul.querySelectorAll('li')[0];
        $flipper.classList.add('active');
        $flipper.closest('.flip-clock').classList.add('play'); // eslint-disable-line no-jquery/no-closest -- Reason: Not a jquery selector
      } else {
        ul.querySelectorAll('li')?.forEach(($el) => {
          $el.classList.remove('before');
        });
        $flipper.classList.add('before');
        $flipper.classList.remove('active');
        $flipper.nextElementSibling.classList.add('active');
        $flipper.closest('.flip-clock').classList.add('play'); // eslint-disable-line no-jquery/no-closest -- Reason: Not a jquery selector
      }
    }
  };

  var flipPlay = function ($clock) {
    var deadline = new Date(parseInt($clock.dataset['endDate'], 10));
    var diff = new Date(deadline) - TimeUtils.getServerDateTime();
    var t = TimeUtils.getCompoundTimeObject(diff);
    domCounter($clock, 3, t.secs);
    domCounter($clock, 2, t.mins);
    domCounter($clock, 1, t.hours);
    domCounter($clock, 0, t.days);
  };

  var init = function () {
    document.querySelectorAll('.js-flip-clock').forEach(function ($clock) {
      const parser = new DOMParser();
      const flipClock = parser.parseFromString('<ul class="flip"><li><div class="flipper"><div class="up"><div class="shadow"></div><div class="inn js-up"></div></div><div class="down"><div class="shadow"></div><div class="inn js-down"></div></div></div></li><li><div class="flipper"><div class="up"><div class="shadow"></div><div class="inn js-up"></div></div><div class="down"><div class="shadow"></div><div class="inn js-down"></div></div></div></li></ul>', 'text/html').body.firstChild;

      $clock.appendChild(flipClock.cloneNode(true)); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      $clock.appendChild(flipClock.cloneNode(true)); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      $clock.appendChild(flipClock.cloneNode(true)); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      $clock.appendChild(flipClock.cloneNode(true)); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

      setInterval(function () {
        flipPlay($clock);
      }, 1000);
    });

    resizeClocks();

    window.addEventListener('resize', () => {
      setTimeout(resizeClocks, 100);
    });
  };

  DOMUtils.ready(function () {
    init();
  });

});
