defineDs('DanskeSpil/Domain/Lotto/Scripts/Helpers/SaturdayLottoApi',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoUtils'
  ],
  function (m, ApiRequest, LottoUtils) {

    // Variables:
    var baseURL = '/dlo/scapi/danskespil/numbergames/lotto';
    var baseJokerURL = '/dlo/scapi/danskespil/numbergames/jokersaturday';
    var trackingURL = '/dlo/scapi/danskespil/sitecoretracking';

    // Functions:
    var prepareParamString = LottoUtils.prepareParamString;

    var createCoupon = function (data) {
      var deferred = m.deferred();

      var req = ApiRequest({
        method: 'POST',
        url: baseURL + '/coupon',
        data: data
      });

      req.then(function (response) {
        response = response || {};

        if (response && !response.Message) {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      }, function (response) {
        response = response || { Message: 'ERROR_NOT_GIVEN' };

        deferred.reject(response);
      });

      return deferred.promise;
    };

    var createPersonalizedCoupon = function (type, data) {
      if (!type) {
        return;
      }

      return ApiRequest({
        method: 'POST',
        background: false,
        url: trackingURL + '/set' + (type.slice(0, 1).toUpperCase() + type.slice(1)) + 'Coupon',
        data: {
          brandName: 'lotto',
          componentDataJson: data
        },
        timeout: 1
      });
    };

    var clearPersonalizedCoupon = function (gameType) {
      return ApiRequest({
        method: 'POST',
        url: trackingURL + '/clearCustomCoupon',
        data: {
          brandName: 'lotto',
          gameType: gameType,
          componentDataJson: null
        },
        timeout: 1
      });
    };

    var getCompletedDrawDates = function (options) {
      var params = prepareParamString(options);

      return ApiRequest({
        url: baseURL + '/completedDrawDates' + params,
        localCacheTTL: 60,
        unwrapSuccess: function (response) {
          return response && response.drawDates ? response.drawDates : [];
        }
      });
    };

    var getWinningNumbersLotto = function (options) {
      var params = prepareParamString(options);

      return ApiRequest({
        url: baseURL + '/winningNumbers' + params,
        localCacheTTL: 60,
        unwrapSuccess: function (response) {
          return response;
        }
      });
    };

    var getLottoInfo = function () {
      return ApiRequest({
        url: baseURL + '/gameinformation',
      });
    };

    var getRandomNumbers = function (options) {
      var params = prepareParamString(options);

      return ApiRequest({
        method: 'POST',
        url: baseURL + '/random' + params,
        unwrapSuccess: function (response) {
          var rows = [];

          if (response && response.rows) {
            response.rows.map(function (row) {
              rows.push(row.numbers);
            });
          }

          return rows;
        }
      });
    };

    var getRandomJokerNumbers = function (options) {
      var params = prepareParamString(options);

      return ApiRequest({
        url: baseJokerURL + '/random' + params,
        unwrapSuccess: function (response) {
          var rows = [];

          if (response && response.length > 0) {
            for (var i = 0; i < response.length; i++) {
              rows.push(response[i].split(''));
            }
          }

          return rows;
        }
      });
    };

    var getSystemKeys = function (systemName) {
      var deferred = m.deferred();

      var params = prepareParamString({ systemName: systemName });

      ApiRequest({
        url: baseURL + '/gameSystemKeys' + params,
        localCacheTTL: 60 * 60 * 24 * 30, // 30 days
        unwrapSuccess: function (response) {
          return response;
        }
      }).then(function (data) {
        if (data && data.keys) {
          deferred.resolve(data.keys);
        } else {
          deferred.reject();
        }
      }, function () {
        deferred.reject();
      });

      return deferred.promise;
    };

    var getSystemWinningProbabilities = function (systemName) {
      var deferred = m.deferred();
      var params = prepareParamString({ systemName: systemName });

      ApiRequest({
        url: baseURL + '/gameSystemwinningprobabilities' + params,
        localCacheTTL: 60 * 60 * 24, // 1 day
        unwrapSuccess: function (response) {
          return response;
        }
      }).then(function (data) {
        if (data) {
          deferred.resolve(data);
        } else {
          deferred.reject();
        }
      });

      return deferred.promise;
    };

    var getCoupon = function (couponId) {
      var options = { couponId: couponId };
      var params = prepareParamString(options);
      return ApiRequest({
        url: baseURL + '/coupon' + params
      });
    };

    var getSubscribeReminder = function () {
      return ApiRequest({
        method: 'GET',
        url: baseURL + '/subscribeReminder'
      });
    };

    var setSubscribeReminder = function (subscribe) {
      return ApiRequest({
        method: 'POST',
        url: baseURL + '/subscribeReminder?subscribe=' + subscribe
      });
    };

    // Public functions:
    return {
      createCoupon: createCoupon,
      createPersonalizedCoupon: createPersonalizedCoupon,
      clearPersonalizedCoupon: clearPersonalizedCoupon,
      getCompletedDrawDates: getCompletedDrawDates,
      getWinningNumbersLotto: getWinningNumbersLotto,
      getLottoInfo: getLottoInfo,
      getRandomNumbers: getRandomNumbers,
      getRandomJokerNumbers: getRandomJokerNumbers,
      getSystemKeys: getSystemKeys,
      getCoupon: getCoupon,
      getSystemWinningProbabilities: getSystemWinningProbabilities,
      getSubscribeReminder: getSubscribeReminder,
      setSubscribeReminder: setSubscribeReminder
    };

  });
