defineDs('DanskeSpil/Domain/NumberGames/Scripts/Helpers/WinningNumbersApi', [
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
], (ApiRequest, Utils) => {

  const baseURL = '/dlo/scapi/danskespil/numbergames';


  const getLottoDrawDates = () => ApiRequest({
    url: `${baseURL}/lotto/completedDrawDates`,
    localCacheTTL: 60,
    unwrapSuccess: (response) => {
      return response?.drawDates ? response.drawDates : [];
    },
  });


  const getVikinglottoDrawDates = () => ApiRequest({
    url: `${baseURL}/vikinglotto/completedDrawDates`,
    localCacheTTL: 60,
    unwrapSuccess: (response) => {
      return response?.drawDates ? response.drawDates : [];
    },
  });


  const getEurojackpotDrawDates = () => ApiRequest({
    method: 'GET',
    url: `${baseURL}/eurojackpot/completedDrawDates`,
    localCacheTTL: 60,
    unwrapSuccess: (response) => {
      return response || [];
    },
  });


  const getLottoWinningNumbers = (options) => ApiRequest({
    url: `${baseURL}/lotto/winningNumbers${Utils.prepareParamString(options)}`,
    localCacheTTL: 60,
    unwrapSuccess: (response) => {
      return response;
    },
  });


  const getVikinglottoWinningNumbers = (options) => ApiRequest({
    url: `${baseURL}/vikinglotto/winningNumbers${Utils.prepareParamString(options)}`,
    localCacheTTL: 60,
    unwrapSuccess: (response) => {
      return response;
    },
  });


  const getEurojackpotWinningNumbers = (options) => ApiRequest({
    method: 'GET',
    url: `${baseURL}/eurojackpot/winningNumbers${Utils.prepareParamString(options)}`,
    localCacheTTL: 60,
    unwrapSuccess: (response) => {
      return response;
    },
  });


  const getJokerWinningNumbers = ({ drawId, jokerType }) => ApiRequest({
    method: 'GET',
    url: `${baseURL}/${jokerType}/winningNumbers${Utils.prepareParamString({ drawId, jokerType })}`,
    localCacheTTL: 60,
    unwrapSuccess: (response) => {
      return response;
    },
  });


  return {
    getLottoDrawDates,
    getVikinglottoDrawDates,
    getEurojackpotDrawDates,
    getLottoWinningNumbers,
    getVikinglottoWinningNumbers,
    getEurojackpotWinningNumbers,
    getJokerWinningNumbers,
  };

});
