defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/GenericOverlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/NumberGames/Scripts/Framework/LottiePlayer'
  ],
  function (m) {

    // Context:
    var GenericOverlay = function (options) {
      var useLottieFile = customElements.get('lottie-player') && options.lottieUrl;
      var onRendered = function (element) {
        if (typeof element.play === 'function') {
          // we need to use setTimeout in order to wait for the element to be shown before triggering play()
          setTimeout(function () {
            element.setAttribute('loop', 'true');
            element.play();
          }, 0);
        }
      };

      // Return:
      return {
        class: m.prop(options.className || 'no-extra-class'),

        render: function (confirm, dismiss) {
          return m('.generic-overlay', {
            class: this.class()
          }, [
            m('.panel', [
              m('.generic-overlay__close-button', { onclick: dismiss }, [
                m('svg', { class: 'icon' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-x' })
                ])
              ]),
              options.svgUsePath ? m('svg.icon-wrapper', [
                m('use', { href: options.svgUsePath })
              ]) : null,
              options.iconUrl ? m('.icon-wrapper', [
                useLottieFile ? m('lottie-player[autoplay]', {
                  src: options.lottieUrl,
                  config: onRendered
                }) : m('img', { src: options.iconUrl }),
              ]) : null,
              options.imageCaption ? m('.image-caption', options.imageCaption) : null,
              m('h2', { class: 'headline' }, options.header),
              options.subheading ? m('h3.subheading', options.subheading) : null,
              options.bodyView ? options.bodyView : null,
              options.body && !options.bodyView ? m('p', { class: 'text' }, m.trust(options.body)) : null,
              m('div', { class: 'buttons' }, [
                function () {
                  if (options.dismiss && !options.confirm) {
                    return m('.generic-overlay__cta-button', { onclick: confirm, }, options.dismiss);
                  }
                  return [
                    options.dismiss ? m('.generic-overlay__cta-button', { onclick: dismiss, }, m('span', options.dismiss)) : null,
                    options.confirm ? m('.generic-overlay__cta-button.primary-button', {
                      class: options.confirmProcessing ? ' processing' : '',
                      onclick: options.confirmCallBack || confirm
                    }, m('span', options.confirm)) : null
                  ];
                }()
              ])
            ])
          ]);
        }
      };
    };

    // Public functions:
    return GenericOverlay;

  });
