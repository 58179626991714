defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/SelectorScreen', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/SelectorScreenOption',
// eslint-disable-next-line no-unused-vars
], function (m, SelectorScreenOption) {
  const SelectorScreen = {
    controller: function ({ controller }) {
      Object.assign(this, controller);

      switch (this.gameName) {
      case 'vikinglotto':
        this.backgroundColor = 'bg-vikinglotto-blue-1';
        break;
      default:
        this.backgroundColor = 'bg-lotto-red-1 group-[.christmas-theme]:bg-lotto-christmas-red-radial-gradient';
        break;
      }
    },
    view: function (controller) {
      return (<div className={`flex flex-col items-center gap-24 ${controller.useCompactView ? '' : 'md:py-32 md:flex-row md:items-stretch md:justify-center'}`}>
        {controller.systemsTypes.map((systemType) => {
          return (<SelectorScreenOption controller={controller} systemType={systemType} />);
        })}
      </div>);
    }
  };

  return SelectorScreen;
});