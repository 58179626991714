defineDs('DanskeSpil/Domain/NumberGames/Scripts/HowToPlay', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Domain/NumberGames/Scripts/HowToPlayContent'
],
function (Component, HowToPlayContent) {

  // Component:
  Component('how-to-play', function (m, route, settings, property) {

    // Components:
    var root = {
      controller: function () {

        // Variables:
        this.loading = m.prop(true);
        this.property = property;

        this.getFrame = function (content, render) {
          if (content.provider === 'vimeo' || content.provider === 'youtube') {
            return m('iframe', {
              config: render.depend(content.provider + '-iframe-' + content.id),
              src: content.url,
              frameborder: '0',
              allowfullscreen: '0',
              id: content.contentId
            });
          } else if (content.provider === 'slideshare') {
            return m.trust(content.html);
          }
        }.bind(this);

        // Setup:
        var youtube = [];
        var youtubeIds = [];
        var vimeo = [];
        var vimeoIds = [];
        var slideshare = [];
        var slideshareIds = [];
        var requests = [];

        settings.contents.forEach(function (value, index) {
          var id = value.contentId;

          value.html = '';
          value.order = index;
          value.playing = false;
          value.ready = false;

          // YouTube:
          if (value.provider === 'youtube' && youtubeIds.indexOf(id) === -1) {
            youtubeIds.push(id);
            youtube.push(value);

            // Vimeo:
          } else if (value.provider === 'vimeo' && vimeoIds.indexOf(id) === -1) {
            vimeo.push(value);
            vimeoIds.push(id);

            // SlideShare:
          } else if (value.provider === 'slideshare' && slideshareIds.indexOf(id) === -1) {
            var requestUrl = value.url + value.contentId + '&format=jsonp';

            requests.push(m.request({
              dataType: 'jsonp',
              url: requestUrl,
              callbackKey: 'callback',
              config: function (xhr) {
                xhr.setRequestHeader('Content-Type', 'application/javascript');
              }
            }).then(function (response) {
              value.html = response.html;

              slideshareIds.push(id);
              slideshare.push(value);
            }, function (error) {
              console.error('Error', error);
            }));
          }
        });

        m.sync(requests).then(function () {
          this.property('howToPlayData', {
            youtube: { ready: false, contents: youtube },
            vimeo: { ready: false, contents: vimeo },
            slideshare: { ready: false, contents: slideshare }
          });

          this.loading(false);

          m.redraw();
        }.bind(this));

      },

      // View:
      view: function (controller) {
        return m('div', { class: 'how-to-play-section' }, [
          m('div', { class: 'how-to-play-content-wrapper' }, [
            m('div', { class: 'close-button-wrapper' }),
            !controller.loading() ? HowToPlayContent(controller, settings) : null
          ])
        ]);
      }
    };

    // Routes:
    route('/', root);

  });

});