defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/WinningNumbers/WinnerTable', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
], (m, Utils) => {
  const WinnerTable = ({ d, gameTypeSelected, gameVersion, hideWinnerIds, prizes }) => {

    prizes = prizes.filter((prize) => {
      return prize.hasOwnProperty('name') && prize.hasOwnProperty('numberOfWinners') && prize.hasOwnProperty('amount');
    });

    if (!prizes || prizes.length === 0) {
      return;
    }

    const getName = ({ name }) => {
      const key = name?.replace(/[^a-z0-9]/gi, '-').replace(/--+/g, '-').replace(/-$/g, '').toLowerCase();
      const dictionaryValues = d(`Table/Cell1/${gameTypeSelected()}`);
      const dictionaryName = typeof dictionaryValues === 'object' ?
        (dictionaryValues[`v${gameVersion()}`] || {})[key] || dictionaryValues[key] : {};
      return typeof dictionaryName === 'string' ? dictionaryName : name;
    };

    return m('table.winning-numbers-winner-table', {
      class: `winning-numbers-winner-table--${gameTypeSelected()}`
    }, [

      m('tr.winning-numbers-winner-table__header', [
        m('th', d(`Table/Column1/${gameTypeSelected()}`)),
        m('th', d(`Table/Column2/${gameTypeSelected()}`)),
        m('th', d(`Table/Column3/${gameTypeSelected()}`)),
      ]),

      prizes.map((prize) => {
        if (hideWinnerIds.includes(prize.id)) {
          return;
        }

        return m('tr.winning-numbers-winner-table__row', {
          'data-winner-or-joker-id': prize.id
        }, [
          m('td', m.trust(getName(prize))),
          m('td', Utils.formatNumber(prize.numberOfWinners)),
          m('td', Utils.formatCurrency(prize.amount)),
        ]);
      }),

    ]
    );

  };

  return WinnerTable;

});
