defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/TopSpotPageHeader',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/NumberGamesDictionary',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
    'DanskeSpil/Framework/FlipClock/Scripts/MithrilFlipClock',
    'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo'
  ],
  function (Component, NumberGamesDictionary, Utils, MithrilFlipClock, JackpotInfo) {

    // Component:
    Component('top-spot-page-header', [NumberGamesDictionary], function (m, route, settings, property) {
      var componentElement = this.$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element

      if (!settings.BackgroundMediaSetting.Url && settings.BackgroundColor) {
        componentElement.style.backgroundColor = settings.BackgroundColor;
      }

      var mancheteCheck = function () {
        if (settings.Manchete.EnableTopBorderFrame) {
          componentElement.classList.add('manchete-top');
        }
        if (settings.Manchete.EnableBottomBorderFrame) {
          componentElement.classList.add('manchete-bottom');
        }
      };

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.d = NumberGamesDictionary.get.bind({ prefix: '/NumberGames' });
          this.property = property;
          this.flipClockSettings = {
            deactivated: settings.FlipClockDeactivated,
            endDate: settings.FlipClockEndDate,
            staticFlip: settings.FlipClockStaticFlip,
            colorLight: settings.FlipClockColorLight,
            labelLight: settings.FlipClockLabelLight,
            sizeSmall: settings.FlipClockSizeSmall,
            sizeDefault: settings.FlipClockSizeDefault,
            sizeLarge: settings.FlipClockSizeLarge,
            sizeExtraLarge: settings.FlipClockSizeExtraLarge,
          };
          this.iconTagLine = m.prop();
          this.preface = m.prop();
          this.title = m.prop();
          this.subtitle = m.prop();
          var prefaceReplacedValue;
          var titleReplacedValue;
          var subTitleReplacedValue;
          var shouldShowFallBack;


          // Functions:
          var checkIfTextHasError = function (rawValue, replacedValue) {
            // 1) if handlebar is undefined, fx. {foobar}, JackpotInfo will return an empty string.
            if (rawValue !== '' && replacedValue === '') {
              shouldShowFallBack = true;
            }

            // 2) if feed is down, there might be a handlebar.
            if (replacedValue.match(/{.*?}/)) {
              shouldShowFallBack = true;
            }

            // 3) We check for dynamic JackpotInfo replacements and get the entire info-object.
            // Info-object is then used to determine if specific game-feed is down (during draw)
            var regex = /{[^{}]+}/g;
            var m;
            while ((m = regex.exec(rawValue)) !== null) {
              // This is necessary to avoid infinite loops with zero-width matches
              if (m.index === regex.lastIndex) {
                regex.lastIndex++;
              }
              // The result can be accessed through the `m`-variable.
              m.forEach(function (match) {
                // we strip handlebars from match and try to get info-object. E.g. '{eurojackpot}' -> 'eurojackpot'
                var info = JackpotInfo.getByGame(match.slice(1, -1));
                // poolSizeDecimal === 0 indicates that the feed was found but has no value, so we show fallback
                if (info && info.poolSizeDecimal === 0) {
                  shouldShowFallBack = true;
                }
              });
            }
          };

          shouldShowFallBack = settings.ShowFallBack;
          var replaceDay = function (str, day) {
            if (str.indexOf('{day}') !== -1 && day) {
              return str.replace('{day}', day);
            }
            return str;
          };

          if (!shouldShowFallBack) {
            prefaceReplacedValue = JackpotInfo.getDynamicFeed(replaceDay(settings.Preface, settings.FlipClockEndDay));
            checkIfTextHasError(settings.Preface, prefaceReplacedValue);

            titleReplacedValue = JackpotInfo.getDynamicFeed(replaceDay(settings.Title, settings.FlipClockEndDay));
            checkIfTextHasError(settings.Title, titleReplacedValue);

            subTitleReplacedValue = JackpotInfo.getDynamicFeed(replaceDay(settings.Subtitle, settings.FlipClockEndDay));
            checkIfTextHasError(settings.Subtitle, subTitleReplacedValue);
          }

          if (shouldShowFallBack) {
            this.preface(settings.PrefaceFallBack || '');
            this.title(settings.TitleFallBack || '');
            this.subtitle(settings.SubtitleFallBack || '');
          } else {
            this.preface(prefaceReplacedValue || '');
            this.title(titleReplacedValue || '');
            this.subtitle(subTitleReplacedValue || '');
          }

          this.splashDOM = function () {
            var splashPosition = function () {
              var position = '';
              if (settings.Splash.PositionAbsolute && !Utils.isMobile()) {
                if (settings.Splash.PositionFromTop) {
                  position += 'top:' + settings.Splash.PositionFromTop + '%;';
                }
                if (settings.Splash.PositionFromLeft) {
                  position += 'left:' + settings.Splash.PositionFromLeft + '%;';
                }
              } else if (!Utils.isMobile()) {
                if (settings.Splash.PositionFromTop) {
                  position += 'margin-top:' + settings.Splash.PositionFromTop + '%;';
                }
                if (settings.Splash.PositionFromLeft) {
                  position += 'margin-left:' + settings.Splash.PositionFromLeft + '%;';
                }
              }

              return position;
            };
            if (settings.Splash.ImageUrl || settings.Splash.ImageTabletUrl || settings.Splash.ImageMobileUrl) {
              return [
                m('picture', {
                  class: 'top-spot-splash' + (settings.Splash.ReverseLeftToRight ? ' right' : ''),
                  style: splashPosition()
                }, [
                  m('img', { src: settings.Splash.ImageUrl })
                ])
              ];
            }
          };

          this.fontSizeAndColorStyles = function (className, size, unit, color) {
            var style = className + '{';
            style += size ? 'font-size:' + parseInt(size, 10) + unit + ' !important;' : '';
            style += color ? 'color:' + color + ' !important;' : '';
            style += '}';
            return size || color ? style : '';
          };

          this.desktopAndMobileStyles = function () {
            var prefaceStyleDesktop = this.fontSizeAndColorStyles('.preface-text__line', settings.PrefaceDecoration.FontSize, 'px', settings.PrefaceDecoration.FontColorHex);
            var titleStyleDesktop = this.fontSizeAndColorStyles('.title-text__line', settings.TitleDecoration.FontSize, 'px', settings.TitleDecoration.FontColorHex);
            var subtitleStyleDesktop = this.fontSizeAndColorStyles('.subtitle-text__line', settings.SubtitleDecoration.FontSize, 'px', settings.SubtitleDecoration.FontColorHex);
            var desktopStyles = prefaceStyleDesktop + titleStyleDesktop + subtitleStyleDesktop;

            var prefaceStyleMobile = this.fontSizeAndColorStyles('.preface-text__line', settings.PrefaceMobileDecoration.FontSize, 'vw', settings.PrefaceMobileDecoration.FontColorHex);
            var titleStyleMobile = this.fontSizeAndColorStyles('.title-text__line', settings.TitleMobileDecoration.FontSize, 'vw', settings.TitleMobileDecoration.FontColorHex);
            var subtitleStyleMobile = this.fontSizeAndColorStyles('.subtitle-text__line', settings.SubtitleMobileDecoration.FontSize, 'vw', settings.SubtitleMobileDecoration.FontColorHex);
            var mobileStyles = '@media (max-width: 767px) {' + prefaceStyleMobile + titleStyleMobile + subtitleStyleMobile + '}';

            return m('style', desktopStyles + mobileStyles);
          };

          // Init
          mancheteCheck();
        },

        view: function (controller) {
          return [
            controller.desktopAndMobileStyles(),
            m('picture', {
              class: 'background-image-wrapper',
              style: 'background-color:' + settings.BackgroundColor
            }, [
              m('source', { srcset: settings.BackgroundImageMobileSource, media: '(max-width: 767px)' }),
              m('source', { srcset: settings.BackgroundImageSource, media: '(min-width: 1224px)' }),
              m('source', { srcset: settings.BackgroundImageTabletSource, media: '(min-width: 768px)' }),
              m('img', { class: 'background-image', src: settings.BackgroundImageSource, alt: '' })
            ]),
            function () {
              if (!Utils.isMobile() || !settings.Splash.ReverseTopToBottom) {
                return controller.splashDOM();
              }
            }(),
            m('.top-spot__content-wrapper', [
              function () {
                if (settings.IconSource || controller.iconTagLine()) {
                  return [m('div', { class: 'top-spot__icon-box' }, [
                    m('img.icon__image', { src: settings.IconSource }),
                    m('span.icon-tag__line', controller.iconTagLine())
                  ])];
                }
              }(),
              m('.top-spot__text-wrapper', [
                controller.preface() ? m('.preface-text__line', controller.preface()) : '',
                controller.title() ? m('.title-text__line', controller.title()) : '',
                controller.subtitle() ? m('.subtitle-text__line', controller.subtitle()) : '',
              ]),

              !controller.flipClockSettings.deactivated ?
                m('.top-spot__flipclock-wrapper', m(MithrilFlipClock, { settings: controller.flipClockSettings })) : null,

              function () {
                if (settings.CallToAction.Text) {
                  return [m('a', {
                    class: 'top-spot__button',
                    href: settings.CallToAction.Url,
                    target: settings.CallToAction.Target,
                    title: settings.CallToAction.Title
                  }, settings.CallToAction.Text)];
                }
              }()
            ]),
            function () {
              if (Utils.isMobile() && settings.Splash.ReverseTopToBottom) {
                return controller.splashDOM();
              }
            }()
          ];
        }

      };

      // Setup routes:
      route('/', root);

    });

  });
