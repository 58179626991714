defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/PurchaseBar',
  [
    'Common/Framework/EventHandling/Scripts/OutsideClick',
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Resize',
    'Shared/Framework/Mithril/Scripts/Helpers/Scroll',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoUtils'
  ],
  function (OutsideClick, m, Render, Resize, Scroll, VikingLottoUtils) {

    var numberOfDrawsFromRoute = VikingLottoUtils.getQueryParam('rows') || undefined;

    // View:
    var PurchaseBar = function (controller) {

      // Variables:
      var $bar = null;
      var $container = null;
      var $top = null;
      var $wrapper = $DS(window);
      var d = controller.d;
      var game = controller.game();
      var render = Render();

      if (numberOfDrawsFromRoute >= 1 && numberOfDrawsFromRoute <= game.numberOfDrawsOptions().length) {
        game.setNumberOfDraws(numberOfDrawsFromRoute);
      }

      var drawRepeatMax = typeof game.getDrawRepeatMax() === 'number' ? game.getDrawRepeatMax() : null;
      var numberOfDraws = game.numberOfDraws();

      if (numberOfDrawsFromRoute == 0) {
        numberOfDraws = 0;
      }

      // Functions:
      var headerHeight = function () {
        var $header = $DS('.top-navigation');

        if ($header.length === 0) {
          return 0;
        }

        var transform = parseInt($header.css('transform').split(',')[5], 10) || 0;

        return transform < 0 ? 0 : $header.outerHeight();
      };

      var daysStyle = function () {
        var height = controller.property('purchaseBarDaysHeight');
        var dayListItemHeight = controller.property('dayListItemHeight');
        var maxHeight = controller.property('purchaseBarMaxHeight') - headerHeight();

        if (maxHeight < dayListItemHeight * 2.5) {
          maxHeight = dayListItemHeight * 2.5;
        }

        return controller.property('purchaseBarDays') ? 'max-height: ' + (height < maxHeight ? height : maxHeight) + 'px;' : '';
      };

      var formatDraws = function (draws) {
        if (draws === 0) {
          return d('PurchaseBar/PlayRowsEveryWeek');
        }

        return d(draws < 2 ? 'PurchaseBar/NumberOfDrawsSingular' : 'PurchaseBar/NumberOfDrawsPluralis', { draws: draws });
      };

      var setDraws = function (value) {
        return function () {
          numberOfDrawsFromRoute = value;
          game.setNumberOfDraws(value);
        };
      };

      var sticky = function () {
        var barHeight = $bar.outerHeight();
        var topTop = $top.length > 0 ? $top.offset().top : 0;
        var containerHeight = $container.outerHeight();
        var containerTop = $container.offset().top;
        var parentWidth = $bar.parent().width();
        var scrollTop = $wrapper.scrollTop();
        var windowHeight = window.innerHeight;

        // Viewport:
        var aboveViewport = scrollTop > (topTop === 0 ? containerTop : topTop) + containerHeight;
        var belowViewport = scrollTop + windowHeight < (topTop === 0 ? containerTop : topTop);
        var relativeViewport = !aboveViewport && scrollTop + windowHeight > containerTop + containerHeight;

        $bar.attr('style', '');
        $container.attr('style', '');

        // Sticky:
        if (!aboveViewport && !belowViewport && !relativeViewport && game.playType() !== 'Lightning') {
          $bar.css({ bottom: '0', position: 'fixed', width: parentWidth });
          $container.css({ paddingBottom: barHeight + 'px' });
        }

        // Dropdown:
        var containerEnd = containerTop + containerHeight;
        var scrollBottom = scrollTop + windowHeight;

        controller.property('purchaseBarMaxHeight', (scrollBottom > containerEnd ? (windowHeight - barHeight) - (scrollBottom - containerEnd) : windowHeight - barHeight) - 20);
      };

      var toggleDays = function () {
        controller.property('purchaseBarDays', !controller.property('purchaseBarDays'));
        controller.property('purchaseBarStake', false);
      };

      // Render:
      render.ready.then(function (nodes) {
        $bar = $DS(nodes['purchase-bar'].$element);
        $container = $DS('.purchase-bar-container');
        $top = $DS('.purchase-bar-top');

        if ($bar) {
          setTimeout(function () {
            sticky();
          }, 250);
        }

        if (!nodes['purchase-bar'].rendered && $container.length > 0) {
          setTimeout(function () {
            sticky();
          }, 50);

          Scroll(function () {
            sticky();
          });

          Resize(function () {
            sticky();
          });

          OutsideClick(nodes['purchase-bar'].$element, nodes['purchase-bar'].context, function () {
            controller.property('purchaseBarDays', false);
          });
        }

        if (!nodes['days-list'].rendered) {
          controller.property('purchaseBarDaysHeight', $DS(nodes['days-list'].$element).outerHeight());
        }
      });

      // Return:
      return m('div', { class: 'purchase-bar-wrapper', config: render.depend('purchase-bar') }, [
        m('div', { class: 'purchase-bar' }, [
          m('div', { class: 'purchase-bar-content' }, [
            m('div', { class: 'number-of-draws dropdown-wrapper', config: render.depend('draws-per-row'), onclick: toggleDays }, [
              m('div', { class: 'arrow-up-icon' + (daysStyle() !== '' ? ' hide' : '') }, [
                m('svg', { class: 'svg-icon-arrow-up' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-up-arrow' })
                ])
              ]),
              m('div', { class: game.isNumberOfDrawsExceed() ? 'number-of-draws-exceeded' : '' }, [
                m('span', d('PurchaseBar/PlayRowsFor')),
                formatDraws(numberOfDraws)
              ]),
              m('div', { class: 'dropdown-list-wrapper', style: daysStyle() }, [
                m('ul', { class: 'select-days dropdown', config: render.depend('days-list') }, [
                  m('li', { onclick: setDraws(0) }, d('PurchaseBar/PlayRowsFor') + d('PurchaseBar/PlayRowsEveryWeek')),
                  game.numberOfDrawsOptions().map(function (draws) {
                    if (drawRepeatMax !== null && draws > drawRepeatMax) {
                      return m('li.is-inactive', {
                        onclick: function (ev) {
                          ev.stopPropagation();
                          ev.preventDefault();
                        }
                      }, [
                        m('span', d('PurchaseBar/PlayRowsFor')),
                        formatDraws(draws)
                      ]);
                    }

                    return m('li', { onclick: setDraws(draws) }, [
                      m('span', d('PurchaseBar/PlayRowsFor')),
                      formatDraws(draws)
                    ]);
                  }), drawRepeatMax !== null && drawRepeatMax < game.numberOfDrawsOptions()[game.numberOfDrawsOptions().length - 1] ? m('li.not-clickable', {
                    onclick: function (ev) {
                      ev.stopPropagation();
                      ev.preventDefault();
                    }
                  }, d('PurchaseBar/PurchaseNotification', { WEEKS: drawRepeatMax })) : null
                ])
              ])
            ]),
            m('div', { class: 'purchase-bar-cta ' + (game.isGameValid() ? 'is-active' : 'is-inactive') + ' ' + game.status(), onclick: controller.purchase }, [
              m('a', { class: 'purchase' }, [
                m('div', { class: 'purchase-button-loader-box' }, [
                  m('span', { class: 'loader-text' }, d('PurchaseBar/Processing')),
                  m('span', { class: 'loader-animation-box' }, [
                    m('span', { class: 'loader-dot' }),
                    m('span', { class: 'loader-dot' }),
                    m('span', { class: 'loader-dot' })
                  ])
                ]),
                m('div', { class: 'total-price' }, d('PurchaseBar/SubmitButton' + (numberOfDraws === 0 ? 'Plus' : ''), {
                  totalstake: VikingLottoUtils.formatNumber(game.totalPrice())
                }))
              ])
            ])
          ])
        ])
      ]);

    };

    // Public functions:
    return PurchaseBar;

  });
