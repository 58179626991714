defineDs('DanskeSpil/Domain/NumberGames/Scripts/Models/MultiClientModel', [
  'Shared/Framework/Mithril/Scripts/Core/Model',
], (Model) => {

  const MultiClientModel = Model('MultiClientModel', function (gameType = '') {

    /**
     * Returns a gaEvent object based on the input step and game parameters.
     * @param {string|number} step - The current step in the game flow. Can be -1 for rows, 'draws' for weeks, or 'joker' for joker.
     * @param {object} game - The game object containing information about the current game.
     * @returns {Object} - Returns a gaEvent object with category, action, and label properties.
     * If there is invalid input or missing information, null is returned.
     */
    this.getGaEventObj = (step, game) => {
      if (!gameType || !game || typeof step === 'undefined') return;

      const isJackpot = game.verticalType?.() === 'jackpot';
      const category = 'coupon';
      const createdAs = game?.trackedInteractionCreationAction() || game?.playType()?.toLowerCase();
      let action = `${gameType?.toLowerCase()}/${createdAs}/`;
      let label;

      if (isJackpot) {
        action = `hoejpulje/${game?.playType()?.toLowerCase()}/`;
      }

      if (step === -1) {
        action += 'rows';
        label = game.getRowAmount();
      }

      if (step === 'pool') {
        action += 'pool';
        label = game.plusSubscriptionJackpot();
      }

      if (step === 'draws') {
        action += 'weeks';
        label = game.numberOfDraws();
        if (label === 0) {
          label = 'subscription';
        }
      }

      if (step === 'joker') {
        action += 'joker';
        const wednesday = game.withJokerWednesday() ? 'wednesday' : '';
        const saturday = game.withJokerSaturday() ? 'saturday' : '';
        label = `${wednesday}${wednesday && saturday ? '_' : ''}${saturday}`;

        // If no joker was selected, set it to `no joker`.
        if (!label) label = 'no joker';
      }

      return { category, action, label };
    };


  });

  return MultiClientModel;

});
