defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/NumbersPicker',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Header/NumbersPickerHeader',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/Core/NumbersPicker',
  // eslint-disable-next-line no-unused-vars
  ], function (m, NumbersPickerHeader, NumbersPicker) {
    const SystemNumbersPicker = {
      controller: function ({ controller }) {
        Object.assign(this, controller);

        this.numbersPickerHeader = <NumbersPickerHeader
          controller={controller}
          labels={{
            heading: 'systemNumbersPickerRowHeading',
            rowCompletedHeading: 'systemNumbersPickerRowCompletedHeading',
            subheading: 'systemNumbersPickerRowSubheading',
            autofill: 'systemNumbersPickerRowAutofill',
            clear: 'systemNumbersPickerRowClear',
          }}
        />;
      },
      view: function (controller, { className }) {
        return (
          <NumbersPicker
            controller={controller}
            Header={controller.numbersPickerHeader}
            className={className || ''}
          />
        );
      }
    };
    return SystemNumbersPicker;
  });