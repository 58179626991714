defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Components/MultiPackages',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Confirm',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/LottieUtils',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/Shared/PopoverDrawer',
    'DanskeSpil/Domain/NumberGamePackage/Scripts/Helpers/PackagesDictionary',
    'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/GenericOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/NumberGamePackage/Scripts/Helpers/PackagesApi',
    'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/Shared/SkeletonCardLoader',
    'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PackagesContainer'
  ],
  function (Component, Params, ConfirmHelper, LottieUtils, PopoverDrawer, PackagesDictionary, JackpotInfo, GenericOverlay, Overlay, PackagesApi, SkeletonCardLoader, PackagesContainer) {
    // Component:
    Component('multi-packages', [PackagesDictionary, JackpotInfo], function (m, route, settings, property, $element) {
    // Components:
      var root = {
        controller: function () {
          this.settings = settings;
          this.property = property;
          this.$element = $element;
          this.route = route;
          this.dictionary = PackagesDictionary.get;
          this.heading = this.settings.labels.heading;
          this.subheading = this.settings.labels.subheading;
          this.standardPackageDictionary = this.dictionary.bind({ prefix: 'MultiPackages/StandardPackageItem/' });
          this.customPackageDictionary = this.dictionary.bind({ prefix: 'MultiPackages/CustomPackage/' });
          this.loading = m.prop(true);
          this.packagesData = m.prop(null);
          this.customPackageGameOptions = this.settings.customPackageGameOptions;
          this.hasCustomPackageOption = this.customPackageGameOptions?.length > 0;
          this.mainPopoverDrawer = new PopoverDrawer();
          this.errorLogged = m.prop(null);

          this.$responsiveBackground = this.$element.querySelector('.responsive-bg')?.outerHTML; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          this.initResponsiveBackground = (element, ignoreIsInitialized, context) => {
            if (context.isInitialized) return;
            const $lottieContainer = element.querySelector('.responsive-lottie-bg');
            const settings = $lottieContainer?.dataset.lottieFiles;
            LottieUtils.initializeLottie({ $lottieContainer, settings: settings ? JSON.parse(settings) : null }).then(() => {
              context.isInitialized = true;
            });
          };

          this.confirmErrorOverlay = (errorType) => {
            const error = ConfirmHelper.getPurchaseError(errorType);
            if (this.errorLogged() === error) return;
            this.errorLogged(error);
            const texts = {
              header: this.dictionary('PurchaseErrors/' + error + 'Header'),
              body: this.dictionary('PurchaseErrors/' + error + 'Body'),
              dismiss: this.dictionary('PurchaseErrors/' + error + 'Dismiss')
            };
            const errorOverlay = new Overlay(GenericOverlay(texts));
            errorOverlay.show(() => this.errorLogged(null), () => this.errorLogged(null));
          };

          if (Params.extract('packageNo')) {
            Params.set('packageFlowStep=purchaseFlow');
          }

          PackagesApi.getPackages('v2').then((response) => {
            const packageSelection = this.settings.packageSelection;
            const filteredData = response?.packages?.filter((packageData) => {
              return packageSelection?.includes(packageData.packageNo);
            });
            filteredData.sort((a, b) => {
              const indexA = packageSelection.indexOf(a.packageNo);
              const indexB = packageSelection.indexOf(b.packageNo);
              return indexA - indexB;
            });

            this.packagesData(filteredData);
            this.loading(false);
            m.redraw();
          });
        },
        view: function (controller) {
          const content = () => {
            if (controller.loading()) {
              return m(SkeletonCardLoader, { controller });
            }
            return m('.multi-packages-section__inner', [
              (controller.heading || controller.subheading) ? m('.multi-packages-header', [
                controller.heading ? m('.multi-packages-header__heading', controller.heading) : null,
                controller.subheading ? m('.multi-packages-header__subheading', controller.subheading) : null
              ]) : null,
              m(PackagesContainer, { controller })
            ]);
          };
          return m('div', [
            content(),
            controller.$responsiveBackground ? m('div', {
              config: controller.initResponsiveBackground
            }, m.trust(controller.$responsiveBackground)) : null
          ]);
        }
      };

      // Setup routes:
      route('/', root);
    });
  });
