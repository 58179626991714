defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/GameSelector',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // View:
    var GameSelector = function (controller) {
      return m('.multi-client-game-selector__wrapper', [
        m('.multi-client-game-selector', [
          controller.playTypes().map(function (playType) {
            return m('span.game-selector-button', {
              class: m.route.param('playType') === playType.playType ? ' active' : '',
              onclick: () => {
                controller.goToRoute(playType.playType);
              }
            }, playType.label);
          })
        ])
      ]);
    };

    return GameSelector;
  });