defineDs('Shared/Framework/Mithril/Scripts/Helpers/Cleanup',
  function () {

    // Functions:
    var Cleanup = function () {
      try {
        var keys = Object.keys(localStorage);
        var now = (new Date()).getTime();
        var ttl = 86400 * 1000;

        for (var i = 0; i < keys.length; i++) {
          var key = keys[i];

          if (key.substring(0, 5) === 'Model') {
            var item = JSON.parse(localStorage.getItem(key));

            if (now - item.timestamp > ttl) {
              var regex = new RegExp(item.id);

              if (!regex.test(location.search)) {
                console.warn('Deleting from localStorage: ' + key);

                localStorage.removeItem(key);
              }
            }
          }
        }
      } catch (e) { }

    };

    // Public functions:
    return Cleanup;

  });
