defineDs('DanskeSpil/Framework/NumberGames/Scripts/Templates/JokerOverlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
    'Shared/Framework/Ensighten/Scripts/Ensighten',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer'
  ],
  function (m, Render, JackpotInfo, Ensighten, DataLayer) {

    // Context:
    var JokerOverlay = function (game, className, d, jokerFeed) {

      // Return:
      return {
        class: m.prop(className),

        render: function (confirm, dismiss) {

          // Variables:
          var isEurojackpotV2 = className.indexOf('eurojackpot') !== -1 &&
                                game.gameVersionNo() === 2;
          var useDoubleSelector = isEurojackpotV2;
          var render = Render();
          var jokerSaturday = JackpotInfo.getByGame('JokerSaturday');
          var jokerWednesday = JackpotInfo.getByGame('JokerWednesday');
          var noOpenDrawSaturday = !jokerFeed['jokerSaturday'].openDraw;
          var noOpenDrawWednesday = !jokerFeed['jokerWednesday'].openDraw;
          var poolLabelSaturday = d('JokerOverlay02/MinimumPoolLabel');
          var poolLabelWednesday = d('JokerOverlay02/MinimumPoolLabel');
          var poolSizeSaturday = d('JokerOverlay02/MinPoolSize');
          var poolSizeWednesday = d('JokerOverlay02/MinPoolSize');
          var numberOfDraws = game.numberOfDraws();
          var totalJokerPrice = game.totalJokerPrice;
          var playType = game.playType();
          var jokerSaturdayPicked = m.prop(false);
          var jokerWednesdayPicked = m.prop(false);
          var jokerMultiplier = game.verticalType ? (game.verticalType() === 'plus' ? 2 : numberOfDraws) : 1;

          if (numberOfDraws === 0) jokerMultiplier = 2;

          if (!useDoubleSelector) {
            if (Object.keys(jokerSaturday).length > 0 && !jokerSaturday.gamestopExceeded && jokerSaturday.poolSizeDecimal > 0) {
              poolSizeSaturday = jokerSaturday.poolSizeFormatted;
              poolLabelSaturday = d('JokerOverlay02/FeedPoolLabel');
            }
            if (Object.keys(jokerWednesday).length > 0 && !jokerWednesday.gamestopExceeded && jokerWednesday.poolSizeDecimal > 0) {
              poolSizeWednesday = jokerWednesday.poolSizeFormatted;
              poolLabelWednesday = d('JokerOverlay02/FeedPoolLabel');
            }
          }


          // Functions:
          var datalayerVirtualPage = function (playType) {
            Ensighten.pushVirtualPage('joker_option', DataLayer.categoryName(playType) + '/joker_option', 'upsellPage');
          };

          var datalayerTrackClick = function () {
            var buttons = jokerSaturdayPicked() && jokerWednesdayPicked()
              ? 'onsdag_og_loerdag' : jokerWednesdayPicked()
                ? 'onsdag' : jokerSaturdayPicked()
                  ? 'loerdag' : null;

            if (buttons) {
              window.dataLayer.push({
                event: 'gaEvent',
                eventSyntax: {
                  action: 'joker_tilfoejet',
                  category: 'joker_addToCart',
                  label: buttons
                }
              });
            }
          };

          datalayerVirtualPage(playType);

          var handleConfirm = function (event) {
            event.stopPropagation();
            datalayerTrackClick();
            if (game.getJokerRowsSummary('Wednesday') && !game['withJokerWednesday']()) {
              game.generatedJokerRows('Wednesday', []);
            }
            if (game.getJokerRowsSummary('Saturday') && !game['withJokerSaturday']()) {
              game.generatedJokerRows('Saturday', []);
            }
            confirm();
          };

          var toggleJokerHelp = function () {
            document.querySelector('.js-joker')?.classList.toggle('active');
          };

          var setButton = function ($element) {
            totalJokerPrice() > 0 ? $element.classList.add('active') : $element.classList.remove('active');
            $element.innerHTML = getCtaLabel();
          };

          var getCtaLabel = function () {
            if (totalJokerPrice() === 0) return d('JokerOverlay02/CtaNoJoker');
            if (numberOfDraws === 0) return d('JokerOverlay02/CtaJokerWeekly', { totalJokerPrice: totalJokerPrice() });
            return d('JokerOverlay02/CtaJoker', { totalJokerPrice: totalJokerPrice() });
          };

          // Render:
          render.ready.then(function (nodes) {
            var container = nodes['container'];

            container.context.count = 0;

            if (!container.rendered) {
              var $button = nodes['button'].$element;

              ['Saturday', 'Wednesday'].forEach(function (element) {
                var node = nodes[element];

                if (node) {
                  var $element = nodes[element].$element;
                  var jokerToggle = game['withJoker' + element];

                  $element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
                    if (element === 'Saturday') {
                      jokerSaturdayPicked(!jokerSaturdayPicked());
                    } else if (element === 'Wednesday') {
                      jokerWednesdayPicked(!jokerWednesdayPicked());
                    }

                    jokerToggle(!jokerToggle());

                    if (!jokerToggle()) {
                      game.generatedJokerRows(element, []);
                    }

                    jokerToggle() ? $element.classList.add('active') : $element.classList.remove('active');
                    jokerToggle() ? $element.parentElement.classList.add('active') : $element.parentElement.classList.remove('active');
                    $element.innerHTML = jokerToggle() ? d('JokerOverlay02/BoxCtaJoker') : d('JokerOverlay02/BoxCtaNoJoker', { price: game.jokerPrice() * jokerMultiplier });

                    setButton($button);

                    game.save();
                  });
                }
              });

              if (nodes['both']) {
                // if only one Joker is selected while playing a v1 ejp game and then you select to play the game as subscription
                // we need to deselect it since ejp v2 subscription requires both jokers
                var withJokerWednesdayAndNoJokerSaturday = game['withJokerWednesday']() && !game['withJokerSaturday']();
                var withJokerSaturdayAndNoJokerWednesday = !game['withJokerWednesday']() && game['withJokerSaturday']();
                if (withJokerWednesdayAndNoJokerSaturday || withJokerSaturdayAndNoJokerWednesday) {
                  if (withJokerWednesdayAndNoJokerSaturday) {
                    game['withJokerWednesday'](false);
                    jokerWednesdayPicked(false);
                    game.generatedJokerRows('Wednesday', []);
                  }
                  if (withJokerSaturdayAndNoJokerWednesday) {
                    game['withJokerSaturday'](false);
                    jokerSaturdayPicked(false);
                    game.generatedJokerRows('Saturday', []);
                  }

                  setButton($button);

                  game.save();
                }

                var $element = nodes['both'].$element;
                var availableJokerDay = numberOfDraws === 0 ? 'wednesday' : game.jokerDrawDates()[0].dayName;
                var toggleWednesdayJoker = function () {
                  jokerWednesdayPicked(!jokerWednesdayPicked());
                  game['withJokerWednesday'](!game['withJokerWednesday']());
                  if (game['withJokerWednesday']()) {
                    game.generatedJokerRows('Wednesday', []);
                  }
                };
                var toggleSaturdayJoker = function () {
                  jokerSaturdayPicked(!jokerSaturdayPicked());
                  game['withJokerSaturday'](!game['withJokerSaturday']());
                  if (game['withJokerSaturday']()) {
                    game.generatedJokerRows('Saturday', []);
                  }
                };
                // based on the numbers of draws we determine which Joker games are selected
                // if joker was selected when 1 draw was selected then when the draw count increase
                // we need to toggle the other joker on
                if (jokerMultiplier > 1) {
                  if ((game['withJokerWednesday']() || game['withJokerSaturday']()) &&
                      !(game['withJokerWednesday']() && game['withJokerSaturday']())) {
                    game['withJokerWednesday']() ? toggleSaturdayJoker() : toggleWednesdayJoker();
                  }
                }
                // if joker was enabled when numberOfDraws was greater than 1 that means that both jokers were selected
                // now if numberOfDraws was changed to 1 we need to check which joker day is available next and toggle off the other joker
                if (jokerMultiplier === 1) {
                  // also if the available joker day has changed we want to disable the old one and switch to the other
                  if (game['withJokerWednesday']() && availableJokerDay !== 'wednesday') {
                    toggleWednesdayJoker();
                    if (!game['withJokerSaturday']()) {
                      toggleSaturdayJoker();
                    }
                  }
                  if (game['withJokerSaturday']() && availableJokerDay !== 'saturday') {
                    toggleSaturdayJoker();
                    if (!game['withJokerWednesday']()) {
                      toggleWednesdayJoker();
                    }
                  }
                }
                var toggleButton = function () {
                  var isJokerSelected = Boolean(game['withJokerWednesday']() || game['withJokerSaturday']());
                  isJokerSelected ? $element.classList.add('active') : $element.classList.remove('active');
                  isJokerSelected ? $element.parentElement.classList.add('active') : $element.parentElement.classList.remove('active');
                  $element.innerHTML = isJokerSelected ? d('JokerOverlay02/BoxCtaJoker') : d('JokerOverlay02/BoxCtaNoJoker', { price: game.jokerPrice() * jokerMultiplier });
                };
                toggleButton();
                $element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
                  if (jokerMultiplier === 1) {
                    availableJokerDay === 'saturday' ? toggleSaturdayJoker() : toggleWednesdayJoker();
                  } else {
                    toggleWednesdayJoker();
                    toggleSaturdayJoker();
                  }

                  toggleButton();
                  setButton($button);
                  game.save();
                });
              }
            }
          });

          // Template:
          var wednesdayDrawDate;
          var saturdayDrawDate;
          var isSaturdayDrawBeforeWednesdayDraw;

          if (jokerFeed
            && jokerFeed.jokerWednesday
            && jokerFeed.jokerWednesday.openDraw
            && jokerFeed.jokerSaturday.openDraw
            && jokerFeed.jokerWednesday.openDraw.closingTime
            && jokerFeed.jokerSaturday.openDraw.closingTime) {
            isSaturdayDrawBeforeWednesdayDraw = jokerFeed.jokerSaturday.openDraw.closingTime < jokerFeed.jokerWednesday.openDraw.closingTime;
          }

          if (jokerFeed && jokerFeed.jokerWednesday && jokerFeed.jokerWednesday.openDraw && jokerFeed.jokerWednesday.openDraw.closingTime) {
            wednesdayDrawDate = new Date(jokerFeed.jokerWednesday.openDraw.closingTime);
            wednesdayDrawDate = wednesdayDrawDate.getDate() + '/' + (wednesdayDrawDate.getMonth() + 1);
          }

          if (jokerFeed && jokerFeed.jokerSaturday && jokerFeed.jokerSaturday.openDraw && jokerFeed.jokerSaturday.openDraw.closingTime) {
            saturdayDrawDate = new Date(jokerFeed.jokerSaturday.openDraw.closingTime);
            saturdayDrawDate = saturdayDrawDate.getDate() + '/' + (saturdayDrawDate.getMonth() + 1);
          }

          return m('.joker-overlay', {
            class: this.class(),
            config: render.depend('container'),
            onclick: dismiss,
            'data-uitest-id': 'JokerOverlay'
          }, m('.joker-overlay__content', {
            onclick: function (ev) {
              // don't close overlay when clicking on content box.
              ev.stopPropagation();
            }
          }, [
            // if doubleSelector is enabled than we show the joker logo above the title
            useDoubleSelector ? m('.joker-overlay__logo.joker-overlay__header-logo', [
              m('img.joker-overlay__logo-hat', {
                src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/icon-joker-hat.svg',
                alt: ''
              })
            ]) : null,

            m('.joker-overlay__title', m.trust(
              useDoubleSelector ?
                d('JokerOverlay02/DoubleSelector/BothJokersTitle', { price: game.jokerPrice() * jokerMultiplier }) :
                d('JokerOverlay02/Title')
            )),

            function () {
              if (useDoubleSelector) {
                var jokerLabel = d('JokerOverlay02/DoubleSelector/BothDrawsDays');
                if (jokerMultiplier === 1) {
                  if (game['withJokerWednesday']()) {
                    jokerLabel = d('JokerOverlay02/DrawValueWednesday', { date: wednesdayDrawDate });
                  } else {
                    jokerLabel = d('JokerOverlay02/DrawValueSaturday', { date: saturdayDrawDate });
                  }
                }
                return [
                  // both joker
                  m('.joker-overlay__box.joker-overlay__double', [

                    // both joker small
                    m('.joker-overlay--small', [
                      m('.joker-overlay__draw-pool', [
                        m('.joker-overlay__draw', [
                          m('.joker-overlay__draw-label', jokerLabel),
                          m('.joker-overlay__logo', [
                            jokerMultiplier > 1 ? m('span.joker-overlay__logo-prefix', jokerMultiplier + 'X') : null,
                            m('img.joker-overlay__logo-text.joker-overlay__only-text', {
                              src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/icon-joker-text-logo.svg',
                              alt: ''
                            })
                          ])
                        ]),
                        m('.joker-overlay__pool', [
                          m('.joker-overlay__pool-label', d('JokerOverlay02/MinimumPoolLabel')),
                          m('.joker-overlay__pool-value', d('JokerOverlay02/PoolValue', { pool: poolSizeWednesday }))
                        ])
                      ]),

                    ]),

                    // both joker medium
                    m('.joker-overlay--medium', [
                      m('.joker-overlay__logo-with-prefix', [
                        m('.joker-overlay__pool-label', jokerLabel),
                        m('.joker-overlay__logo', [
                          jokerMultiplier > 1 ? m('span.joker-overlay__logo-prefix', jokerMultiplier + 'X') : null,
                          m('img.joker-overlay__logo-text.joker-overlay__only-text', {
                            src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/icon-joker-text-logo.svg',
                            alt: ''
                          })
                        ])
                      ]),

                      m('.joker-overlay__pool', [
                        m('.joker-overlay__pool-label', d('JokerOverlay02/MinimumPoolLabel')),
                        m('.joker-overlay__pool-value', d('JokerOverlay02/PoolValue', { pool: poolSizeWednesday }))
                      ])
                    ]),

                    // both joker cta
                    m('a.joker-overlay__box-cta', {
                      class: (game['withJokerWednesday']() && game['withJokerSaturday']() ? ' active' : ''),
                      config: render.depend('both'),
                      'data-uitest-id': 'JokerOverlay__cta--both'
                    }, game['withJokerWednesday']() && game['withJokerSaturday']() ? d('JokerOverlay02/BoxCtaJoker') : d('JokerOverlay02/BoxCtaNoJoker', { price: game.jokerPrice() * jokerMultiplier }))
                  ])
                ];
              } else {
                return [
                  // joker wednesday
                  m('.joker-overlay__box', [

                    // joker wednesday small
                    m('.joker-overlay--small', [

                      m('.joker-overlay__logo', [
                        m('img.joker-overlay__logo-hat', {
                          src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/icon-joker-hat.svg',
                          alt: ''
                        }),
                        m('img.joker-overlay__logo-text', {
                          src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/icon-joker-text-logo.svg',
                          alt: ''
                        })
                      ]),

                      m('.joker-overlay__draw-pool', [
                        m('.joker-overlay__draw', [
                          m('.joker-overlay__draw-label', d('JokerOverlay02/Draw')),
                          m('.joker-overlay__draw-value', wednesdayDrawDate
                            ? d('JokerOverlay02/DrawValueWednesday', { date: wednesdayDrawDate })
                            : d('JokerOverlay02/DrawValueWednesdayNoDate'))
                        ]),
                        m('.joker-overlay__pool', [
                          m('.joker-overlay__pool-label', poolLabelWednesday),
                          m('.joker-overlay__pool-value', d('JokerOverlay02/PoolValue', { pool: poolSizeWednesday }))
                        ])
                      ]),

                    ]),

                    // joker wednesday medium
                    m('.joker-overlay--medium', [

                      m('div', [
                        m('.joker-overlay__logo', [
                          m('img.joker-overlay__logo-hat', {
                            src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/icon-joker-hat.svg',
                            alt: ''
                          }),
                          m('img.joker-overlay__logo-text', {
                            src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/icon-joker-text-logo.svg',
                            alt: ''
                          })
                        ]),

                        m('.joker-overlay__draw', [
                          m('.joker-overlay__draw-label', d('JokerOverlay02/Draw')),
                          m('.joker-overlay__draw-value', wednesdayDrawDate
                            ? d('JokerOverlay02/DrawValueWednesday', { date: wednesdayDrawDate })
                            : d('JokerOverlay02/DrawValueWednesdayNoDate'))
                        ])
                      ]),

                      m('.joker-overlay__pool', [
                        m('.joker-overlay__pool-label', poolLabelWednesday),
                        m('.joker-overlay__pool-value', d('JokerOverlay02/PoolValue', { pool: poolSizeWednesday }))
                      ])
                    ]),

                    // joker wednesday cta
                    m('a.joker-overlay__box-cta', {
                      class: (game['withJokerWednesday']() ? ' active' : '') + (noOpenDrawWednesday ? ' unavailable' : ''),
                      config: !noOpenDrawWednesday ? render.depend('Wednesday') : null,
                      'data-uitest-id': 'JokerOverlay__cta--wednesday'
                    }, game['withJokerWednesday']() ? d('JokerOverlay02/BoxCtaJoker') : d('JokerOverlay02/BoxCtaNoJoker', { price: game.jokerPrice() }))
                  ]),

                  // joker saturday
                  m('.joker-overlay__box', {
                    class: isSaturdayDrawBeforeWednesdayDraw ? 'joker-overlay__box--first' : ''
                  }, [

                    // joker saturday small
                    m('.joker-overlay--small', [

                      m('.joker-overlay__logo', [
                        m('img.joker-overlay__logo-hat', {
                          src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/icon-joker-hat.svg',
                          alt: ''
                        }),
                        m('img.joker-overlay__logo-text', {
                          src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/icon-joker-text-logo.svg',
                          alt: ''
                        })
                      ]),

                      m('.joker-overlay__draw-pool', [
                        m('.joker-overlay__draw', [
                          m('.joker-overlay__draw-label', d('JokerOverlay02/Draw')),
                          m('.joker-overlay__draw-value', saturdayDrawDate
                            ? d('JokerOverlay02/DrawValueSaturday', { date: saturdayDrawDate })
                            : d('JokerOverlay02/DrawValueSaturdayNoDate'))
                        ]),
                        m('.joker-overlay__pool', [
                          m('.joker-overlay__pool-label', poolLabelSaturday),
                          m('.joker-overlay__pool-value', d('JokerOverlay02/PoolValue', { pool: poolSizeSaturday }))
                        ])
                      ]),

                    ]),

                    // joker saturday medium
                    m('.joker-overlay--medium', [

                      m('div', [
                        m('.joker-overlay__logo', [
                          m('img.joker-overlay__logo-hat', {
                            src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/icon-joker-hat.svg',
                            alt: ''
                          }),
                          m('img.joker-overlay__logo-text', {
                            src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/icon-joker-text-logo.svg',
                            alt: ''
                          })
                        ]),

                        m('.joker-overlay__draw', [
                          m('.joker-overlay__draw-label', d('JokerOverlay02/Draw')),
                          m('.joker-overlay__draw-value', saturdayDrawDate
                            ? d('JokerOverlay02/DrawValueSaturday', { date: saturdayDrawDate })
                            : d('JokerOverlay02/DrawValueSaturdayNoDate'))
                        ])
                      ]),

                      m('.joker-overlay__pool', [
                        m('.joker-overlay__pool-label', poolLabelSaturday),
                        m('.joker-overlay__pool-value', d('JokerOverlay02/PoolValue', { pool: poolSizeSaturday }))
                      ])
                    ]),

                    m('a.joker-overlay__box-cta', {
                      class: (game['withJokerSaturday']() ? ' active' : '') + (noOpenDrawSaturday ? ' unavailable' : ''),
                      config: !noOpenDrawSaturday ? render.depend('Saturday') : null,
                      'data-uitest-id': 'JokerOverlay__cta--saturday'
                    }, game['withJokerSaturday']() ? d('JokerOverlay02/BoxCtaJoker') : d('JokerOverlay02/BoxCtaNoJoker', { price: game.jokerPrice() }))
                  ])
                ];
              }
            }(),

            m('a.joker-overlay__cta', {
              class: totalJokerPrice() > 0 ? ' active' : '',
              config: render.depend('button'),
              onclick: handleConfirm,
              'data-uitest-id': 'JokerOverlay__cta'
            }, getCtaLabel()),

            m('.joker-overlay__read-more.js-joker', {}, [
              m('a.joker-overlay__read-more-cta', {
                onclick: toggleJokerHelp,
                'data-uitest-id': 'JokerOverlay__cta--read-more'
              }, d('JokerOverlay02/WhatsJokerButtonText')),
              m('.joker-overlay__read-more-text', m('p', d('JokerOverlay02/WhatsJokerText')))
            ])

          ]));

        }
      };
    };

    // Public functions:
    return JokerOverlay;

  });
