defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/ReceiveWinnerMessageToggle',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoDictionary'
  ],
  function (m, VikingLottoDictionary) {

    // View:
    var ReceiveWinnerMessageToggle = function () {

      this.d = VikingLottoDictionary.get;

      var toggleEmail = function (value) {
        console.debug('toggleEmail value', value);
      };

      var toggleSms = function (value) {
        console.debug('toggleSms value', value);
      };

      // View:
      return m('div', { class: 'checkbox-container' }, [
        m('div', { class: 'vikinglotto-checkbox' }, [
          m('label', { class: 'ios-skin', for: 'email' }, [
            m('input', { type: 'checkbox', id: 'email', 'data-role': 'email', value: '1', onchange: m.withAttr('checked', toggleEmail) }),
            m('span', { class: 'ios-skin_body' }, [
              m('span', { class: 'ios-skin_switch' })
            ]),
            m('i', { class: 'checkmark' })
          ]),
          m('label', { class: 'large bold white', for: 'email' }, [
            m('p', { class: 'p' }, this.d('ConfirmStep/ReceiveWinnerMessage/EmailText'))
          ])
        ]),
        m('div', { class: 'vikinglotto-checkbox' }, [
          m('label', { class: 'ios-skin', for: 'sms' }, [
            m('input', { type: 'checkbox', id: 'sms', 'data-role': 'sms', value: '1', onchange: m.withAttr('checked', toggleSms) }),
            m('span', { class: 'ios-skin_body' }, [
              m('span', { class: 'ios-skin_switch' })
            ]),
            m('i', { class: 'checkmark' })
          ]),
          m('label', { class: 'large bold white', for: 'sms' }, [
            m('p', { class: 'p' }, this.d('ConfirmStep/ReceiveWinnerMessage/SmsText'))
          ])
        ])
      ]);
    };

    // Public functions:
    return ReceiveWinnerMessageToggle;

  });
