defineDs('DanskeSpil/Framework/NumberGames/Scripts/Templates/Countdown',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils',
    'Shared/Framework/Mithril/Scripts/Helpers/MithrilRedrawEverySecond'
  ],
  function (m, TimeUtils, MithrilRedrawEverySecond) {

    // View:
    var Countdown = function (options) {

      // Variables:
      var d = options.dictionary;

      // Calculate diff
      var currentTime = TimeUtils.getCurrentDateTime();
      var diffSeconds = 0;

      if (options.countdownTime) {
        diffSeconds = Math.floor((options.countdownTime) - (currentTime.getTime() / 1000));
        var days = Math.floor(diffSeconds / 86400) <= 0 ? 0 : Math.floor(diffSeconds / 86400);
        var hours = Math.floor(diffSeconds / 3600 % 24) <= 0 ? 0 : Math.floor(diffSeconds / 3600) % 24;
        var minutes = Math.floor(diffSeconds % 3600 / 60) <= 0 ? 0 : Math.floor(diffSeconds % 3600 / 60);
        var seconds = Math.floor(diffSeconds % 3600 % 60) <= 0 ? 0 : Math.floor(diffSeconds % 3600 % 60);
      }

      // Config:
      var setupTimer = function (ignoredElement, rendered) {
        if (!rendered) {
          MithrilRedrawEverySecond.start();
        }
      };


      // View:
      if (diffSeconds < 1) {
        return null;
      }

      if (options.disabled) {
        return m('.countdown', [
          m('h2', { class: 'countdown-headline disabled' }, d('Countdown/CountdownDisabledHeading'))
        ]);
      } else {
        let headlineText = d('Countdown/ReceiptCountdownHeading', { gameName: options.gameName });
        if (options.noOpenDraw) {
          headlineText = d('Countdown/NoOpenDrawCountdownHeading') !== '!' ? d('Countdown/NoOpenDrawCountdownHeading') : null;
        }
        if (options.alternativeHeadline) {
          headlineText = options.alternativeHeadline;
        }
        return m('.countdown' + (options.noOpenDraw === true ? ' .no-opendraw' : ''), { config: setupTimer }, [
          m('h2', { class: 'countdown-headline' }, headlineText),
          (days > 0 ?
            m('.number-container', [
              m('span.number.days', days),
              m('span.time-denominator', days === 1 ? 'dag' : 'dage')
            ]) : ''
          ),
          m('.number-container', [
            m('span.number.hours', hours),
            m('span.time-denominator', hours === 1 ? 'time' : 'timer')
          ]),
          m('.number-container', [
            m('span.number.minutes', minutes),
            m('span.time-denominator', 'min.')
          ]),
          (days <= 0 ? [
            m('.number-container', [
              m('span.number.seconds', seconds),
              m('span.time-denominator', 'sek.')
            ])
          ] : '')
        ]);
      }

    };

    // Public functions:
    return Countdown;

  });
