defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/StatisticsModule/PeriodFilter',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage'
  ],
  function (m, Storage) {

    // Template:
    var PeriodFilter = function (controller) {

      // Variables:
      var d = controller.d;
      var months = d('Months').split(',');

      // Functions:
      var filterOrderDirection = function (direction) {
        return function () {
          if (controller.property('filterOrder') === direction) {
            direction = 'Number';
          }

          controller.property('filterOrder', direction);

          controller.getStatisticsFeed();
        };
      };

      var filterToggle = function () {
        return function () {
          controller.property('filterToggle', !controller.property('filterToggle'));
        };
      };

      var gameDropdownToggle = function () {
        return function () {
          controller.property('gameDropdownActive', !controller.property('gameDropdownActive'));
        };
      };

      var resetFilter = function (filterField) {
        return function () {
          if (filterField === 'years') {
            controller.property('selectedYears', []);
          }

          if (filterField === 'months') {
            controller.property('selectedMonths', []);
          }

          if (filterField === 'order') {
            controller.property('filterOrder', 'Number');
            controller.property('filterZeroToggle', false);
          }

          controller.getStatisticsFeed();
        };
      };

      var selectGame = function (game) {
        return function () {
          controller.property('gameDropdownActive', false);

          controller.selectedGameClient(game);
          controller.getCompletedDrawDates();
          if (!controller.property('selectedPoints')[game]) {
            controller.property('selectedPoints')[game] = JSON.parse(Storage.get(game + '-StatisticsNumbers'));
          }

          controller.getStatisticsFeed();
        };
      };

      var selectMonth = function (month) {
        return function () {
          if (controller.property('selectedMonths').indexOf(month) !== -1) {
            controller.property('selectedMonths').splice(controller.property('selectedMonths').indexOf(month), 1);
          } else {
            controller.property('selectedMonths').push(month);
          }

          controller.property('selectedMonths').sort(function (a, b) {
            return a - b ? -1 : 1;
          });

          controller.getStatisticsFeed();
        };
      };

      var selectYear = function (year) {
        return function () {
          if (controller.property('selectedYears').indexOf(year) !== -1) {
            controller.property('selectedYears').splice(controller.property('selectedYears').indexOf(year), 1);
          } else {
            controller.property('selectedYears').push(year);
          }

          controller.property('selectedYears').sort(function (a, b) {
            return a - b ? -1 : 1;
          });

          controller.getStatisticsFeed();
        };
      };

      var toggleNoZeroFilter = function () {
        controller.property('filterZeroToggle', !controller.property('filterZeroToggle'));

        controller.getStatisticsFeed();
      };

      // Return:
      return m('div', { class: 'statistics-module-period-filter-wrapper' + (controller.property('filterToggle') ? ' opened' : '') }, [
        m('div', { class: 'period-filter-inner-wrapper' }, [
          m('div', { class: 'period-filter-toggle-buttons-wrapper' }, [
            m('div', { class: 'period-filter-toggle-button', onclick: filterToggle() }, [
              m('svg', { class: 'icon' }, [
                m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesStatisticsModuleIcons.svg#icon-filter' })
              ]),
              m('span', { class: 'text' }, d('GraphModule/PeriodFilter/PeriodFilterButtonText'))
            ])
          ]),
          m('div', { class: 'period-filter-content-wrapper' }, [
            m('div', { class: 'period-filter-header' }, [
              controller.property('selectedYears').length > 0 || controller.property('selectedMonths').length > 0 ? [
                m('div', { class: 'filter-text-wrapper' }, [
                  d('GraphModule/PeriodFilter/FilterHeaderWithSelectedPeriod'),
                  m('span', controller.property('selectedYears').map(function (year) {
                    return year;
                  }).join(', ')),
                  (controller.property('selectedYears').length > 0 && controller.property('selectedMonths').length > 0) ? m('span', ' / ') : null,
                  m('span', controller.property('selectedMonths').map(function (month) {
                    return months[parseInt(month, 10) - 1];
                  }).join(', '))
                ])
              ] : m('div', { class: 'filter-text-wrapper' }, d('GraphModule/PeriodFilter/FilterHeaderWithoutSelections')),
              m('div', { class: 'period-filter-game-client-dropdown' + (controller.property('gameDropdownActive') ? ' active' : '') }, [
                m('div', { class: 'game-client-dropdown-placeholder', onclick: gameDropdownToggle() }, [
                  controller.selectedGameClient(),
                  m('svg', { class: 'icon' }, [
                    m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-down-arrow' })
                  ])
                ]),
                m('div', { class: 'game-client-dropdown-list' }, [
                  controller.gameClients.map(function (gameClient) {
                    if (gameClient !== controller.selectedGameClient()) {
                      return m('div', { class: 'game-client-dropdown-list-item', onclick: selectGame(gameClient) }, gameClient);
                    }
                  })
                ])
              ])
            ]),
            m('div', { class: 'period-filter-row' }, [
              m('span', { class: 'filter-row-name' }, [
                m('span', { class: 'filter-row-reset' + (controller.property('selectedYears').length > 0 ? ' active' : ''), onclick: resetFilter('years') }, [
                  m('svg', { class: 'icon' }, [
                    m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesStatisticsModuleIcons.svg#icon-refresh' })
                  ])
                ]),
                d('GraphModule/PeriodFilter/YearFilter')
              ]),
              m('span', { class: 'filter-row-options' }, [
                controller.completedDrawYears.map(function (year) {
                  return m('span', { class: 'option' + (controller.property('selectedYears').indexOf(year) !== -1 ? ' active' : ''), onclick: selectYear(year) }, year);
                })
              ])
            ]),
            m('div', { class: 'period-filter-row' }, [
              m('span', { class: 'filter-row-name' }, [
                m('span', { class: 'filter-row-reset' + (controller.property('selectedMonths').length > 0 ? ' active' : ''), onclick: resetFilter('months') }, [
                  m('svg', { class: 'icon' }, [
                    m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesStatisticsModuleIcons.svg#icon-refresh' })
                  ])
                ]),
                d('GraphModule/PeriodFilter/MonthFilter')
              ]),
              m('span', { class: 'filter-row-options' }, [
                controller.completedDrawMonths.map(function (month) {
                  return m('span', { class: 'option' + (controller.property('selectedMonths').indexOf(month) !== -1 ? ' active' : '') + (controller.completedDrawDatesFeed[controller.property('selectedYears')] ? (controller.completedDrawDatesFeed[controller.property('selectedYears')].indexOf(month) !== -1 ? '' : ' inactive') : ''), onclick: selectMonth(month) }, months[parseInt(month, 10) - 1]);
                })
              ])
            ]),
            m('div', { class: 'period-filter-row' }, [
              m('span', { class: 'filter-row-name' }, [
                m('span', { class: 'filter-row-reset' + (controller.property('filterZeroToggle') || controller.property('filterOrder') !== 'Number' ? ' active' : ''), onclick: resetFilter('order') }, [
                  m('svg', { class: 'icon' }, [
                    m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesStatisticsModuleIcons.svg#icon-refresh' })
                  ])
                ]),
                d('GraphModule/PeriodFilter/OrderFilter')
              ]),
              m('span', { class: 'filter-row-options' }, [
                m('span', { class: 'option' + (controller.property('filterZeroToggle') ? ' active' : ''), onclick: toggleNoZeroFilter }, d('GraphModule/PeriodFilter/FilterOutNumbersWithoutValue')),
                m('span', { class: 'option filter-desc-button' + ((controller.property('filterOrder') === 'DrawnCountDesc') ? ' active' : ''), onclick: filterOrderDirection('DrawnCountDesc') }, [
                  m('svg', { class: 'filter-icon' }, [
                    m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesStatisticsModuleIcons.svg#desc_filter_icon' })
                  ])
                ]),
                m('span', { class: 'option filter-normal-button' + ((controller.property('filterOrder') === 'Number') ? ' active' : ''), onclick: filterOrderDirection('Number') }, [
                  m('svg', { class: 'filter-icon' }, [
                    m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesStatisticsModuleIcons.svg#normal_filter_icon' })
                  ])
                ]),
                m('span', { class: 'option filter-asc-button' + ((controller.property('filterOrder') === 'DrawnCountAsc') ? ' active' : ''), onclick: filterOrderDirection('DrawnCountAsc') }, [
                  m('svg', { class: 'filter-icon' }, [
                    m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesStatisticsModuleIcons.svg#asc_filter_icon' })
                  ])
                ])
              ])
            ])
          ])
        ])
      ]);
    };

    // Public functions:
    return PeriodFilter;

  });
