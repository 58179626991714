defineDs('DanskeSpil/Domain/Tutorial/Scripts/Pages/TutorialFrontpage',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialFooter',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialNavigation',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialSearchBar',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialHero'
  ], function (m, TutorialFooter, Navigation, Searchbar, Hero) {

    return {
      controller: function (args) {
        window.scrollTo(0, 0);

        this.categories = args.settings.categories;
        this.headerImage = args.settings.headerImage;
        this.searchQuery = args.searchQuery;
        this.tutorials = args.tutorials;
        this.titleCssStyle = args.titleCssStyle;
        this.filteredTutorials = args.filteredTutorials;
        this.relatedHelpText = args.settings.relatedHelpText;
        this.frontpageHeroTitle = args.settings.frontpageHeroTitle;
        this.categories.forEach(function (category) {
          category.jointList = category.slideTutorials.concat(category.avalonTutorials).concat(category.richTextTutorials).concat(category.tipsSystemTutorial);
          category.jointList.forEach(function (tutorial) {
            tutorial['category'] = category.category;
          });
        });
        this.nullResultHelpText = args.settings.nullResultHelpText;
        this.searchResultNull = args.settings.searchResultNull;
        this.searchBarPlaceholder = args.settings.searchBarPlaceholder;
        this.mostVisitedLabel = args.settings.mostVisitedLabel;
        this.mostVisitedList = args.settings.mostVisitedList;
        this.latestSearches = args.settings.latestSearches;
        this.footerSettings = args.footerSettings;
        this.jointList = args.jointList;
        this.allTutorials = args.allTutorials;

        // Toggles the html class that shows tutorial categories
        this.categoryClickHandler = function (event) {
          event.currentTarget.classList.toggle('tutorial__category--show');
        };

        // Shows tutorial columns depending on if they're filtered or not
        this.showTutorialCategories = function (tutorials, isFiltered) {
          return tutorials.map(function (tutorial) {
            return m('a.tutorial__category-tutorials-wrapper', {
              onclick: function (event) {
                this.saveToLocalStorage(event.currentTarget.id, tutorial.image?.Source ?? '');
              }.bind(this),
              class: isFiltered ? 'tutorial__category-tutorials-wrapper--filtered' : '',
              href: tutorial.urlPrefix + '/' + tutorial.safeTitle,
              config: m.route,
              id: tutorial.title
            }, [
              m('.tutorial__category-tutorials-image-wrapper', [
                m('img.tutorial__category-tutorials-image', {
                  src: (isFiltered ? (tutorial.imageFiltered?.Source ?? '') : (tutorial.image?.Source ?? ''))
                })
              ]),
              m('.tutorial__category-tutorials-text-wrapper', [
                m('.tutorial__category-tutorials-title', { style: this.titleCssStyle }, isFiltered
                  ? m.trust(tutorial.title.replace(new RegExp(this.searchQuery(), 'ig'), function (match) {
                    return '<b>' + match + '</b>';
                  }.bind(this)))
                  : m.trust(tutorial.title)),
                m('.tutorial__category-tutorials-text', tutorial.subtitle)
              ]),
              m('.tutorial__category-tutorials-arrow')
            ]);
          }.bind(this));
        }.bind(this);

        // Gets saved tutorials the user has clicked on in an array
        this.getFromLocalStorage = function () {
          return JSON.parse(localStorage.getItem('tutorial-latest-searched')) || [];
        };

        // Saves the 5 newest tutorials the user clicks on in an array in local storage and checks if it's already saved
        this.saveToLocalStorage = function (title, image) {
          var currentLocalStorage = this.getFromLocalStorage();
          currentLocalStorage = currentLocalStorage.reduce(function (acc, storage) {
            if (storage.title !== title) acc.push(storage);
            return acc;
          }, []);
          var storage = { title: title, image: image };
          currentLocalStorage.push(storage);
          currentLocalStorage.slice(-5);
          localStorage.setItem('tutorial-latest-searched', JSON.stringify(currentLocalStorage));
        }.bind(this);

        // Gets tutorials in same category
        this.matchRelatedTutorialsWithFilteredTutorials = function () {
          var filtratedTutorialsCategories = [];
          this.filteredTutorials().forEach(function (tutorial) {
            filtratedTutorialsCategories.push(tutorial.category);
          });
          var x = this.categories.filter(function (category) {
            return filtratedTutorialsCategories.includes(category.category);
          });
          var y = x.map(function (category) {
            return this.showTutorialCategories(category.jointList.filter(function (tutorial) {
              return this.filteredTutorials().every(function (filteredTutorial) {
                return filteredTutorial.title !== tutorial.title;
              });
            }.bind(this)));
          }.bind(this));
          return y;
        }.bind(this);

        this.getCategoryDOM = function (category) {
          return m('.tutorial__category', {
            onclick: this.categoryClickHandler
          }, [
            m('.tutorial__category-wrapper', [
              m('.tutorial__category-text-wrapper', [
                m('.tutorial__category-title', { style: this.titleCssStyle }, category.title),
                m('.tutorial__category-label', category.subtitle),
              ]),
              m('.tutorial__category-cross')
            ]),
            m('.tutorial__category-tutorials', this.showTutorialCategories(category.jointList))
          ]);
        }.bind(this);

        this.getTutorialDOM = function (tutorial) {
          return m('a.tutorial__category', {
            href: tutorial.urlPrefix + '/' + tutorial.safeTitle,
            config: m.route,
          }, [
            m('.tutorial__category-wrapper.tutorial__category-wrapper--tutorial', [
              m('.tutorial__category-text-wrapper', [
                m('.tutorial__category-title', { style: this.titleCssStyle }, m.trust(tutorial.title)),
                m('.tutorial__category-label', tutorial.subtitle),
              ]),
              m('.tutorial__category-tutorials-arrow')
            ])
          ]);
        }.bind(this);
      },

      view: function (controller) {
        return m('.tutorial-frontpage', {
          config: controller.onStepRendered
        }, [
          Navigation(controller), // eslint-disable-line compat/compat
          m('.tutorial-frontpage__hero', [
            Hero({ title: controller.frontpageHeroTitle, style: controller.titleCssStyle, isButtonVisible: !!controller.searchQuery(), onclick: () => controller.searchQuery('') }),
            Searchbar(controller)
          ]),
          m('.tutorial__frontpage-wrapper',
            m('.tutorial__content-box.tutorial__frontpage-inner-wrapper.tutorial__frontpage-animation', {
              class: controller.searchQuery() ? 'tutorial__frontpage-inner-wrapper--hide' : ''
            },
            [
              m('.tutorial__categories', controller.jointList.map(function (entry) {
                return !entry.hasOwnProperty('urlPrefix') ? controller.getCategoryDOM(entry) : controller.getTutorialDOM(entry);
              })),
              controller.mostVisitedList.length > 0
                ? m('.tutorial__most-visited',
                  m('.tutorial__most-visited-title', { style: controller.titleCssStyle }, controller.mostVisitedLabel),
                  controller.mostVisitedList.map(function (tutorial) {
                    return m('a.tutorial__most-visited-wrapper', {
                      href: tutorial.urlPrefix + '/' + tutorial.safeTitle,
                      config: m.route
                    },
                    m('.tutorial__most-visited-element-wrapper', [
                      m('.tutorial__most-visited-element-image-wrapper',
                        m('img.tutorial__most-visited-element-image', {
                          src: tutorial.image?.Source ?? ''
                        })
                      ),
                      m('.tutorial__most-visited-element-text-wrapper', [
                        m('.tutorial__most-visited-element-title', { style: controller.titleCssStyle }, m.trust(tutorial.title)),
                        m('.tutorial__most-visited-element-text', tutorial.subtitle),
                      ]),
                      m('.tutorial__most-visited-element-arrow')
                    ])
                    );
                  }))
                : null
            ]),
            controller.filteredTutorials().length > 0
              ? m('.tutorial__search-result.tutorial__frontpage-animation', {
                class: controller.searchQuery() ? 'tutorial__search-result--show' : '',
              },
              [
                m('.tutorial__search-result-wrapper', [
                  controller.showTutorialCategories(controller.filteredTutorials(), true),
                ]),
                m('.tutorial__content-box.tutorial__search-result-related-wrapper', [
                  m('.tutorial__search-result-related-inner-wrapper', [
                    m('.tutorial__search-result-related-help-text', controller.relatedHelpText),
                    m('.tutorial__search-result-related-help-categories', controller.matchRelatedTutorialsWithFilteredTutorials())
                  ]),
                ]),
              ])
              : m('.tutorial__search-result-null-wrapper.tutorial__frontpage-animation', {
                class: controller.searchQuery() ? 'tutorial__search-result-null-wrapper--show' : ''
              },
              m('.tutorial__search-result-null-inner-wrapper', [
                m('.tutorial__search-result-null-title', { style: controller.titleCssStyle }, controller.searchResultNull),
                m('.tutorial__search-result-null-search-query', '\'' + controller.searchQuery() + '\''),
                m('.tutorial__search-result-null-text', m.trust(controller.nullResultHelpText)),
                controller.getFromLocalStorage().length > 0 ?
                  m('.tutorial__search-last-visited-wrapper', [
                    m('.tutorial__search-last-visited-title', { style: controller.titleCssStyle }, controller.latestSearches),
                    m('.tutorial__search-last-visited-element-wrapper', controller.getFromLocalStorage().map(function (storage) {
                      return m('a.tutorial__search-last-visited-element-innerwrapper', {
                        href: storage.urlPrefix + storage.title,
                        config: m.route
                      }, [
                        m('img.tutorial__search-last-visited-element-image', {
                          src: storage.image
                        }),
                        m('.tutorial__search-last-visited-element-title', { style: controller.titleCssStyle }, storage.title)
                      ]);
                    }).slice(-5)
                    )
                  ]) : null
              ]))),
          controller.footerSettings ? TutorialFooter({ style: controller.titleCssStyle, ...controller.footerSettings }) : null
        ]);
      }
    };
  });
