defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/KeysAccordion',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Accordions/Accordion',
  // eslint-disable-next-line no-unused-vars
  ], function (m, Accordion) {
    const KeysAccordion = {
      controller: function ({ controller, rows, countStartsAt }) {
        Object.assign(this, controller);

        this.rowsCountStart = countStartsAt + 1;
        this.rowsCountEnd = countStartsAt + rows.length;

        switch (this.gameName) {
        case 'vikinglotto':
          this.oddTableRowColor = 'odd:bg-vikinglotto-blue-1/10';
          break;
        case 'eurojackpot':
          this.oddTableRowColor = 'odd:bg-eurojackpot-gold-1/10';
          break;
        default:
          this.oddTableRowColor = 'odd:bg-lotto-red-1/10';
          break;
        }

        this.rowsTable = rows.map((row, index) => {
          return <div className={`flex justify-between px-16 py-8 text-black ${this.oddTableRowColor}`}>
            <div className='text-12 leading-18 font-semibold'>{this.labelDictionary('systemKeysTabAccordionTableRowHeading', {
              rowCount: countStartsAt + index + 1
            })}</div>
            <div className='text-12 leading-18 font-semibold'>{row.join(', ')}</div>
          </div>;
        });
      },
      view: function (controller) {

        return <Accordion
          allowOnlyOneOpen={true}
          heading={controller.labelDictionary('systemKeysTabAccordionLabel', {
            systemName: controller.systemName,
            rowsLabel: controller.rowsCountStart + '-' + controller.rowsCountEnd
          })}
          content={<div className='bg-white'>{controller.rowsTable}</div>}
        />;
      }
    };

    return KeysAccordion;
  });