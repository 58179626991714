defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/VikingLottoSystem',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoDictionary',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
    'DanskeSpil/Domain/VikingLotto/Scripts/Components/GameClients/VikingLottoSystemClient'
  ],
  function (Component, VikingLottoDictionary, VikingLottoInfo, VikingLottoSystemClient) {

    // Component:
    Component('vikinglotto-system', [VikingLottoDictionary, VikingLottoInfo], function (m, route, settings, property) {

      // Feed check:
      if (VikingLottoInfo.isFeedDown() || VikingLottoInfo.noOpenDraw()) {
        location.href = '/vikinglotto/notavailable';

        return;
      }

      // Components:
      var client = VikingLottoSystemClient(m, settings, property);

      // Routes:
      route('/', client);

    });

  });
