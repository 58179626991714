defineDs('DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Domain/Authentification/Scripts/SitecoreAuth',
  'DanskeSpil/Domain/Authentification/Scripts/LoginController'
], function (m, Event, LoginCache, SitecoreAuth, LoginController) {

  // Variables:
  var ready = m.deferred();

  var triggerBalanceUpdate = function () {
    window.DSAPI && DSAPI.ready(function () {
      DSAPI.Account.doBalanceUpdate();
    });
  };

  var getLoginStatus = function () {
    var deferred = m.deferred();

    SitecoreAuth.getLoginStatus(function (loggedIn) {
      deferred.resolve(loggedIn);
    });

    return deferred.promise;
  };

  var checkBalance = function (requiredAmount) {
    console.debug('AccountIntegration.checkBalance');
    var deferred = m.deferred();

    SitecoreAuth.getUserObj(function (data) {
      if (data && data.customerInfo && typeof data.customerInfo.balance !== 'undefined') {
        console.debug('current balance', data.customerInfo.balance);

        if (requiredAmount <= data.customerInfo.balance) {
          deferred.resolve(true);
        } else { // not enough money
          deferred.resolve(false);
        }
      } else { // communcation error or user not logged in
        deferred.reject();
      }

    }, false);

    return deferred.promise;

  };


  var getUserData = function () {
    var deferred = m.deferred();

    var userObj = LoginCache.getUserObj();
    if (userObj && userObj.customerInfo) {
      // First, use cache:
      deferred.resolve(userObj.customerInfo);
    } else {
      // Retrieve user data:
      SitecoreAuth.getUserObj(function (data) {
        if (data && data.customerInfo) {
          deferred.resolve(data.customerInfo);
        } else { // communcation error or user not logged in
          deferred.reject();
        }
      });
    }

    return deferred.promise;
  };


  var openLogin = function (loginSuccessUrl, cancelUrl) {
    window.DSAPI && DSAPI.ready(function () {
      DSAPI.Account.openLogin({
        cancelUrl: cancelUrl || null,
        loginSuccessUrl: loginSuccessUrl || null,
      });

    });
  };

  var openDeposit = function (price) {
    var url = DS.Config.PRETTYPREFIX + '/indbetaling?returnurl=' + encodeURIComponent(location.pathname + location.search + location.hash);

    if (price && LoginCache.getUserObj() && LoginCache.getUserObj().customerInfo) {
      var instantAmount = price - LoginCache.getUserObj().customerInfo.balance;
      if (instantAmount > 0) {
        url += '&instantAmount=' + instantAmount;
      }
    }

    location.href = url;
  };

  var openAccountPage = function () {
    window.location.href = DS.Config.PRETTYPREFIX + '/mine-spil';
  };

  var logout = function (callback) {
    LoginController.logout(callback ? { callback: callback } : undefined);
  };

  // Initialize - Ready when page load auth sync is finished
  if (LoginController.isSynced()) {
    ready.resolve();
  } else {
    Event.subscribe('pageload-auth-synced', function () {
      ready.resolve();
    });
  }


  return {
    ready: ready.promise,
    getUserData: getUserData,
    getLoginStatus: getLoginStatus,
    triggerBalanceUpdate: triggerBalanceUpdate,
    checkBalance: checkBalance,
    openLogin: openLogin,
    openDeposit: openDeposit,
    openAccountPage: openAccountPage,
    logout: logout
  };


});
