defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/Core/NumberCell',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/Core/NumberFace'
  ],
  // eslint-disable-next-line no-unused-vars
  function (m, NumberFace) {
    const NumberCell = {
      controller: function ({ controller, value, specialNumbersType = null }) {
        Object.assign(this, controller);
        this.hasNumber = this.gameInstance().hasNumber;
        this.addNumber = this.gameInstance().addNumber;
        this.removeNumber = this.gameInstance().removeNumber;
        this.numbersPerRowMax = this.gameInstance().numbersPerRowMax;
        let numbersProperty = 'numbers';

        switch (specialNumbersType) {
        case 'starNumbers':
          this.hasNumber = this.gameInstance().hasStarNumber;
          this.addNumber = this.gameInstance().addStarNumber;
          this.removeNumber = this.gameInstance().removeStarNumber;
          this.numbersPerRowMax = this.gameInstance().starNumbersPerRowMax;
          numbersProperty = 'starNumbers';
          break;
        case 'vikingNumber':
          this.hasNumber = this.gameInstance().hasVikingNumber;
          this.addNumber = this.gameInstance().addVikingNumber;
          this.removeNumber = this.gameInstance().removeVikingNumber;
          this.numbersPerRowMax = this.gameInstance().vikingNumbersPerRowMax;
          numbersProperty = 'vikingNumber';
          break;
        }

        this.onClick = () => {
          if (this.gameInstance().isRowValid(this.currentRow(), { checkOnlyMaxLimit: true }) &&
              !this.hasNumber(this.currentRow(), value)) {
            return;
          }

          if (this.hasNumber(this.currentRow(), value)) {
            this.removeNumber(this.currentRow(), value);
            return;
          }

          const row = this.gameInstance().getRow(this.currentRow());
          if (row[numbersProperty]?.length >= this.numbersPerRowMax()) {
            return;
          }
          if (numbersProperty === 'vikingNumber' && row[numbersProperty]) {
            return;
          }
          this.addNumber(this.currentRow(), value);
        };
      },
      view: function (controller, { value, isRowValid = false, specialNumbersType = null }) {
        const isSelected = controller.hasNumber(controller.currentRow(), value);
        const normalFace = <NumberFace key={`${value}-normal`} controller={controller} value={value} isRowValid={isRowValid} specialNumbersType={specialNumbersType} />;
        const selectedFace = <NumberFace key={`${value}-selected`} controller={controller} value={value} selected={true} specialNumbersType={specialNumbersType} />;
        const canBePressed = isRowValid ? isSelected : true;
        let className = `group/number-cell ${controller.useCompactView ? 'w-40 h-40' : 'w-54 h-54'} select-none relative rounded-full ${isSelected ? 'selected' : ''} transition`;
        if (canBePressed) {
          className += ' cursor-pointer hover:scale-105';
        } else {
          className += ' cursor-not-allowed opacity-75 scale-90';
        }
        return (
          <div
            className={className}
            onclick={controller.onClick}
            canBePressed={canBePressed}
            data-uitest-id={`${specialNumbersType ? 'special-' : ''}cell ${specialNumbersType ? 'special-' : ''}number-cell-${value}`}
          >
            {isSelected ? selectedFace : normalFace}
          </div>
        );
      }
    };

    return NumberCell;
  });