defineDs('DanskeSpil/Domain/NumberGames/Scripts/Helpers/MultiClientDictionary',
  [],
  function () {
    var mainDictionary = null;
    var dictionaries = function () {
      return mainDictionary ? function () {
        var setItem = this.setItem.bind(null, mainDictionary);
        return {
          defaultDictionary: setItem,
          closeButton: setItem('MultiClient/CloseButton'),
          okayButton: setItem('MultiClient/OkayButton'),
          nextButton: setItem('MultiClient/NextButton'),
          nextButtonWithRow: setItem('MultiClient/NextButtonWithRow'),
          nextButtonWithRows: setItem('MultiClient/NextButtonWithRows'),
          nextButtonWithoutPrice: setItem('MultiClient/NextButtonWithoutPrice'),
          nextButtonPlus: setItem('PurchaseBar/SubmitButtonPlus'),
          purchaseErrors: {
            header: setItem,
            body: setItem,
            dismiss: setItem
          },
          game: {
            title: setItem('MultiClient/GameStep/Title'),
            subtitle: setItem('MultiClient/GameStep/Subtitle'),
            lightningRow: setItem('MultiClient/GameStep/Row'),
            lightningRows: setItem('MultiClient/GameStep/Rows'),
            helpOverlay: setItem('MultiClient/GameStep/HelpOverlay')
          },
          draws: {
            title: setItem('MultiClient/DrawsStep/Title'),
            subtitle: setItem('MultiClient/DrawsStep/Subtitle'),
            subscriptionOptionLabel: setItem(['PurchaseBar/PlayRowsFor', 'PurchaseBar/PlayRowsEveryWeek']),
            multiDrawsLabel: setItem('MultiClient/DrawsStep/MultiDrawsLabel'),
            singleDrawLabel: setItem('MultiClient/DrawsStep/SingleDrawLabel'),
            helpOverlay: setItem('MultiClient/DrawsStep/HelpOverlay')
          },
          joker: {
            title: setItem('MultiClient/JokerStep/Title'),
            subtitle: setItem('MultiClient/JokerStep/Subtitle'),
            noJokerLabel: setItem('MultiClient/JokerStep/NoJokerLabel'),
            multipleJokerLabel: setItem('MultiClient/JokerStep/MultipleJokerLabel'),
            wednesdayJokerLabel: setItem('MultiClient/JokerStep/WednesdayJokerLabel'),
            saturdayJokerLabel: setItem('MultiClient/JokerStep/SaturdayJokerLabel'),
            bothJokerLabel: setItem('MultiClient/JokerStep/BothDrawsDaysLabel'),
            jokerSublabel: setItem('MultiClient/JokerStep/JokerSublabel'),
            jokerSublabelDrawInProgress: setItem('MultiClient/JokerStep/JokerSublabelDrawInProgress'),
            jokerSublabelPoolSize: setItem('MultiClient/JokerStep/JokerSublabelPoolSize'),
            jokerSublabelPoolSizeBoth: setItem('MultiClient/JokerStep/JokerSublabelPoolSizeBoth'),
            minimumPoolSize: setItem('MultiClient/JokerStep/MinimumPoolSize'),
            helpOverlay: setItem('MultiClient/JokerStep/HelpOverlay')
          },
          confirm: {
            nextButton: setItem('MultiClient/ConfirmStep/ConfirmButton'),
            depositHeader: setItem('ConfirmStep/DepositHeader'),
            depositText: setItem('ConfirmStep/DepositText'),
            depositButton: setItem('ConfirmStep/DepositButton'),
            confirmTitle: setItem('ConfirmPage/Title'),
            playType: setItem,
            tableDraw: setItem('ConfirmPage/TableDraw'),
            tableRows: setItem('ConfirmPage/TableRows'),
            tableWeeks: setItem('ConfirmPage/TableWeeks'),
            tableChosenSystemNumbers: setItem('ConfirmPage/TableChosenSystemNumbers'),
            tableJoker: setItem('ConfirmPage/TableJoker'),
            tableJokerWednesdayAndSaturday: setItem('ConfirmPage/TableJokerWednesdayAndSaturday'),
            tableJokerWednesday: setItem('ConfirmPage/TableJokerWednesday'),
            tableJokerSaturday: setItem('ConfirmPage/TableJokerSaturday'),
            tableJokerNo: setItem('ConfirmPage/TableJokerNo'),
            tableJokerAddButton: setItem('ConfirmPage/TableJokerAddButton'),
            tableTotal: setItem('ConfirmPage/TableTotal'),
            gameVendor: setItem('ConfirmPage/GameVendor'),
            termsLink: setItem('ConfirmPage/TermsLink'),
            viewTerms: setItem('ConfirmPage/ViewTerms'),
            helpOverlay: setItem('MultiClient/ConfirmStep/HelpOverlay')
          },
          receipt: {
            title: setItem('MultiClient/ReceiptStep/Title'),
            subtitle: setItem('MultiClient/ReceiptStep/Subtitle'),
            helpOverlay: setItem('MultiClient/ReceiptStep/HelpOverlay')
          }
        };
      }.bind(this)() : null;
    };

    this.setItem = function (dictionary, keys) {
      // if no keys are provided we return the dictionary so it can be used outside
      // the multi client dictionary system like `dictionary('dictionaryPath')`
      if (!keys) {
        return dictionary;
      }
      if (!Array.isArray(keys)) {
        if (typeof dictionary(keys) === 'object') {
          return dictionary(keys);
        }
        keys = new Array(keys);
      }
      return function (replacements) {
        var text = '';
        for (var i = 0; i < keys.length; i++) {
          text += dictionary(keys[i], replacements);
        }
        return text;
      };
    };

    var setDictionary = function (_dictionaries) {
      mainDictionary = this;
      if (typeof dictionaries === 'function') {
        dictionaries = dictionaries();
      }
      if (_dictionaries) {
        var deepMerge = function (target, source) {
          Object.entries(source).forEach(function (item) {
            var key = item[0];
            var value = item[1];
            if (value && typeof value === 'object') {
              deepMerge(target[key] = target[key] || {}, value);
              return;
            }
            target[key] = value;
          });
          return target;
        };
        dictionaries = [dictionaries, _dictionaries].reduce(deepMerge, {});
      }
    };

    var get = function (key, replacements) {
      var segments = key.split('/');
      var dictionary = dictionaries;

      if (!dictionary) {
        if (dictionary === undefined) {
          console.warn('Multi client dictionary is missing!');
        }
        return '';
      }

      for (var i = 0; i < segments.length; i++) {
        dictionary = dictionary[segments[i]];
        if (!dictionary) {
          dictionary = null;
          break;
        }
      }
      if (!dictionary) {
        if (dictionary === undefined) {
          console.warn('Missing multi client dictionary: ' + key);
        }
        return;
      }

      if (typeof dictionary === 'object') return dictionary;

      return dictionary(replacements);
    };

    return {
      get: get,
      setDictionary: setDictionary,
      setItem: this.setItem
    };
  });
