defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PurchaseFlow/PurchaseFlow', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Models/Package',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/Shared/Modal',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PurchaseFlow/BuyAsSubscriptionModal',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PurchaseFlow/Confirm',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PurchaseFlow/Receipt',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/SimpleCubeLoader'
], function (m, Ensighten, Params, AccountIntegration, PackageModel, Modal, BuyAsSubscriptionModal, Confirm, Receipt, SimpleCubeLoader) {

  // Component:
  var PurchaseFlow = {
    controller: function ({ controller }) {
      Object.assign(this, controller);

      this.packageModel = m.prop(null);
      this.loading = m.prop(true);
      this.isUserLoggedIn = m.prop(false);
      this.headlineText = m.prop(null);
      this.processingPurchase = m.prop(false);
      this.buyAsSubscriptionModal = new Modal({ extraClassNames: 'multi-packages-subscription-options-modal' });
      this.gameTypes = {
        AllOrNothing: 'allornothing',
        LottoWednesday: 'lotto',
        LottoSaturday: 'lotto',
        VikingLotto: 'vikinglotto',
        EuroJackpot: 'eurojackpot',
        Keno: 'keno',
        Joker: 'joker',
        JokerSaturday: 'joker',
        JokerWednesday: 'joker'
      };
      this.gameLogos = {
        allornothing: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/allornothing_logo.svg',
        eurojackpot: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/icon-eurojackpot_logo_bg_yellow.svg',
        lotto: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/icon-lotto_logo_red.svg',
        vikinglotto: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/icon-vikinglotto_logo_blue.svg',
        keno: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes/keno.svg',
        joker: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/joker_black_with_hat.svg'
      };

      const packageInstanceId = Params.extract('packageInstanceId');
      const packageNo = Params.extract('packageNo') ? parseInt(Params.extract('packageNo'), 10) : null;

      const retrieveFullCoupon = () => {
        if (!this.packageModel()) {
          Params.remove('packageNo');
          controller.mainPopoverDrawer.close();
          return;
        }
        this.packageModel().retrieveFullCoupon().then(() => {
          this.packageModel().fullCoupon();

          Params.set(`packageInstanceId=${this.packageModel().id()}`);
          Params.remove('packageNo');
          this.loading(false);
          m.redraw();
        }, () => {
          this.confirmErrorOverlay();
          controller.mainPopoverDrawer.close();
          m.redraw();
        });
      };

      if (packageNo) {
        this.packageModel(PackageModel.new({ packageNo: packageNo }));
        const isV2Package = this.packagesData().some((packageData) => {
          return packageData.packageNo === packageNo;
        });
        this.packageModel().retrievePackageInformation(isV2Package ? { apiVersion: 'v2' } : null).then(() => {
          retrieveFullCoupon();
        }, () => {
          this.confirmErrorOverlay();
          controller.mainPopoverDrawer.close();
          m.redraw();
        });
      } else {
        this.packageModel(PackageModel.get(packageInstanceId));

        if (!this.packageModel()) {
          this.confirmErrorOverlay();
          controller.mainPopoverDrawer.close();
          m.redraw();
          return;
        }
        if (this.packageModel().information().isCustomPackage) {
          this.loading(false);
        } else {
          retrieveFullCoupon();
        }
      }

      this.showSubscriptionOptionsModal = () => {
        this.buyAsSubscriptionModal.setContent(m(BuyAsSubscriptionModal, { controller: this }));
        this.buyAsSubscriptionModal.onClose(() => {
          this.processingPurchase(false);
        });
        this.buyAsSubscriptionModal.show();

        Ensighten.pushGaEvent('week_packages', 'create_subscription', this.packageModel().information().description);
      };

      AccountIntegration.triggerBalanceUpdate();
      AccountIntegration.getLoginStatus().then((isLoggedIn) => {
        const cancelUrl = location.pathname.split('/', 2).join('/');
        if (!isLoggedIn) {
          this.isUserLoggedIn(false);
          AccountIntegration.openLogin(null, cancelUrl);
        } else {
          this.isUserLoggedIn(true);
        }
        m.redraw();
      });

      // If user logs out
      window.DSAPI && DSAPI.ready(() => {
        DSAPI.Account.subscribeUserLoggedOut({
          callback: () => {
            controller.mainPopoverDrawer.close();
            this.isUserLoggedIn(false);
          }
        });
      });
    },

    view: function (controller) {
      const content = () => {
        if (controller.loading() ||
            !controller.isUserLoggedIn()) {
          return SimpleCubeLoader();
        }

        if (controller.packageModel().status() === 'completed') {
          return m(Receipt, { controller });
        }
        if (!controller.packageModel().information().isOpenForSale) {
          return m('.multi-packages-purchase-flow__inner-section', [
            m('.multi-packages-purchase-flow__inner-section-heading', controller.dictionary('MultiPackages/PackageClosed/Heading')),
            m('.multi-packages-purchase-flow__inner-section-text', controller.dictionary('MultiPackages/PackageClosed/Desctiption')),
            m('span.multi-packages-button', {
              onclick: () => {
                Params.set('packageFlowStep=packageSelection');
                Params.remove('packageInstanceId');
                location.reload();
              }
            }, controller.dictionary('MultiPackages/PackageClosed/ConfirmButton'))
          ]);
        }
        return m(Confirm, { controller });
      };

      return m('.multi-packages-purchase-flow', [
        controller.headlineText() ? m('.multi-packages-purchase-flow__headline', controller.headlineText()) : null,
        m('.multi-packages-purchase-flow__content', content())
      ]);
    }
  };

  return PurchaseFlow;
});
