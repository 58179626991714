defineDs('DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/DateUtils', [
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/NumberUtils',
], (NumberUtils) => {


  var formatISO8601 = function (timestamp, options) {
    if (!timestamp) return '';

    options = options || {};

    var includeDate = typeof options.includeDate === 'undefined' ? true : options.includeDate;
    var includeTime = typeof options.includeTime === 'undefined' ? true : options.includeTime;
    var includeTimePrefix = typeof options.includeTimePrefix === 'undefined' ? true : options.includeTimePrefix;
    var includeYear = typeof options.includeYear === 'undefined' ? true : options.includeYear;
    var includeDayName = typeof options.includeDayName === 'undefined' ? false : options.includeDayName;
    var dayNameSeparatorFromDate = typeof options.dayNameSeparatorFromDate === 'undefined' ? false : options.dayNameSeparatorFromDate;

    var parsed = parseISO8601(timestamp);

    var output = [];

    if (includeDayName) {
      output.push(parsed.dayName);
    }

    if (dayNameSeparatorFromDate !== false) {
      output.push(dayNameSeparatorFromDate);
    }

    if (includeDate) {
      var monthName = options.shortenMonthNames ? parsed.monthName.slice(0, 3) + '.' : parsed.monthName;
      options.shortenDate ? output.push(parseInt(parsed.date, 10) + ' ' + monthName) : output.push(parseInt(parsed.date, 10) + '. ' + monthName);
    }

    if (includeYear) {
      output.push(parsed.year);
    }

    if (includeTime) {
      if (includeTimePrefix) {
        output.push('kl.');
      }

      output.push(parsed.hours + ':' + parsed.minutes);
    }

    return output.join(' ');
  };


  var getLongDate = function (date, addYear) {
    var dateObj = parseISO8601(date.toISOString());
    return dateObj.dayName.charAt(0).toUpperCase() + dateObj.dayName.slice(1)
      + ' d. ' + Number(dateObj.date) + '. ' + dateObj.monthName
      + (addYear ? ' ' + dateObj.year : '');
  };


  const getNumberOfDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };


  /**
   * Returns the year of a date.
   * * @param {String|Date} date
   * * @return {Number|String}
   */
  const getYear = (date) => {
    if (isDateInvalid(date)) return '';
    const d = getDate(date);
    return d.getFullYear();
  };


  /**
   * Returns week number from a date.
   * Function converts a locale date object to a UTC date, then calculates a week number based on the start of the year
   * This function takes Week 53 into account as well (happens roughly every 6 years)
   * "Inspiration" from: https://stackoverflow.com/a/6117889
   * * @param {Date} date - The date to where the week number is to be found.
   * * @return {Number|String}
   */
  const getWeekNumber = (date) => {
    if (isDateInvalid(date)) return '';
    const d = getDate(date);
    const utcDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    const dayNum = utcDate.getUTCDay() || 7;
    utcDate.setUTCDate(utcDate.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(utcDate.getUTCFullYear(), 0, 1));
    return Math.ceil((((utcDate - yearStart) / 86400000) + 1) / 7);
  };


  /**
   * Returns a date formatted with ex. '27. oktober'.
   * @param {String|Date} date - The date to be formatted.
   * @return {String}
   */
  const formatDateDayMonth = (date) => {
    if (isDateInvalid(date)) return '';
    const d = getDate(date);
    return new Intl.DateTimeFormat('da-DK', { day: 'numeric', month: 'long' }).format(d);
  };


  /**
   * Returns a date formatted with ex. 'oktober 2022'.
   * @param {String|Date} date - The date to be formatted.
   * @return {String}
   */
  const formatDateMonthYear = (date) => {
    if (isDateInvalid(date)) return '';
    const d = getDate(date);
    return new Intl.DateTimeFormat('da-DK', { month: 'long', year: 'numeric' }).format(d);
  };


  /**
   * Returns a date formatted with ex. '27. oktober 2022'.
   * @param {String|Date} date - The date to be formatted.
   * @return {String}
   */
  const formatDateDayMonthYear = (date) => {
    if (isDateInvalid(date)) return '';
    const d = getDate(date);
    return new Intl.DateTimeFormat('da-DK', { day: 'numeric', month: 'long', year: 'numeric' }).format(d);
  };


  /**
   * Returns a date formatted with ex. 'Lørdag 27. oktober 2022'.
   * @param {String|Date} date - The date to be formatted.
   * @return {String}
   */
  const formatDateDayDateMonthYear = (date) => {
    if (isDateInvalid(date)) return '';
    const d = getDate(date);
    let dayString = formatDateWeekDay(d);
    dayString = dayString.charAt(0).toUpperCase() + dayString.slice(1);
    const dateString = formatDateDayMonthYear(d);
    return `${dayString} ${dateString}`;
  };


  /**
   * Returns a week date, ex. 'torsdag'.
   * @param {String|Date} date - The date from where the day is found.
   * @return {String}
   */
  const formatDateWeekDay = (date) => {
    if (isDateInvalid(date)) return '';
    const d = getDate(date);
    return new Intl.DateTimeFormat('da-DK', { weekday: 'long' }).format(d);
  };


  /**
   * Returns a date formatted with ex. '2022-10-27'.
   * @param {String|Date} date - The date to be formatted.
   * @return {String}
   */
  const formatDateYearMonthDay = (date) => {
    if (isDateInvalid(date)) return '';
    const d = getDate(date);
    const year = d.getFullYear();
    const month = NumberUtils.prefixNumberWithZeros(d.getMonth() + 1);
    const day = NumberUtils.prefixNumberWithZeros(d.getDate());
    return `${year}-${month}-${day}`;
  };

  /**
   * Calculates the difference in months between two dates.
   * @param {String|Date} date1
   * @param {String|Date} date2
   * @returns {number}
   */
  const getDifferenceInMonths = (date1 = new Date(), date2 = new Date()) => {
    if (isDateInvalid(date1) || isDateInvalid(date2)) return 0;
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };


  const getMatchingDateFromWeekAndYear = (week, year, arrayOfDates, property) => {
    if (!arrayOfDates || !week || !year) return;

    const dateToMatch = arrayOfDates.filter((d) => {
      if (property) {
        return week === getWeekNumber(d[property]) && year === getYear(d[property]);
      }

      return week === getWeekNumber(d) && year === getYear(d);
    });

    if (dateToMatch?.[0]) {
      return dateToMatch[0];
    }
  };


  const getWeekAndYearFromHash = () => {
    // hash should look like "#uge35-2020".
    const hashMatch = location.hash.match(/uge(\d{1,2})-(\d{4})/);
    if (hashMatch) {
      const week = Number(hashMatch[1]);
      const year = Number(hashMatch[2]);
      if (week && year) {
        return { week, year };
      }
    }
  };


  /**
   * Checks if a date is invalid.
   * @param {String|Date} date - The date to be checked.
   * @return {Boolean}
   */
  const isDateInvalid = (date) => {
    return isNaN(Date.parse(date));
  };


  /**
   * Compares two dates, to see if they're the same day (ignoring time differences).
   * @param {String|Date} date1 - The first date to be compared.
   * @param {String|Date} date2 - The second date to be compared.
   * @return {Boolean}
   */
  const isSameDay = (date1, date2) => {
    if (isDateInvalid(date1) || isDateInvalid(date2)) return false;
    return formatDateYearMonthDay(date1) === formatDateYearMonthDay(date2);
  };


  /**
   * Checks to see if ex. January 1, 2022 is part of Week 52 of 2021.
   * @param {String|Date} date
   * @return {Boolean}
   */
  const isWeekEndOfYearAndMonthIsJanuary = (date) => {
    if (isDateInvalid(date)) return false;
    const d = getDate(date);
    return getWeekNumber(d) > 50 && d.getMonth() === 0;
  };

  const parseTimeZone = (timeOffset) => {
    const parts = timeOffset.split(':');
    if (parts.length !== 2) {
      throw new Error('Invalid time offset format');
    }

    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);

    if (isNaN(hours) || isNaN(minutes)) {
      throw new Error('Invalid time offset format');
    }

    const millisecondsInHour = 60 * 60 * 1000;
    const millisecondsInMinute = 60 * 1000;

    return (hours * millisecondsInHour) + (minutes * millisecondsInMinute);
  };


  var parseISO8601 = function (timestamp) {
    var data = {};
    if (timestamp.length < 19) {
      console.warn('Timestamp too short for ISO 8601 formatting!');
    } else if (timestamp.indexOf('Z') === timestamp.length - 1) {
      // if timestamp is given in UTC, convert it to danish timezone
      // swedish locale is used because it resembles ISO8601
      data.dateObject = new Date(timestamp);
      timestamp = data.dateObject.toLocaleString('sv', { timeZone: 'Europe/Copenhagen' });
    }

    data.year = timestamp.substring(0, 4);
    data.month = timestamp.substring(5, 7);
    data.date = timestamp.substring(8, 10);
    data.hours = timestamp.substring(11, 13);
    data.minutes = timestamp.substring(14, 16);
    data.seconds = timestamp.substring(17, 19);
    data.timezone = timestamp.substring(19, 25);

    if (!data.dateObject) {
      try {
        data.dateObject = new Date(data.year, data.month - 1, data.date, data.hours, data.minutes, data.seconds);
        if (data.timezone) {
          data.dateObject = new Date(Date.UTC(data.year, data.month - 1, data.date, data.hours, data.minutes, data.seconds)
            - parseTimeZone(data.timezone));
        }
      } catch (e) {
        // Empty
      }
    }

    data.dayName = data.dateObject.toLocaleString('da-DK', { weekday: 'long', timeZone: 'Europe/Copenhagen' });
    data.monthName = data.dateObject.toLocaleString('da-DK', { month: 'long', timeZone: 'Europe/Copenhagen' });

    return data;
  };


  const setWeekAndYearToHash = (date) => {
    if (history.pushState) {
      let year = getYear(date);
      if (isWeekEndOfYearAndMonthIsJanuary(date)) {
        year--;
      }
      history.pushState(null, null, `#uge${getWeekNumber(date)}-${year}`);
    }
  };


  /**
   * Returns a new Date object with default Copenhagen time zone.
   * Useful when you only have a simple date string, eg. '2022-12-31', and you want to convert
   * it to a date that still resembles December 31, 2022 for eg. Greenland users.
   * @param {String|Date} [date=new Date()]
   * @param {String} timeZone [timeZone='sv']
   * @returns {Date}
   */
  const getDate = (date = new Date(), timeZone = 'Europe/Copenhagen') => {
    // Swedish locale is used because its output format can be used to create a new Date object.
    const locales = 'sv';

    if (typeof date === 'string') {
      date = new Date(date);
    }

    date = date.toLocaleString(locales, { timeZone });
    date = date.replace(/-/g, '/'); // Safari version 0-15 fix.
    return new Date(date);
  };


  return {
    formatISO8601,
    getDate,
    getLongDate,
    getNumberOfDaysInMonth,
    getYear,
    getWeekNumber,
    formatDateDayMonth,
    formatDateMonthYear,
    formatDateDayMonthYear,
    formatDateDayDateMonthYear,
    formatDateWeekDay,
    formatDateYearMonthDay,
    getDifferenceInMonths,
    getMatchingDateFromWeekAndYear,
    getWeekAndYearFromHash,
    isDateInvalid,
    isSameDay,
    isWeekEndOfYearAndMonthIsJanuary,
    parseISO8601,
    setWeekAndYearToHash,
  };
});

