defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/PackageListItem', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
  'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/Shared/PackageCard',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/ItemGamePlaceholder',
], function (m, Ensighten, Params, LottoInfo, VikingLottoInfo, EurojackpotInfo, PackageCard, ItemGamePlaceholder) {
  const PackageListItem = {
    controller: function ({ controller }) {
      Object.assign(this, controller);

      this.loadingGamesInfo = m.prop(true);
      this.gamesInfoData = m.prop(null);
      this.openCreateCustomPackageFlow = () => {
        Params.set('packageFlowStep=customPackageFlow');
        Ensighten.pushGaEvent('custom_package', 'select', '');
      };

      Promise.all([
        EurojackpotInfo.ready,
        LottoInfo.ready,
        VikingLottoInfo.ready,
      ]).then(() => {
        this.gamesInfoData({
          lotto: LottoInfo.data().lottoSaturday,
          vikinglotto: VikingLottoInfo.data(),
          eurojackpot: EurojackpotInfo.data()
        });
        this.loadingGamesInfo(false);
        m.redraw();
      });
    },

    view: function (controller) {
      return PackageCard({
        tagline: controller.customPackageDictionary('PackageListItem/Heading'),
        title: controller.customPackageDictionary('PackageListItem/Subheading'),
        body: m('.custom-package__games-options', controller.customPackageGameOptions.map((gameName) => {
          return m(ItemGamePlaceholder, { controller, gameName });
        })),
        footer: m('.multi-packages-button', controller.customPackageDictionary('PackageListItem/Button')),
        onclick: controller.openCreateCustomPackageFlow
      });
    }
  };

  return PackageListItem;
});