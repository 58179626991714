defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/VikingLottoReceipt',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Countdown',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/CouponOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoDictionary',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoUtils',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
    'DanskeSpil/Domain/VikingLotto/Scripts/Models/VikingLottoGame',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoApi',
    'Shared/Framework/Ensighten/Scripts/Ensighten'
  ],
  function (Component, Countdown, CouponOverlay, Overlay, VikingLottoDictionary, VikingLottoUtils, VikingLottoInfo, VikingLottoGame, AccountIntegration, Api, Ensighten) {

    // Component:
    Component('vikinglotto-receipt', [AccountIntegration, VikingLottoDictionary, VikingLottoInfo], function (m, route, settings) {

      // Variables:
      var d = VikingLottoDictionary.get;
      var id = VikingLottoUtils.getQueryParam('gameInstanceId');

      // Missing id:
      if (!id) {
        return;
      }

      var game = VikingLottoGame.get(id);

      // Missing game:
      if (!game) {
        console.error('VikingLottoReceipt: Game not found, id: ' + id);
        return;
      }

      // Components:
      var root = {
        controller: function () {

          // This controller:
          var controller = this;

          this.api = function () {
            return Api;
          }.bind(this);

          // Variables:
          this.game = m.prop(game);
          this.options = m.prop(settings || []);
          this.showSubscribeReminder = m.prop(false);
          this.alreadySubscribed = m.prop(false);
          this.subscribed = m.prop(false);
          this.loadingReminderAlert = m.prop(null);

          this.couponOverlay = m.prop(new Overlay(CouponOverlay('vikinglotto-coupon-overlay', this.game().couponId(), {
            printButton: d('ReceiptStep/CouponOverlay/printButton'),
            closeButton: d('ReceiptStep/CouponOverlay/closeButton')
          })));

          // Functions:
          this.back = function () {
            window.history.go(-1);
          };

          this.toggleReminderAlert = function () {
            var success = function () {
              if (this.subscribed()) {
                this.loadingReminderAlert('success-remind-me');
                Ensighten.pushGaEvent('permission', 'checkBoxActivated', 'multiWeekReminder', null, false);
              } else {
                this.loadingReminderAlert('success-dont-remind-me');
                Ensighten.pushGaEvent('permission', 'checkBoxDeactivated', 'multiWeekReminder', null, false);
              }
              m.redraw();
            }.bind(this);

            var fail = function (err) {
              if (err.errorMessage === 'NUMBERGAMES.USER_UNAUTHORIZED') {
                this.loadingReminderAlert('error-user-unauthorized');
              } else {
                this.loadingReminderAlert('error');
              }
              m.redraw();
            }.bind(this);

            this.subscribed(!this.subscribed());
            this.loadingReminderAlert('loading');
            this.api().setSubscribeReminder(this.subscribed()).then(success, fail);
            m.redraw();
          }.bind(this);

          this.gotoCoupon = function () {
            this.couponOverlay().show();
            return false;
          }.bind(this);

          this.getJokerTypes = function () {
            var jokerSaturday = this.game().withJokerSaturday();
            var jokerWednesday = this.game().withJokerWednesday();

            if (jokerSaturday && jokerWednesday) {
              return d('JokerTypes/JokerWednesdayAndSaturday');
            } else if (jokerSaturday) {
              return d('JokerTypes/JokerSaturday');
            } else if (jokerWednesday) {
              return d('JokerTypes/JokerWednesday');
            } else {
              return '';
            }
          }.bind(this);

          var playType = d('PlayTypes/VikingLotto' + this.game().playType());
          var totalPrice = this.game().totalPrice();
          var rows = this.game().getRowsSummary().length;
          var draws = this.game().numberOfDraws();
          var jokerTypes = this.getJokerTypes();
          var jokerPrice = this.game().totalJokerPrice();
          var firstName;
          var lastName;
          var userName;

          AccountIntegration.getUserData().then(function (userData) {
            firstName = userData.firstName;
            lastName = userData.lastName;
            userName = userData.userName;
          });

          this.heading = d('ReceiptStep/Heading', {
            rows: rows,
            playType: playType,
            totalPrice: totalPrice,
            draws: draws,
            firstName: firstName,
            lastName: lastName,
            userName: userName,
            jokerPrice: jokerPrice,
            jokerTypes: jokerTypes
          });


          var draw = VikingLottoInfo.data().openDraw;
          var drawDate = draw ? VikingLottoUtils.parseISO8601(draw.closingTime).dateObject : null;
          this.countdownOptions = {
            countdownTime: drawDate ? drawDate.getTime() / 1000 : 0,
            dictionary: d,
            disabled: settings.isCountdownDisabled,
            noOpenDraw: false
          };


          // Update balance in UI
          AccountIntegration.triggerBalanceUpdate();

          if (this.game().status() == 'completed') {
            // Ecommerce tracking:
            this.game().trackPurchase();
          }

          // If user logs out - redirect to VikingLotto frontpage
          window.DSAPI && DSAPI.ready(function () {
            DSAPI.Account.subscribeUserLoggedOut({
              callback: function () {
                // TODO: make this more dynamic
                location.href = controller.game().startUrl();

              }
            });
          });

          if (this.game().numberOfDraws() > 1) {
            var success = function (data) {
              this.showSubscribeReminder(data.showSubscribeReminder);
              var subscribed = data.alreadySubscribed;
              this.alreadySubscribed(subscribed);
              this.subscribed(subscribed);
              m.redraw();
            }.bind(this);

            var fail = function () {
              // we fail silently by not showing the option to subscribe if the feed fails
            };

            this.api().getSubscribeReminder().then(success, fail);
          }
        },

        view: function (controller) {
          return m('.vikinglotto-receipt', [
            m('.countdown-wrapper', [
              m('.vikinglotto-section', [
                m('.vikinglotto-content-wrapper', [
                  m('.receipt-confirmation', [
                    settings.iconUrl != '' ?
                      m('svg', { class: 'receipt-icon' }, [
                        m('use', {
                          class: 'receipt-icon',
                          href: '/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#' + settings.iconUrl
                        })
                      ])
                      : '',
                    m('p.receipt-text', controller.heading),
                    m('p.receipt-text', [
                      m('a.go-to-coupon', { onclick: controller.gotoCoupon }, d('ReceiptStep/GotoCoupon'))
                    ]),

                    controller.showSubscribeReminder() ? [
                      m('.reminder-alert', {
                        class: controller.loadingReminderAlert() === 'loading' ? 'reminder-alert--loading' : ''
                      }, [
                        m('input', {
                          type: 'checkbox',
                          id: 'reminderCheckbox',
                          checked: (controller.subscribed() ? 'checked' : ''),
                          onchange: m.withAttr('checked', controller.toggleReminderAlert)
                        }),
                        m('label', {
                          for: 'reminderCheckbox'
                        }, [
                          m('.checkbox'),
                          controller.alreadySubscribed() ?
                            d('ReceiptStep/SubscribeReminder/KeepRemindMe') :
                            d('ReceiptStep/SubscribeReminder/RemindMe')
                        ]),
                        controller.loadingReminderAlert() === 'error-user-unauthorized' ? m('p', d('ReceiptStep/SubscribeReminder/ErrorUserUnauthorized')) : '',
                        controller.loadingReminderAlert() === 'error' ? m('p', d('ReceiptStep/SubscribeReminder/Error')) : '',
                        controller.loadingReminderAlert() === 'success-remind-me' ? m('p', d('ReceiptStep/SubscribeReminder/SuccessRemindMe')) : '',
                        controller.loadingReminderAlert() === 'success-dont-remind-me' ? m('p', d('ReceiptStep/SubscribeReminder/SuccessDontRemindMe')) : '',
                      ])
                    ] : '',

                  ]),
                  m('hr', { class: 'hr' }),
                  Countdown(controller.countdownOptions)
                ])
              ])
            ])
          ]);
        }
      };
      // Setup Routes:
      route('/', root);
    });
  });
