defineDs('DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialHero',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
  ],
  function (m) {
    return function ({ title, style, isButtonVisible = true, activate = true, onclick = () => {} }) {
      return m('.tutorial__hero', {
        class: activate ? '' : 'tutorial__hero--disable'
      }, [
        isButtonVisible ? m('a.tutorial__hero-arrow', {
          href: '/',
          config: m.route,
          onclick
        }) : null,
        m('.tutorial__hero-title', { style }, m.trust(title)),
      ]);
    };
  });
