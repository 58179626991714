defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoWinningNumbers/Numbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (m, Utils) {

    // Template:
    var Numbers = function (controller, numbers, type) {

      // Variables:
      var d = controller.d;
      var weekNumber;
      var headLine;

      var square = function (int) {
        return m('li', [
          m('svg', { class: 'number-icon' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#icon-numbershape' })
          ]),
          m('span', int)
        ]);
      };

      var star = function (int) {
        return m('li', [
          m('svg', { class: 'number-icon helmet' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#icon-viking-helmet-blue' })
          ]),
          m('span', int)
        ]);
      };

      var numbersContruct = [];
      if (numbers) {
        numbersContruct = numbers.map(function (num) {
          return (type == 'bonus' ? star(num) : square(num));
        });
      }


      if (controller.selectedDrawDate && controller.selectedDrawDate()) {
        weekNumber = Utils.getWeekNumber(controller.selectedDrawDate());
      } else {
        weekNumber = controller.completedDrawsDates && controller.completedDrawsDates() ? Utils.getWeekNumber(controller.completedDrawsDates()[0]) || null : null;
      }

      if (weekNumber) {
        if (type === 'bonus') {
          headLine = d('WinningNumbers/BonusNumbersWithWeek', { week: weekNumber });
        } else if (type === 'joker') {
          headLine = d('WinningNumbers/JokerNumbersWithWeek', { week: weekNumber });
        } else {
          headLine = d('WinningNumbers/WinningNumbersWithWeek', { week: weekNumber });
        }
      } else {
        if (type === 'bonus') {
          headLine = d('WinningNumbers/BonusNumbers');
        } else if (type === 'joker') {
          headLine = d('WinningNumbers/JokerNumbers');
        } else {
          headLine = d('WinningNumbers/WinningNumbers');
        }
      }

      // Return:
      return [
        m('div', { class: 'number-container' }, [
          m('h2', { class: 'numbers-headline' }, headLine),
          m('ul', { class: type === 'bonus' ? 'bonus' : '' }, numbersContruct)
        ])
      ];

    };

    // Public functions:
    return Numbers;

  });
