defineDs('DanskeSpil/Domain/NumberGames/Scripts/Models/ViewCouponModel',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Core/Model',
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (m, Model, ApiRequest, Utils) {

    // Model:
    var ViewCouponModel = Model('ViewCouponModel', function (content) {

      // Variables:
      this.couponId = m.prop(content.couponId || null);
      this.isPlayTogetherGame = m.prop(content.isPlayTogetherGame || false);
      this.isPlayTogetherTempCoupon = m.prop(content.isPlayTogetherTempCoupon || false);
      this.fundingId = m.prop(content.fundingId || null);
      this.groupPlayId = m.prop(content.groupPlayId || null);
      this.feed = m.prop();
      this.feedReady = m.prop(m.deferred());
      this.game = m.prop(content.game || null);
      this.data = m.prop(content.data || null);

      // Context:
      var game = content.game;
      var requireFeed = 'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo';

      if (game.substring(0, 5) === 'Lotto') {
        requireFeed = 'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo';
      } else if (game === 'Eurojackpot') {
        requireFeed = 'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo';
      } else if (game === 'VikingLotto') {
        requireFeed = 'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo';
      } else if (game === 'AllOrNothing') {
        requireFeed = 'DanskeSpil/Domain/AllOrNothing/Scripts/Helpers/AllOrNothingInfo';
      }

      requireDs(requireFeed, function (feed) {
        if (game.substring(0, 5) === 'Lotto') {
          feed = feed[game.slice(5).toLowerCase()]();
        }

        this.feed(feed);

        this.feedReady().resolve();
      }.bind(this));

      // Functions:
      this.rebuyPossible = function () {
        var data = this.data();
        var feed = this.feed();
        var openDraw = feed.getOpenDraw();

        // No data or open draw:
        if (!data || !openDraw) {
          return false;
        }

        // If migrated, only allow Keno and Eurojackpot:
        var isMigrated = data.couponId && data.couponId.indexOf('-') > -1;

        if (isMigrated && data.couponGame !== 'Keno' && data.couponGame !== 'EuroJackpot') {
          return false;
        }

        var closingDate = new Date(openDraw.closingTime);
        var latestWagerDate = null;

        // Multi wagers:
        if (data.multiWagers && data.multiWagers.length > 0) {
          var multiWagers = data.multiWagers;

          for (var i = 0; i < multiWagers.length; i++) {
            var multiWager = multiWagers[i];
            var date = new Date(multiWager.drawDate);

            if (!latestWagerDate || date.getTime() > latestWagerDate.getTime()) {
              latestWagerDate = date;
            }
          }

        // Single wager:
        } else if (data.games && data.games.length > 0) {
          var games = data.games;
          var game = null;

          for (var j = 0; j < games.length; j++) {
            game = games[j];

            if (game.gameType.toLowerCase() === this.game().toLowerCase()) {
              latestWagerDate = new Date(game.drawDate);
            }
          }
        }

        return closingDate && latestWagerDate && closingDate.getTime() > latestWagerDate.getTime();
      }.bind(this);

      this.rebuyUrl = function () {
        var feed = this.feed();
        var data = feed ? feed.data() : null;

        if (data && data.confirmUrl) {
          return data.confirmUrl + '?rebuyCouponId=' + this.data().couponId;
        }

        console.error('ViewCouponModel: unable to return confirmUrl, feed is not loaded.');
      }.bind(this);

      this.loadCoupon = function () {
        var baseMap = {
          AllOrNothing: 'allornothing',
          LottoSaturday: 'lotto',
          Eurojackpot: 'eurojackpot',
          Keno: 'keno',
          BingoBoxen: 'bingoboxen',
          Viking: 'vikinglotto',
          Vikinglotto: 'vikinglotto',
          VikingLotto: 'vikinglotto'
        };

        var url = DS.Config.CONTEXTPREFIX + '/scapi/danskespil/numbergames/' + baseMap[this.game() || ''] + '/coupon';
        var options = { couponId: this.couponId() };
        var params = Utils.prepareParamString(options);
        var self = this;

        const couponDataFormat = (couponData) => {
          // playType System fix:
          if ((!couponData.playType || couponData.playType.toLowerCase() === 'unknown') && couponData.systemName) {
            couponData.playType = 'System';
          }

          if (couponData.prizeSections?.length > 0) {
            const sortedPrizes = [];
            for (let i = 0; i < couponData.prizeSections.length; i++) {
              const prizeData = couponData.prizeSections[i];
              const isCampaignPrize = prizeData.gameType === 'Campaign';
              let prizeGameType = prizeData.gameType;
              if (isCampaignPrize) {
                prizeGameType = couponData.couponGame;
              }
              const prizeSection = sortedPrizes.find((item) => item.gameType === prizeGameType);
              if (prizeSection) {
                if (isCampaignPrize) {
                  prizeSection.prizes.push(...prizeData.prizes);
                } else {
                  prizeSection.prizes.unshift(...prizeData.prizes);
                }
              } else {
                sortedPrizes.push({
                  gameType: prizeGameType,
                  winDate: prizeData.winDate,
                  winWeek: prizeData.winWeek,
                  prizes: prizeData.prizes,
                  isCampaignPrize: isCampaignPrize
                });
              }
            }
            couponData.prizeSections = sortedPrizes;
          }

          return couponData;
        };

        if (this.isPlayTogetherGame()) {
          if (this.isPlayTogetherTempCoupon()) {
            url = DS.Config.CONTEXTPREFIX + '/scapi/danskespil/numbergames/groupplay/plays/' + this.groupPlayId() + '/funding/' + this.fundingId();
          } else if (this.fundingId()) {
            url = DS.Config.CONTEXTPREFIX + '/scapi/danskespil/numbergames/' + baseMap[this.game() || ''] + '/groupPlayCoupon';
          }

          options = {};

          if (this.couponId()) {
            options.couponId = this.couponId();
          }

          if (this.fundingId()) {
            options.fundingId = this.fundingId();
          }

          if (this.groupPlayId()) {
            options.groupPlay = this.groupPlayId();
          }

          params = Utils.prepareParamString(options);

          return ApiRequest({
            url: url + params,
            unwrapSuccess: function (response) {
              let couponData = couponDataFormat(response);
              couponData = self.createPlaytogetherCouponObject(couponData);
              return couponData;
            }
          }).then((couponData) => {
            self.data(couponData);
            return couponData;
          });
        }

        return ApiRequest({
          url: url + params,
          unwrapSuccess: function (response) {
            const couponData = couponDataFormat(response);
            return couponData;
          }
        }).then((couponData) => {
          self.data(couponData);
          return couponData;
        });
      }.bind(this);

      this.createPlaytogetherCouponObject = function (content) {
        var couponObject = {
          groupPlay: content.groupPlay || {},
          campaigns: content.campaigns || [],
          campaignNumbers: content.campaignNumbers || [],
          campaignData: content.campaignData || [],
          clubName: content.groupPlay && content.groupPlay.groupName ? content.groupPlay.groupName : null,
          couponId: content.couponId || null,
          transactionDate: content.transactionDate || null,
          couponState: content.couponState || null,
          clientType: content.clientType || 'Unknown',
          price: content.price || 0,
          couponGame: content.couponGame || content.game || null,
          playType: content.playType || 'Unknown',
          systemName: content.systemName || null,
          systemLevel: content.systemLevel || null,
          systemRows: content.systemRows || null,
          drawFundings: content.drawFundings || [],
          games: content.games || [],
          multiWagerIndex: content.multiWagerIndex || null,
          multiWagers: content.multiWagers || [],
          prizesAvailable: content.prizesAvailable || false,
          prizeSections: content.prizeSections || [],
          totalWinAmount: content.totalWinAmount || 0
        };

        if (this.isPlayTogetherTempCoupon()) {
          var numberOfDraws = content.drawFundings.length || 1;

          couponObject.groupPlay = {
            totalRowCount: content.rowCount / numberOfDraws,
            playerSharePercentage: content.fundingSharePercentage
          };
          couponObject.clubName = content.groupName;
          couponObject.transactionDate = content.fundingTime;
          couponObject.price = content.totalShareAmount / numberOfDraws;
          couponObject.multiWagers = content.drawFundings;

          var date = new Date(content.drawDate);

          couponObject.drawDates = {
            drawWeek: this.getWeekNumber(content.drawDate),
            drawYear: date.getFullYear(),
            date: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear()
          };
        }

        return couponObject;
      }.bind(this);

      /**
       * Get week number from a date.
       * Function converts a locale date object to a UTC date, then calculates a week number based on the start of the year
       * This function takes Weeek 53 into account as well (happens roughly every 6 years)
       * "Inspiration" from: https://stackoverflow.com/a/6117889
       */
      this.getWeekNumber = function (date) {
        if (isNaN(Date.parse(date))) {
          console.error('Not a valid date format', date);
          return;
        }

        date = new Date(date);

        var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        var dayNum = utcDate.getUTCDay() || 7;
        utcDate.setUTCDate(utcDate.getUTCDate() + 4 - dayNum);
        var yearStart = new Date(Date.UTC(utcDate.getUTCFullYear(), 0, 1));
        var weekNumber = Math.ceil((((utcDate - yearStart) / 86400000) + 1) / 7);

        return weekNumber;
      };

      this.toJSON = function () {
        return {
          id: this.id(),
          couponId: this.couponId(),
          game: this.game(),
          data: this.data()
        };
      }.bind(this);

      // Init:
      this.init = function () {

      }.bind(this);

    });

    // Public functions:
    return ViewCouponModel;

  });
