defineDs('Shared/Framework/Mithril/Scripts/Helpers/Scroll', function () {

  // Variables:
  var callbacks = [];

  // Functions:
  var add = function ($element, callback) {
    if (typeof callback === 'undefined') {
      callbacks.push($element);
    } else {
      $element.addEventListener('scroll', callback); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    }
  };

  // Init:
  window.addEventListener('scroll', function () {
    var amount = callbacks.length;

    for (var i = 0; i < amount; i++) {
      callbacks[i]();
    }
  });

  // Public functions:
  return add;

});
