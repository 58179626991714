defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/PrizeSections',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (m, Utils) {

    var PrizeSections = function (controller) {

      // Variables
      const coupon = controller.model.data();


      // Functions:
      return coupon.prizeSections.map(function (section) {
        const isCampaignPrize = section.isCampaignPrize;
        var winDate = Utils.formatISO8601(section.winDate, { shortenDate: true, shortenMonthNames: true, includeYear: true, includeTime: false });

        if (section.gameType === 'AllOrNothing') {
          var compare = function (a, b) {
            var aPrizeValue = parseInt(a.name.split('/', 1)[0], 10);
            var bPrizeValue = parseInt(b.name.split('/', 1)[0], 10);
            if (aPrizeValue > bPrizeValue) {
              return -1;
            }
            if (aPrizeValue < bPrizeValue) {
              return 1;
            }
            return 0;
          };

          section.prizes.sort(compare);
        }

        return m('.prize-section', [
          m('p.prize-section-header', controller.d('GameTypes/' + section.gameType)),
          m('p.prize-section-date', controller.d('Prizes/WinningDate', { week: section.winWeek }), [
            m('span', winDate)
          ]),
          m('p', section.prizes[0].winDate),

          m('table.prizes-table', [
            m('tr', [
              m('th', controller.d('Prizes/WinningName')),
              m('th', controller.d('Prizes/WinningCount')),
              m('th', controller.d('Prizes/WinningAmount'))
            ]),

            section.prizes.map(function (prize) {

              var prizeName;

              if (isNaN(prize.name)) {
              // Keno etc
                prizeName = prize.name.replace('rigtige', '').replace('rigt.', '').trim();
              } else {
              // VikingLotto has only numbers
                prizeName = prize.name.split(' ')[0];
              }

              prizeName = isNaN(prizeName) ? prizeName : controller.d('Prizes/Name', { amount: prizeName });

              return m('tr', [
                m('td', prizeName),
                m('td', prize.count),
                m('td', Utils.formatCurrency(prize.amount))
              ]);

            }),

            isCampaignPrize ? m('tr', [
              m('td', {
                colspan: 3
              }, controller.d('Prizes/CampaignPrizeText'))
            ]) : null

          ])

        ]);
      });
    };


    // Public functions:
    return PrizeSections;

  });
