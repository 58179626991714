defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoClassic/Rows',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoClassic/Navigation',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoClassic/RowsList',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoClassic/Row'
  ],
  function (m, Navigation, RowsList, Row) {

    // Template:
    var Rows = function (controller) {

      // Variables:
      var game = controller.game();
      var maxRows = game.classicMaxRows();

      // Functions:
      var rowsClass = function () {
        var animate = controller.property('animate');
        var className = 'rows-container';
        var currentRow = controller.property('currentRow');

        if (/left|right/.test(animate)) {
          className += ' slide-to-' + animate;

          if (animate === 'left' && currentRow === 1) {
            className += '-half';
          }
        } else if (currentRow > 1) {
          className += ' rows-static-position';
        }

        if (game.getRow(currentRow).state === 'remove') {
          className += ' delete-row';
        }

        return className;
      };

      var rowsConfig = function ($element, rendered) {
        if (!rendered) {
          $DS($element).on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function () {
            var animate = controller.property('animate');
            var currentRow = controller.property('currentRow');
            var rows = game.rows();

            if (/remove/.test(animate)) {
              var clean = [];

              for (var i = 0; i < rows.length; i++) {
                if (rows[i].state !== 'remove') {
                  clean.push(rows[i]);
                }
              }

              for (var j = 0; j < clean.length; j++) {
                clean[j].count = j;
              }

              game.rows(clean);
              game.save();

              if (currentRow > clean.length) {
                game.addRow();

                controller.property('animate', 'left');
              } else {
                controller.property('animate', '');
              }

              m.redraw();
            } else if (animate === 'left' && currentRow === game.rows().length) {
              controller.property('animate', '');
            }
          });
        }
      };

      // View:
      return [
        m('div', { class: rowsClass(), config: rowsConfig }, [
          controller.game().rows().map(function (row, index) {
            if (index < maxRows) {
              return Row(controller, row, index);
            }
          }),
          Navigation(controller) // eslint-disable-line compat/compat
        ]),
        RowsList(controller)
      ];

    };

    // Public functions:
    return Rows;

  });
