defineDs('DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/ModalDialog', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Components/Button',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/Svg'
], function (m, Utils, Button, Svg) {
  'use strict';

  var ModalDialog = function (template, options) {

    options.buttons = options.buttons ? options.buttons.map(function (button) {
      return Utils.extend(true, {
        text: 'Test button',
        callback: function (close) {
          close();
        },
        size: 'large',
        color: 'primary',
        disabled: m.prop(false)
      }, button);
    }) : options.buttons;

    options = Utils.extend(true, {
      shouldBeComponent: false,
      closable: true,
      stackButtons: false,
      buttons: [],
      readMore: {},
      subText: null,
      class: [],
      controller: options.controller ? options.controller : null
    }, options);

    var renderFunction = function (ctrlOrHandleClose, registerElementOrVnode) {

      var handleClose = function () {
        options.iosPositionFix = false;
        return (options.shouldBeComponent ? ctrlOrHandleClose.handleClose() : ctrlOrHandleClose());
      };
      var registerElement = options.shouldBeComponent ? ctrlOrHandleClose.$overlayElement : registerElementOrVnode;

      if (options.iosPositionFix) {
        try {
          window.scroll({
            top: 0,
            behavior: 'smooth'
          });
        } catch (ignoredError) {
          // Older browsers does not support the object signature.
          window.scroll(0, 0);
        }
      }

      return m('div', (function () {
        var obj = {
          class: [
            'ds-modal-frame',
            'is--shown',
            (options.iosPositionFix ? 'ds-modal-frame--ios-position-fix' : '')
          ].concat(options.class, ModalDialogObj.class()).join(' '),
          config: function (element) {
            registerElement(element);
          }
        };
        if (options.id) {
          obj.id = options.id;
        }
        return obj;
      })(), [
        m('div', {
          class: 'ds-modal-wrap'
        }, [
          options.closable ?
            Svg('close', {
              onclick: handleClose,
              width: '18px',
              height: '18px',
              class: 'ds-modal--close sprite-symbol'
            }) : '',
          m('div', {
            class: 'ds-modal-content'
          }, [
            m('div', {
              class: 'ds-modal-html'
            }, Array.isArray(template) ? template : [template]),

            m('.button-row', { class: options.stackButtons ? 'button-row--stack' : '' }, options.buttons ? options.buttons.filter(function (button) {
              return button != null;
            }).map(function (button) {
              return m(Button, {
                uiTestId: button.uiTestId,
                id: button.id,
                loading: button.loading,
                color: button.color,
                size: button.size,
                class: button.class,
                disabled: button.disabled(),
                text: { default: button.text },
                eventId: button.eventId,
                onclick: function () {
                  if (!button.disabled()) {
                    button.callback.bind(this, handleClose)();
                  }
                }
              });
            }) : ''),
            (options.readMore.url && options.readMore.title) ? m('a', { href: options.readMore.url, class: 'ds-modal-link' }, [
              options.readMore.title
            ]) : null,
            options.subText ? m('small', {
              class: 'ds-modal-subtext'
            }, options.subText) : ''
          ])
        ])
      ]);
    };

    var ModalDialogObj = {
      controller: options.controller ? options.controller : null,
      class: m.prop('ds-modal-frame')
    };

    var renderKey = options.shouldBeComponent ? 'view' : 'render';

    ModalDialogObj[renderKey] = renderFunction;

    return ModalDialogObj;
  };

  return ModalDialog;
});
