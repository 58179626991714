defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/MultiClient', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
  'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoApi',
  'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoDictionary',
  'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
  'DanskeSpil/Domain/VikingLotto/Scripts/Models/VikingLottoGame',
  'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoClassic/DeleteOverlay',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/MultiClientDictionary',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/MultiClient/MultiClient'
], function (Component, AccountIntegration, Api, VikingLottoDictionary, VikingLottoInfo, VikingLottoGame, DeleteOverlay, mcDictionary, MultiClient) {
  Component('vikinglotto-multi-client', [AccountIntegration, VikingLottoDictionary, VikingLottoInfo, VikingLottoGame], function (m, ignoreRoute, settings, property, element) {
    this.d = VikingLottoDictionary.get;
    this.settings = settings;
    this.property = property;
    this.element = element;
    this.gameType = 'VikingLotto';
    this.infoFeed = m.prop(VikingLottoInfo);
    this.isFeedDown = m.prop(this.infoFeed().isFeedDown());
    this.getLightningDefaultRowCount = m.prop(this.infoFeed().getDefaultRowCount());
    this.getLightningOptions = m.prop(this.infoFeed().getLightningRowOptions);
    this.api = function () {
      return Api;
    }.bind(this);
    this.deleteOverlayForClassicRow = DeleteOverlay;

    this.isCampaignEngineOn = this.settings.useCampaignEngine;
    this.campaign = m.prop(this.settings.campaign);
    if (this.isCampaignEngineOn) {
      this.campaign(null);
    }

    var setItem = mcDictionary.setItem.bind(null, this.d);

    var vikinglottoDictionaries = {
      closedGame: {
        noOpenDrawHeader: setItem('VikingLottoClosed/FrontpageLightning/NoOpenDrawHeader'),
        noOpenDrawText1: setItem('VikingLottoClosed/FrontpageLightning/NoOpenDrawText_1'),
        noOpenDrawText2: setItem('VikingLottoClosed/FrontpageLightning/NoOpenDrawText_2'),
        gameNotAvailableHeader: setItem('VikingLottoClosed/FrontpageLightning/GameNotAvailableHeader'),
        gameNotAvailableText1: setItem('VikingLottoClosed/FrontpageLightning/GameNotAvailableText_1'),
        gameNotAvailableText2: setItem('VikingLottoClosed/FrontpageLightning/GameNotAvailableText_2')
      },
      joker: {
        wednesdayJokerLabel: setItem('MultiClient/JokerStep/WednesdayJokerLabel'),
        saturdayJokerLabel: setItem('MultiClient/JokerStep/SaturdayJokerLabel'),
        bothJokerLabel: setItem('MultiClient/JokerStep/BothDrawsDaysLabel')
      },
      receipt: {
        subscribeReminder: {
          keepRemindMe: setItem('ReceiptStep/SubscribeReminder/KeepRemindMe'),
          remindMe: setItem('ReceiptStep/SubscribeReminder/RemindMe'),
          errorUserUnauthorized: setItem('ReceiptStep/SubscribeReminder/ErrorUserUnauthorized'),
          error: setItem('ReceiptStep/SubscribeReminder/Error'),
          successRemindMe: setItem('ReceiptStep/SubscribeReminder/SuccessRemindMe'),
          successDontRemindMe: setItem('ReceiptStep/SubscribeReminder/SuccessDontRemindMe')
        }
      }
    };

    mcDictionary.setDictionary.bind(this.d)(vikinglottoDictionaries);

    // Component
    MultiClient(m, VikingLottoGame, this);
  });
});