defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoWinningNumbers/Results',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoUtils'
  ],
  function (m, Utils) {

    // Template:
    var Results = function (controller, data, type) {

      // Variables:
      var d = controller.d;

      var head = function () {
        return m('thead', [
          m('tr', [
            m('th', type === 'joker' ? d('WinningNumbers/JokerResultsCorrectNumbers') : d('WinningNumbers/ResultsCorrectNumbers')),
            m('th', type === 'joker' ? d('WinningNumbers/JokerResultsWinners') : d('WinningNumbers/ResultsWinners')),
            m('th', type === 'joker' ? d('WinningNumbers/JokerResultsPrize') : d('WinningNumbers/ResultsPrize'))
          ])
        ]);
      };

      var row = function (data) {
        return m('tr', [
          m('th', controller.d('WinningNumbers/Numbers', { amount: data.name.replace(' rigt.', '').replace(' rigtige', '') })),
          m('td', Utils.formatNumber(data.numberOfWinners)),
          m('td', Utils.formatCurrency(data.amount))
        ]);
      };

      var body = function (data) {
        var rows = [];

        rows = data.map(function (obj) {
          return row(obj);
        });

        return m('tbody', rows);
      };

      // Return:
      return m('table', [
        head(),
        body(data)
      ]);

    };

    // Public functions:
    return Results;

  });
