defineDs('DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialSearchBar',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
  ],
  function (m) {
    return function (controller) {

      var updateSearchQuery = function (value) {
        controller.searchQuery(value);
        controller.filteredTutorials(controller.allTutorials.filter(matchTutorialTitleWithSearchQuery));
      }.bind(this);

      var collapseCategories = function () {
        document.querySelectorAll('.tutorial__category--show').forEach(function (category) {
          category.classList.remove('tutorial__category--show');
        });
      }.bind(this);

      var matchTutorialTitleWithSearchQuery = function (tutorial) {
        if (!controller.searchQuery()) return false;

        var textToSearch = tutorial.title + tutorial.subtitle;
        if (tutorial.urlPrefix === '/simple' && tutorial.richText) {
          var firstOpeningParagraphTagIndex = tutorial.richText.indexOf('<p>');
          var lastClosingParagraphTagIndex = tutorial.richText.lastIndexOf('</p>');
          var searchableRichText = tutorial.richText.slice(firstOpeningParagraphTagIndex, lastClosingParagraphTagIndex);
          searchableRichText = searchableRichText.replace(/(<([^>]+)>)/gi, '');
          textToSearch += searchableRichText;
        } else if (tutorial.elements) {
          textToSearch += tutorial.elements.map(function (element) {
            return element.title + element.text.replace(/(<([^>]+)>)/gi, '');
          }).join('');
        } else if (tutorial.urlPrefix === '/avalon' && tutorial.content) {
          var content = tutorial.content.outerHTML;
          var firstOpeningDivTagIndex = content.indexOf('<div');
          var lastClosingDivTagIndex = content.lastIndexOf('</div>');
          var searchableText = content.slice(firstOpeningDivTagIndex, lastClosingDivTagIndex);
          searchableText = searchableText.replace(/(<([^>]+)>)/gi, '');
          textToSearch += searchableText;
        }

        return controller.searchQuery().trim().split(' ').every(function (word) {
          return new RegExp('(?=.*' + word + ')', 'gmi').test(textToSearch);
        });
      }.bind(this);

      return m('.tutorial__searchbar', [
        m('svg.tutorial__searchbar-loop', {
          height: '20', width: '20', xmlns: 'http://www.w3.org/2000/svg'
        }, [
          m('g', [
            m('circle', {
              cx: '12', cy: '9', r: '7'
            }),
            m('path', {
              d: 'm7 14.5 -6 6'
            }),
          ])
        ]),
        m('input.tutorial__searchbar-input', {
          oninput: function (event) {
            if (event.target.value.length > -1) {
              updateSearchQuery(event.target.value);
              collapseCategories();
            }
          },
          value: controller.searchQuery(),
          placeholder: controller.searchBarPlaceholder
        }),
        m('.tutorial__searchbar-clear', {
          onclick: () => {
            controller.searchQuery('');
          }
        })
      ]);
    };
  });
