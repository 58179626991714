defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/StatisticsComponentForGameClient',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',
    'Shared/Framework/Mithril/Scripts/Helpers/Dictionary',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (m, Storage, Dictionary, Render, Utils) {
    // Pre-init:
    var StatisticsDictionary = new Dictionary('/NumberGames/StatisticsModule');

    var StatisticsComponentForGameClient = function (controller, gameName) {

      var numbersFromStatistics = JSON.parse(Storage.get(gameName + '-StatisticsNumbers'));
      if (!numbersFromStatistics) {
        return;
      }

      // Variables
      var d = StatisticsDictionary.get;
      var render = Render();
      var isMobile = m.prop(Utils.isMobile());
      var game = controller.game();
      var currentRow = controller.property('currentRow');

      // Functions:
      var toggleStaticticsModulePanel = function () {
        return function () {
          controller.property('toggleStaticticsModulePanel', !controller.property('toggleStaticticsModulePanel'));
          m.redraw();
        };
      };

      var toggleNumber = function (number, numberType) {
        return function () {
          // Eurojackpot
          if (gameName === 'Eurojackpot') {
            if (numberType === 'numbers') {
              if (game.hasNumber(currentRow, number)) {
                game.removeNumber(currentRow, number);
              } else {
                if ((game.numbersPerRowMax() !== game.getRow(currentRow).numbers.length) && (!controller.property('autogeneratingNumbers'))) {
                  game.addNumber(currentRow, number, false);
                } else {
                  controller.property('animate', 'shake');
                  return;
                }
              }
            } else if (numberType === 'extraNumbers') {
              if (game.hasStarNumber(currentRow, number)) {
                game.removeStarNumber(currentRow, number);
              } else {
                if ((game.starNumbersPerRowMax() !== game.getRow(currentRow).starNumbers.length) && (!controller.property('autogeneratingNumbers'))) {
                  game.addStarNumber(currentRow, number, false);
                } else {
                  controller.property('animate', 'shake-star-numbers');
                  return;
                }
              }
            }
          }

          // Vikinglotto
          if (gameName === 'Vikinglotto') {
            if (numberType === 'numbers') {
              if (game.hasNumber(currentRow, number)) {
                game.removeNumber(currentRow, number);
              } else {
                if ((game.numbersPerRowMax() !== game.getRow(currentRow).numbers.length) && (!controller.property('autogeneratingNumbers'))) {
                  game.addNumber(currentRow, number, false);
                } else {
                  controller.property('animate', 'shake');
                  return;
                }
              }
            } else if (numberType === 'extraNumbers') {
              if (game.hasVikingNumber(currentRow, number)) {
                game.removeVikingNumber(currentRow, number);
              } else {
                if ((game.vikingNumbersPerRowMax() !== (game.getRow(currentRow).vikingNumber ? 1 : 0)) && (!controller.property('autogeneratingNumbers'))) {
                  game.addVikingNumber(currentRow, number, false);
                } else {
                  controller.property('animate', 'shake-viking-numbers');
                  return;
                }
              }
            }
          }

          // Lotto or Keno
          if ((gameName === 'Lotto') || (gameName === 'Keno')) {
            if (game.hasNumber(currentRow, number)) {
              game.removeNumber(currentRow, number);
            } else {
              if ((game.numbersPerRowMax() !== game.getRow(currentRow).numbers.length) && (!controller.property('autogeneratingNumbers'))) {
                game.addNumber(currentRow, number, false);
              } else {
                controller.property('animate', 'shake');
                return;
              }
            }
          }

          if (numberType === 'numbers') {
            controller.property('numberSelected' + number, true);
          }
        };
      };

      var removeNumber = function (numberIndex, numberType) {
        return function () {
          event.stopPropagation();
          delete numbersFromStatistics[numberType][numberIndex];
          Storage.set(gameName + '-StatisticsNumbers', JSON.stringify(numbersFromStatistics));
        };
      };

      var autoSelectFromStatistics = function () {
        return function () {
          if (gameName === 'Keno') {
            if ((game.getRow(currentRow).numbers.length === game.numbersPerRowMax()) || controller.property('autogeneratingNumbers')) {
              return;
            }
          } else {
            if ((game.isRowValid(currentRow)) || (controller.property('autogeneratingNumbers'))) {
              return;
            }
          }
          var amountOfNumbersToGenerate = ((gameName === 'Keno') ? game.numbersPerRowMax() : game.numbersPerRow()) - game.getRow(currentRow).numbers.length;
          var amountOfSpecialNumbersToGenerate = null;
          var unselectedNumbers = [];
          var unselectedSpecialNumbers = [];
          var randomNumbers = [];
          var randomSpecialNumbers = [];

          if (gameName === 'Eurojackpot') {
            amountOfSpecialNumbersToGenerate = game.starNumbersPerRowMax() - game.getRow(currentRow).starNumbers.length;
          }
          if (gameName === 'Vikinglotto') {
            amountOfSpecialNumbersToGenerate = game.vikingNumbersPerRowMax() - (game.getRow(currentRow).vikingNumber ? 1 : 0);
          }

          for (var numbersType in numbersFromStatistics) {
            for (var statisticsNumber in numbersFromStatistics[numbersType]) {
              var number = numbersFromStatistics[numbersType][statisticsNumber];

              if (gameName === 'Eurojackpot') {
                if (numbersType === 'numbers') {
                  if (!game.hasNumber(currentRow, number)) {
                    unselectedNumbers.push(number);
                  }
                } else if (numbersType === 'extraNumbers') {
                  if (!game.hasStarNumber(currentRow, number)) {
                    unselectedSpecialNumbers.push(number);
                  }
                }
              }

              if (gameName === 'Vikinglotto') {
                if (numbersType === 'numbers') {
                  if (!game.hasNumber(currentRow, number)) {
                    unselectedNumbers.push(number);
                  }
                } else if (numbersType === 'extraNumbers') {
                  if (!game.hasVikingNumber(currentRow, number)) {
                    unselectedSpecialNumbers.push(number);
                  }
                }
              }

              if ((gameName === 'Lotto') || (gameName === 'Keno')) {
                if (!game.hasNumber(currentRow, number)) {
                  unselectedNumbers.push(number);
                }
              }
            }
          }

          var randomNumbersGenerator = function (limit, numbersArray, randomArray) {
            for (; (randomArray.length < limit) && (numbersArray.length > 0);) {
              var randomNumber = numbersArray[Math.floor(Math.random() * numbersArray.length)];
              if (randomArray.indexOf(randomNumber) === -1) {
                numbersArray.splice(numbersArray.indexOf(randomNumber), 1);
                randomArray.push(randomNumber);
              }
            }
          };

          randomNumbersGenerator(amountOfNumbersToGenerate, unselectedNumbers, randomNumbers);
          randomNumbersGenerator(amountOfSpecialNumbersToGenerate, unselectedSpecialNumbers, randomSpecialNumbers);

          if ((gameName === 'Eurojackpot') || (gameName === 'Vikinglotto')) {
            randomNumbers.map(function (number) {
              toggleNumber(number, 'numbers')();
            });
            randomSpecialNumbers.map(function (number) {
              toggleNumber(number, 'extraNumbers')();
            });
          }

          if ((gameName === 'Lotto') || (gameName === 'Keno')) {
            randomNumbers.map(function (number) {
              toggleNumber(number, 'numbers')();
            });
          }
        };
      };

      var getNumbersFromStatistics = function () {
        var numbersDOM = [];
        controller.property('countOfNumbersFromStatistics', 0);
        for (var numbersType in numbersFromStatistics) {
          for (var statisticsNumber in numbersFromStatistics[numbersType]) {
            var number = numbersFromStatistics[numbersType][statisticsNumber];

            var className = 'selected-number-box';
            if (numbersType === 'extraNumbers') {
              className += ' special';
            }
            if (((gameName === 'Eurojackpot') || (gameName === 'Vikinglotto')) && (numbersType === 'numbers')) {
              if (game.hasNumber(currentRow, number)) {
                className += ' selected';
              }
            } else if ((gameName === 'Eurojackpot') && (numbersType === 'extraNumbers')) {
              if (game.hasStarNumber(currentRow, number)) {
                className += ' selected';
              }
            } else if ((gameName === 'Vikinglotto') && (numbersType === 'extraNumbers')) {
              if (game.hasVikingNumber(currentRow, number)) {
                className += ' selected';
              }
            } else if (game.hasNumber(currentRow, number)) {
              className += ' selected';
            }
            controller.property('countOfNumbersFromStatistics', (controller.property('countOfNumbersFromStatistics') + 1));
            numbersDOM.push(m('div', { class: className, onclick: toggleNumber(number, numbersType) }, [
              m('div', { class: 'selected-number-delete-button', onclick: removeNumber(number - 1, numbersType) }, [
                m('svg', { class: 'icon' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-x' })
                ])
              ]),
              m('div', { class: 'number-cell' }, number)
            ]));
          }
        }
        return numbersDOM;
      };

      var goToStatistics = function () {
        return function () {
          var baseURL = window.location.origin;
          window.location.href = baseURL + '/' + gameName + '/statistik';
        };
      };

      var deleteStatisticsNumbers = function () {
        return function () {
          Storage.set(gameName + '-StatisticsNumbers', null);
        };
      };

      // Render:
      render.ready.then(function (nodes) {
        var $statisticsComponentInnerWrapper = nodes['statistics-component-inner-wrapper'].$element;
        var statisticsNumberWrapperHeight = nodes['numbers-from-statistics-wrapper'].$element.offsetHeight;

        $statisticsComponentInnerWrapper.style.height = statisticsNumberWrapperHeight + 'px';

        if (!controller.property('hideStatisticsNumbersDemoMode') && isMobile()) {
          setTimeout(function () {
            controller.property('hideStatisticsNumbersDemoMode', true);
            m.redraw();
          }, 2500);
        }
      });

      // Return:
      return m('div', { class: 'statistics-component-for-game-client-wrapper' + (controller.property('hideStatisticsNumbersDemoMode') ? (controller.property('toggleStaticticsModulePanel') ? ' opened' : ' closed') : '') }, [
        m('div', { class: 'statistics-component-outer-wrapper' }, [
          m('div', { class: 'statistics-component-toggle-button-wrapper', onclick: toggleStaticticsModulePanel() }, [
            m('div', { class: 'toggle-button' }, [
              m('span', { class: 'icon-wrapper' }, [
                m('svg', { class: 'icon' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-up-arrow' })
                ])
              ]),
              m('span', { class: 'text-wrapper' }, d('SelectedNumbersForGameClientsComponent/Headline')),
              m('span', { class: 'numbers-count-wrapper' }, [
                m('span', { class: 'count-value' }, controller.property('countOfNumbersFromStatistics'))
              ])
            ])
          ]),
          m('div', { class: 'statistics-component-inner-wrapper', config: render.depend('statistics-component-inner-wrapper') }, [
            m('div', { class: 'numbers-from-statistics-wrapper', config: render.depend('numbers-from-statistics-wrapper') }, getNumbersFromStatistics()),
            m('div', { class: 'actions-buttons-wrapper' }, [
              m('div', { class: 'action-button-wrapper', onclick: autoSelectFromStatistics() }, [
                m('span', { class: 'text-wrapper' }, d('SelectedNumbersForGameClientsComponent/Buttons/Autoselect')),
                m('svg', { class: 'icon' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-shuffle' })
                ])
              ]),
              m('div', { class: 'action-button-wrapper', onclick: goToStatistics() }, [
                m('span', { class: 'text-wrapper' }, d('SelectedNumbersForGameClientsComponent/Buttons/Edit')),
                m('svg', { class: 'icon' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-edit' })
                ])
              ]),
              m('div', { class: 'action-button-wrapper', onclick: deleteStatisticsNumbers() }, [
                m('span', { class: 'text-wrapper' }, d('SelectedNumbersForGameClientsComponent/Buttons/Delete')),
                m('svg', { class: 'icon' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-rubbish' })
                ])
              ])
            ])
          ])
        ])
      ]);
    };


    // Public functions:
    return StatisticsComponentForGameClient;

  });
