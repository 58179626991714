defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoApi',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  ],
  function (m, VikingLottoApi, Storage) {

    // Variables:
    var data = m.prop({});
    var isFeedDown = m.prop(false);
    var jokerData = m.prop({});
    var noOpenDraw = m.prop(false);
    var playSystems = m.prop();
    var ready = m.deferred();

    // Functions:
    var getDrawRepeatOptions = function () {
      var options = data().drawRepeatOptions && data().drawRepeatOptions.options ? data().drawRepeatOptions.options : [];

      // Filter: Draw repeat value should not be higher than known amount of upcomming draws
      options = options.filter(function (v) {
        return v <= data().draws.length;
      });

      // Sort:
      options.sort(function (a, b) {
        return a > b ? 1 : -1;
      });

      return options;
    };

    var getDrawRepeatMax = function () {
      return data().drawRepeatMax;
    };

    var getJokerPrice = function () {
      var dayData = jokerData().jokerWednesday || jokerData().jokerSaturday;

      if (dayData && dayData.openDraw && dayData.openDraw.rowPrice) {
        return dayData.openDraw.rowPrice;
      }

      return dayData && dayData.draws && dayData.draws[0] && dayData.draws[0].rowPrice ? dayData.draws[0].rowPrice : 0;
    };

    // Classic Options:
    const getClassicRowOptions = function () {
      return data().classicOptions?.options || [1, 2, 3, 4, 5, 10, 20, 50];
    };

    const getClassicRowDefaultRowCount = function () {
      return data().classicOptions?.default || 4;
    };

    // Lightning options:
    var getLightningRowOptions = function () {
      return data().lightningOptions && data().lightningOptions.options ? data().lightningOptions.options : [1, 2, 3, 4, 5];
    };

    var getDefaultRowCount = function () {
      return data().lightningOptions && data().lightningOptions.default ? data().lightningOptions.default : 10;
    };

    // Lucky options:
    var getLuckyRowOptions = function () {
      return data().luckyOptions && data().luckyOptions.options ? data().luckyOptions.options : [10, 20, 30, 40, 50];
    };

    var getLuckyDefaultRowCount = function () {
      return data().luckyOptions && data().luckyOptions.default ? data().luckyOptions.default : 10;
    };

    var getDrawRepeatDefault = function () {
      return data().drawRepeatOptions && data().drawRepeatOptions.default ? data().drawRepeatOptions.default : 1;
    };

    this.getPlaySystems = () => {
      if (playSystems()) {
        // Cache
        return playSystems();
      }

      var systemNames = data().playSystems;

      playSystems(systemNames.map(function (name) {
        var splitted = name.split(' ');
        var counts = splitted[1].split('-');

        return ({ name: name, type: splitted[0], selectedNumbersCount: counts[0], rowsCount: counts[1] });
      }));

      return playSystems();
    };

    var getSystemNames = (type) => {
      var systems = this.getPlaySystems();
      var names = [];

      for (var i = 0; i < systems.length; i++) {
        if (!type || systems[i].type === type) {
          names.push(systems[i].name);
        }
      }

      return names;
    };

    // Open draw:
    var getOpenDraw = function () {
      return data().openDraw;
    };

    var feedValid = function (str) {
      try {
        var json = JSON.parse(str);

        if (json && typeof json.vikinglotto.openDraw !== 'undefined') {
          return true;
        }
      } catch (e) {
      }

      return false;
    };

    var storeToCache = function (json) {
      // Store with TTL 2 min.
      Storage.set('VikingLottoInfoFeed', json ? JSON.stringify(json) : null, 120);
    };

    // Initialize:
    var init = function () {

      // 1) LOOK IN DOM

      var $vikingLottoFeedData = document.querySelector('#vikinglotto-info');
      if ($vikingLottoFeedData) {
        var domData = $vikingLottoFeedData.innerHTML;

        if (feedValid(domData)) {
          var domJson = JSON.parse(domData);

          data(domJson.vikinglotto);

          noOpenDraw(!data().openDraw);

          jokerData({
            jokerSaturday: domJson.jokerSaturday,
            jokerWednesday: domJson.jokerWednesday
          });

          // Store to cache
          storeToCache(domJson);

          ready.resolve();

          return;
        }
      }

      // 2) LOOK IN LOCALSTORAGE
      var cacheData = Storage.get('VikingLottoInfoFeed');

      if (!cacheData || !feedValid(cacheData)) {
        // Clear cache
        storeToCache(null);
      } else {
        // Use cache
        var d = JSON.parse(cacheData);

        data(d.vikinglotto);

        noOpenDraw(!data().openDraw);

        jokerData({
          jokerSaturday: d.jokerSaturday,
          jokerWednesday: d.jokerWednesday
        });

        ready.resolve();

        return;
      }

      // 3) LOAD INFO FROM FEED
      VikingLottoApi.getVikingLottoInfo().then(function (d) {
        data(d.vikinglotto);

        noOpenDraw(!data().openDraw);

        jokerData({
          jokerSaturday: d.jokerSaturday,
          jokerWednesday: d.jokerWednesday
        });

        // Store to cache
        storeToCache(d);

        ready.resolve();
      }, function () {
        isFeedDown(true);
        noOpenDraw(true);

        ready.resolve();

        console.error('### VIKING LOTTO FEED DOWN ###');
      });
    };

    init();

    // Public functions:
    return {
      data: data,
      getOpenDraw: getOpenDraw,
      jokerData: jokerData,
      ready: ready.promise,
      isFeedDown: isFeedDown,
      noOpenDraw: noOpenDraw,
      getDrawRepeatOptions: getDrawRepeatOptions,
      getDrawRepeatDefault: getDrawRepeatDefault,
      getJokerPrice: getJokerPrice,
      getSystemNames: getSystemNames,
      getClassicRowOptions: getClassicRowOptions,
      getClassicRowDefaultRowCount: getClassicRowDefaultRowCount,
      getLightningRowOptions: getLightningRowOptions,
      getDefaultRowCount: getDefaultRowCount,
      getLuckyRowOptions: getLuckyRowOptions,
      getLuckyDefaultRowCount: getLuckyDefaultRowCount,
      getDrawRepeatMax: getDrawRepeatMax
    };

  });
