defineDs('DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialFooter',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
  ],
  function (m) {
    return function ({ title, text, footerElements, style, activate = true }) {
      return m('.tutorial-footer', {
        class: activate ? '' : 'tutorial-footer--disable'
      },
      m('.tutorial-footer-inner', [
        m('.tutorial-footer__text-wrapper', [
          m('.tutorial-footer__title', { style }, title),
          m('.tutorial-footer__text', m.trust(text))
        ]),
        m('.tutorial-footer__footerElement', footerElements ? footerElements.map(function (footerElement) {
          return m('.tutorial-footer__footerElement-wrapper', [
            m('.tutorial-footer__footerElement-image-wrapper',
              m('img.tutorial-footer__footerElement-image', {
                src: footerElement.image
              })
            ),
            m('a.tutorial-footer__footerElement-text-wrapper', {
              href: footerElement.helpLink.Url
            }, [
              m('.tutorial-footer__footerElement-title', footerElement.title),
              m('.tutorial-footer__footerElement-text', footerElement.helpLink.Text)
            ]),
          ]);
        }) : null)
      ])
      );
    };
  });
