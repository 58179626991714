defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/VikingLottoMosaik',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoMosaik/Tile'
  ],
  function (Component, Tile) {

    // Component:
    Component('vikinglotto-mosaik', function (m, route, settings) {

      var root = {
        controller: function () {

          // Variables
          this.states = {
            'mosaik-smil': m.prop(null),
            'mosaik-smilewithtext': m.prop(null),
            'mosaik-grafik': m.prop(null),
            'mosaik-pineapples': m.prop(null)
          };
          this.fileType = {
            'mosaik-smil': 'svg',
            'mosaik-smilewithtext': 'svg',
            'mosaik-grafik': 'png',
            'mosaik-pineapples': 'png',
            'mosaik-static-spot': 'png'
          };
          this.colorOrder = ['dark-blue', 'pink', 'yellow', 'purple', 'babyblue', 'yellow', 'babyblue', 'purple', 'dark-blue', 'pink', 'yellow', 'dark-blue', 'purple'];
          this.topTiles = m.prop([]);
          this.bottomTiles = m.prop([]);
          this.staticTileId = 4;
          this.cta = {
            ctaLink: settings.ctaLink,
            ctaTitle: settings.ctaTitle
          };

          // Initialization of tiles
          this.colorOrder.forEach(function (color, index) {
            var tile = {
              tileColor: m.prop(color),
              id: m.prop(index),
              isStatic: m.prop(index === this.staticTileId),
              state: m.prop(index === this.staticTileId ? 'mosaik-static-spot' : null),
              timeToLive: m.prop(null),
              fadeTimer: m.prop(null),
              showTileOnMobile: m.prop(index === 4 || index === 10 || index === 12),
              show: m.prop(false)
            };

            index <= 5 ? this.topTiles().push(tile) : this.bottomTiles().push(tile);
          }.bind(this));

          // Functions
          this.getRandomNumber = function (span, offset) {
            return Math.floor((Math.random() * span) + offset);
          };

          this.tick = function () {

            // Find occupied tiles and add newly emptied tiles to ignore list
            var tilesToIgnore = this.topTiles().concat(this.bottomTiles()).filter(function (tile) {
              if (tile.id() === this.staticTileId) return true;
              if (tile.state() !== null) {

                // Start fade-out animation if less than one second remains
                if ((tile.timeToLive() - 1000) < Date.now()) {
                  tile.show(false);
                }

                // Remove image (DOM-element) if timeToLive has reached zero
                if (tile.timeToLive() < Date.now()) {

                  this.states[tile.state()](null);
                  tile.state(null);
                  tile.timeToLive(null);
                  tile.fadeTimer(null);

                  return true;
                }
              }
            }.bind(this));

            // Get a random tile (that is not on ignore list)
            this.randomTile = function () {
              var emptyTiles =  this.topTiles().concat(this.bottomTiles()).filter(function (tile) {
                return tile.state() === null && !tilesToIgnore.some(function (ignoreTile) {
                  return tile.id() === ignoreTile.id();
                });
              }.bind(this));
              return emptyTiles[Math.floor(Math.random() * (emptyTiles.length - 1))];
            }.bind(this);

            // Find unused images
            var unusedStates = Object.keys(this.states).filter(function (stateKey) {
              return !this.states[stateKey]();
            }.bind(this));

            // Pair image and tile
            unusedStates.forEach(function (stateKey) {
              var tile = this.randomTile();
              tile.state(stateKey);
              tile.timeToLive(Date.now() + this.getRandomNumber(5000, 5000));
              tile.show(true);
              this.states[stateKey](true);
              tile.fadeTimer(this.getRandomNumber(700, 300));
            }.bind(this));
          };

          // Hero spot color toggle
          var colorToggle = document.querySelector('.js-vikinglotto-color-toggle');
          var sectionWithBackground = document.querySelector('.js-top-spot');
          var cssColorSelectors = ['dark-blue', 'babyblue', 'pink', 'purple', 'yellow', 'default'];
          var currentColorIndex = 5;
          colorToggle.addEventListener('click', function () {
            sectionWithBackground.classList.remove('vikinglotto-top-spot--' + cssColorSelectors[currentColorIndex]);
            currentColorIndex = currentColorIndex === 5 ? 0 : currentColorIndex + 1;
            if (currentColorIndex !== 5) sectionWithBackground.classList.add('vikinglotto-top-spot--' + cssColorSelectors[currentColorIndex]);
          });
        },

        view: function (ctrl) {
          ctrl.tick();
          return m('section.vikinglotto-section', [
            m('.vikinglotto-mosaik', [
              m('.vikinglotto-mosaik__tiles', [
                ctrl.topTiles().map(function (tile) {
                  return Tile(tile, ctrl);
                })
              ]),
              m('.vikinglotto-mosaik__tiles', [
                ctrl.bottomTiles().map(function (tile) {
                  return Tile(tile, ctrl);
                })])
            ])
          ]);
        }
      };

      // Setup Routes:
      route('/', root);
    });
  });
