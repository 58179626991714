defineDs('DanskeSpil/Domain/NumberGames/Scripts/HowToPlayContent', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Helpers/Render'
],
function (m, Render) {

  // Template:
  var HowToPlayContent = function (controller, settings) {

    // Variables:
    var render = Render();
    var data = controller.property('howToPlayData');

    // Functions:
    var playVideo = function (content) {
      return function () {

        if (content) {
          content.playing = true;
          var player;

          // YouTube:
          if (content.provider === 'youtube') {
            player = new window.YT.Player(content.contentId, {
              events: {
                onReady: function () {
                  play();
                }
              }
            });

            var play = function () {
              player.playVideo();
            };

            // Vimeo:
          } else if (content.provider === 'vimeo') {
            player = new window.Vimeo.Player(data.nodes['vimeo-iframe-' + content.contentId].$element);

            player.ready().then(function () {
              player.play();
            });
          }
        }
      };
    };

    // Render:
    render.ready.then(function (nodes) {
      data.nodes = nodes;

      if (!data.youtube.ready) {
        include('https://www.youtube.com/iframe_api', function () {
          window.onYouTubeIframeAPIReady = function () {
            data.youtube.ready = true;
          };
        });
      }

      if (!data.vimeo.ready) {
        include('https://player.vimeo.com/api/player.js', function () {
          data.vimeo.ready = true;
        });
      }

      controller.property('howToPlayData', data);
    });

    // View:
    var items = [].concat(data.youtube.contents).concat(data.vimeo.contents).concat(data.slideshare.contents).sort(function (a, b) {
      return a.order > b.order ? 1 : -1;
    });

    return m('div', { class: 'how-to-play-content', config: render.depend('content') }, [
      m('h1', { class: 'headline' }, settings.header),
      m('p', { class: 'text' }, settings.text),
      m('div', { class: 'video-list' }, [
        items.map(function (content) {
          var id = content.contentId;
          var name = content.title;
          var provider = content.provider;

          return m('div', { class: 'video-element' }, [
            m('h2', { class: 'video-headline' }, name),
            m('div', { class: 'video-box ' + (content.playing ? 'video-playing' : 'video-ready') }, [
              m('div', { class: 'video-frame' }, controller.getFrame(content, render)),
              content.hasPreviewImage ? m('img', { src: content.previewImage, class: 'video-image', alt: name }) : null,
              m('div', { class: 'video-overlay', config: render.depend(provider + '-play-button-' + id), onclick: playVideo(content) }, [
                m('div', { class: 'video-play-button' }, [
                  m('div', { class: 'play-spinner' }),
                  m('div', { class: 'play-button' })
                ])
              ])
            ])
          ]);
        })
      ])
    ]);

  };

  // Public functions:
  return HowToPlayContent;

});
