defineDs('DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
  [
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/SaturdayLottoApi',
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  ],
  function (SaturdayLottoApi, m, Storage) {

    // Variables:
    var data = m.prop({});
    var isFeedDown = m.prop(false);
    var ready = m.deferred();

    // Functions:
    var Base = function (data, jokerData) {

      if (!data()) {
        console.error('Error: data() is empty');
        data = m.prop({});
      }

      // Test scenario: no data()
      /// /////////////////////////////////////////////
      // data = m.prop({});
      /// /////////////////////////////////////////////

      this.data = data;
      this.jokerData = jokerData;
      this.playSystems = m.prop();
      this.noOpenDraw = m.prop(isFeedDown() ? false : !data() || !data().openDraw);

      this.getJokerPrice = function () {
        var jokerData = this.jokerData();

        if (jokerData && jokerData.openDraw && jokerData.openDraw.rowPrice) {
          return jokerData.openDraw.rowPrice;
        }

        return jokerData && jokerData.draws && jokerData.draws[0] && jokerData.draws[0].rowPrice ? jokerData.draws[0].rowPrice : 0;
      };

      // Draw repeat options:
      this.getDrawRepeatOptions = function () {
        var options = data() && data().drawRepeatOptions && data().drawRepeatOptions.options ? data().drawRepeatOptions.options : [];

        // Filter: Draw repeat value should not be higher than known amount of upcoming draws:
        options = options.filter(function (v) {
          return v <= data().draws.length;
        });

        // Sort
        options.sort(function (a, b) { return a > b ? 1 : -1; });

        return options;
      };

      this.getDrawRepeatMax = function () {
        return data().drawRepeatMax;
      };

      this.getDrawRepeatDefault = function () {
        return data().drawRepeatOptions && data().drawRepeatOptions.default ? data().drawRepeatOptions.default : 1;
      };

      // Classic Options:
      this.getClassicOptions = function () {
        return data().classicOptions?.options || [1, 2, 3, 4, 5, 10, 20, 50];
      };

      this.getClassicDefaultRowCount = function () {
        return data().classicOptions?.default || 3;
      };

      // Lightning options:
      this.getLightningOptions = function () {
        return data().lightningOptions && data().lightningOptions.options ? data().lightningOptions.options : [1, 2, 3, 4, 5];
      };

      this.getLightningDefaultRowCount = function () {
        return data().lightningOptions && data().lightningOptions.default ? data().lightningOptions.default : 10;
      };

      // Campaign options:
      this.getCampaignOptions = function () {
        return (((data().campaign || {}).campaignOptions || {}).options) || [1, 2, 3, 4, 5, 10];
      };

      this.getCampaignDefaultRowCount = function () {
        return (((data().campaign || {}).campaignOptions || {}).default) || 10;
      };

      // Lucky options:
      this.getLuckyRowOptions = function () {
        return data().luckyOptions && data().luckyOptions.options ? data().luckyOptions.options : [10, 20, 30, 40, 50];
      };

      this.getLuckyDefaultRowCount = function () {
        return data().luckyOptions && data().luckyOptions.default ? data().luckyOptions.default : 10;
      };

      // Play systems:
      this.getPlaySystems = function () {
        if (this.playSystems()) {
        // Cache
          return this.playSystems();
        }

        var systemNames = this.data().playSystems;

        this.playSystems(systemNames.map(function (name) {
          var splitted = name.split(' ');
          var counts = splitted[1].split('-');
          return ({ name: name, type: splitted[0], selectedNumbersCount: counts[0], rowsCount: counts[1] });
        }));

        return this.playSystems();
      }.bind(this);

      // System names:
      this.getSystemNames = function (type) {
        var systems = this.getPlaySystems();
        var names = [];

        for (var i = 0; i < systems.length; i++) {
          if (!type || systems[i].type === type) {
            names.push(systems[i].name);
          }
        }

        return names;
      }.bind(this);

      // Open draw:
      this.getOpenDraw = function (type) {
        var data = (type === 'joker' ? this.jokerData() : this.data());

        return data ? data.openDraw : null;
      }.bind(this);

    };

    var saturday = function () {
      return new Base(m.prop(data().lottoSaturday), m.prop(data().jokerSaturday));
    };

    var feedValid = function (str) {
      try {
        var json = JSON.parse(str);

        return json && typeof json.lottoSaturday !== 'undefined' && json.lottoSaturday.openDraw;
      } catch (error) {
      }

      return false;
    };

    var storeToCache = function (json) {
    // Store with TTL 2 min.
      Storage.set('LottoInfoFeed', json ? JSON.stringify(json) : null, 120);
    };

    // Initialize:
    var init = function () {

      // 1) LOOK IN DOM
      var $lottoFeedData = document.querySelector('#lotto-info');
      if ($lottoFeedData) {
        var domData = $lottoFeedData.innerHTML;

        if (feedValid(domData)) {
          var domJson = JSON.parse(domData);
          data(domJson);

          // Store to cache
          storeToCache(domJson);
          ready.resolve();
          return;
        }
      }

      // 2) LOOK IN LOCALSTORAGE
      var cacheData = Storage.get('LottoInfoFeed');

      if (!cacheData || !feedValid(cacheData)) {
      // Clear cache
        storeToCache(null);
      } else {
      // Use cache
        data(JSON.parse(cacheData));

        ready.resolve();

        return;
      }

      // 3) LOAD INFO FROM FEED

      SaturdayLottoApi.getLottoInfo().then(function (d) {
        data(d);

        // Store to cache
        storeToCache(d);

        ready.resolve();
      }, function () {
        isFeedDown(true);

        ready.resolve();

        console.error('### LOTTOINFO FEED DOWN ###');
      });
    };

    init();

    // Public functions:
    return {
      data: data,
      isFeedDown: isFeedDown,
      ready: ready.promise,
      saturday: saturday
    };

  });
