defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoSystem/TypeSwitch',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Template:
    var TypeSwitch = function (controller) {

      // Variables:
      var d = controller.d;
      var game = controller.game();
      var systemName = controller.property('SystemNameChosen') || game.systemName();
      var systemType = systemName ? systemName.trim().slice(0, 1) : null;

      // Functions:
      var changeType = function (type) {
        return function () {
          controller.property('SystemNameChosen', type);
        };
      };

      // View:
      return m('div', { class: 'vikinglotto-system-game-header' }, [
        m('div', { class: 'game-navigation-wrapper' }, [
          m('span', { class: 'game-navigation-item' + (!systemType || systemType === 'M' ? ' active' : ''), onclick: changeType('M') }, d('TypeSwitch/Mathematical')),
          m('span', { class: 'game-navigation-item' + (systemType === 'R' ? ' active' : ''), onclick: changeType('R') }, d('TypeSwitch/Reduced')),
          m('span', { class: 'game-navigation-item' + (systemType === 'C' ? ' active' : ''), onclick: changeType('C') }, d('TypeSwitch/Chance'))
        ])
      ]);

    };

    // Public functions:
    return TypeSwitch;

  });
