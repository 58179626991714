defineDs(
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Statistics/MostWinningNumbers/MostWinningNumbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/LottieUtils',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Statistics/MostWinningNumbers/YearSelector',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Statistics/MostWinningNumbers/utils',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/SimpleCubeLoader',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  ],
  (Component, LottieUtils, YearSelector, utils, SimpleCubeLoader, Storage) => {
    Component('most-winning-numbers', function (m, route, settings, ignoreProperty, $element) {
      const root = {
        controller: function () {
          this.years = m.prop(null);
          this.selectedYear = m.prop(null);
          this.mostDrawnNumbers = m.prop(null);
          this.settings = settings;
          this.$desktopCta = $element.querySelector('.most-winning-numbers__cta.most-winning-numbers__cta--desktop'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          this.$mobileCta = $element.querySelector('.most-winning-numbers__cta.most-winning-numbers__cta--mobile'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          this.$responsiveBg = $element.querySelector('.most-winning-numbers__lottie-wrapper .responsive-bg'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          this.lottieSettingsString = this.$responsiveBg.querySelector('.responsive-lottie-bg')?.dataset.lottieFiles; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          this.lottieSettings = this.lottieSettingsString ? JSON.parse(
            this.lottieSettingsString
          ) : {};

          utils.getDrawYears({ game: settings.game }).then((data) => {
            this.years(data);
            this.selectedYear(data[data.length - 1]);
            utils.getDrawStatistics({ game: settings.game, year: this.selectedYear() }).then((data) => {
              this.mostDrawnNumbers(data);
            });
          });

          this.onYearChange = (event) => {
            this.selectedYear(event.target.value);
            this.mostDrawnNumbers(null);
            utils.getDrawStatistics({ game: settings.game, year: this.selectedYear() }).then((data) => {
              this.mostDrawnNumbers(data);
            });
          };

          this.onCtaClick = (event) => {
            event.preventDefault();
            const upperCaseGame = this.settings.game.charAt(0).toUpperCase() + this.settings.game.slice(1);
            const statisticsNumberSaveFormat = this.mostDrawnNumbers().reduce(
              (accumulator, currentVal) => {
                accumulator.numbers[currentVal.number] = currentVal.number;
                return accumulator;
              },
              {
                numbers: {},
                extraNumbers: {},
              }
            );
            Storage.set(upperCaseGame + '-StatisticsNumbers', JSON.stringify(statisticsNumberSaveFormat));
            window.location.href = event.target.href;
          };

          this.initLottie = async (element, ignoreIsInitialized, context) => {
            if (context.mostDrawnNumbers === this.mostDrawnNumbers()) return;
            if (!this.mostDrawnNumbers()) {
              const $responsiveLottieBg = element.querySelector('.responsive-lottie-bg');
              if ($responsiveLottieBg) $responsiveLottieBg.style.display = 'none';
              return;
            }
            context.mostDrawnNumbers = this.mostDrawnNumbers();
            const mappedReplacements = this.settings.lottieReplacements?.reduce((accumulator, currentValue, index) => {
              accumulator[currentValue] = context.mostDrawnNumbers[index].number;
              return accumulator;
            }, {});

            LottieUtils.initializeLottie({
              $lottieContainer: element,
              settings: {
                ...this.lottieSettings,
                lottieReplacements: mappedReplacements,
              },
            });
            const $responsiveLottieBg = element.querySelector('.responsive-lottie-bg');
            if ($responsiveLottieBg) $responsiveLottieBg.style.display = null;
          };
        },
        view: function (controller) {
          return [
            m('.most-winning-numbers__text-container', [
              m(
                '.most-winning-numbers__title',
                controller.settings.title,
                controller.years()
                  ? m(YearSelector, {
                    years: controller.years,
                    selectedYear: controller.selectedYear,
                    onChangeHandler: controller.onYearChange,
                  })
                  : null
              ),
              m('.most-winning-numbers__text', controller.settings.text),
              m(
                'a.most-winning-numbers__cta.most-winning-numbers__cta--desktop',
                { onclick: controller.onCtaClick, href: controller.$desktopCta.href },
                controller.$desktopCta.text
              ),
            ]),
            m('.most-winning-numbers__lottie-wrapper', { config: controller.initLottie }, [
              m.trust(controller.$responsiveBg.outerHTML),
              m(
                '.most-winning-numbers__loader',
                { style: { display: controller.mostDrawnNumbers() ? 'none' : '' } },
                SimpleCubeLoader()
              ),
            ]),
            m(
              'a.most-winning-numbers__cta.most-winning-numbers__cta--mobile',
              { onclick: controller.onCtaClick, href: controller.$mobileCta.href },
              controller.$mobileCta.text
            ),
          ];
        },
      };

      // Setup routes:
      route('/', root);
    });
  }
);
