defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Models/Package',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Core/Model',
    'Common/Framework/EventHandling/Scripts/AppEvent',
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer'
  ],
  function (m, Model, AppEvent, ApiRequest, Utils, DataLayer) {

    // Model:
    var Package = Model('Package', function (content) {

      // Variables:
      this.status = m.prop(content.status || 'open');
      this.packageNo = m.prop(content.packageNo || null);
      this.information = m.prop(content.information || null);
      this.fullCoupon = m.prop(content.fullCoupon || null);
      this.isRowsGenerated = m.prop(false);
      this.generatedRows = m.prop(content.generatedRows || null);
      this.purchaseTracked = m.prop(content.purchaseTracked || false);
      this.startUrl = m.prop(content.startUrl || window.location.href.split(/[#|?]/)[0]);
      this.isAddToCartTracked = m.prop(content.isAddToCartTracked || false);
      this.isPurchaseCompleteTracked = m.prop(content.isPurchaseCompleteTracked || false);

      // Functions:
      this.retrievePackageInformation = function (args) {
        var self = this;
        var deferred = m.deferred();

        let apiUrl = '/dlo/scapi/danskespil/numbergames/packages/packagesinformation';
        if (args?.apiVersion) {
          apiUrl = `/dlo/scapi/danskespil/numbergames/packages/${args.apiVersion}/packagesInformation`;
        }

        ApiRequest({
          url: apiUrl,
          localCacheTTL: 10
        }).then(function (data) {
          var item = null;

          if (data.packages) {
            for (var i = 0; i < data.packages.length; i++) {
              if (data.packages[i].packageNo == self.packageNo()) {
                item = data.packages[i];
              }
            }
          }

          if (item) {
            self.information(item);
            self.isRowsGenerated(false);

            self.save();

            deferred.resolve();
          } else {
            console.error('package not found');

            deferred.reject('not-found');
          }
        }, function () {
          deferred.reject();
        });

        return deferred.promise;
      }.bind(this);

      this.setPackageInformation = (item) => {
        const deferred = m.deferred();

        if (item) {
          this.information(item);
          this.isRowsGenerated(false);
          if (item.isCustomPackage) {
            const couponsForFullCouponData = item.coupons.map((coupon) => {
              const couponGames = coupon.couponGames.map((couponGame) => {
                return {
                  multiDraw: couponGame.drawCount,
                  gameType: couponGame.gameType,
                  price: couponGame.price,
                  rowCount: couponGame.rowCount,
                  rows: couponGame.rows,
                };
              });
              return {
                gameType: coupon.gameType,
                playtype: coupon.playType,
                games: couponGames
              };
            });
            const fullCouponData = {
              ...item,
              coupons: couponsForFullCouponData
            };
            this.fullCoupon(fullCouponData);
          }
          this.save();

          deferred.resolve();
        } else {
          console.error('package not found');
          deferred.reject('not-found');
        }

        return deferred.promise;
      };

      this.createDataLayerProduct = (brand, quantity, price) => {
        var product = {};

        // fallback - should be overwritten, but will be easier to spot if something goes wrong.
        product.name = 'not_found';
        product.brand = 'not_found';
        product.variant = 'not_found';

        // Generic data for all brands
        product.category = 'lyn';
        product.quantity = quantity;
        product.price = (price / quantity);
        product.coupon = this.information()?.description || 'ugepakken';

        // Specific data for each brand
        if (brand == 'LottoSaturday') {
          product.name = 'lotto';
          product.brand = 'dlo_lot';
          product.variant = 'lot_01uger_raekke';
        }

        if (brand == 'VikingLotto') {
          product.name = 'vikinglotto';
          product.brand = 'dlo_vik';
          product.variant = 'vik_01uger_raekke';
        }

        if (brand == 'EuroJackpot') {
          product.name = 'eurojackpot';
          product.brand = 'dlo_ejp';
          product.variant = 'ejp_01uger_raekke';
        }

        if (brand == 'JokerSaturday' || brand == 'JokerWednesday') {
          product.name = 'joker';
          product.brand = 'dlo_jok';
          product.price = price;
          product.quantity = (quantity / 2);
          brand == 'JokerSaturday' ? product.variant = 'jok_loerdag_01uger' : product.variant = 'jok_onsdag_01uger';
        }

        return product;
      };

      this.createDataLayerProductArray = (callback) => {
        const dataLayerProducts = [];
        const coupons = this.fullCoupon()?.coupons;

        for (var i = 0; i < coupons?.length; i++) {
          var coupon = coupons[i];

          for (var j = 0; j < coupon.games.length; j++) {
            var game = coupon.games[j];
            var product = this.createDataLayerProduct(game.gameType, game.rows.length, game.price);
            dataLayerProducts.push(product);
          }
        }

        callback(dataLayerProducts, this.id(), this.totalPrice());

        return dataLayerProducts;
      };

      this.addPackageToCart = function () {
        if (this.isAddToCartTracked() == false) {
          this.isAddToCartTracked(true);
          this.save();

          // Push DataLayer addToCart Event
          this.createDataLayerProductArray(DataLayer.addToCart);
        } else {
          console.warn('addToCart has been pushed already!');
        }
      }.bind(this);

      this.purchasePackageCallback = function (productArray, id, totalPrice) {
        AppEvent.fire('purchase-complete');
        DataLayer.purchaseCompleted({ id: id, totalPrice: totalPrice }, productArray);

      };

      this.purchasePackageComplete = function () {
        if (this.isPurchaseCompleteTracked() == false) {
          this.isPurchaseCompleteTracked(true);
          this.save();

          // Push DataLayer purchaseComplete Event
          this.createDataLayerProductArray(this.purchasePackageCallback);
        } else {
          console.warn('purchaseComplete has been pushed already!');
        }
      }.bind(this);

      this.trackPurchase = () => {
        if (!this.purchaseTracked() && this.status() === 'completed') {
          window.DSAPI && DSAPI.ready(() => {
            this.purchaseTracked(true);
          });

          this.purchasePackageComplete();
        }
      };

      this.retrieveFullCoupon = function () {
        var self = this;
        var deferred = m.deferred();

        ApiRequest({
          url: '/dlo/scapi/danskespil/numbergames/packages/fullpackage?packageNo=' + this.packageNo()
        }).then(function (item) {

          for (var i = 0; i < item.coupons.length; i++) {
            var coupon = item.coupons[i];

            for (var j = 0; j < coupon.games.length; j++) {
              var game = coupon.games[j];

              // Verify gameType against information
              const localCouponGame = self.information().coupons[i].couponGames[j];
              const isGameTypeMatching = localCouponGame.gameType === game.gameType;
              const isOpenDrawIdMatching = localCouponGame.openDraw?.id === game.drawId;
              if (isGameTypeMatching && isOpenDrawIdMatching) {
                self.information().coupons[i].couponGames[j].rows = game.rows;
              } else {
                console.error('gameType not equals information', game);
                deferred.reject();
              }
            }
          }

          self.information().isOpenForSale = item.isOpenForSale;
          self.fullCoupon(item);
          self.isRowsGenerated(true);

          self.save();

          deferred.resolve();
        }, function () {
          deferred.reject();
        });

        return deferred.promise;
      }.bind(this);

      this.purchase = function () {
        var deferred = m.deferred();
        var self = this;

        if (this.status() != 'open') {
          deferred.reject();

          return deferred.promise;
        }

        this.status('pending-confirmation');

        this.save();

        // Setup data:
        var options = this.fullCoupon();

        // Channel:
        options.salesChannel = Utils.isMobile() ? 'mobile' : 'web';

        // Request:
        var request = ApiRequest({
          method: 'POST',
          url: '/dlo/scapi/danskespil/numbergames/packages/package',
          data: options
        });

        // Request
        request.then(function (data) {
          self.status('completed');
          const packageCoupon = data.coupons.map((coupon) => {
            let responseCoupon = self.information().coupons.find((_coupon) => _coupon.gameType === coupon.gameType);
            responseCoupon.couponId = coupon.couponId;
            return responseCoupon;
          });
          self.information().coupons = packageCoupon;
          self.save();

          deferred.resolve(data);
        }, function (data) {
          self.status('open');

          self.save();

          deferred.reject(data);
        });

        return deferred.promise;
      }.bind(this);

      this.extractGames = function () {
        var games = [];
        var coupons = this.information().coupons;

        for (var i = 0; i < coupons.length; i++) {
          games = games.concat(coupons[i].couponGames);
        }

        return games;
      }.bind(this);

      this.getGames = function () {
        return this.extractGames().filter(function (item) {
        // No Joker Games
          if (item.gameType.indexOf('Joker') === -1) {
            return true;
          }
          return false;

          /*
        // No Keno Games
        if (item.gameType.indexOf('Keno') === -1) {
          // No Joker Games
          if (item.gameType.indexOf('Joker') === -1) {
            return true;
          }
        }
        return false;
        */

        });

      }.bind(this);

      this.getJokers = function () {
      // Only Joker Games
        return this.extractGames().filter(function (item) {
          return item.gameType.indexOf('Joker') > -1;
        });
      }.bind(this);

      this.totalPrice = function () {
        return this.information().price;
      }.bind(this);

      this.confirmUrl = function () {
        return this.startUrl() + '/pakke-bekraeft?packageInstanceId=' + this.id();
      }.bind(this);

      this.receiptUrl = function () {
        return this.startUrl() + '/pakke-kvittering?packageInstanceId=' + this.id();
      }.bind(this);

      this.cancelUrl = function () {
      // This is called when going from confirm page, back to game.
        return this.startUrl() + '?packageInstanceId=' + this.id();
      }.bind(this);

      this.sendToSubscription = ({ playType } = {}) => {
        let plusUrl = '/plus-abonnement/se-kurv?plus&packageInstanceId=' + this.id();
        if (playType) {
          plusUrl += '&packagePlayType=' + playType;
        }
        location.href = plusUrl;
      };

      this.toJSON = function () {
        return {
          id: this.id(),
          packageNo: this.packageNo(),
          information: this.information(),
          generatedRows: this.generatedRows(),
          fullCoupon: this.fullCoupon(),
          startUrl: this.startUrl(),
          state: this.state(),
          status: this.status(),
          isAddToCartTracked: this.isAddToCartTracked(),
          purchaseTracked: this.purchaseTracked(),
          isPurchaseCompleteTracked: this.isPurchaseCompleteTracked()
        };
      }.bind(this);

    });

    return Package;

  });
