defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/RowsSelection', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersRotarySelector/RotarySelector'
], function (m, Ensighten, RotarySelector) {
  // Component:
  const RowsSelection = {
    controller: function ({ controller }) {
      Object.assign(this, controller);
      if (this.selectedGameType() === 'VikingLotto') {
        this.rowsOptions = this.selectedGameModel().infoFeed().getLightningRowOptions;
      } else {
        this.rowsOptions = this.selectedGameModel().infoFeed().getLightningOptions;
      }

      this.goToJokerSelection = () => {
        this.customPackageFlowStep('jokerSelection');
        Ensighten.pushGaEvent('custom_package', `${this.gameTypes[this.selectedGameType()]}/custom_package/rows`, this.selectedGameModel().rowsToGenerate());
      };
    },

    view: function (controller) {
      const label = controller.selectedGameModel().rowsToGenerate() === 1 ? controller.customPackageDictionary('CustomPackageFlow/RowsSelection/RowsLabelSingle') : controller.customPackageDictionary('CustomPackageFlow/RowsSelection/RowsLabelPlural');
      const rowsCount = controller.selectedGameModel().rowsToGenerate();
      const rowsPrice = controller.selectedGameModel().totalRowsPricePerDraw();

      return  m('.custom-package-flow', [
        m('.custom-package-flow__rows-selection', m(RotarySelector, {
          classNames: `${controller.gameTypes[controller.selectedGameType()]}`,
          options: controller.rowsOptions(),
          value: controller.selectedGameModel().rowsToGenerate,
          valuePostfix: label,
          onchange: (newValue) => {
            controller.selectedGameModel().rowsToGenerate(newValue);
            controller.selectedGameModel().save();
          }
        })),
        m('.custom-package-flow__footer', m('.multi-packages-button', {
          onclick: controller.goToJokerSelection
        }, controller.customPackageDictionary('CustomPackageFlow/RowsSelection/NextButton', { rowsCount, rowsPrice })))
      ]);
    }
  };

  return RowsSelection;
});