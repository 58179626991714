defineDs('DanskeSpil/Framework/FlipClock/Scripts/MithrilFlipClock',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils',
    'Shared/Framework/Mithril/Scripts/Helpers/MithrilRedrawEverySecond'
  ],
  function (m, Render, TimeUtils, MithrilRedrawEverySecond) {
    var render = Render();

    return {
      controller: function (options) {
        this.settings = m.prop(options.settings);
        this.flipClockSizeCss = m.prop('flip-clock--hidden');
        this.deadline = m.prop(new Date(this.settings().endDate));
        var countersBefore = {};
        var countersActive = {};
        var toggleStates = {};
        var flipClockOptionsCss = ' ';
        flipClockOptionsCss += this.settings().staticFlip ? 'flip-clock--static ' : '';
        flipClockOptionsCss += this.settings().colorLight ? 'flip-clock--light ' : '';
        flipClockOptionsCss += this.settings().labelLight ? 'flip-clock--label-light ' : '';

        this.flipper = function (counter) {
          var diff = this.deadline() - TimeUtils.getServerDateTime();
          var t = TimeUtils.getCompoundTimeObject(diff);

          if (countersBefore[counter] !== t[counter]) {
            countersBefore[counter] = t[counter];
            countersActive[counter] = t[counter] + 1;
            toggleStates[counter] = !toggleStates[counter];
          }

          var state = toggleStates[counter];
          var valBefore = formatCounter(counter, countersBefore[counter]);
          var valActive = formatCounter(counter, countersActive[counter]);

          return m('ul.flip', [
            m('li', { class: state ? 'active' : 'before' },
              m('.flipper', [
                m('.up', [
                  m('.shadow'),
                  m('.inn.js-up', state ? valBefore : valActive)
                ]),
                m('.down', [
                  m('.shadow'),
                  m('.inn.js-down', state ? valBefore : valActive)
                ]),
              ])),
            m('li', { class: !state ? 'active' : 'before' },
              m('.flipper', [
                m('.up', [
                  m('.shadow'),
                  m('.inn.js-up', !state ? valBefore : valActive)
                ]),
                m('.down', [
                  m('.shadow'),
                  m('.inn.js-down', !state ? valBefore : valActive)
                ]),
              ]))
          ]);
        }.bind(this);

        var formatCounter = function (counter, val) {
          if (counter === 'days') {
            val = val >= 99 ? 99 : val;
          } else if (counter === 'hours') {
            val = val === 24 ? 0 : val;
          } else {
            // mins and secs
            val = val === 60 ? 0 : val;
          }
          // prefix with zero for value 0-9
          val = ('0' + val).slice(-2);
          return val;
        };

        var resized = function () {
          var windowWidth = document.querySelector('body').offsetWidth;
          var breakPoints = {
            small: parseInt(this.settings().sizeSmall, 10) || 400,
            default: parseInt(this.settings().sizeDefault, 10) || 550,
            large: parseInt(this.settings().sizeLarge, 10) || 700,
            extraLarge: parseInt(this.settings().sizeExtraLarge, 10) || 900,
          };

          if (breakPoints.extraLarge && windowWidth >= breakPoints.extraLarge) {
            this.flipClockSizeCss('flip-clock--xlarge');
          } else if (breakPoints.large && windowWidth >= breakPoints.large) {
            this.flipClockSizeCss('flip-clock--large');
          } else if (breakPoints.default && windowWidth >= breakPoints.default) {
            this.flipClockSizeCss('flip-clock--default');
          } else if (breakPoints.small && windowWidth >= breakPoints.small) {
            this.flipClockSizeCss('flip-clock--small');
          } else {
            this.flipClockSizeCss('flip-clock--xsmall');
          }
        }.bind(this);

        this.flipClockStyles = function () {
          return this.flipClockSizeCss() + flipClockOptionsCss;
        }.bind(this);

        render.ready.then(function () {
          // "manually" redraw every second if Time Consuming Notification is not doing it for us
          if (!document.querySelector('div[data-component=time-consuming-notification]')) {
            MithrilRedrawEverySecond.start();
          }

          window.addEventListener('resize', resized);
          resized();
        });

        return this;
      },

      view: function (controller) {
        if (controller.settings().deactivated || !controller.settings().endDate) {
          // returning null or an empty string generates a Mithril error, as it
          // cannot read the attributes of that, so we return an empty mithril instead
          return m('');
        }

        return m('.flip-clock.play.js-mithril-flip-clock', {
          config: render.depend(),
          class: controller.flipClockStyles()
        }, [
          controller.flipper('days'),
          controller.flipper('hours'),
          controller.flipper('mins'),
          controller.flipper('secs')
        ]);
      }
    };

  });
