defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Helpers/PackagesUtils',
  [
    'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo'
  ],
  function (JackpotInfo) {
    const getPackagePool = (coupons) => {
      const data = {
        totalPoolSize: 0,
        isMinimumPoolSize: false,
        addedJokerPool: []
      };
      coupons.forEach((coupon) => {
        let couponTotalPoolSize = 0;
        coupon.couponGames.forEach((couponGame) => {
          const isJokerGame = ['JokerSaturday', 'JokerWednesday'].includes(couponGame.gameType);
          const gamePoolInfo = JackpotInfo.getByGame(couponGame.gameType);
          let poolSize = gamePoolInfo?.poolSizeDecimal || 0;
          const minimumPoolSize = gamePoolInfo?.minimumPoolSizeDecimal || 0;

          if (isJokerGame) {
            if (data.addedJokerPool.includes(couponGame.gameType)) {
              return;
            } else {
              data.addedJokerPool.push(couponGame.gameType);
            }
          }

          if (!poolSize) {
            poolSize = minimumPoolSize;
            data.isMinimumPoolSize = true;
          }
          if (couponGame.drawCount > 1 && !isJokerGame) {
            data.isMinimumPoolSize = true;
            poolSize = poolSize + (minimumPoolSize * (couponGame.drawCount - 1));
          }
          poolSize = poolSize / 1000000;

          couponTotalPoolSize += poolSize;
        });
        data.totalPoolSize = data.totalPoolSize + couponTotalPoolSize;
      });

      return data;
    };

    return {
      getPackagePool
    };
  });
