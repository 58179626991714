defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/JokerSelection', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/CheckButton'
], function (m, Ensighten, JackpotInfo, CheckButton) {
  // Component:
  var JokerSelection = {
    controller: function ({ controller }) {
      Object.assign(this, controller);
      this.jokerWednesdayOpenDraw = this.jokerData().jokerWednesday.openDraw;
      this.jokerSaturdayOpenDraw = this.jokerData().jokerSaturday.openDraw;
      this.selectedJokerOption = m.prop(null);
      const wednesdayJackpotPool = JackpotInfo.getByGame('jokerwednesday');
      const wednesdayPoolText = wednesdayJackpotPool?.poolSizeDecimal ? `${wednesdayJackpotPool?.poolSize}` : `min ${wednesdayJackpotPool?.minimumPoolSize}`;
      const saturdayJackpotPool = JackpotInfo.getByGame('jokersaturday');
      const saturdayPoolText = saturdayJackpotPool?.poolSizeDecimal ? `${saturdayJackpotPool?.poolSize}` : `min ${saturdayJackpotPool?.minimumPoolSize}`;
      this.jokerOptions = [{
        value: 'NoJoker',
        label: this.customPackageDictionary('CustomPackageFlow/JokerSelection/NoJokerLabel')
      }, {
        value: 'JokerWednesday',
        label: this.customPackageDictionary('CustomPackageFlow/JokerSelection/WednesdayJokerLabel', { price: this.selectedGameModel().jokerPrice() }),
        sublabel: this.jokerWednesdayOpenDraw ? this.customPackageDictionary('CustomPackageFlow/JokerSelection/JokerSublabelPoolSize', { poolSize: wednesdayPoolText }) : this.customPackageDictionary('CustomPackageFlow/JokerSelection/JokerSublabelDrawInProgress'),
        isDisabled: !this.jokerWednesdayOpenDraw
      }, {
        value: 'JokerSaturday',
        label: this.customPackageDictionary('CustomPackageFlow/JokerSelection/SaturdayJokerLabel', { price: this.selectedGameModel().jokerPrice() }),
        sublabel: this.jokerSaturdayOpenDraw ? this.customPackageDictionary('CustomPackageFlow/JokerSelection/JokerSublabelPoolSize', { poolSize: saturdayPoolText }) : this.customPackageDictionary('CustomPackageFlow/JokerSelection/JokerSublabelDrawInProgress'),
        isDisabled: !this.jokerSaturdayOpenDraw
      }, {
        value: 'BothJokers',
        label: this.customPackageDictionary('CustomPackageFlow/JokerSelection/BothDrawsDaysLabel', { price: this.selectedGameModel().jokerPrice() * 2 }),
        sublabel: this.jokerWednesdayOpenDraw && this.jokerSaturdayOpenDraw ? this.customPackageDictionary('CustomPackageFlow/JokerSelection/JokerSublabelPoolSizeBoth', { poolWednesday: wednesdayPoolText, poolSaturday: saturdayPoolText }) : this.customPackageDictionary('CustomPackageFlow/JokerSelection/JokerSublabelDrawInProgress'),
        isDisabled: !this.jokerWednesdayOpenDraw || !this.jokerSaturdayOpenDraw
      }];

      if (this.selectedGameType() === 'EuroJackpot') {
        this.jokerOptions = [{
          value: 'NoJoker',
          label: this.customPackageDictionary('CustomPackageFlow/JokerSelection/NoJokerLabel')
        }, {
          value: 'BothJokers',
          label: this.customPackageDictionary('CustomPackageFlow/JokerSelection/BothDrawsDaysLabel', { price: this.selectedGameModel().jokerPrice() * 2 }),
          sublabel: this.jokerWednesdayOpenDraw && this.jokerSaturdayOpenDraw ? this.customPackageDictionary('CustomPackageFlow/JokerSelection/JokerSublabelPoolSizeBoth', { poolWednesday: wednesdayPoolText, poolSaturday: saturdayPoolText }) : this.customPackageDictionary('CustomPackageFlow/JokerSelection/JokerSublabelDrawInProgress'),
          isDisabled: !this.jokerWednesdayOpenDraw || !this.jokerSaturdayOpenDraw
        }];
      }

      if (!this.jokerWednesdayOpenDraw) {
        this.selectedGameModel().withJokerWednesday(false);
      }
      if (!this.jokerSaturdayOpenDraw) {
        this.selectedGameModel().withJokerSaturday(false);
      }
      this.selectedGameModel().save();

      const hasJokerWednesday = this.selectedGameModel().withJokerWednesday();
      const hasJokerSaturday = this.selectedGameModel().withJokerSaturday();

      if (hasJokerWednesday && hasJokerSaturday) {
        this.selectedJokerOption('BothJokers');
      } else if (hasJokerWednesday) {
        this.selectedJokerOption('JokerWednesday');
      } else if (hasJokerSaturday) {
        this.selectedJokerOption('JokerSaturday');
      }

      this.onJokerSelection = (option) => {
        if (option.isDisabled) {
          return;
        }
        this.selectedJokerOption(option.value);

        if (this.selectedJokerOption() === 'BothJokers') {
          this.selectedGameModel().withJokerWednesday(true);
          this.selectedGameModel().withJokerSaturday(true);
        } else if (this.selectedJokerOption() === 'JokerWednesday') {
          this.selectedGameModel().withJokerWednesday(true);
          this.selectedGameModel().withJokerSaturday(false);
        } else if (this.selectedJokerOption() === 'JokerSaturday') {
          this.selectedGameModel().withJokerWednesday(false);
          this.selectedGameModel().withJokerSaturday(true);
        } else {
          this.selectedGameModel().withJokerWednesday(false);
          this.selectedGameModel().withJokerSaturday(false);
        }

        this.selectedGameModel().save();
      };

      this.goToNext = () => {
        if (!this.selectedJokerOption()) {
          return;
        }

        this.addCouponToPackage();
        this.customPackageFlowStep('customPackage');

        const jokerTrackingLabels = {
          NoJoker: 'no joker',
          JokerWednesday: 'wednesday',
          JokerSaturday: 'saturday',
          BothJokers: 'wednesday_saturday'
        };
        Ensighten.pushGaEvent('custom_package', `${this.gameTypes[this.selectedGameType()]}/custom_package/joker`, jokerTrackingLabels[this.selectedJokerOption()]);
      };
    },

    view: function (controller) {
      const jokersSelector = m('.custom-package-flow__joker-selection', controller.jokerOptions.map((jokerOption) => {
        const isSelected = jokerOption.value === controller.selectedJokerOption();
        return CheckButton(jokerOption, controller.onJokerSelection, isSelected);
      }));

      return  m('.custom-package-flow', [
        jokersSelector,
        m('.custom-package-flow__footer', m('.multi-packages-button', {
          onclick: controller.goToNext,
          class: !controller.selectedJokerOption() ? 'btn--disabled' : ''
        }, controller.customPackageDictionary('CustomPackageFlow/JokerSelection/NextButton', { price: controller.selectedGameModel().totalPrice() })))
      ]);
    }
  };

  return JokerSelection;
});