defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoDictionary',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/Dictionary'
  ],
  function (Dictionary) {

    // Variables:
    var vikingLottoDictionary = new Dictionary('/NumberGames/VikingLotto');

    // Public functions:
    return vikingLottoDictionary;

  });
