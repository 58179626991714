defineDs('DanskeSpil/Domain/NumberGames/Scripts/NonMithril/TwoColumnBanner', [
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/LottieUtils',
], (LottieUtils) => {
  const setupLottie = async ({ $lottieContainer, lottieContainerSettings }) => {
    await LottieUtils.initializeLottie({
      $lottieContainer,
      settings: lottieContainerSettings,
    });
  };

  const initialize = () => {
    document.querySelectorAll('.two-column-banner').forEach(($banner) => {
      const $lottieContainer = $banner.querySelector('.responsive-lottie-bg'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      if (!$lottieContainer) return;
      const lottieContainerSettingsString = $lottieContainer.dataset.lottieFiles;
      const lottieContainerSettings = lottieContainerSettingsString ? JSON.parse(lottieContainerSettingsString) : null;
      setupLottie({ lottieContainerSettings, $lottieContainer });
    });
  };

  initialize();
});
