defineDs('DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer',
  function () {

    // Variables:
    var localDataLayer = [];

    // Functions:
    var returnAsArray = function (array) {
      if (!Array.isArray(array)) {
        return [array];
      } else {
        return array;
      }
    };

    var promoView = function (productArray) {
      productArray = returnAsArray(productArray);
      push('promoView', {
        promoView: {
          promotions: productArray
        }
      });
    };

    var promoClick = function (productArray) {
      productArray = returnAsArray(productArray);
      push('promoClick', {
        promoClick: {
          promotions: productArray
        }
      });
    };

    var productImpression = function (productArray) {
      productArray = returnAsArray(productArray);
      push('productImpression', {
        impressions: productArray
      });
    };

    var productDetail = function (productArray) {
      productArray = returnAsArray(productArray);
      push('productDetail', {
        detail: {
          products: productArray
        }
      });
    };

    var addToCart = function (productArray) {
      productArray = returnAsArray(productArray);
      push('addToCart', {
        add: {
          products: productArray
        }
      });
    };

    var removeFromCart = function (productArray) {
      productArray = returnAsArray(productArray);
      if (productArray.length > 0) {
        push('removeFromCart', {
          remove: {
            products: productArray
          }
        });
      }
    };

    var checkout = function (data, productArray) {
      productArray = returnAsArray(productArray);
      var step = data.step;
      var option = data.option;

      push('checkout', {
        checkout: {
          actionField: {
            step: step,
            option: option
          },
          products: productArray
        }
      });
    };

    var purchaseCompleted = function (data, productArray) {
      productArray = returnAsArray(productArray);
      var id = data.id;
      var totalPrice = data.totalPrice.toString();
      const actionField = {
        id: id,
        revenue: totalPrice
      };
      if (data.firstInteraction) {
        actionField.firstInteraction = data.firstInteraction;
      }
      if (data.lastInteraction) {
        actionField.lastInteraction = data.lastInteraction;
      }

      push('purchaseComplete', {
        purchase: {
          actionField: actionField,
          products: productArray
        }
      });
    };

    var push = function (event, payload) {
      getDataLayer().push({
        event: event,
        ecommerce: payload
      });
    };

    var getDataLayer = function () {
      if (window.dataLayer) {
        for (var i = 0; i < localDataLayer.length; i++) {
          window.dataLayer.push(localDataLayer[i]);
        }
      }

      return window.dataLayer || localDataLayer;
    };

    // Set the category field to the correct naming
    var categoryName = function (category) {
      var lynCategories = ['KenoMillion', 'KenoLarge', 'KenoSmall', 'Lightning'];
      var systemCategories = ['KenoSystem', 'System'];
      var vaelgSelvCategories = ['KenoClassic', 'Classic'];
      var lykkeCategories = ['Lucky'];

      if (lynCategories.indexOf(category) > -1) {
        category = 'lyn';
      }

      if (systemCategories.indexOf(category) > -1) {
        category = 'system';
      }

      if (vaelgSelvCategories.indexOf(category) > -1) {
        category = 'vaelg_selv';
      }

      if (lykkeCategories.indexOf(category) > -1) {
        category = 'lykke';
      }

      return category;
    };

    // Generate the variant field
    var variantName = function (brand, rows, playType, systemName) {
      var variant;

      // Make sure there is always 2 digits in the row number (fx 02).
      rows = ('0' + rows).slice(-2);

      // -- AllOrNothing Variants --
      if (brand == 'allornothing' || brand == 'plus_allornothing') {
        rows = rows + 'dag' + (rows !== 1 ? 'e' : '');
        variant = 'alt_';

        if (brand == 'plus_allornothing') {
          rows = '';
          variant = 'plu_alt';
          // ensure that plus will always have _raekker variant
          playType = 'Lightning';
        }

        if (playType == 'Lightning' || playType == 'Classic') {
          variant += rows + '_raekker';
        }
      }

      // -- Keno Variants --
      if (brand == 'keno' || brand === 'plus_keno') {
        rows = rows + 'dage';
        variant = 'ken_';

        if (brand === 'plus_keno') {
          variant = 'plu_ken';
        }

        if (playType == 'KenoMillion') {
          variant += rows + '_million';
        }

        if (playType == 'KenoClassic') {
          variant += rows + '_raekker';
        }

        if (playType == 'KenoSystem') {
          variant += rows + '_system';
        }

        if (playType == 'KenoLarge') {
          variant += rows + '_store';
        }

        if (playType == 'KenoSmall') {
          variant += rows + '_lille';
        }
      }

      // -- Lotto Variants --
      if (brand == 'lotto' || brand == 'plus_lotto') {
        rows = rows + 'uger';
        variant = 'lot_';

        if (brand == 'plus_lotto') {
          rows = '';
          variant = 'plu_lot';
          // ensure that plus will always have _raekker variant
          playType = 'Lightning';
        }

        if (playType == 'System') {
        // Set systemName to 'R', 'M', or 'C'
        // These are Reduced, Mathematical, or Chance
          systemName = systemName.charAt(0);
          if (systemName == 'R' || systemName == 'C') {
            variant += rows + '_reduceret';
          }

          if (systemName == 'M') {
            variant += rows + '_matematisk';
          }
        }

        if (playType == 'Lightning' || playType == 'Classic' || playType == 'Lucky') {
          variant += rows + '_raekker';
        }
      }

      // -- VikingeLotto Variants --
      if (brand == 'viking' || brand == 'plus_viking') {
        variant = 'vik_';
        rows = rows + 'uger';

        if (brand == 'plus_viking') {
          rows = '';
          variant = 'plu_vik';
          // ensure that plus will always have _raekker variant
          playType = 'Lightning';
        }

        if (playType == 'System') {
        // Set systemName to 'R', 'M', or 'C'
        // These are Reduced, Mathematical, or Chance
          systemName = systemName.charAt(0);
          if (systemName == 'R' || systemName == 'C') {
            variant += rows + '_reduceret';
          }

          if (systemName == 'M') {
            variant += rows + '_matematisk';
          }
        }

        if (playType == 'Lightning' || playType == 'Classic' || playType == 'Lucky') {
          variant += rows + '_raekker';
        }
      }

      // -- Joker --
      if (brand == 'joker' || brand == 'plus_joker') {
        rows = '_' + rows + 'uger';
        variant = 'jok_';

        if (brand == 'plus_joker') {
          rows = '';
          variant = 'plu_jok_';
        }

        if (playType == 'saturday') {
          variant += 'loerdag' + rows;
        }

        if (playType == 'wednesday') {
          variant += 'onsdag' + rows;
        }
      }

      // -- EuroJackpot --
      if (brand == 'eurojackpot' ||
        brand == 'plus_eurojackpot' ||
        brand.indexOf('eurojackpot') !== -1) {
        rows = rows + 'draws';
        variant = 'ejp_';

        var eurojackpotDrawDay = brand.split('eurojackpot_')[1];
        if (eurojackpotDrawDay) variant = variant + eurojackpotDrawDay + '_';

        if (brand == 'plus_eurojackpot') {
          rows = '';
          variant = 'plu_ejp';
          // ensure that plus will always have _raekker variant
          playType = 'Lightning';
        }

        if (playType == 'Lightning' || playType == 'Classic' || playType == 'Lucky') {
          variant += rows + '_raekker';
        }

        if (playType == 'System') {
          variant += rows + '_matematisk';
        }
      }

      return variant;
    };

    var quantity = function (brand, rows, playType, getSystemCombinationAmount) {
      if (brand == 'keno') {
        if (playType == 'KenoSystem') {
          rows = getSystemCombinationAmount;
        }

        if (playType == 'KenoLarge' || playType == 'KenoSmall') {
          rows = 1;
        }
      }

      // -- Lotto Variants --
      if (brand == 'lotto') {
        rows = getSystemCombinationAmount;
      }

      // -- VikingeLotto Variants --
      if (brand == 'viking') {
        rows = getSystemCombinationAmount;
      }

      // -- EuroJackpot --
      if (brand == 'eurojackpot') {
        rows = getSystemCombinationAmount;
      }

      return rows;
    };

    var rowPrice = function (feedData, numberOfDraws, type) {
      var rowPrice;

      if (type === 'AllOrNothingClassic' || type === 'AllOrNothingLightning') {
        rowPrice = feedData * numberOfDraws;
      } else if (type == 'KenoMillion' || type == 'KenoClassic' || type == 'KenoSystem' || type == 'KenoLarge' || type == 'KenoSmall') {
        if (type == 'KenoMillion' || type == 'KenoClassic' || type == 'KenoSystem') {
          rowPrice = feedData * numberOfDraws;
        }

        if (type == 'KenoLarge') {
          rowPrice = (feedData * numberOfDraws) * 10;
        }

        if (type == 'KenoSmall') {
          rowPrice = (feedData * numberOfDraws) * 5;
        }
      } else {
        if (type != 'joker') {
          rowPrice = feedData.draws && feedData.draws[0] && feedData.draws[0].rowPrice ? feedData.draws[0].rowPrice : 0;
          rowPrice = rowPrice * numberOfDraws;
        }

        if (type == 'plus') {
          rowPrice = feedData.draws && feedData.draws[0] && feedData.draws[0].rowPrice ? feedData.draws[0].rowPrice : 0;
        }

        if (type == 'joker') {
          rowPrice = feedData * 2 * numberOfDraws;
        }

        if (type == 'plus_joker') {
          rowPrice = feedData * 2;
        }
      }

      rowPrice = rowPrice.toString();

      return rowPrice;
    };

    // Public functions:
    return {
      variantName: variantName,
      categoryName: categoryName,
      quantity: quantity,
      rowPrice: rowPrice,
      promoView: promoView,
      promoClick: promoClick,
      productImpression: productImpression,
      productDetail: productDetail,
      addToCart: addToCart,
      removeFromCart: removeFromCart,
      checkout: checkout,
      purchaseCompleted: purchaseCompleted,
      push: push
    };

  });
