defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/Client/Flow/CouponTabs', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Common/Framework/EventHandling/Scripts/Event'
], function (m, Event) {
  // Component:
  var CouponTabs = {
    controller: function ({ controller }) {
      Object.assign(this, controller);

      this.activeIndicatorRender = ($element) => {
        const $activeTab = $element.querySelector('.multi-client__coupon-tab--active'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        const $activeTabIndicator = $element.querySelector('.multi-client__coupon-tab-active-indicator'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        if (!$activeTab || !$activeTabIndicator) return;
        $activeTabIndicator.style.width = `${$activeTab.clientWidth}px`;
        $activeTabIndicator.style.left = `${$activeTab.offsetLeft}px`;
      };
    },
    view: function (controller) {
      return m('.multi-client__coupon-tabs-wrapper',
        m('.multi-client__coupon-tabs', {
          config: controller.activeIndicatorRender
        },
        m('.multi-client__coupon-tab-active-indicator'),
        controller.couponTabsOptions.map((option) => {
          return m('.multi-client__coupon-tab', {
            class: controller.selectedCouponId() === option.couponId ? 'multi-client__coupon-tab--active' : '',
            onclick: () => {
              Event.fire('multi-client:receipt-step:select-coupon', option.couponId);
            }
          }, option.label);
        })
        ));
    }
  };

  return CouponTabs;
});
