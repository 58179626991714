defineDs('DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',
    'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils',
    'Shared/Framework/Mithril/Scripts/Helpers/Utils',
    'Shared/Framework/Ensighten/Scripts/Ensighten'
  ],
  function (Storage, TimeUtils, Utils, Ensighten) {

    // Variables:
    var aliases = {
      lottosaturday: 'lotto',
      saturdaylotto: 'lotto',
      lottowednesday: 'onsdagslotto',
      wednesdaylotto: 'onsdagslotto',
      superpulje: 'superpulje',
      jokersaturday: 'jokerloerdag',
      jokerwednesday: 'jokeronsdag',

      // nye aliases 11/5/2017
      joker: 'jokerloerdag',
      onsdagsjoker: 'jokeronsdag'
    };

    // Minimum pool sizes:
    // window.minimumPoolSizes is intended to be used in case we need to change the minimum pool size for a specific game without a deploy.
    const minimumPoolSizes = {
      lotto: window.minimumPoolSizes?.lotto || 8000000,
      eurojackpot: window.minimumPoolSizes?.eurojackpot || 63000000,
      vikinglotto: window.minimumPoolSizes?.vikinglotto || 19000000,
      jokerloerdag: window.minimumPoolSizes?.jokerloerdag || 2000000,
      jokeronsdag: window.minimumPoolSizes?.jokeronsdag || 2000000
    };

    var data = {};

    // Functions:

    var getSetData = function (val) {
      // Mimics m.prop functionality
      if (typeof val !== 'undefined') {
        data = val;
      }
      return data;
    };

    var getByGame = function (name) {
      var poolSizeDecimalsRequested = false;
      var secondPrizeRequested = false;
      name = name.toLowerCase();

      // check to see if decimal value is requested.
      if (name.indexOf('d:') === 0) {
        poolSizeDecimalsRequested = true;
        name = name.split(':');
        name.shift();
        name = name.join(':');
      }

      // check to see if second prize is requested.
      if (name.indexOf(':2') !== -1) {
        secondPrizeRequested = true;
        name = name.split(':')[0];
      }

      if (aliases[name]) {
        name = aliases[name];
      }

      var items = data && Object.keys(data).length !== 0 ? data.filter(function (item) {
        return (item.GameId && item.GameId.toLowerCase() === name);
      }) : [];

      var item = items.length > 0 ? items[0] : null;

      if (item) {
        var currentDateTime = TimeUtils.getCurrentDateTime();
        var gameStopDate = item.gameStopDate;
        var gameStopDateTime = new Date(gameStopDate);
        var gamestopExceeded = currentDateTime > gameStopDateTime;
        var poolSize = poolSizeDecimalsRequested ? item.PoolSizeDecimal : item.PoolSizeFormatted;
        var poolSizeSecondPrize = poolSizeDecimalsRequested ? item.SecondPrizePoolSizeDecimal : item.SecondPrizePoolSizeFormatted;

        if (secondPrizeRequested) {
          return {
            gamestopExceeded: gamestopExceeded,
            poolSize: Utils.numberFormat(poolSizeSecondPrize),
            poolSizeDecimal: item.SecondPrizePoolSizeDecimal,
            poolSizeFormatted: item.SecondPrizePoolSizeFormatted,
            minimumPoolSize: Utils.numberFormat(minimumPoolSizes[name] / 1000000),
            minimumPoolSizeDecimal: minimumPoolSizes[name]
          };
        }

        return {
          gamestopExceeded: gamestopExceeded,
          poolSize: Utils.numberFormat(poolSize),
          poolSizeDecimal: item.PoolSizeDecimal,
          poolSizeFormatted: item.PoolSizeFormatted,
          minimumPoolSize: Utils.numberFormat(minimumPoolSizes[name] / 1000000),
          minimumPoolSizeDecimal: minimumPoolSizes[name]
        };
      }

      return {
        minimumPoolSize: Utils.numberFormat(minimumPoolSizes[name] / 1000000),
        minimumPoolSizeDecimal: minimumPoolSizes[name]
      };
    };

    /**
     * This string replaces all occurences of {alias\\puljefeedname} to the corresponding feed.
     * @param str - The string containing the handlebars.
     */
    var getDynamicFeed = function (str) {

      // if the context is expierience editor or the str is not of type string; break!
      if (window.sitecoreEnv || typeof str !== 'string') {
        return str;
      }

      // initiate variable
      var pulje = '';

      // variables for preventing infinite looping; maxDepth is the maximum loop depth; iter is the iteration counter.
      var maxDepth = 100;
      var iter = 0;
      // Might be more occurances of '{' and '}' in the text
      while (str.indexOf('{') >= 0) {

        // prevent infinite looping, by restricting the iteration depth
        iter += 1;
        if (iter > maxDepth) {
          return str;
        }

        // Find occurance of {
        var index = str.indexOf('{');
        // Find occurance of }
        var lastIndex = str.indexOf('}');
        // Did the text contain { or }?
        if (index < 0 || lastIndex < 0) {
          // Did not contain a handlebar, return original string
          return str;
        } else {
          // Take out the whole {pulje} string
          var handleBarString = str.substring(index, lastIndex + 1);
          // Take out only 'pulje' from {pulje}
          var substring = str.substring(index + 1, lastIndex);
          // Special occasion, on onsdagslotto combined pulje should be presented by adding superpulje and onsdagspulje together
          if (substring !== 'combowednesday') {
            pulje = getByGame(substring).poolSize;
          } else {
            pulje = parseInt(getByGame('superpulje').poolSizeFormatted, 10) + parseInt(getByGame('onsdagslotto').poolSizeFormatted, 10);
          }

          // if the corresponding feed value is undefined return the empty string -> wrong feed keys etc should not be displayed to the end user.
          if (typeof (pulje) === 'undefined') {
            return '';
          }

          // Replace the handlebar {pulje} with for example 30
          str = str.replace(handleBarString, pulje);
        }
      }
      return str;
    };

    var feedValid = function (str) {
      try {
        var json = JSON.parse(str);

        if (json instanceof Array) {
          return true;
        }
      } catch (e) {
      }

      return false;
    };

    var storeToCache = function (json) {
      // Store with TTL 30 min.
      Storage.set('JackpotInfoFeed', json ? JSON.stringify(json) : null, 60 * 30);
    };

    // Initialize:
    var ready = new Promise(function (resolve, reject) {

      // 1) LOOK IN DOM
      var $jackpotInfo = document.querySelector('#jackpot-info');

      if ($jackpotInfo) {
        var domData = $jackpotInfo.innerHTML;

        if (feedValid(domData)) {
          var domJson = JSON.parse(domData);

          Ensighten.pushJackpots(domJson);

          data = domJson;

          // Store to cache
          storeToCache(domJson);
          resolve();

          return;
        }
      }

      // 2) LOOK IN LOCALSTORAGE
      var cacheData = Storage.get('JackpotInfoFeed');
      if (!cacheData || !feedValid(cacheData)) {
        // Clear cache
        storeToCache(null);
        data = {};
        reject();
      } else {
        // Use cache
        data = JSON.parse(cacheData);
        resolve();
      }

    });

    // Public functions:
    return {
      data: getSetData,
      ready: ready,
      getByGame: getByGame,
      getDynamicFeed: getDynamicFeed
    };

  });
