defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PackagesContainer', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Common/Framework/EventHandling/Scripts/Event',
  'Common/Framework/ScriptLibraries/Scripts/TinySlider',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/Shared/PackagesTabs',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/StandardPackage/StandardPackageItem',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/PackageListItem',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PurchaseFlow/PurchaseFlow',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/CustomPackageFlow'
], function (m, Event, TinySlider, Utils, Params, PackagesTabs, StandardPackageItem, PackageListItem, PurchaseFlow, CustomPackageFlow) {
  // Component:
  var PackagesContainer = {
    controller: function ({ controller }) {
      Object.assign(this, controller);

      this.componentKey = 'multi-packages-container';
      this.gameTypes = {
        AllOrNothing: 'allornothing',
        LottoWednesday: 'lotto',
        LottoSaturday: 'lotto',
        VikingLotto: 'vikinglotto',
        EuroJackpot: 'eurojackpot',
        Keno: 'keno',
        Joker: 'joker',
        JokerSaturday: 'joker',
        JokerWednesday: 'joker'
      };
      this.gameLogos = {
        allornothing: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/allornothing_logo.svg',
        eurojackpot: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/icon-eurojackpot_logo_bg_yellow.svg',
        lotto: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/icon-lotto_logo_red.svg',
        vikinglotto: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/icon-vikinglotto_logo_blue.svg',
        keno: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes/keno.svg',
        joker: '/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/PackageCommonIcons/joker_black_with_hat.svg'
      };
      this.gameBadges = {
        lotto: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameBadges/game_badge-lotto.svg',
        vikinglotto: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameBadges/game_badge-vikinglotto.svg',
        eurojackpot: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameBadges/game_badge-eurojackpot.svg',
        keno: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameBadges/game_badge-keno.svg'
      };

      this.currentFlowStep = m.prop('packageSelection');
      this.packagesList = this.packagesData().map((packageData) => {
        return m(StandardPackageItem, { controller: this, packageData });
      });

      this.tabsOptions = this.packagesData().map((packageData) => {
        return { label: packageData.tabLabel };
      });

      if (this.hasCustomPackageOption) {
        this.tabsOptions.push({
          label: this.customPackageDictionary('TabLabel'),
          tabIndexOffset: Utils.isMobile() ? 0 : 1
        });
      }

      this.packagesSlider = m.prop(null);
      this.isSliderRequired = m.prop(false);
      this.preventTabsUpdateOnSlide = m.prop(false);
      this.packageSliderForcedIndex = m.prop(0);
      Event.subscribe('multi-packages-tab:changed', ({ index, key }) => {
        if (this.componentKey !== key) return;
        this.packageSliderForcedIndex(index);
        this.preventTabsUpdateOnSlide(true);
        this.packagesSlider()?.goTo(index);
      });

      this.onPackagesRender = ($element, isRendered) => {
        if (isRendered && !this.packagesSlider()) {
          const $packagesList = $element.querySelector('.multi-packages-packages-list'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          if (!$packagesList.childElementCount) {
            return;
          }

          if ($packagesList.clientWidth > $element.clientWidth) {
            this.isSliderRequired(true);
            m.redraw();
          }

          this.packagesSlider(TinySlider({
            container: $packagesList,
            autoWidth: true,
            center: true,
            loop: false,
            items: 1,
            controls: false,
            nav: false,
            edgePadding: Utils.isMobile() ? 0 : 100,
            onInit: (info) => {
              info.slideItems[0].classList.add('multi-packages-package-card--in-focus');
            },
            responsive: {
              768: {
                center: false
              }
            }
          }));

          this.packagesSlider().events.on('transitionStart', (info) => {
            info.container.querySelector('.multi-packages-package-card--in-focus')?.classList.remove('multi-packages-package-card--in-focus');
            info.slideItems[info.index]?.classList.add('multi-packages-package-card--in-focus');

            setTimeout(() => {
              if (!this.preventTabsUpdateOnSlide()) {
                Event.fire('multi-packages-tab:change', { index: info.index, key: this.componentKey });
              }
              this.preventTabsUpdateOnSlide(false);
              m.redraw();
            }, 0);
          });

          this.packagesSlider().events.on('transitionEnd', (info) => {
            setTimeout(() => {
              const $slideItem = info?.slideItems?.[info?.index];
              let nextSlideIndex = info.index;
              if ($slideItem?.dataset.skipSlide) {
                nextSlideIndex = info.index + (info.index > info.indexCached ? 1 : -1);
                this.packagesSlider().goTo(nextSlideIndex);
              }
              info.container.querySelector('.multi-packages-package-card--in-focus')?.classList.remove('multi-packages-package-card--in-focus');
              info.slideItems[nextSlideIndex]?.classList.add('multi-packages-package-card--in-focus');
            }, 0);
          });
        }
      };
    },

    view: function (controller) {
      const flowStep = Params.extract('packageFlowStep');
      if (!flowStep) {
        Params.set('packageFlowStep=packageSelection');
      }

      if (controller.currentFlowStep() !== flowStep) {
        if (flowStep === 'purchaseFlow') {
          controller.$element.scrollIntoView({ behavior: 'smooth' }); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          controller.mainPopoverDrawer.setContent(m(PurchaseFlow, { controller }));
          controller.mainPopoverDrawer.onClose(() => {
            Params.set('packageFlowStep=packageSelection');
            Params.remove('packageInstanceId');
          });
          setTimeout(() => {
            m.redraw();
          }, 0);
        }

        if (flowStep === 'customPackageFlow') {
          controller.mainPopoverDrawer.setContent(m(CustomPackageFlow, { controller }));
          controller.property('purchaseFlow:onGoBack', () => Params.set('packageFlowStep=customPackageFlow'));
          controller.mainPopoverDrawer.onClose(() => {
            Params.set('packageFlowStep=packageSelection');
            Params.remove('packageInstanceId');
            controller.property('purchaseFlow:onGoBack', null);
          });
        }

        controller.currentFlowStep(flowStep);
        if (!controller.mainPopoverDrawer.isShown()) {
          controller.mainPopoverDrawer.show();
        }
      }

      return m('.multi-packages-inner-container', {
        config: controller.onPackagesRender
      }, [
        controller.isSliderRequired() ? m(PackagesTabs, { controller }) : null,
        m('.multi-packages-packages-list', [
          controller.packagesList,
          controller.hasCustomPackageOption ? [
            Utils.isMobile() ? null : m('.multi-packages-package-card__divider', {
              'data-skip-slide': true
            }, m('.multi-packages-package-card__divider-text', controller.customPackageDictionary('PackageListItem/Divider'))),
            m(PackageListItem, { controller })
          ] : null
        ])
      ]);
    }
  };

  return PackagesContainer;
});
