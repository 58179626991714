defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/Statistics/TotalWinningsBeams', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/NumberUtils',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/StatisticsDictionary',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/StatisticsModuleApi'
], function (Component, NumberUtils, StatisticsDictionary, StatisticsModuleApi) {
  Component('total-winnings-beams', [StatisticsDictionary], function (m, route, settings) {
    const root = {
      controller: function () {
        this.dictionary = StatisticsDictionary.get.bind({ prefix: 'TotalWinningsBeams/' });
        this.beamsData = {
          totalPaid: 0,
          millionaireCount: 0,
          prizeCount: 0
        };
        this.beamsKeys = Object.keys(this.beamsData);
        const themeColors = {
          lotto: {
            bg: 'bg-corporate-red-2/10',
            text: 'text-corporate-red-2'
          },
          eurojackpot: {
            bg: 'bg-[#FDF6EA]',
            text: 'text-[#2E3535]'
          },
          vikinglotto: {
            bg: 'bg-corporate-blue-1/10',
            text: 'text-corporate-blue-1'
          }
        };
        this.region = settings.game;
        this.regionColors = themeColors[this.region];

        StatisticsModuleApi.yearlyWinnings(this.region).then((response) => {
          this.beamsData.totalPaid = NumberUtils.formatNumber(response.totalPaid / 1000000);
          this.beamsData.millionaireCount = response.millionaireCount;
          this.beamsData.prizeCount = response.prizeCount;
          m.redraw();
        });
      },
      view: function (controller) {
        return m('.my-80.px-24.max-w-1280.mx-auto.flex.flex-col.gap-24 lg:px-0 md:flex-row md:gap-56', {
        }, controller.beamsKeys.map((key) => {
          return m('.py-16.px-12.rounded-16.flex.flex-row.flex-1.gap-20', {
            class: `${controller.regionColors.bg}`
          }, [
            m('img', {
              class: 'w-[7rem] h-[7rem]',
              src: `/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/Statistics/TotalWinningsBeams/${controller.region}_${key}.svg`
            }),
            m('.flex.flex-col.justify-center.flex-1', {
              class: `${controller.regionColors.text}`
            }, [
              m('.font-extrabold', {
                class: 'text-[4.6rem] leading-[5.6rem]'
              }, controller.dictionary(`${key}Value`, { value: controller.beamsData[key] })),
              m('.text-14.font-semibold', controller.dictionary(`${key}Label`))
            ]),
          ]);
        }));
      },
    };

    route('/', root);
  });
});