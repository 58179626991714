defineDs('DanskeSpil/Domain/Tutorial/Scripts/Pages/TutorialSimplePage',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialFooter',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialNavigation',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialHero'
  ], function (m, TutorialFooter, Navigation, Hero) {

    return {
      controller: function (args) {
        window.scrollTo(0, 0);

        this.safeTutorialName = decodeURI(m.route.param('tutorial'));
        this.mostVisitedLabel = args.mostVisitedLabel;
        this.mostVisitedList = args.mostVisitedList;
        this.footerSettings = args.footerSettings;
        this.titleCssStyle = args.titleCssStyle;
        this.getTextWithHighligt = args.getTextWithHighligt;
        this.tutorial = args.allTutorials.filter(function (entry) {
          return entry.safeTitle === this.safeTutorialName;
        }.bind(this))[0];
      },

      view: function (controller) {
        return m('.tutorial__simple', [
          Navigation(controller), // eslint-disable-line compat/compat
          Hero({ title: controller.tutorial.title, style: controller.titleCssStyle, activate: controller.tutorial.activateHeader }),
          m('.tutorial__content-box.tutorial__simple-content', {
            class: [
              controller.tutorial.useFullWidth ? 'tutorial__content-box--full-width' : '',
              controller.tutorial.useFullWidth && !controller.tutorial.activateHeader ? 'tutorial__content-box--no-radius' : ''
            ].join(' ')
          },
          m.trust(controller.getTextWithHighligt(controller.tutorial.richText))),
          controller.footerSettings ? TutorialFooter({ style: controller.titleCssStyle, activate: controller.tutorial.activateFooter, ...controller.footerSettings }) : null
        ]);
      }
    };
  });
