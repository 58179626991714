defineDs('DanskeSpil/Framework/NumberGames/Scripts/Helpers/StorageCheck',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage'
  ],
  function (Overlay, ErrorOverlay, Storage) {

    // Support:
    if (!Storage.supported) {
      new Overlay(ErrorOverlay('all-or-nothing-classic-game-overlay eurojackpot-classic-game-overlay keno-million-game-overlay lotto-classic-game-overlay vikinglotto-classic-game-overlay', {
        header: 'HOVSA!',
        body: 'Din browser er indstillet til ikke at gemme webside-data. Hvis du vil tilgå danskespil.dk, er du nødt til at gøre det i normal browsertilstand.',
        dismiss: 'OK'
      })).show();
    }

  });
