defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/PurchaseFlow/Receipt/GamesCountdowns', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/Countdown',
  'Common/Framework/EventHandling/Scripts/Event',
  'Common/Framework/ScriptLibraries/Scripts/TinySlider',
], function (m, Utils, Countdown, Event, TinySlider) {
  const GamesCountdowns = {
    controller: function ({ controller }) {
      Object.assign(this, controller);

      this.countdownsSlider = m.prop(null);
      Event.subscribe('multi-packages-tab:changed', ({ index, key }) => {
        if (this.componentKey !== key) return;
        this.selectCoupon(index);
        this.countdownsSlider()?.goTo(index);
      });
      this.initSlider = ($element, isInitialized, context) => {
        if (!isInitialized || context.isSliderInitialized) {
          return;
        }
        context.isSliderInitialized = true;
        this.countdownsSlider(TinySlider({
          container: $element,
          autoWidth: true,
          controls: false,
          nav: false,
          center: true,
          edgePadding: 20,
          loop: false,
          gutter: 20,
          items: 1,
          axis: Utils.isMobile() ? 'horizontal' : 'vertical',
        }));
        this.countdownsSlider()?.events.on('transitionStart', (info) => {
          if (typeof info.index !== 'number') return;
          setTimeout(() => {
            Event.fire('multi-packages-tab:change', { index: info.index, key: this.componentKey });
            m.redraw();
          }, 0);
        });
      };
    },
    view: function (controller) {
      return m('.multi-packages-receipt__games', {
        config: controller.packageModel().information().coupons.length > 1 ? controller.initSlider : null
      }, controller.packageModel().information().coupons.map((coupon, index) => {
        const couponGame = coupon.couponGames.find((game) => game.gameType === coupon.gameType);
        const isoDate = couponGame.openDraw?.closingTime;
        const drawDate = isoDate ? Utils.parseISO8601(isoDate).dateObject : null;
        const countdownOptions = {
          countdownTime: drawDate ? drawDate.getTime() / 1000 : 0,
          dictionary: controller.receiptDictionary,
          gameName: controller.gameTypes[couponGame.gameType],
          noOpenDraw: false
        };

        const className = () => {
          let className = `multi-packages-receipt__game-countdown--${controller.gameTypes[couponGame.gameType]}`;
          if (couponGame.gameType === controller.selectedCoupon()?.gameType) {
            className += ' multi-packages-receipt__game-countdown--selected';
          }

          return className;
        };

        return m('div', m('.multi-packages-receipt__game-countdown', {
          class: className(),
          onclick: () => Event.fire('multi-packages-tab:change', { index, key: controller.componentKey })
        }, Countdown(countdownOptions)));
      }));
    }
  };
  return GamesCountdowns;
});