defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/CustomPackageFlow', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Models/Package',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Helpers/PackagesApi',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
  'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
  'DanskeSpil/Domain/Lotto/Scripts/Models/LottoGame',
  'DanskeSpil/Domain/VikingLotto/Scripts/Models/VikingLottoGame',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Models/EurojackpotGame',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/Shared/SkeletonCardLoader',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/CustomPackageCard',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/RowsSelection',
  'DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/JokerSelection'
], function (m, Params, PackageModel, PackagesApi, LottoInfo, VikingLottoInfo, EurojackpotInfo, LottoGame, VikingLottoGame, EurojackpotGame, SkeletonCardLoader, CustomPackageCard, RowsSelection, JokerSelection) {
  const CustomPackageFlow = {
    controller: function ({ controller }) {
      Object.assign(this, controller);

      this.loadingGamesInfo = m.prop(true);
      this.processingPurchase = m.prop(false);
      this.selectedGameType = m.prop(null);
      this.selectedGameModel = m.prop(null);
      this.gamesInfoData = m.prop(null);
      this.jokerData = m.prop(null);
      this.customPackageFlowStep = m.prop('customPackage');

      this.getOrGeneratePackageModel = () => {
        const packageInstanceId = Params.extract('packageInstanceId');
        this.customPackageModel = m.prop(PackageModel.get(packageInstanceId));
        if (!this.customPackageModel()) {
          this.customPackageModel(PackageModel.new());
          this.customPackageModel().setPackageInformation({
            description: this.customPackageDictionary('Description'),
            coupons: []
          });
          Params.set(`packageInstanceId=${this.customPackageModel().id()}`);
        }
      };
      this.getOrGeneratePackageModel();

      this.validateRowsToGenerate = () => {
        if (!this.selectedGameModel()?.infoFeed) {
          return;
        }

        let defaultRowCount = 0;
        if (this.selectedGameType() === 'VikingLotto') {
          defaultRowCount = this.selectedGameModel().infoFeed().getDefaultRowCount();
        } else {
          defaultRowCount = this.selectedGameModel().infoFeed().getLightningDefaultRowCount();
        }

        this.selectedGameModel().rowsToGenerate(this.selectedGameModel().rowsToGenerate() > 0 ? this.selectedGameModel().rowsToGenerate() : defaultRowCount);
        this.selectedGameModel().save();
      };

      this.getGameModel = async (gameType) => {
        switch (this.gameTypes[gameType]) {
        case 'lotto':
          this.jokerData({
            jokerWednesday: LottoInfo.data().jokerWednesday,
            jokerSaturday: LottoInfo.data().jokerSaturday
          });
          return LottoGame.setupGame({ playType: 'Lightning' });
        case 'vikinglotto':
          this.jokerData(VikingLottoInfo.jokerData());
          return VikingLottoGame.setupGame({ playType: 'Lightning' });
        case 'eurojackpot':
          this.jokerData(EurojackpotInfo.jokerData());
          return EurojackpotGame.setupGame({ playType: 'Lightning' });
        default:
          console.error(`Unknown game type for custom package: ${gameType}`);
          break;
        }
      };

      this.totalPrice = () => {
        let totalPrice = 0;
        this.customPackageModel().information().coupons.forEach((coupon) => {
          totalPrice += coupon.price;
        });
        return totalPrice;
      };

      this.addCouponToPackage = () => {
        const hasJokerSaturday = this.selectedGameModel().withJokerSaturday();
        const hasJokerWednesday = this.selectedGameModel().withJokerWednesday();
        const couponStructure = {
          gameType: this.selectedGameType(),
          playType: 'Lightning',
          price: this.selectedGameModel().totalPrice(),
          couponGames: [
            {
              gameType: this.selectedGameType(),
              drawCount: this.selectedGameModel().numberOfDraws(),
              rowCount: this.selectedGameModel().rowsToGenerate(),
              price: this.selectedGameModel().totalRowsPricePerDraw(),
              openDraw: this.selectedGameModel().infoFeed().data().openDraw,
              draws: this.selectedGameModel().infoFeed().data().draws
            }
          ]
        };

        const addJoker = (joker) => {
          couponStructure.couponGames.push({
            gameType: joker,
            drawCount: 1,
            rowCount: 2,
            price: this.selectedGameModel().jokerPrice(),
            openDraw: this.jokerData()[joker.charAt(0).toLowerCase() + joker.slice(1)].openDraw,
            draws: this.jokerData()[joker.charAt(0).toLowerCase() + joker.slice(1)].draws
          });
        };

        if (hasJokerSaturday && hasJokerWednesday) {
          addJoker('JokerWednesday');
          addJoker('JokerSaturday');
        } else if (hasJokerSaturday) {
          addJoker('JokerSaturday');
        } else if (hasJokerWednesday) {
          addJoker('JokerWednesday');
        }

        const informationCouponsCopy = [...this.customPackageModel().information().coupons];
        const existingCouponIndex = this.customPackageModel().information().coupons.findIndex((coupon) => {
          return coupon.gameType === couponStructure.gameType;
        });

        if (existingCouponIndex !== -1) {
          informationCouponsCopy[existingCouponIndex] = couponStructure;
        } else {
          informationCouponsCopy.push(couponStructure);
        }

        this.customPackageModel().setPackageInformation({
          ...this.customPackageModel().information(),
          coupons: informationCouponsCopy
        });
      };

      this.goBackInCustomPackageFlow = () => {
        switch (this.customPackageFlowStep()) {
        case 'rowsSelection':
          this.customPackageFlowStep('customPackage');
          break;
        case 'jokerSelection':
          this.customPackageFlowStep('rowsSelection');
          break;
        default:
          break;
        }
      };

      this.generatePackage = () => {
        const packageInformation = this.customPackageModel().information();
        if (packageInformation.coupons.length === 0) {
          return;
        }
        this.processingPurchase(true);
        PackagesApi.generateLightningRowsForPackageGames(packageInformation).then((response) => {
          this.customPackageModel().setPackageInformation({
            packageNo: 0,
            isOpenForSale: true,
            price: this.totalPrice(),
            isCustomPackage: true,
            ...response
          });
          this.customPackageModel().isRowsGenerated(true);
          this.customPackageModel().save();
          Params.set('packageFlowStep=purchaseFlow');
          this.processingPurchase(false);
          m.redraw();
        }, () => {
          this.confirmErrorOverlay();
          this.processingPurchase(false);
          m.redraw();
        });
      };

      Promise.all([
        EurojackpotInfo.ready,
        LottoInfo.ready,
        VikingLottoInfo.ready,
      ]).then(() => {
        this.gamesInfoData({
          lotto: LottoInfo.data().lottoSaturday,
          vikinglotto: VikingLottoInfo.data(),
          eurojackpot: EurojackpotInfo.data()
        });
        this.loadingGamesInfo(false);
        m.redraw();
      });
    },

    view: function (controller) {
      const content = () => {
        controller.validateRowsToGenerate();

        if (controller.customPackageFlowStep() === 'rowsSelection') {
          return [
            m('.multi-packages-custom-package-flow__inner-section-heading', controller.customPackageDictionary('CustomPackageFlow/RowsSelection/Heading')),
            m('.multi-packages-custom-package-flow__inner-section-text', m.trust(controller.customPackageDictionary('CustomPackageFlow/RowsSelection/Subheading'))),
            m(RowsSelection, { controller })
          ];
        }
        if (controller.customPackageFlowStep() === 'jokerSelection') {
          return [
            m('img.multi-packages-custom-package-flow__joker-icon', {
              src: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/icon-joker-hat.svg'
            }),
            m('.multi-packages-custom-package-flow__inner-section-heading', controller.customPackageDictionary('CustomPackageFlow/JokerSelection/Heading')),
            m('.multi-packages-custom-package-flow__inner-section-text', m.trust(controller.customPackageDictionary('CustomPackageFlow/JokerSelection/Subheading'))),
            m(JokerSelection, { controller })
          ];
        }

        return [
          m('.multi-packages-custom-package-flow__inner-section-heading', controller.customPackageDictionary('CustomPackageFlow/PackageOverview/Heading')),
          m('.multi-packages-custom-package-flow__inner-section-text', m.trust(controller.customPackageDictionary('CustomPackageFlow/PackageOverview/Subheading'))),
          m(CustomPackageCard, { controller })
        ];
      };

      if (controller.loadingGamesInfo()) {
        return m(SkeletonCardLoader, { controller });
      }

      return m('.multi-packages-custom-package-flow__inner-section', [
        controller.customPackageFlowStep() !== 'customPackage' ?
          m('.multi-packages-custom-package-flow__back-button', {
            onclick: controller.goBackInCustomPackageFlow
          }, m('svg.multi-packages-custom-package-flow__back-button-icon',
            m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#back-icon' })
          ),
          ) : null,
        content()
      ]);
    }
  };

  return CustomPackageFlow;
});