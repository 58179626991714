defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/CustomPackage/ItemGamePlaceholder', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/DateUtils',
  'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo'
], function (m, Utils, DateUtils, JackpotInfo) {
  const ItemGamePlaceholder = {
    controller: function ({ controller, gameName }) {
      Object.assign(this, controller);

      this.gameName = gameName;
      this.gameType = this.gameTypes[gameName];
      this.jackpotData = JackpotInfo.getByGame(this.gameName);
      this.hasOpenDraw = this.gamesInfoData?.()?.[this.gameType]?.openDraw;

      this.gameDictionary = this.dictionary.bind({ prefix: `MultiPackages/CustomPackage/CustomPackageFlow/PackageOverview/Games/${Utils.formatNaming(this.gameType, 'capitalized')}/` });
    },

    view: function (controller, { onAddGame, gameName }) {
      const poolSizeText = controller.jackpotData?.poolSizeDecimal ? `${controller.jackpotData?.poolSize} Mio` : `min ${controller.jackpotData?.minimumPoolSize} Mio`;
      const needsOpenDraw = onAddGame && controller.hasOpenDraw;
      const useAsPlaceholder = !onAddGame;
      const gameInfo = controller.gamesInfoData()?.[controller.gameType];
      let drawDay = '';
      if (gameInfo?.openDraw || gameInfo?.draws?.length) {
        drawDay = DateUtils.parseISO8601(gameInfo.openDraw?.drawingTime || gameInfo.draws[0].drawingTime).dayName;
      }

      return m('.custom-package__games-options-item', {
        class: !useAsPlaceholder && !needsOpenDraw ? 'custom-package__games-options-item--disabled' : '',
        onclick: () => {
          if (needsOpenDraw) return onAddGame(gameName);
        }
      }, [
        m('img.custom-package__games-options-item-badge', {
          src: controller.gameBadges[controller.gameType]
        }),
        m('.custom-package__games-options-item-details', [
          m('img.custom-package__games-options-game-logo', {
            src: controller.gameLogos[controller.gameType],
            class: `custom-package__games-options-game-logo--${controller.gameType}`
          }),
          useAsPlaceholder || needsOpenDraw ? [
            m('.custom-package__games-options-game-heading', controller.gameDictionary('PoolHeading', { drawDay })),
            m('.custom-package__games-options-game-subheading', poolSizeText)
          ] : [
            m('.custom-package__games-options-game-subheading', controller.gameDictionary('DrawInProgressHeading')),
            m('.custom-package__games-options-game-heading', controller.gameDictionary('DrawInProgressSubheading'))
          ]
        ]),
        needsOpenDraw ? m('.multi-packages-coupon-card__action-button', m('.multi-packages-coupon-card__action-button-icon', {
          style: 'background-image:url("/Components/DanskeSpil/Domain/NumberGamePackage/Graphics/SpriteSheets/UI-Icons/plus.svg")'
        })) : null
      ]);
    }
  };

  return ItemGamePlaceholder;
});