defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/VikingLottoGameClosed',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoDictionary',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo'
  ],
  function (Component, VikingLottoDictionary, VikingLottoInfo) {

    // Component:
    Component('vikinglotto-game-closed', [VikingLottoDictionary, VikingLottoInfo], function (m, route, settings) {

      // Feed check:
      if (!VikingLottoInfo.isFeedDown() && !VikingLottoInfo.noOpenDraw()) {
        location.href = '/vikinglotto';
        return;
      }

      // Components:
      var root = {
        controller: function () {
          this.d = VikingLottoDictionary.get;
        },

        view: function (controller) {
          return m('div', { class: 'vikinglotto-no-open-draw' }, [
            m('section', { class: 'vikinglotto-section' + (settings.topManchete || settings.bottomManchete ? ' manchete' : '') + (settings.topManchete ? ' top' : '') + (settings.bottomManchete ? ' bot' : '') }, [
              m('div', { class: 'vikinglotto-content-wrapper' }, [
                VikingLottoInfo.isFeedDown() ? [
                  m('h2', { class: 'no-open-draw-text' }, controller.d('VikingLottoClosed/GameNotAvailableHeader')),
                  m('h2', { class: 'no-open-draw-text' }, controller.d('VikingLottoClosed/GameNotAvailableText'))
                ] : [
                  m('h2', { class: 'no-open-draw-text' }, controller.d('VikingLottoClosed/NoOpenDrawHeader')),
                  m('h2', { class: 'no-open-draw-text' }, controller.d('VikingLottoClosed/NoOpenDrawText'))
                ]
              ])
            ])
          ]);
        }
      };

      // Setup routes:
      route('/', root);

    });

  });
