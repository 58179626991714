defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/DatePicker',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (m, Render, Utils) {
    // Template:
    var DatePicker = function (controller, pushWeekToHash) {
      var render = Render();

      if (!controller.property('datePickerMonthOffset')) {
        controller.property('datePickerMonthOffset', 0);
      }

      var dateFormating = function (year, month, day) {
        if (month < 10) {
          month = '0' + month;
        }
        if (day < 10) {
          day = '0' + day;
        }
        return year + '-' + month + '-' + day;
      };
      if (!controller.property('datepickerOnce')) {
        controller.property('datepickerOnce', true);

        // sort completed draw dates and select the newsest draw
        controller.completedDrawsDates().sort(function compare(a, b) {
          var dateA = new Date(a).valueOf();
          var dateB = new Date(b).valueOf();

          return dateB - dateA;
        });
        controller.selectDate(controller.completedDrawsDates()[0]);
      }

      // Warning:
      // This component is shared for Vikinglotto, Lotto, Keno And All or nothing.

      // Variables:
      var headerNames = ['Uge', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'];
      var datePickerDays = [];
      var formattedSelectedDate = Utils.formatISO8601(controller.selectedDrawDate() + ' 00:00:00', { includeTime: false });
      var selectedDateYear = parseInt(Utils.parseISO8601(controller.selectedDrawDate() + ' 00:00:00').year, 10);
      var selectedDateMonth = parseInt(Utils.parseISO8601(controller.selectedDrawDate() + ' 00:00:00').month, 10);
      var datePickerMonthOffset = (selectedDateMonth + controller.property('datePickerMonthOffset'));
      var datePickerMonth = new Date(selectedDateYear, datePickerMonthOffset).getMonth();
      var datePickerYear = new Date(selectedDateYear, (datePickerMonth === 0 ? (datePickerMonthOffset - 1) : datePickerMonthOffset)).getFullYear();
      var datePickerMonthName = Utils.parseISO8601(dateFormating(datePickerYear, (datePickerMonth === 0 ? 12 : datePickerMonth), '1') + ' 00:00:00').monthName;
      var numberOfDaysForCurrentMonth = new Date(selectedDateYear, datePickerMonthOffset, 0).getDate();
      var numberOfDaysForPreviousMonth = new Date(selectedDateYear, (datePickerMonthOffset - 1), 0).getDate();
      var firstDayIndex = new Date(selectedDateYear, (datePickerMonthOffset - 1)).getDay();
      var lastDayIndex = new Date(selectedDateYear, (datePickerMonthOffset - 1), numberOfDaysForCurrentMonth).getDay();
      var numberOfDaysForNextMonth = 7 - (lastDayIndex + 1);

      var startDayLimit = Utils.parseISO8601(controller.completedDrawsDates()[controller.completedDrawsDates().length - 1] + ' 00:00:00');
      var isFirstMonth = new Date(parseInt(startDayLimit.year, 10), parseInt(startDayLimit.month, 10)).getTime() >= new Date(datePickerYear, (datePickerMonth === 0 ? 12 : datePickerMonth)).getTime();
      var endDayLimit = Utils.parseISO8601(controller.completedDrawsDates()[0] + ' 00:00:00');
      var isLastMonth = new Date(parseInt(endDayLimit.year, 10), parseInt(endDayLimit.month, 10)).getTime() <= new Date(datePickerYear, (datePickerMonth === 0 ? 12 : datePickerMonth)).getTime();

      function daysFromPreviousMonth() {
        if (firstDayIndex === 0) {
          firstDayIndex = 7;
        }

        for (var i = 0; i < firstDayIndex - 1; i++) {
          var day = (numberOfDaysForPreviousMonth - firstDayIndex + 1) + i + 1;
          var className = 'previous-month' + (i === 0 ? ' first-day-of-the-month' : '') + (i === (firstDayIndex - 1) ? ' last-day-of-the-month' : '');
          var dayObj = {
            dayNumber: day,
            date: new Date(datePickerYear, (datePickerMonth - 1), day),
            dateFormattet: dateFormating(datePickerYear, (datePickerMonth - 1), day),
            cssClass: className
          };

          // January
          if (datePickerMonth === 1) {
            dayObj.date = new Date((datePickerYear - 1), 11, day); // 11 = deceber
            dayObj.dateFormattet = dateFormating(datePickerYear - 1, 12, day); // shown as 12
          }
          // December fix
          if (datePickerMonth === 0) {
            dayObj.date = new Date(datePickerYear, 10, day); //  10 = november
            dayObj.dateFormattet = dateFormating(datePickerYear, 11, day); // shown as 11
          }

          datePickerDays.push(dayObj);
        }


      }

      function daysFromCurrentMonth() {
        for (var i = 0; i < numberOfDaysForCurrentMonth; i++) {
          var day = i + 1;
          var className = 'current-month';
          var dayObj = {
            dayNumber: day,
            date: new Date(datePickerYear, (datePickerMonth === 0 ? 11 : datePickerMonth - 1), day),
            dateFormattet: dateFormating(datePickerYear, (datePickerMonth === 0 ? 12 : datePickerMonth), day),
            cssClass: className
          };

          datePickerDays.push(dayObj);
        }
      }

      function daysFromNextMonth() {
        for (var i = 0; i < numberOfDaysForNextMonth + 1; i++) {
          var day = i + 1;
          var className = 'next-month' + (day === 1 ? ' first-day-of-the-month' : '') + (i === (numberOfDaysForNextMonth) ? ' last-day-of-the-month' : '');
          var dayObj = {
            dayNumber: day,
            date: new Date(datePickerYear, (datePickerMonth), day),
            dateFormattet: dateFormating(datePickerYear, (datePickerMonth + 1), day),
            cssClass: className
          };

          if (datePickerMonth === 0) {
            dayObj.dateFormattet = dateFormating((datePickerYear + 1), 1, day);
            dayObj.date = new Date((datePickerYear + 1), 0, day);
          }

          datePickerDays.push(dayObj);
        }
      }

      function insertWeekNumbers() {
        var numberOfDays = datePickerDays.length;

        for (var splice = 0; splice < numberOfDays; splice += 8) {
          var weeknr = Utils.getWeekNumber(datePickerDays[splice + 4].date);
          var className = 'weeknr';
          var dayObj = {
            dayNumber: weeknr,
            date: '',
            dateFormattet: '',
            cssClass: className
          };

          datePickerDays.splice(splice, 0, dayObj);
        }
      }

      // Functions:
      var toggleDatepicker = function () {
        if (!controller.isDatepickerOpened()) {
          controller.property('datePickerMonthOffset', 0);
        }
        controller.isDatepickerOpened(!controller.isDatepickerOpened());
      };

      var selectDate = function (date, state) {
        return function () {
          if (!state) {
            controller.selectDate(date);
            controller.isDatepickerOpened(false);

            if (pushWeekToHash) {
              Utils.setWeekAndYearToHash(date);
            }
          }
        };
      };

      var changeMonth = function (direction) {
        return function () {
          if (direction === 'prev' && !isFirstMonth) {
            controller.property('datePickerMonthOffset', (controller.property('datePickerMonthOffset') - 1));
          }
          if (direction === 'next' && !isLastMonth) {
            controller.property('datePickerMonthOffset', (controller.property('datePickerMonthOffset') + 1));
          }
        };
      };

      // check to see if we have week and date in hash, to show this.
      var checkHashForDate = function () {
        if (pushWeekToHash && history.pushState) {
          var weekAndYear = Utils.getWeekAndYearFromHash();
          if (weekAndYear) {
            var matchingDate = Utils.getMatchingDateFromWeekAndYear(weekAndYear.week, weekAndYear.year, controller.completedDrawsDates());
            if (matchingDate) controller.selectDate(matchingDate);
          }
        }
      };

      daysFromPreviousMonth();
      daysFromCurrentMonth();
      daysFromNextMonth();
      insertWeekNumbers();

      render.ready.then(function (nodes) {
        if (!nodes['date-picker'].rendered) {
          checkHashForDate();
        }
      });

      // View:
      return m('div', {
        class: 'date-picker-wrapper' + (controller.isDatepickerOpened() ? ' opened' : ''),
        config: render.depend('date-picker')
      }, [
        m('div', { class: 'date-picker-placeholder-wrapper', onclick: toggleDatepicker }, [
          m('span', { class: 'date-title' }, formattedSelectedDate),
          m('svg', { class: 'icon-arrow' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-down-arrow' })
          ])
        ]),

        // datepicker section
        m('div', { class: 'date-picker-calendar-outer-wrapper' }, [
          m('div', { class: 'date-picker-header' }, [
            m('span', { class: 'navigate-arrow prev' + (isFirstMonth ? ' disabled' : ''), onclick: changeMonth('prev') }, [
              m('svg', { class: 'icon' }, [
                m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-down-arrow' })
              ])
            ]),
            m('span', { class: 'date-title' }, datePickerMonthName + ' ' + datePickerYear),
            m('span', { class: 'navigate-arrow next' + (isLastMonth ? ' disabled' : ''), onclick: changeMonth('next') }, [
              m('svg', { class: 'icon' }, [
                m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-down-arrow' })
              ])
            ])
          ]),

          m('div', { class: 'date-picker-calendar-wrapper' }, [
            // header cells
            headerNames.map(function (day) {
              if (day === 'Uge') {
                return m('span', { class: 'calendar-cell-wrapper header weeknr' }, day);
              }
              return m('span', { class: 'calendar-cell-wrapper header' }, day);
            }),

            // days cells
            datePickerDays.map(function (day) {
              var className = 'calendar-cell-wrapper ' + day.cssClass;
              var isInactive = false;
              if (controller.completedDrawsDates().indexOf(day.dateFormattet) === -1) {
                isInactive = true;
                className += ' inactive';
              }
              if (day.dateFormattet === controller.selectedDrawDate()) {
                className += ' selected';
              }
              return m('span', { class: className, onclick: selectDate(day.dateFormattet, isInactive), title: day.dateFormattet }, [
                m('span', { class: 'day-wrapper' }, day.dayNumber)
              ]);
            })
          ])
        ])
      ]);

    };

    // Public functions:
    return DatePicker;

  });
