defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/Coupon/ExtraCampaignsDraws',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (m, Utils) {

    // Context:
    var ActivePlayForGameOverlay = {
      controller: function ({ controller }) {
        Object.assign(this, controller);

        this.coupon = controller.model.data();
        this.millionaireCampaignRows = m.prop(null);
        this.superMillionaireCampaignRows = m.prop(null);

        if (this.isPlayTogetherTempCoupon()) {
          this.mainGame = {
            ...this.coupon.drawFundings[0],
            gameType: this.coupon.couponGame,
            multiWagers: this.coupon.multiWagers,
            gameVersionNo: 3
          };
          this.rowsCount = this.mainGame.rowCount;
        } else {
          this.mainGame = this.coupon.games.find((game) => !game.gameType.toLowerCase().includes('joker'));
          this.rowsCount = this.mainGame.rows?.length || this.coupon.systemRows;
        }

        const millionaireRowsCountText = controller.d(`Campaigns/ExtraCampaigns/${this.rowsCount > 1 ? 'MillionaireMultipleRowsCount' : 'MillionaireSingleRowCount'}`, {
          count: this.rowsCount
        });
        const superMillionaireRowsCountText = controller.d(`Campaigns/ExtraCampaigns/${this.rowsCount > 1 ? 'SuperMillionaireMultipleRowsCount' : 'SuperMillionaireSingleRowCount'}`, {
          count: this.rowsCount
        });

        const dateFormatter = (date, { shortenDate = true, shortenMonthNames = true, includeYear = false, includeTime = false } = {}) => {
          return Utils.formatISO8601(date, {
            shortenDate,
            shortenMonthNames,
            includeYear,
            includeTime
          });
        };

        const formatMultiWeeks = () => {
          const multiWagerMillionaireCampaign = [];
          const multiWagerSuperMillionaireCampaign = new Map();

          this.coupon.multiWagers.forEach((wager, index) => {
            multiWagerMillionaireCampaign.push({
              label: `${index + 1}/${this.coupon.multiWagers.length}`,
              date: dateFormatter(wager.drawDate),
              rowCount: millionaireRowsCountText
            });

            const superMillionaireCampaign = wager.campaignData?.find((campaign) => {
              return campaign.type === 'Accumulating' && campaign.name?.toLowerCase().includes('super');
            });
            if (superMillionaireCampaign) {
              multiWagerSuperMillionaireCampaign.set(superMillionaireCampaign.id, superMillionaireCampaign);
            }
          });

          this.millionaireCampaignRows(multiWagerMillionaireCampaign);
          this.superMillionaireCampaignRows([...multiWagerSuperMillionaireCampaign.values()].map((campaign, index) => {
            const wagesInCampaignCount = this.coupon.multiWagers.filter((wager) => wager.campaignNumbers.includes(campaign.id)).length;
            return {
              label: `${index + 1}/${multiWagerSuperMillionaireCampaign.size}`,
              date: dateFormatter(campaign.date),
              rowCount: controller.d(`Campaigns/ExtraCampaigns/${this.rowsCount > 1 ? 'SuperMillionaireMultipleRowsCount' : 'SuperMillionaireSingleRowCount'}`, {
                count: wagesInCampaignCount * this.rowsCount
              })
            };
          }));
        };

        const formatSingleWeek = () => {
          const millionaireLabel = dateFormatter(this.mainGame.drawDate, {
            includeYear: true
          });
          this.millionaireCampaignRows([{
            label: millionaireLabel,
            rowCount: millionaireRowsCountText
          }]);

          const superMillionaireCampaign = this.coupon?.campaignData?.find((campaign) => {
            return campaign.type === 'Accumulating' && campaign.name?.toLowerCase().includes('super');
          });
          if (superMillionaireCampaign) {
            const superMillionaireLabel = dateFormatter(superMillionaireCampaign.date, {
              includeYear: true
            });

            this.superMillionaireCampaignRows([{
              label: superMillionaireLabel,
              rowCount: superMillionaireRowsCountText
            }]);
          }
        };

        if (this.coupon.multiWagers?.length > 0) {
          formatMultiWeeks();
        } else {
          formatSingleWeek();
        }
      },

      view: function (controller) {
        if (!['Lotto', 'LottoSaturday'].includes(controller.mainGame.gameType) ||
            controller.mainGame.gameVersionNo < 3) {
          return m('div');
        }

        const extraCampaignStructure = ({ headline, headerGraphicsUrl, rows }) => {
          if (!rows?.length) return null;
          return m('.coupon-extra-campaign-draws', [
            headline ? m('.coupon-extra-campaign-draws__headline', headline) : null,
            headerGraphicsUrl ? m('img.coupon-extra-campaign-draws__header-graphics', { src: headerGraphicsUrl }) : null,
            m('.coupon-extra-campaign-draws__table', rows.map((row) => {
              return m('.coupon-extra-campaign-draws__row', [
                row.label ? m('.coupon-extra-campaign-draws__cell.coupon-extra-campaign-draws__cell--name', row.label) : null,
                row.date ? m('.coupon-extra-campaign-draws__cell', row.date) : null,
                row.rowCount ? m('.coupon-extra-campaign-draws__cell', row.rowCount) : null
              ]);
            })
            )
          ]);
        };

        return m('div', [
          extraCampaignStructure({
            headline: controller.d('Campaigns/ExtraCampaigns/MillionaireChanceHeadline'),
            rows: controller.millionaireCampaignRows()
          }),
          extraCampaignStructure({
            headline: controller.d('Campaigns/ExtraCampaigns/SuperMillionaireChanceHeadline'),
            headerGraphicsUrl: '/Components/DanskeSpil/Domain/Lotto/Graphics/Campaigns/ExtraCampaigns/superMillionaireCouponHeader.svg',
            rows: controller.superMillionaireCampaignRows()
          })
        ]);
      }
    };

    // Public functions:
    return ActivePlayForGameOverlay;

  });
