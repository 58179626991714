defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/StepInfo', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/GenericOverlay'
], function (m, GlobalUtils, Utils, Overlay, GenericOverlay) {
  // Component:
  var StepInfo = {
    controller: function (args) {
      GlobalUtils.extend(this, args.controller);

      const capitalizedPlayType = Utils.formatNaming(this.playType(), 'capitalized');
      const dictionaryFolder = () => {
        if (this.currentFlowStepIndex() === -1 &&
          this.preGameClientStep?.active()) {
          return 'preGame';
        }

        return this.currentFlowStepIndex() === -1 ? 'game' : this.currentFlowStep();
      };

      const getText = (name) => {
        if (this.mcDictionary(dictionaryFolder() + '/helpOverlay')) {
          var text = this.mcDictionary(dictionaryFolder() + '/helpOverlay')[name];
          if (this.mcDictionary(dictionaryFolder() + '/helpOverlay')[capitalizedPlayType]) {
            var playTypeText = this.mcDictionary(dictionaryFolder() + '/helpOverlay')[capitalizedPlayType][name];
          }

          if (typeof playTypeText === 'string' && playTypeText.length) return playTypeText;
          if (typeof text === 'string' && text.length) return text;
        }
      };

      this.infoTitle = () => getText('Title');
      this.infoText = () => getText('Text');

      this.showInfoOverlay = () => {
        var infoOverlayDictionary = {
          dismiss: this.mcDictionary('closeButton')
        };

        if (this.infoTitle()) infoOverlayDictionary.header = this.infoTitle();
        if (this.infoText()) infoOverlayDictionary.body = this.infoText();

        return new Overlay(GenericOverlay(infoOverlayDictionary)).show();
      };
    },

    view: function (controller) {
      var hideInfoButton = !controller.infoTitle() && !controller.infoText();
      return m('.multi-client__question-button', {
        class: hideInfoButton ? 'multi-client__question-button--hidden' : '',
        onclick: controller.showInfoOverlay
      }, [
        m('svg', { class: 'icon' }, [
          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#question-icon' })
        ])
      ]);
    }
  };

  return StepInfo;
});
