defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Models/VikingLottoGame',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Core/Model',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoApi',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoUtils',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer'
  ],
  function (m, Model, VikingLottoApi, VikingLottoInfo, VikingLottoUtils, DataLayer) {

    // Model:
    var VikingLottoGame = Model('VikingLottoGame', function (content) {
      this.infoFeed = () => {
        return VikingLottoInfo;
      };
      this.api = () => {
        return VikingLottoApi;
      };

      // Data:
      this._generatedRows = m.prop(content._generatedRows || []);
      this._generatedJokerRowsSaturday = m.prop(content._generatedJokerRowsSaturday || []);
      this._generatedJokerRowsWednesday = m.prop(content._generatedJokerRowsWednesday || []);
      this.couponId = m.prop(content.couponId || null);
      this.numberOfDraws = m.prop(typeof content.numberOfDraws !== 'undefined' ? content.numberOfDraws : VikingLottoInfo.getDrawRepeatDefault()); // NEED TO USE TYPEOF UNDEFINED CHECK HERE, BECAUSE 0 IS A VALID OPTION
      this.playType = m.prop(content.playType || null);
      this.purchaseTracked = m.prop(content.purchaseTracked || false);
      this.ready = m.prop(m.deferred());
      this.rebuyCouponId = m.prop(content.rebuyCouponId || null);
      this.rows = m.prop(content.rows || []);
      this.rowsToGenerate = m.prop(content.rowsToGenerate || 0);
      this.startUrl = m.prop(content.startUrl || window.location.href.split('?')[0]);
      this.status = m.prop(content.status || 'open');
      this.systemName = m.prop(content.systemName || (this.playType() === 'System' ? 'M 7-7' : null));
      // we want to check the typeof `withJoker...` as it would be set to `false` when the user selects `no joker`
      // if the game instance is new or the user has not interacted with the joker selector than we want to set it to `null`
      this.withJokerSaturday = m.prop(typeof content.withJokerSaturday === 'boolean' ? content.withJokerSaturday : null);
      this.withJokerWednesday = m.prop(typeof content.withJokerWednesday === 'boolean' ? content.withJokerWednesday : null);
      this.isProductDetailsTracked = m.prop(content.isProductDetailsTracked || false);
      this.isAddToCartTracked = m.prop(content.isAddToCartTracked || false);
      this.isPurchaseCompleteTracked = m.prop(content.isPurchaseCompleteTracked || false);
      this.verticalType = m.prop(content.verticalType);
      this.plusSubscriptionJackpot = m.prop(content.plusSubscriptionJackpot || null);
      this.firstDepositInfo = m.prop(content.firstDepositInfo || null);
      this.playTogetherDepositType = m.prop(content.playTogetherDepositType || null);
      this.rowPriceChanged = m.prop(false);
      this.originalNumberOfDraws = m.prop(null);
      this.originalPrice = m.prop(null);
      this.reducedWeeks = m.prop(false);
      this.clientContext = m.prop(content.clientContext || null);
      this.trackedInteractionCreationAction = m.prop(content.trackedInteractionCreationAction || null);

      // Constants:
      this.classicMaxRows = m.prop(50);
      this.numberOfDrawsOptions = m.prop(VikingLottoInfo.getDrawRepeatOptions());
      this.getDrawRepeatMax = m.prop(VikingLottoInfo.getDrawRepeatMax());
      this.numbersIntervalMax = m.prop(48);
      this.stakePerJoker = m.prop(VikingLottoInfo.getJokerPrice());
      this.version = m.prop((VikingLottoInfo.getOpenDraw() && VikingLottoInfo.getOpenDraw().gameVersionNo) || 1);
      this.vikingNumbersIntervalMax = m.prop(this.version() === 1 ? 8 : 5);
      this.vikingNumbersPerRowMax = m.prop(1);
      // drawDays is an array of booleans, where each index represents a day of the week, starting with Monday
      this.drawDays = m.prop([false, false, true, false, false, false, false]);

      // Functions:
      this.vikingNumbersPerRowMin = function () {
        return this.playType() === 'Lucky' ? 0 : 1;
      }.bind(this);

      this.numbersPerRowMin = function () {
        return this.numbersPerRow('min');
      }.bind(this);

      this.numbersPerRowMax = function () {
        return this.numbersPerRow('max');
      }.bind(this);

      this.totalNumbersPerRowMax = function () {
        if (this.playType() === 'Lucky') {
          return 6;
        }

        return this.numbersPerRowMax() + this.vikingNumbersPerRowMax();
      }.bind(this);

      this.numbersPerRow = function (type) {
        var playType = this.playType();

        // System:
        if (playType === 'System') {
          return this.getSystemNumberAmount();
        }

        // Lucky:
        if (playType === 'Lucky') {
          return type === 'min' ? 0 : 6;
        }

        // Classic:
        return 6;

      }.bind(this);

      // Functions - For manipulating rows:
      this.rowCount = function () {
        var count = 0;
        var rows = this.rows();
        var amount = rows.length;

        for (var i = 0; i < amount; i++) {
          var row = rows[i];

          if (row.count > count) {
            count = row.count + 1;
          } else {
            count++;
          }
        }

        return count;
      }.bind(this);

      this.sortRow = function (numbers) {
        numbers.sort(function (a, b) {
          return a.number < b.number ? -1 : 1;
        });

        return numbers;
      };

      this.addNumber = function (row, number, autogenerated) {
        row = this.getRow(row);

        if (row.numbers.length >= this.numbersPerRowMax()) {
          return;
        }

        row.numbers.push({ number: number, autogenerated: autogenerated || false });
        row.numbers = this.sortRow(row.numbers);

        this.save();
      }.bind(this);

      this.removeNumber = function (row, number) {
        row = this.getRow(row);

        var index = -1;
        var numbers = row.numbers;

        for (var i = 0; i < numbers.length; i++) {
          if (numbers[i].number === number) {
            index = i;

            break;
          }
        }

        if (index > -1) {
          row.numbers.splice(index, 1);
        }

        row.numbers = this.sortRow(row.numbers);

        this.save();
      }.bind(this);

      this.addVikingNumber = function (row, vikingNumber, autogenerated) {
        row = this.getRow(row);

        if (row.vikingNumber) {
          return;
        }

        row.vikingNumber = { number: vikingNumber, autogenerated: autogenerated || false };

        this.save();
      }.bind(this);

      this.removeVikingNumber = function (row) {
        row = this.getRow(row);

        row.vikingNumber = null;

        this.save();
      }.bind(this);

      this.addRow = function (numbers) {
        numbers = numbers || [];

        var count = this.rowCount();

        this.rows().push({ count: count, numbers: numbers, state: 'clean', vikingNumber: null });

        this.save();
      }.bind(this);

      this.removeRow = function (row) {
        var rows = this.rows();

        rows.splice(row - 1, 1);

        this.rows(rows);

        if (rows.length === 0) {
          this.addRow();
        }

        this.save();
      }.bind(this);

      this.removeAllRows = function () {
        this.rows([]);

        // We need double rows, as we're practically resetting the rows to original state
        this.addRow();
        this.addRow();

        this.save();
      }.bind(this);

      this.resetRow = function (rowNumber) {
        var row = this.getRow(rowNumber);

        row.numbers = [];
        row.vikingNumber = null;
        row.state = 'clean';

        this.setRow(rowNumber, row);

        this.save();
      }.bind(this);

      this.setRow = function (row, content) {
        var rows = this.rows();

        rows[row - 1] = content;

        this.rows(rows);

        this.save();
      }.bind(this);

      // Viking Lotto Classic and Viking Lotto System: generate numbers for a specific row:
      this.autogenerateNumbers = function (rowNumber) {
        var self = this;
        var deferred = m.deferred();
        var numbers = [];
        var row = this.getRow(rowNumber);
        var vikingNumber = row.vikingNumber;

        // Prepare list of existing user selected numbers:
        for (var i = 0; i < row.numbers.length; i++) {
          var number = row.numbers[i];

          if (!number.autogenerated) {
            numbers.push(number.number);
          }
        }

        // Setup options for random service:
        var options = { playType: this.playType(), requiredNumbers: numbers };

        if (vikingNumber && !vikingNumber.autogenerated) {
          options.requiredVikingNumber = vikingNumber.number;
        }

        if (this.playType() === 'System') {
          options.system = this.systemName();
        }

        VikingLottoApi.getRandomNumbers(options).then(function (rows) {
          rows = rows[0];

          const manualSelectedNumbers = row.numbers.filter((number) => !number.autogenerated);
          row.numbers = manualSelectedNumbers;
          if (vikingNumber && vikingNumber.autogenerated) {
            row.vikingNumber = null;
          }
          self.setRow(rowNumber, row);

          var generatedNumbers = rows.numbers;
          var generatedVikingNumber = rows.vikingNumber;

          for (var i = 0; i < generatedNumbers.length; i++) {
            var generated = generatedNumbers[i];

            if (numbers.indexOf(generated) === -1) {
              self.addNumber(rowNumber, generated, true);
            }
          }

          if (generatedVikingNumber) {
            self.addVikingNumber(rowNumber, generatedVikingNumber, true);
          }

          deferred.resolve();
        }, function () {
          deferred.reject();
        });

        return deferred.promise;
      }.bind(this);

      this.areAllRowsValid = () => {
        return this.rows().every((row) => this.isRowValid(row.count + 1));
      };

      this.autofillClassicRows = async () => {
        const promises = [];
        const emptyRows = [];
        for (let i = 0; i < this.rows().length; i++) {
          const row = this.rows()[i];
          const rowNumber = row.count + 1;
          if (!this.isRowValid(rowNumber)) {
            if (row.numbers.length > 0 || row.vikingNumber?.number) {
              promises.push(this.autogenerateNumbers(rowNumber));
            } else {
              emptyRows.push(row);
            }
          }
        }
        if (emptyRows.length > 0) {
          const fullyGeneratedRows = this.api().getRandomNumbers({ rowsToGenerate: emptyRows.length }).then((rows) => {
            for (let i = 0; i < rows.length; i++) {
              const row = rows[i];
              const emptyRow = emptyRows[i];
              this.addVikingNumber(emptyRow.count + 1, row.vikingNumber, true);
              for (let j = 0; j < row.numbers.length; j++) {
                this.addNumber(emptyRow.count + 1, row.numbers[j], true);
              }
            }
          });
          promises.push(fullyGeneratedRows);
        }
        await Promise.all(promises);

        this.save();
      };

      // Viking Lotto Lightning, Viking Lotto Lucky and Viking Lotto System: generate output rows:
      this.gameGenerateRows = function () {
        var self = this;
        var deferred = m.deferred();

        if (['Lightning', 'Lucky', 'System'].indexOf(this.playType()) === -1) {
          console.error('VikingLottoGame gameGenerateRows: This method cannot be used for playType', this.playType());

          deferred.reject();

          return;
        }

        if (this.playType() === 'System') {
          var generatedRows = [];
          var selectedNumbers = this.getRow(1).numbers;
          var vikingNumber = this.getRow(1).vikingNumber;

          VikingLottoApi.getSystemKeys(this.systemName()).then(function (systemKeys) {
            if (systemKeys) {
              generatedRows = systemKeys.map(function (row, i) {
                var numbers = row.map(function (index) {
                  // Replace each index with the numbers chosen by the user:

                  return { number: selectedNumbers[index - 1].number, autogenerated: true };
                });

                return { count: i, numbers: numbers, vikingNumber: vikingNumber ? vikingNumber.number : null, state: 'clean' };
              });
            }

            self.generatedRows(generatedRows);
            self.save();

            deferred.resolve();
          }, function () {
            deferred.reject();
          });
        } else {

          // Lightning and Lucky:
          var options = { rowsToGenerate: this.rowsToGenerate() };
          var requiredNumbers = [];
          var requiredVikingNumber = null;
          var row = this.getRow(1);

          if (row && row.numbers.length > 0) {
            requiredNumbers = row.numbers.map(function (number) {
              return number.number;
            });
          }

          if (row && row.vikingNumber) {
            requiredVikingNumber = row.vikingNumber.number;
          }

          if (this.playType() === 'Lucky') {
            options.requiredNumbers = requiredNumbers.join(',');
            options.requiredVikingNumber = requiredVikingNumber;
          }

          VikingLottoApi.getRandomNumbers(options).then(function (rows) {
            var generatedRows = [];

            for (var i = 0; i < rows.length; i++) {
              row = rows[i];

              var numbers = row.numbers.map(function (number) {
                return { number: number, autogenerated: true };
              });
              var vikingNumber = { number: row.vikingNumber, autogenerated: true };

              generatedRows.push({ count: i, numbers: numbers, state: 'clean', vikingNumber: vikingNumber });
            }

            // Store generated rows to our object
            self.generatedRows(generatedRows);

            self.save();

            deferred.resolve();
          }, function () {
            deferred.reject();
          }).bind(this);
        }

        return deferred.promise;
      }.bind(this);

      this.gameGenerateJokerRows = function (type) {
        var deferred = m.deferred();
        var self = this;

        VikingLottoApi.getRandomJokerNumbers(type).then(function (rows) {
          var generatedJokerRows = [];

          for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            var numbers = row.map(function (number) {
              return { number: number, autogenerated: true };
            });

            generatedJokerRows.push({ count: i, numbers: numbers, state: 'clean' });
          }

          self.generatedJokerRows(type, generatedJokerRows);
          self.save();

          deferred.resolve();
        }, function () {
          deferred.reject();
        });

        return deferred.promise;
      }.bind(this);

      this.generatedJokerRows = function (type, value) {
        if (value) {
          this['_generatedJokerRows' + type](value);
        }

        return this['_generatedJokerRows' + type]();
      }.bind(this);

      // Functions for number of draws:
      this.setNumberOfDraws = function (draws) {
        this.numberOfDraws(draws);

        this.save();
      }.bind(this);

      // Functions for purchase:
      this.purchase = function (params) {
        params = params || {};

        var deferred = m.deferred();
        var proceed = true;
        var self = this;

        if (this.status() != 'open') {
          deferred.reject();
        } else if (!params.drawId) {
          console.error('No drawId given.');

          deferred.reject({ errorMessage: 'NUMBERGAMES.NO_OPEN_DRAW' });
        } else {
          this.status('pending-confirmation');

          this.save();

          var rows = this.playType() === 'System' ? this.getRowsSummary(false) : this.getRowsSummary();

          var options = {
            drawId: params.drawId,
            totalPrice: parseInt(self.totalPrice(), 10),
            multidraw: parseInt(self.numberOfDraws(), 10),
            playType: this.playType() || null,
            rows: rows,
            salesChannel: (VikingLottoUtils.isMobile() || VikingLottoUtils.isTabletDevice()) ? 'mobile' : 'web',
          };

          // Prize notification - register only enabling:
          if (params.notifyPrizeEmail) {
            options.notifyPrizeEmail = true;
          }

          if (params.notifyPrizeSms) {
            options.notifyPrizeSms = true;
          }

          // Jokers:
          if (this.withJokerSaturday()) {
            options.jokerSaturdayRows = this.getJokerRowsSummary('Saturday');

            if (options.jokerSaturdayRows.length === 0) {
              proceed = false;

              deferred.reject('joker-saturday-error');
            }
          }

          if (this.withJokerWednesday()) {
            options.jokerWednesdayRows = this.getJokerRowsSummary('Wednesday');

            if (options.jokerWednesdayRows.length === 0) {
              proceed = false;

              deferred.reject('joker-wednesday-error');
            }
          }

          // Proceed:
          if (proceed) {

            // System:
            if (this.playType() === 'System') {
              options.system = this.systemName();
            }

            // Create request:
            var request = VikingLottoApi.createCoupon(options);

            request.then(function (data) {
              self.status('completed');
              self.couponId(data.couponId);

              self.save();

              deferred.resolve(data);
            }, function (data) {
              self.status('open');

              self.save();

              deferred.reject(data);
            });

          }

        }

        return deferred.promise;
      }.bind(this);

      this.prepareConfirm = function () {
        var confirmUrl = this.confirmUrl();
        var deferred = m.deferred();
        var status = this.status();

        if (status !== 'processing') {
          var promises = this.playType() !== 'Classic' ? [this.gameGenerateRows()] : [];
          var self = this;

          if (status === 'prepare-confirm-error') {
            status = 'open';
          }

          this.status(this.numberOfDraws() > 0 ? 'processing' : status);

          if (this.withJokerSaturday()) {
            promises.push(this.gameGenerateJokerRows('Saturday'));
          }

          if (this.withJokerWednesday()) {
            promises.push(this.gameGenerateJokerRows('Wednesday'));
          }

          m.sync(promises).then(function () {
            self.status(status);

            self.save();

            deferred.resolve(confirmUrl);
          }, function () {
            self.status('prepare-confirm-error');

            self.save();

            deferred.reject();
          });
        }

        return deferred.promise;
      }.bind(this);

      // Functions - For tracking (DataLayer eCommerce buzzword)
      this.createDataLayerJoker = function (day, subscription) {
        var joker;

        if (!subscription) {
          joker = {
            name: 'joker',
            price: DataLayer.rowPrice(this.stakePerJoker(), this.numberOfDraws(), 'joker'),
            brand: 'dlo_jok',
            category: DataLayer.categoryName(this.playType()),
            variant: DataLayer.variantName('joker', this.numberOfDraws(), day),
            quantity: 1
          };
        }

        if (subscription) {
          joker = {
            name: 'plus',
            price: DataLayer.rowPrice(this.stakePerJoker(), this.numberOfDraws(), 'plus_joker'),
            brand: 'dlo_plu',
            category: DataLayer.categoryName(this.playType()),
            variant: DataLayer.variantName('plus_joker', this.numberOfDraws(), day),
            quantity: 1
          };
        }

        if (this.trackedInteractionCreationAction()) {
          joker.coupon = this.trackedInteractionCreationAction();
        }

        return joker;
      };

      this.createDataLayerProduct = function (type, event) {

        // Product array for datalayer
        var dataLayerProducts = [];
        // Main product
        var product = {};
        var subscription = type === 'plus';

        if (type === 'plus') {
          // subscription product (PLUS abodoment)
          product.name = 'plus';
          product.price = DataLayer.rowPrice(VikingLottoInfo.data(), this.numberOfDraws(), 'plus');
          product.brand = 'dlo_plu';
          product.category = DataLayer.categoryName(this.playType());
          product.variant = DataLayer.variantName('plus_viking', this.numberOfDraws(), this.playType(), this.systemName());
          product.quantity = this.getRowAmount();
        } else {
          product.name = 'vikinglotto';
          product.price = DataLayer.rowPrice(VikingLottoInfo.data(), this.numberOfDraws());
          product.brand = 'dlo_vik';
          product.category = DataLayer.categoryName(this.playType());
          product.variant = DataLayer.variantName('viking', this.numberOfDraws(), this.playType(), this.systemName());

          if (type === 'playtogether') {
            product.category = 'spil_sammen';
          }

          if (event != 'productDetails') {
            product.quantity = this.getRowAmount();
          }

          if (this.trackedInteractionCreationAction()) {
            product.coupon = this.trackedInteractionCreationAction();
          }
        }
        dataLayerProducts.push(product);

        // Check for joker saturday
        if (this.withJokerSaturday()) {
          !subscription ? dataLayerProducts.push(this.createDataLayerJoker('saturday')) : dataLayerProducts.push(this.createDataLayerJoker('saturday', 'plus'));
        }

        // Check for joker wednesday
        if (this.withJokerWednesday()) {
          !subscription ? dataLayerProducts.push(this.createDataLayerJoker('wednesday')) : dataLayerProducts.push(this.createDataLayerJoker('wednesday', 'plus'));
        }

        return dataLayerProducts;
      };

      this.trackingAddToCart = function (type) {
        if (!this.isAddToCartTracked()) {
          this.isAddToCartTracked(true);

          this.save();

          // Push DataLayer addToCart Event
          if (type == 'plus' || type === 'playtogether') {
            DataLayer.addToCart(this.createDataLayerProduct(type));
          } else {
            DataLayer.addToCart(this.createDataLayerProduct());
          }
        } else {
          console.warn('addToCart has been pushed already!');
        }
      }.bind(this);

      this.trackingProductDetails = function () {
        // Check if the event has already been pushed
        if (this.isProductDetailsTracked() == false) {
          this.isProductDetailsTracked(true);
          this.save();

          // Push DataLayer addToCart Event
          DataLayer.productDetail(this.createDataLayerProduct(false, 'productDetails'));
        } else {
          console.warn('productDetails has been pushed already!');
        }
      }.bind(this);

      this.trackingPurchaseComplete = function (type) {
        if (this.isPurchaseCompleteTracked() == false) {
          this.isPurchaseCompleteTracked(true);
          this.save();

          // Push DataLayer purchaseComplete Event
          DataLayer.purchaseCompleted({
            totalPrice: this.totalPrice().toString(),
            id: this.couponId() || this.fallbackFakeCouponId()
          }, this.createDataLayerProduct(type));
        } else {
          console.warn('purchaseCompleted has been pushed already!');
        }
      }.bind(this);

      // Ensighten will group all events together if the ID is not unique.
      // In Playtogether we don't have a couponId upon purchase, so we create a fake one
      this.fallbackFakeCouponId = function () {
        var couponId = 'NA-VIK-' + Math.random().toString(36).slice(2);

        return couponId;
      };

      // Functions - For extracting data:
      this.getRow = function (row) {
        // Note: the row index starts with 1:

        return this.rows()[row - 1];
      }.bind(this);

      this.isRowValid = function (rowNumber, { checkOnlyMaxLimit = false } = {}) {
        var playType = this.playType();
        var row = this.getRow(rowNumber) || {};

        // Lucky:
        if (playType === 'Lucky') {
          var totalNumbers = (row.numbers.length || 0) + (row.vikingNumber ? 1 : 0);

          if (checkOnlyMaxLimit) {
            return totalNumbers === 6;
          }
          return totalNumbers >= 1 && totalNumbers <= 6;
        }

        // System:
        if (playType === 'System') {
          return row.numbers.length === this.getSystemNumberAmount() && row.vikingNumber;
        }

        // All other play types:
        return row.numbers.length >= this.numbersPerRowMin() && row.numbers.length <= this.numbersPerRowMax() && row.vikingNumber;
      }.bind(this);

      this.getRows = function (valid) {
        var self = this;

        return this.rows().filter(function (row, index) {
          if (row.state === 'remove') {
            return false;
          }

          return !valid || self.isRowValid(index + 1);
        });
      }.bind(this);

      this.hasNumber = function (row, number) {
        row = this.getRow(row);

        var numbers = row.numbers;

        for (var i = 0; i < numbers.length; i++) {
          if (numbers[i].number === number) {
            return true;
          }
        }

        return false;
      }.bind(this);

      this.hasVikingNumber = function (row, vikingNumber) {
        var rowVikingNumber = this.getRow(row).vikingNumber;

        return rowVikingNumber && rowVikingNumber.number === vikingNumber;
      }.bind(this);

      this.drawDateHtml = function (delimiter) {
        delimiter = delimiter || ' - ';

        var openDraw = VikingLottoInfo.data().openDraw;
        if (!openDraw || !openDraw.closingTime) {
          return '';
        }

        var closingTime = openDraw.closingTime;
        var draws = this.numberOfDraws();

        var date = new Date(closingTime);

        var result = VikingLottoUtils.formatISO8601(date.toISOString(), { includeTime: false });

        if (draws > 1) {
          date.setDate(date.getDate() + ((draws - 1) * 7));

          result = '<span class="multiple-draws">' + result + delimiter + VikingLottoUtils.formatISO8601(date.toISOString(), { includeTime: false }) + '</span>';
        }

        return result;
      }.bind(this);

      this.drawDateHtmlShort = function () {
        var dates = this.drawDates();
        var firstDate = dates[0];
        var lastDate = dates[dates.length - 1];

        // if there's more than one date, output the range. otherwise output the first date.
        if (dates.length > 1) {
          return firstDate.draw.getDate() + '/' + (firstDate.draw.getMonth() + 1) + ' - ' + lastDate.draw.getDate() + '/' + (lastDate.draw.getMonth() + 1) + ' ' + lastDate.draw.getFullYear();
        } else {
          return firstDate.draw.getDate() + '/' + (firstDate.draw.getMonth() + 1) + ' ' + firstDate.draw.getFullYear();
        }
      }.bind(this);

      this.drawDates = function () {
        var drawDates = [];
        var openDraw = VikingLottoInfo.data().openDraw;
        var draws = this.numberOfDraws();

        if (!openDraw || !openDraw.closingTime) {
          return drawDates;
        }

        var closingTime = openDraw.closingTime;

        while (draws--) {
          var futureDate = new Date(closingTime);
          futureDate.setDate(futureDate.getDate() + (draws * 7));
          drawDates.push({
            draw: futureDate,
            hasJoker: this.withJokerWednesday()
          });
        }

        // if joker on saturdays are added, add their dates.
        if (this.withJokerSaturday()
          && VikingLottoInfo.jokerData()
          && VikingLottoInfo.jokerData().jokerSaturday
          && VikingLottoInfo.jokerData().jokerSaturday.openDraw
          && VikingLottoInfo.jokerData().jokerSaturday.openDraw.closingTime) {

          var firstSaturdayJoker = new Date(VikingLottoInfo.jokerData().jokerSaturday.openDraw.closingTime);

          draws = this.numberOfDraws();
          while (draws--) {
            drawDates.push({
              draw: new Date(firstSaturdayJoker),
              hasJoker: true
            });
            firstSaturdayJoker.setDate(firstSaturdayJoker.getDate() + 7);
          }
        }

        drawDates.sort(function (a, b) {
          return a.draw.getTime() - b.draw.getTime();
        });

        return drawDates;
      }.bind(this);

      this.rawDrawsDataFromInfoFeed = () => {
        const drawTimes = this.drawDates().map((draw) => {
          return new Date(draw.draw).getTime();
        });

        return VikingLottoInfo.data().draws.filter((feedDraw) => {
          return drawTimes.indexOf(new Date(feedDraw.closingTime).getTime()) !== -1;
        });
      };

      this.drawDatesWithoutJokerInfo = function (numberOfDraws) {
        var drawDates = [];
        var openDraw = VikingLottoInfo.data().openDraw;
        var draws = numberOfDraws || this.numberOfDraws();
        var drawsFromVikingLottoInfo = VikingLottoInfo.data().draws;
        var firstDraw = VikingLottoInfo.data().openDraw;

        if (!openDraw || !openDraw.closingTime) {
          return drawDates;
        }

        var firstDrawIndex = -1;

        for (var i = 0; i < drawsFromVikingLottoInfo.length; i++) {
          if (drawsFromVikingLottoInfo[i].id === firstDraw.id) {
            firstDrawIndex = i;
            break;
          }
        }
        var feedDrawDates = drawsFromVikingLottoInfo.slice(firstDrawIndex, firstDrawIndex + draws);
        drawDates = feedDrawDates.map(function (draw) {
          const drawObject = {
            draw: new Date(draw.closingTime)
          };

          if (draw.campaigns) {
            drawObject.campaigns = draw.campaigns;
            drawObject.campaignNumbers = draw.campaignNumbers;
          }

          return drawObject;
        }.bind(this));

        drawDates.sort(function (a, b) {
          return a.draw.getTime() - b.draw.getTime();
        });

        return drawDates;
      }.bind(this);

      this.jokerDrawDates = function (drawDay) {
        if (!(VikingLottoInfo.jokerData()
            && VikingLottoInfo.jokerData().jokerSaturday
            && VikingLottoInfo.jokerData().jokerWednesday
            && VikingLottoInfo.jokerData().jokerSaturday.draws
            && VikingLottoInfo.jokerData().jokerWednesday.draws)) return;

        if (this.drawDatesWithoutJokerInfo().length === 0) return [];

        var saturdayDraws = VikingLottoInfo.jokerData().jokerSaturday.draws.map(function (draw) {
          draw.dayName = 'saturday';
          return draw;
        });
        var wednesdayDraws = VikingLottoInfo.jokerData().jokerWednesday.draws.map(function (draw) {
          draw.dayName = 'wednesday';
          return draw;
        });
        var combinedDraws = saturdayDraws.concat(wednesdayDraws);
        combinedDraws.sort(function (a, b) {
          return (a.closingTime).localeCompare((b.closingTime));
        });

        switch (drawDay) {
        case 'saturday':
          return saturdayDraws.slice(0, this.numberOfDraws());
        case 'wednesday':
          return wednesdayDraws.slice(0, this.numberOfDraws());
        case 'both':
          return combinedDraws.slice(0, (this.numberOfDraws() * 2));
        }
      }.bind(this);

      this.campaignDrawsCount = (wantedCampaign) => {
        return this.rawDrawsDataFromInfoFeed().reduce(function (count, draw) {
          if (Array.isArray(wantedCampaign)) {
            return draw?.campaignNumbers?.some((campaignNumber) => {
              return wantedCampaign.includes(campaignNumber);
            }) ? ++count : count;
          }
          if (typeof wantedCampaign === 'number') {
            return draw?.campaignNumbers?.includes(wantedCampaign) ? ++count : count;
          }
          return draw?.campaigns?.some(function (c) {
            return c.indexOf(wantedCampaign) === 0;
          }) ? ++count : count;
        }, 0);
      };

      this.generatedRows = function (value) {
        var playType = this.playType();

        // Classic and Lightning:
        if (playType === 'Classic') {
          if (value) {
            console.error('VikingLottoGame: play type Classic does not support setting generatedRows.');
          }

          return this.getRows(true);
        }

        // Lightning, Lucky and System:
        if (playType === 'Lightning' || playType === 'Lucky' || playType === 'System') {
          if (value) {
            this._generatedRows(value);
          }

          return this._generatedRows();
        }
      }.bind(this);

      this.checkGeneratedConsistency = function () {
        var playType = this.playType();

        if (playType === 'Classic' || playType === 'Lightning') {
          return true;
        }

        if (playType === 'Lucky') {
          return this.generatedRows().length !== this.rowsToGenerate();
        }

        if (playType === 'System') {
          // TODO: check that amount of generated rows corresponds to the chosen system.

          return true;
        }
      }.bind(this);

      this.getRowsSummary = function (useGenerated) {
        useGenerated = typeof useGenerated === 'undefined' ? true : useGenerated;

        var summary = [];
        var rows = useGenerated ? this.generatedRows() : this.getRows(true);

        for (var i = 0; i < rows.length; i++) {
          var row = {
            numbers: rows[i].numbers.map(function (numbers) {
              return numbers.number;
            }),
            vikingNumber: rows[i].vikingNumber ? rows[i].vikingNumber.number : null
          };

          summary.push(row);
        }

        return summary;
      }.bind(this);

      this.getJokerRowsSummary = function (type) {
        var summary = [];
        var rows = this['_generatedJokerRows' + type]();

        for (var i = 0; i < rows.length; i++) {
          var row = rows[i].numbers.map(function (numbers) {
            return numbers.number;
          });

          summary.push({ numbers: row });
        }

        return summary;
      }.bind(this);

      this.getRowAmount = function () {
        var playType = this.playType();

        if (playType === 'Lucky' || playType === 'Lightning') {
          return this.rowsToGenerate();
        }

        if (playType === 'System') {
          return this.getSystemRowAmount();
        }

        // Classic:
        return this.getRows(true).length;
      }.bind(this);

      this.rowPrice = function (rowIndex) {
        var data = VikingLottoInfo.data();
        var rowPrice = rowIndex !== undefined && data.draws && data.draws[rowIndex] && data.draws[rowIndex].rowPrice ? data.draws[rowIndex].rowPrice : 0;

        return rowPrice;
      };

      this.totalRowsPricePerDraw = function (drawIndex) {
        var rowAmount = this.getRowAmount();
        var price = 0;

        if (drawIndex !== undefined) {
          return this.rowPrice(drawIndex) * rowAmount;
        }

        for (var i = 0; i < this.numberOfDraws() ; i++) {
          price += rowAmount * this.rowPrice(i);
        }

        return price;
      }.bind(this);

      this.totalPrice = function () {
        return this.totalJokerPrice() + this.totalRowsPricePerDraw();
      }.bind(this);

      this.jokerPrice = function () {
        return VikingLottoInfo.getJokerPrice() * 2;
      }.bind(this);

      this.totalJokerPrice = function () {
        return this.getJokerCount() * this.stakePerJoker() * (this.numberOfDraws() || 1);
      }.bind(this);

      this.getJokerCount = function () {
        return (this.withJokerSaturday() ? 2 : 0) + (this.withJokerWednesday() ? 2 : 0);
      }.bind(this);

      this.isNumberOfDrawsExceed = function () {
        return this.numberOfDraws() > VikingLottoInfo.data().draws.length;
      }.bind(this);

      this.isGameValid = function () { // Required by PurchaseBar
        var playType = this.playType();

        // Not valid, if numberOfDraws is higher than known upcomming draws in the feed
        if (this.numberOfDraws() > VikingLottoInfo.data().draws.length) {
          return false;
        }

        // Lightning:
        if (playType === 'Lightning') {
          return this.totalPrice() > 0 || this.numberOfDraws() === 0;
        }

        // Rows:
        var hasValidRows = false;

        for (var i = 0; i < this.rows().length; i++) {
          if (this.isRowValid(i + 1)) {
            hasValidRows = true;
          }
        }

        if (!hasValidRows) {
          return false;
        }

        // System:
        if (playType === 'System') {
          return (this.systemName() && this.getSystemNumberAmount() == this.getRow(1).numbers.length);

          // Lucky, Classic:
        } else if (playType === 'Lucky' || playType === 'Classic') {
          return this.getRows(true).length > 0 && (this.totalPrice() > 0 || this.numberOfDraws() === 0);
        }
      }.bind(this);

      this.isSubscriptionValid = function () {
        if (this.playType() === 'System') {
          // TODO - CR: System - ingen systemer, der overstiger 2.000 kr. (Kasper har listen, hvis det er)
          return true;
        }

        // Classic, Lykke, Lyn - mindst 10 rækker
        return this.getRowAmount() >= 10;
      }.bind(this);

      // Helpers for Viking Lotto System:
      this.getSystemNumberAmount = function () {
        return this.systemName() ? parseInt(this.systemName().split(' ')[1].split('-')[0], 10) : 0;
      }.bind(this);

      this.getSystemRowAmount = function () {
        return this.systemName() ? parseInt(this.systemName().split(' ')[1].split('-')[1], 10) : 0;
      }.bind(this);


      this.trackPurchase = function () {
        var self = this;

        if (!self.purchaseTracked() && self.status() === 'completed') {
          window.DSAPI && DSAPI.ready(function () {
            self.purchaseTracked(true);
          });

          this.trackingPurchaseComplete();
        }
      }.bind(this);

      this.confirmUrl = function () {
        var data = VikingLottoInfo.data();

        if (data && data.confirmUrl) {
          return data.confirmUrl + '?gameInstanceId=' + this.id();
        }

        console.error('VikingLottoGame: unable to return confirmUrl, feed is not loaded.');
      }.bind(this);

      this.receiptUrl = function () {
        var data = VikingLottoInfo.data();

        if (data && data.receiptUrl) {
          return data.receiptUrl + '?gameInstanceId=' + this.id();
        }

        console.error('VikingLottoGame: unable to return receiptUrl, feed is not loaded.');
      }.bind(this);

      this.cancelUrl = function () {
        if (this.rebuyCouponId()) {
          // This is called when going from confirm page, back to completed games overview page.
          return this.startUrl();
        }

        if (this.clientContext() === 'multiClient') {
          var multiClientPath = window.location.href.split('#/');
          return this.startUrl() + '?gameInstanceId=' + this.id() + '#/' + multiClientPath;
        }

        // This is called when going from confirm page, back to game.
        return this.startUrl() + '?gameInstanceId=' + this.id();
      }.bind(this);

      this.copyFromOldCoupon = function () {
        var self = this;

        VikingLottoApi.getCoupon(this.rebuyCouponId()).then(function (data) {
          self.numberOfDraws(!data.multiWagers ? 1 : data.multiWagers.length);
          self.playType(data.playType);
          self.systemName(data.systemName);
          self.reducedWeeks(data.rebuyDrawRepeatMax && data.rebuyDrawRepeatMax < self.numberOfDraws());
          self.rowPriceChanged(data.rowPriceChanged);

          if (self.rowPriceChanged()) {
            self.originalPrice((data.price * self.numberOfDraws()) + self.totalJokerPrice());
          }

          if (self.reducedWeeks()) {
            self.originalPrice((data.price * self.numberOfDraws()) + self.totalJokerPrice());
            self.originalNumberOfDraws(self.numberOfDraws());
            self.numberOfDraws(data.rebuyDrawRepeatMax);
          }

          var promises = [];

          // If systemName is defined, set playType to System, otherwise Classic:
          if (!self.playType() || self.playType().toLowerCase() === 'unknown') {
            self.playType(self.systemName() ? 'System' : 'Classic');
          }

          data.games.forEach(function (game) {
            var rows = [];
            var rowNumbers = [];

            // VikingLotto:
            if (game.gameType === 'VikingLotto') {

              // Classic:
              if (self.playType() === 'Classic') {
                game.rows.forEach(function (row, index) {
                  rowNumbers = { count: index, numbers: [], state: 'clean', vikingNumber: null };

                  row.numbers.forEach(function (number) {
                    rowNumbers.numbers.push({ autogenerated: false, number: number.number });
                  });

                  if (row.extraNumbers && row.extraNumbers.length === 1) {
                    rowNumbers.vikingNumber = { number: row.extraNumbers[0].number, autogenerated: false };
                  }

                  self.rows().push(rowNumbers);
                });

                // System:
              } else if (self.playType() === 'System') {
                rowNumbers = { count: 0, numbers: [], state: 'clean', vikingNumber: null };

                game.systemNumbers.numbers.forEach(function (number) {
                  rowNumbers.numbers.push({ autogenerated: false, number: number.number });
                });

                if (game.systemNumbers && game.systemNumbers.extraNumbers && game.systemNumbers.extraNumbers.length === 1) {
                  rowNumbers.vikingNumber = { number: game.systemNumbers.extraNumbers[0].number, autogenerated: false };
                }

                self.rows().push(rowNumbers);

                promises.push(self.gameGenerateRows());

                // Lightning and Lucky:
              } else if (self.playType() === 'Lucky' || self.playType() === 'Lightning') {
                game.rows.forEach(function (row, index) {
                  rowNumbers = { count: index, numbers: [], state: 'clean', vikingNumber: null };

                  row.numbers.forEach(function (number) {
                    rowNumbers.numbers.push({ autogenerated: false, number: number.number });
                  });

                  if (row.extraNumbers && row.extraNumbers.length === 1) {
                    rowNumbers.vikingNumber = { number: row.extraNumbers[0].number, autogenerated: false };
                  }

                  rows.push(rowNumbers);
                });

                self.generatedRows(rows);
                self.rowsToGenerate(rows.length);
              }

              // Joker:
            } else {
              var gameType = /wednesday/.test(game.gameType.toLowerCase()) ? 'wednesday' : 'saturday';

              rows = [];

              if (gameType === 'wednesday') {
                self.withJokerWednesday(true);
              } else {
                self.withJokerSaturday(true);
              }

              game.rows.forEach(function (row, index) {
                var rowNumbers = { count: index, numbers: [], state: 'clean' };

                row.numbers.forEach(function (number) {
                  rowNumbers.numbers.push({ autogenerated: false, number: number.number });
                });

                rows.push(rowNumbers);
              });

              self.generatedJokerRows(gameType.charAt(0).toUpperCase() + gameType.slice(1), rows);
            }
          });

          m.sync(promises).then(function () {
            self.save();

            self.ready().resolve();
          });
        }, function () {

          self.ready().reject('coupon-not-found');
        });
      }.bind(this);


      this.sendToSubscription = function () {
        // Tracking for DataLayer
        this.trackingAddToCart('plus');

        const link = this.linkToPlusPurchase?.();

        if (link) {
          const linkArr = link.split('?');
          if (!(linkArr.includes('plus') || linkArr.includes('jackpot'))) linkArr.push('plus');
          linkArr.push('mcGameType=vikinglotto');
          linkArr.push('mcGameId=' + this.id());
          location.href = linkArr.shift() + '?' + linkArr.join('&');
        } else {
          location.href = '/plus-abonnement/se-kurv?plus&mcGameType=vikinglotto&mcGameId=' + this.id();
        }
      }.bind(this);

      this.toJSON = function () {
        return {
          _generatedRows: this._generatedRows(),
          _generatedJokerRowsSaturday: this._generatedJokerRowsSaturday(),
          _generatedJokerRowsWednesday: this._generatedJokerRowsWednesday(),
          couponId: this.couponId(),
          id: this.id(),
          playType: this.playType(),
          purchaseTracked: this.purchaseTracked(),
          numberOfDraws: this.numberOfDraws(),
          rows: this.rows(),
          rowsToGenerate: this.rowsToGenerate(),
          state: this.state(),
          startUrl: this.startUrl(),
          status: this.status(),
          systemName: this.systemName(),
          withJokerSaturday: this.withJokerSaturday(),
          withJokerWednesday: this.withJokerWednesday(),
          isProductDetailsTracked: this.isProductDetailsTracked(),
          isAddToCartTracked: this.isAddToCartTracked(),
          isPurchaseCompleteTracked: this.isPurchaseCompleteTracked(),
          firstDepositInfo: this.firstDepositInfo(),
          plusSubscriptionJackpot: this.plusSubscriptionJackpot(),
          playTogetherDepositType: this.playTogetherDepositType(),
          trackedInteractionCreationAction: this.trackedInteractionCreationAction(),
          drawDays: this.drawDays(),
        };
      }.bind(this);

      // Init:
      this.init = function () {

        // Rebuy coupon:
        if (this.rebuyCouponId() && this.state() === 'new') {
          this.copyFromOldCoupon();

          // Regular coupon:
        } else {
          if (this.state() === 'new') {
            this.addRow();
            this.addRow();

            if (this.playType() === 'Lucky') {
              this.rowsToGenerate(this.rowsToGenerate() || VikingLottoInfo.getLuckyDefaultRowCount());
            }
          }

          this.ready().resolve();
        }

      }.bind(this);

    });

    VikingLottoGame.setupGame = function (options) {
      var game = undefined;

      if (options.gameInstanceId) {
        game = VikingLottoGame.get(options.gameInstanceId);

        if (!game || game.status() === 'completed' || (options.playType && game.playType() !== options.playType)) {
          game = undefined;
        }
      }

      if (!game) {
        game = VikingLottoGame.construct(options, options.state || 'new');
      }

      if (options.linkToPlusPurchase && !game.linkToPlusPurchase) {
        game.linkToPlusPurchase = m.prop(options.linkToPlusPurchase);
      }

      return game;
    };

    // Public functions:
    return VikingLottoGame;

  });
