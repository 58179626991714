defineDs('DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/NumberUtils', () => {


  /**
   * Formats a number as currency.
   * @param {number} [amount=0] - The amount to be formatted as currency.
   * @param {number} [decimals=0] - The number of decimal places to use in the formatting.
   * @returns {string} - The formatted currency string.
   */
  const formatCurrency = (amount = 0, decimals = 0) => {
    amount = Number(amount);
    if (isNaN(amount)) return '-';
    return new Intl.NumberFormat('da-DK', {
      style: 'currency',
      currency: 'DKK',
      minimumFractionDigits: decimals ? decimals : 0,
      maximumFractionDigits: decimals ? decimals : 0,
    }).format(amount);
  };


  /**
   * Formats a number to the Danish decimal format.
   * @param {number} [number=0] The number to be formatted.
   * @param {number} [decimals=0] The number of decimal places to include in the formatted number.
   * @returns {string} The formatted number as a string. If the input is not a valid number, returns '-'.
   */
  const formatNumber = (number = 0, decimals = 0) => {
    number = Number(number);
    if (isNaN(number)) return '-';
    return new Intl.NumberFormat('da-DK', {
      style: 'decimal',
      minimumFractionDigits: decimals ? decimals : 0,
      maximumFractionDigits: decimals ? decimals : 0,
    }).format(number);
  };


  /**
   * Prefixes a number with zeros.
   * @param {number} number - The number to be prefixed.
   * @param {number} [digits=2] - The total number of digits the result should have.
   * @returns {string} The prefixed number as a string.
   */
  const prefixNumberWithZeros = (number, digits = 2) => {
    if (typeof number !== 'number' || digits <= 0) return '';
    let numString = number.toString().padStart(digits, '0');
    return numString;
  };

  return {
    formatCurrency,
    formatNumber,
    prefixNumberWithZeros,
  };
});

