defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/WinningNumbers', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/WinningNumbersUtils',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/NumberGamesDictionary',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/WinningNumbers/DatePicker',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/WinningNumbers/DrawnBalls',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/WinningNumbers/GameSelector',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/WinningNumbers/WinnerTable',
], function (Component, Utils, WinningNumbersUtils, Dictionary, DatePicker, DrawnBalls, GameSelector, WinnerTable) {
  Component('winning-numbers', [Dictionary], function (m, route, { title, subtitle, tableDescription, gameType, ctaText, ctaUrl, hideWinnerIdsFromTable, hideJokerIdsFromTable }, property) {

    const root = {
      controller: function () {
        const d = Dictionary.get.bind({ prefix: 'Components/WinningNumbers/' });
        const completedDrawObjects = m.prop();
        const completedDrawsDates = m.prop([]);
        const completedDrawsDatesJoker = m.prop([]);
        const selectedDrawDate = m.prop();
        const selectedDrawDateJoker = m.prop();
        const gameTypeSelected = m.prop(gameType);
        const gameVersion = m.prop(1);
        const prizesLotto = m.prop([{ amount: 0 }]);
        const prizesJoker = m.prop([]);
        const winningNumbers = m.prop([]);
        const winningBonusNumbers = m.prop([]);
        const winningJokerNumbers = m.prop([]);
        const showJokerNumbers = m.prop(false);
        const latestDrawDate = m.prop();

        hideWinnerIdsFromTable = hideWinnerIdsFromTable.split(',').map((num) => Number(num));
        hideJokerIdsFromTable = hideJokerIdsFromTable.split(',').map((num) => Number(num));

        const updateNumbers = ({ numbers, bonusNumbers, jokerNumbers, prizes, jokerPrizes, gameVersionNo }) => {
          if (numbers) winningNumbers(numbers);
          if (bonusNumbers) winningBonusNumbers(bonusNumbers);
          if (jokerNumbers) winningJokerNumbers(jokerNumbers);
          if (prizes) prizesLotto(prizes);
          if (gameVersionNo) gameVersion(gameVersionNo);
          if (jokerPrizes) prizesJoker(jokerPrizes);
          m.redraw();
        };

        const getSelectedDrawDateForEurojackpot = (date) => {
          // Eurojackpot draws happens one day before Joker draws.
          // Let's see if we have such a Eurojackpot date based on the Joker draw date.
          const { eurojackpot } = completedDrawObjects();

          let d = Utils.getDate(date);
          d = Utils.formatDateYearMonthDay(d);
          const dPrev = eurojackpot.drawDates.filter((drawDate) => drawDate < d);

          if (dPrev[0]) {
            return dPrev[0];
          }
        };

        const getSelectedDrawDateForJoker = (gameDrawDate) => {
          if (gameType === 'lotto' || gameType === 'vikinglotto') {
            // Joker draws happen same day as game draws for lotto and vikinglotto.
            return gameDrawDate;
          }

          if (gameType === 'eurojackpot') {
            // Joker draws happens one day after Eurojackpot draws.
            // Let's see if we have such a Joker date based on the Eurojackpot draw date.
            const { joker } = completedDrawObjects();

            // For Eurojackpot, both the tuesday and the friday draw should connect with the
            // wednesday or saturday draw (the day after the draw).
            let oneDayAfterGameDrawDate = Utils.getDate(gameDrawDate);
            oneDayAfterGameDrawDate.setDate(oneDayAfterGameDrawDate.getDate() + 1);
            oneDayAfterGameDrawDate = Utils.formatDateYearMonthDay(oneDayAfterGameDrawDate);

            let jokerDrawDatesOneDayAfterGameDrawDate = joker.drawDates.filter((drawDate) => drawDate.includes(oneDayAfterGameDrawDate));
            if (jokerDrawDatesOneDayAfterGameDrawDate[0]) {
              return jokerDrawDatesOneDayAfterGameDrawDate[0];
            }

            // Since we don't have the Joker draw for the day after the Eurojackpot draw,
            // let's find the last Joker draw before the Eurojackpot draw available.
            let jokerDrawDatesBeforeOneDayAfterGameDrawDate = joker.drawDates.filter((drawDate) => drawDate < oneDayAfterGameDrawDate);

            if (jokerDrawDatesBeforeOneDayAfterGameDrawDate[0]) {
              return jokerDrawDatesBeforeOneDayAfterGameDrawDate[0];
            }
          }
        };

        const getWinningNumbers = (date) => {
          if (gameType === 'lotto') {
            WinningNumbersUtils.getLottoWinningNumbers(date).then(updateNumbers);
          }

          if (gameType === 'vikinglotto') {
            WinningNumbersUtils.getVikinglottoWinningNumbers(date).then(updateNumbers);
          }

          if (gameType === 'eurojackpot') {
            WinningNumbersUtils.getEurojackpotWinningNumbers(date, completedDrawObjects).then(updateNumbers);
            selectedDrawDateJoker(getSelectedDrawDateForJoker(date));

            if (selectedDrawDateJoker()) {
              WinningNumbersUtils.getEurojackpotJokerWinningNumbers(selectedDrawDateJoker(), completedDrawObjects).then(updateNumbers);
            } else {
              updateNumbers({
                jokerNumbers: [],
                jokerPrizes: [],
              });
            }
          }
        };

        const selectDrawDate = (date) => {
          selectedDrawDate(date);
          selectedDrawDateJoker(date);
          getWinningNumbers(date);

          if (latestDrawDate() !== date) {
            WinningNumbersUtils.setDrawDateToUrlParam(date);
          } else {
            WinningNumbersUtils.setDrawDateToUrlParam();
          }

          m.redraw();
        };

        const selectDrawDateJoker = (date) => {
          selectedDrawDateJoker(date);
          WinningNumbersUtils.getEurojackpotJokerWinningNumbers(date, completedDrawObjects).then(updateNumbers);
          selectedDrawDate(getSelectedDrawDateForEurojackpot(date));
          m.redraw();
        };

        const handleGameTypeSelected = (type) => {
          gameTypeSelected(type);
          showJokerNumbers(type === 'joker');
          if (type === 'joker' && gameType === 'eurojackpot') {
            WinningNumbersUtils.getEurojackpotJokerWinningNumbers(selectedDrawDateJoker(), completedDrawObjects).then(updateNumbers);
          } else {
            getWinningNumbers(selectedDrawDate());
          }
          m.redraw();
        };

        const initialize = () => {
          if (gameType === 'lotto') {
            WinningNumbersUtils.getLottoDrawDates().then((drawDates) => {
              completedDrawsDates(drawDates);
              latestDrawDate(drawDates[0]);
              const initDrawDate = WinningNumbersUtils.initializeFirstDrawDate(drawDates);
              selectDrawDate(initDrawDate);
            });
          }

          if (gameType === 'vikinglotto') {
            WinningNumbersUtils.getVikinglottoDrawDates().then((drawDates) => {
              completedDrawsDates(drawDates);
              latestDrawDate(drawDates[0]);
              const initDrawDate = WinningNumbersUtils.initializeFirstDrawDate(drawDates);
              selectDrawDate(initDrawDate);
            });
          }

          if (gameType === 'eurojackpot') {
            WinningNumbersUtils.getEurojackpotDrawDates().then(({ eurojackpot, joker }) => {
              completedDrawObjects({ eurojackpot, joker });
              completedDrawsDates(eurojackpot.drawDates);
              completedDrawsDatesJoker(joker.drawDates);
              latestDrawDate(eurojackpot.drawDates[0]);
              const initDrawDate = WinningNumbersUtils.initializeFirstDrawDate(eurojackpot.drawDates);
              selectDrawDate(initDrawDate);
            });
          }
        };

        initialize();

        return {
          d,
          ctaText,
          ctaUrl,
          gameType,
          completedDrawsDates,
          completedDrawsDatesJoker,
          handleGameTypeSelected,
          hideWinnerIdsFromTable,
          hideJokerIdsFromTable,
          gameTypeSelected,
          gameVersion,
          prizesJoker,
          prizesLotto,
          property,
          selectDrawDate,
          selectDrawDateJoker,
          selectedDrawDate,
          selectedDrawDateJoker,
          showJokerNumbers,
          winningNumbers,
          winningBonusNumbers,
          winningJokerNumbers,
        };
      },

      view: (
        {
          d,
          ctaText,
          ctaUrl,
          gameType,
          completedDrawsDates,
          completedDrawsDatesJoker,
          handleGameTypeSelected,
          hideWinnerIdsFromTable,
          hideJokerIdsFromTable,
          gameTypeSelected,
          gameVersion,
          prizesJoker,
          prizesLotto,
          property,
          selectDrawDate,
          selectDrawDateJoker,
          selectedDrawDate,
          selectedDrawDateJoker,
          showJokerNumbers,
          winningNumbers,
          winningBonusNumbers,
          winningJokerNumbers,
        }
      ) => {

        return m('.winning-numbers', { class: `winning-numbers--${gameType}` }, [
          m('.winning-numbers__select', [

            m('.winning-numbers__header', [
              m('.winning-numbers__title', title),
              m('.winning-numbers__subtitle', subtitle),
            ]),

            gameType !== 'eurojackpot' || (gameType === 'eurojackpot' && !showJokerNumbers())
              ? DatePicker({
                completedDrawsDates,
                gameTypeSelected,
                property,
                selectDrawDate,
                selectedDrawDate,
              }) : '',

            gameType === 'eurojackpot' && showJokerNumbers()
              ? DatePicker({
                completedDrawsDates: completedDrawsDatesJoker,
                gameTypeSelected,
                property,
                selectDrawDate: selectDrawDateJoker,
                selectedDrawDate: selectedDrawDateJoker,
              }) : '',

          ]),

          m('.winning-numbers__result', [

            GameSelector({
              gameType,
              handleGameTypeSelected,
              gameTypeSelected,
              hasJoker: winningJokerNumbers()?.length > 0,
            }),

            !showJokerNumbers()
              ? DrawnBalls({
                gameType,
                numbers: winningNumbers(),
                label: d('Balls/WinningNumbers'),
                bonusNumbers: winningBonusNumbers(),
                bonusLabel: d(`Balls/BonusNumbers/${gameType}`),
                property,
                gameTypeSelected,
                selectedDrawDate,
              })
              : DrawnBalls({
                gameType: 'joker',
                numbers: winningJokerNumbers(),
                label: d('Balls/JokerNumbers'),
                property,
                gameTypeSelected,
                selectedDrawDate: selectedDrawDateJoker,
              }),

            !showJokerNumbers()
              ? WinnerTable({
                d,
                gameTypeSelected,
                gameVersion,
                hideWinnerIds: hideWinnerIdsFromTable,
                prizes: prizesLotto(),
              })
              : WinnerTable({
                d,
                gameTypeSelected,
                gameVersion,
                hideWinnerIds: hideJokerIdsFromTable,
                prizes: prizesJoker(),
              }),

            tableDescription ? m('.winning-numbers__table-description', tableDescription) : '',

            ctaText && ctaUrl
              ? m('.winning-numbers__cta-wrapper', m('a.winning-numbers__cta', {
                href: ctaUrl
              }, ctaText))
              : '',

          ]),
        ]);
      }

    };

    route('/', root);
  });
});
