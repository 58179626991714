defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/Client/Flow/Draws',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Common/Framework/EventHandling/Scripts/Event',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/UIHelpers',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/CheckButton',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/NotificationBox',
    'Shared/Framework/Ensighten/Scripts/Ensighten'
  ],
  function (m, Event, Storage, Utils, UIHelpers, CheckButton, NotificationBox, Ensighten) {

    const Draws = {
      controller: function (args) {
        Object.assign(this, args.controller);

        const infoOverlayIdentifier = m.prop('MultiClient-Draw-InfoOverlay-' + this.gameType + this.gameInstanceId());
        const onConfirmInfoOverlay = () => {
          Storage.set(infoOverlayIdentifier(), true);
        };

        this.showGenericOverlay = () => {
          if (Storage.get(infoOverlayIdentifier())) {
            return;
          }
          this.genericOverlay?.()?.show(onConfirmInfoOverlay);
        };

        this.property('stepGoToNext', (goToNextFlowStep) => {
          this.isStepValid(true);
          goToNextFlowStep();
        });

        this.isStepValid(true);
        this.numberOfDraws = this.game().numberOfDraws;
        this.drawOptions = [...this.game().numberOfDrawsOptions()];
        this.drawRepeatMax = typeof this.infoFeed().getDrawRepeatMax() === 'number' ? this.infoFeed().getDrawRepeatMax() : null;
        this.hasDrawOptionUnavailable = this.drawRepeatMax && this.drawOptions.some((draw) => draw > this.drawRepeatMax);
        this.openDraw = this.infoFeed().getOpenDraw;

        if (this.hasActiveCampaign()) {
          this.hasCampaignInOpenDraw = this.activeCampaignModel().campaignNumbers.some((campaignNumber) => {
            return this.openDraw?.().campaignNumbers?.includes(campaignNumber);
          });

          if (this.hasCampaignInOpenDraw) {
            this.showCampaignInformOverlay();
          }
        }

        // add plus option
        if (!this.drawOptions.includes(0)) {
          this.drawOptions.push(0);
        }

        if (this.gameType === 'Eurojackpot' &&
            this.currentFlowStep() === 'draws' &&
            this.notifications()?.filter((notification) => notification.key === 'ejp_normal').length &&
            !Storage.get(infoOverlayIdentifier())) {
          NotificationBox('ejp_normal', this.notifications(), { asOverlay: true, dismissText: this.mcDictionary('okayButton') }).show(onConfirmInfoOverlay);
        }

        if ((this.campaign?.() || this.hasActiveCampaign()) &&
          !Storage.get(infoOverlayIdentifier())) {
          let genericOverlayDictionary = null;
          if (this.campaign()?.indexOf('Sommerlotto') !== -1) {
            genericOverlayDictionary = {
              className: 'summer-campaign',
              svgUsePath: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/SummerLotto.svg#printer_ticket',
              header: this.mcDictionary('campaignInformOverlay/title'),
              body: this.mcDictionary('campaignInformOverlay/body'),
              dismiss: this.mcDictionary('campaignInformOverlay/confirmButton')
            };
          }

          if (genericOverlayDictionary) {
            this.genericOverlayDictionary(genericOverlayDictionary);
          }
        }

        if (this.gameType === 'Lotto') {
          if (this.openDraw?.()?.gameVersionNo > 2 &&
              this.openDraw?.()?.campaignNumbers?.includes(-1)) {
            const extraCampaignOverlayIdentifier = 'extraCampaignOverlayIdentifier';

            const onConfirm = () => {
              Event.fire('ds.event.accordionInfoOverlay:show', {
                key: 'productchange'
              });
              Storage.set(extraCampaignOverlayIdentifier, true);
              Ensighten.pushGaEvent('onboarding_popup', 'cta_click', this.d('Campaigns/ExtraCampaigns/InformOverlay/ConfirmButton'));
            };

            const onClose = () => {
              Ensighten.pushGaEvent('onboarding_popup', 'closed', '');
            };

            if (!Storage.get(extraCampaignOverlayIdentifier)) {
              Event.fire('ds.event.accordionInfoOverlay:show', {
                key: 'readmore',
                onConfirm,
                onClose
              });

              Ensighten.pushGaEvent('onboarding_popup', 'shown', '');
            }
          }
        }

        if (this.campaignExtraDraw) {
          const lastDrawOption = this.game().numberOfDrawsOptions().at(-1);
          const extraDrawOption = lastDrawOption + 1;
          if (!this.drawOptions.includes(extraDrawOption)) {
            this.drawOptions.splice(this.drawOptions.length - 1, 0, extraDrawOption);
          }
        }
        if (!this.drawOptions.includes(this.numberOfDraws())) {
          this.drawOptions.splice(this.drawOptions.length - 1, 0, this.numberOfDraws());
        }

        // format draw options:
        this.drawOptionsFormatted = m.prop([]);
        for (let drawIndex = 0; drawIndex < this.drawOptions.length; drawIndex++) {
          const draw = this.drawOptions[drawIndex];
          const unavailableDraw = this.drawRepeatMax !== null && draw > this.drawRepeatMax;
          const feedDraw = this.game().drawDatesWithoutJokerInfo(draw);

          const hasOpenDrawInCampaign = this.openDraw?.().campaigns?.includes(this.campaign?.());
          const hasCampaign = (drawItem) => {
            if (draw === 0) {
              return;
            }
            if (this.hasActiveCampaign()) {
              return this.activeCampaignModel().campaignNumbers.some((campaignNumber) => {
                return drawItem.campaignNumbers.includes(campaignNumber);
              });
            }
            return drawItem.campaigns?.some((campaign) => {
              return (campaign.indexOf(this.campaign()) !== -1);
            });
          };

          const hasAllDrawsInCampaign = () => {
            if (!this.campaign?.()) return;
            return feedDraw.every((drawItem) => {
              return hasCampaign(drawItem);
            });
          };

          const hasSomeDrawsInCampaign = () => {
            if (!this.hasActiveCampaign()) {
              if (!this.campaign?.()) return;
            }
            return feedDraw.some((drawItem) => {
              return hasCampaign(drawItem);
            });
          };

          const hasSomeDrawsInExtraDrawCampaign = this.campaignExtraDraw && hasSomeDrawsInCampaign();

          const drawDatesDetails = (drawDates, isUnavailableDraw) => {
            const formattedDates = [];
            for (let index = 0; index < drawDates.length; index++) {
              const date = drawDates[index].draw;
              let withCampaign = drawDates[index].campaigns && this.campaign;
              if (withCampaign) {
                withCampaign = drawDates[index].campaigns.some((campaign) => {
                  return campaign.indexOf(this.campaign()) !== -1;
                });
              }
              if (drawDates[index].campaignNumbers?.length &&
                  this.hasActiveCampaign()) {
                withCampaign = this.activeCampaignModel().campaignNumbers.some((campaignNumber) => {
                  return drawDates[index].campaignNumbers.includes(campaignNumber);
                });
              }
              let icon = '';
              if (withCampaign) {
                if (this.campaign()?.indexOf('Sommerlotto') !== -1) {
                  icon = m('svg.draw-date__icon', m('use', { href: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/SummerLotto.svg#ticket' }));
                }

                if (this.hasActiveCampaign()) {
                  icon = m('svg.draw-date__icon', m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#campaign_coupon' }));
                }
              }
              const dateNode = m('.draw-date', {
                class: isUnavailableDraw && (this.drawRepeatMax !== null && index >= this.drawRepeatMax) ? ' unavailable' : '',
              }, [
                Utils.getLongDate(date, true),
                icon
              ]);
              formattedDates.push(dateNode);
            }
            return formattedDates;
          };

          const onSelect = (option) => {
            Event.fire('multi-client:draws-step-on-select', option);
            const value = typeof option === 'object' ? option.value : option;
            if (option.isDisabled) {
              return;
            }
            this.game().setNumberOfDraws(value);
            this.game().verticalType(value === 0 ? 'plus' : undefined);

            if (value === 0 ||
                hasSomeDrawsInExtraDrawCampaign) {
              this.game().setUpsellExtra?.(null);
            }

            if (this.hasActiveCampaign() &&
                this.activeCampaignModel().showOnboarding &&
                option.hasSomeDrawsInCampaign) {
              this.showCampaignInformOverlay();
            }
          };

          const label = () => {
            if (draw === 0) {
              return this.mcDictionary('draws/subscriptionOptionLabel');
            }
            if (hasSomeDrawsInExtraDrawCampaign) {
              if (drawIndex === 0 &&
                  this.openDraw?.().drawType?.toLowerCase() === 'extra') {
                return this.d('Campaigns/ChristmasMultiClient/DrawsStep/OnlyCampaignDrawLabel');
              }
              const _draw = draw - 1 || 1;
              return _draw > 1 ? this.d('Campaigns/ChristmasMultiClient/DrawsStep/MultiDrawsLabel', { count: _draw }) : this.d('Campaigns/ChristmasMultiClient/DrawsStep/SingleDrawLabel', { count: _draw });
            }
            return draw > 1 ? this.mcDictionary('draws/multiDrawsLabel', { count: draw }) : this.mcDictionary('draws/singleDrawLabel', { count: draw });
          };

          const sublabel = () => {
            const showSublabel = () => {
              if (this.hasActiveCampaign()) {
                const showSublabel = this.activeCampaignModel().campaignType === 'Accumulating' && hasSomeDrawsInCampaign();
                return showSublabel;
              }

              if (this.campaign?.()?.indexOf('JuleLotto') > -1) {
                if (hasOpenDrawInCampaign && !hasAllDrawsInCampaign()) {
                  return false;
                }

                return hasSomeDrawsInCampaign();
              }
              return hasAllDrawsInCampaign();
            };
            if (showSublabel()) {
              const campaignTicketsCount = feedDraw.reduce((count, drawItem) => {
                return hasCampaign(drawItem) ? ++count : count;
              }, 0);
              if (campaignTicketsCount === 0) {
                return this.mcDictionary('draws/drawSublabelNoTickets');
              }
              const tickets = campaignTicketsCount * this.game().getRowAmount();
              const ticketsPerWeek = campaignTicketsCount > 0 ? this.game().getRowAmount() : 0;
              let dictionaryPath = 'draws/drawSublabel';
              if (draw === 1) {
                dictionaryPath += 'SingleWeek';
              }
              if (ticketsPerWeek === 1) {
                dictionaryPath += 'SingleTicket';
              }
              const subtitleVariables = () => {
                const variables = { tickets, ticketsPerWeek };

                if (this.hasActiveCampaign()) {
                  variables.campaignName = this.activeCampaignModel().name;
                }

                return variables;
              };
              return this.mcDictionary(dictionaryPath, subtitleVariables());
            }
          };

          const details = () => {
            if (draw !== 0 &&
                this.gameType === 'Eurojackpot') {
              return drawDatesDetails(feedDraw, unavailableDraw);
            }
          };

          const iconIndicator = () => {
            const indicatorIcon = () => {
              const drawOptionIndicatorIcon = this.drawOptionIndicatorIcon?.() ||
                '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#campaign_coupon';
              return m('svg.icon', {
                key: drawOptionIndicatorIcon
              },
              m('use', { href: drawOptionIndicatorIcon })
              );
            };

            if (this.hasActiveCampaign()) {
              if (hasSomeDrawsInCampaign()) {
                return indicatorIcon();
              }
            }
          };

          const _option = {
            label: label(),
            sublabel: sublabel(),
            value: draw,
            isDisabled: unavailableDraw,
            showDetails: drawIndex < 2,
            details: details(),
            iconIndicator: iconIndicator
          };

          if (this.hasActiveCampaign()) {
            _option.hasSomeDrawsInCampaign = hasSomeDrawsInCampaign();
          }

          this.drawOptionsFormatted().push({ _option, onSelect });
        }

        if (this.currentFlowStep() === 'draws') {
          Event.fire('multi-client:draws-step-loaded', this);
        }
      },

      view: function (controller) {
        const drawOptionColumns = controller.drawOptionsFormatted().map((drawOption) => {
          const isSelected = drawOption._option.value === controller.numberOfDraws();
          return CheckButton(drawOption._option, drawOption.onSelect, isSelected);
        });

        return m('.multi-client__flow-step-inner', [
          m('.multi-client__flow-step-header', [
            m('.multi-client__flow-step-title', controller.mcDictionary('draws/title')),
            m('.multi-client__flow-step-subtitle', controller.mcDictionary('draws/subtitle'))
          ]),
          UIHelpers.columnDivider(drawOptionColumns, {
            className: 'multi-client-draw-options ' + Utils.formatNaming(controller.gameType, 'lowercase')
          }),
          controller.hasDrawOptionUnavailable ? m('.multi-client__flow-step-description', m.trust(controller.d('MultiClient/DrawsStep/PurchaseNotification', { WEEKS: controller.drawRepeatMax }))) : null
        ]);
      }

    };

    return Draws;
  });
