defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotApi',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotUtils'
  ],
  function (m, ApiRequest, EurojackpotUtils) {

    // Variables:
    var baseURL = '/dlo/scapi/danskespil/numbergames/eurojackpot';
    var baseJokerURL = '/dlo/scapi/danskespil/numbergames/joker';

    // Functions:
    var prepareParamString = EurojackpotUtils.prepareParamString;

    var createCoupon = function (data) {
      var deferred = m.deferred();

      var req = ApiRequest({
        method: 'POST',
        url: baseURL + '/coupon',
        data: data
      });

      req.then(function (response) {
        response = response || {};

        if (response && !response.Message) {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      }, function (response) {
        response = response || { Message: 'ERROR_NOT_GIVEN' };

        deferred.reject(response);
      });

      return deferred.promise;
    };

    var getEurojackpotInfo = function () {
      return ApiRequest({
        method: 'GET',
        url: baseURL + '/gameInformation'
      });
    };

    var getCompletedDrawDates = function (options) {
      var params = prepareParamString(options);

      return ApiRequest({
        method: 'GET',
        url: baseURL + '/completedDrawDates' + params,
        localCacheTTL: 60,
        unwrapSuccess: function (response) {
          return response || [];
        }
      });
    };

    var getEurojackpotWinningNumbers = function (options) {
      var params = prepareParamString(options);

      return ApiRequest({
        method: 'GET',
        url: baseURL + '/winningNumbers' + params,
        localCacheTTL: 60,
        unwrapSuccess: function (response) {
          return response;
        }
      });
    };


    var getJokerWinningNumbers = function (options) {
      var params = prepareParamString(options);
      var endpointUrl;

      if (options.jokerType === 'JokerSaturday') {
        endpointUrl = baseJokerURL + 'saturday/winningNumbers' + params;
      } else {
        endpointUrl = baseJokerURL + 'wednesday/winningNumbers' + params;
      }

      return ApiRequest({
        method: 'GET',
        url: endpointUrl,
        localCacheTTL: 60,
        unwrapSuccess: function (response) {
          return response;
        }
      });
    };

    var getRandomNumbers = function (options) {
      var params = prepareParamString(options);

      return ApiRequest({
        method: 'POST',
        url: baseURL + '/random' + params,
        background: true,
        unwrapSuccess: function (response) {
          var rows = [];

          if (response && response.rows) {
            response.rows.map(function (row) {
              rows.push({ numbers: row.numbers, starNumbers: row.starNumbers });
            });
          }

          return rows;
        }
      });
    };

    var getSystemKeys = function (systemName) {
      var deferred = m.deferred();
      var params = prepareParamString({ systemName: systemName });

      ApiRequest({
        method: 'GET',
        url: baseURL + '/gameSystemKeys' + params,
        localCacheTTL: 60 * 60 * 24 * 30, // 30 days
        unwrapSuccess: function (response) {
          return response;
        }
      }).then(function (data) {
        if (data.keys) {
          deferred.resolve(data.keys);
        } else {
          deferred.reject();
        }
      }, function () {
        deferred.reject();
      });

      return deferred.promise;
    };

    var getSystemWinningProbabilities = function (systemName) {
      var deferred = m.deferred();
      var params = prepareParamString({ systemName: systemName });

      ApiRequest({
        method: 'GET',
        url: baseURL + '/gameSystemwinningprobabilities' + params,
        localCacheTTL: 60 * 60 * 24, // 1 day
        unwrapSuccess: function (response) {
          return response;
        }
      }).then(function (data) {
        if (data) {
          deferred.resolve(data);
        } else {
          deferred.reject();
        }
      }, function (error) {
        deferred.reject(error);
      });

      return deferred.promise;
    };

    var getCoupon = function (couponId) {
      var options = { couponId: couponId };
      var params = prepareParamString(options);

      return ApiRequest({
        method: 'GET',
        url: baseURL + '/coupon' + params
      });
    };

    var getRandomJokerNumbers = function (type, options) {
      var params = prepareParamString(options);

      return ApiRequest({
        url: baseJokerURL + type.toLowerCase() + '/random' + params,
        unwrapSuccess: function (response) {
          var rows = [];

          if (response && response.length > 0) {
            for (var i = 0; i < response.length; i++) {
              rows.push(response[i].split(''));
            }
          }

          return rows;
        }
      });
    };

    var getSubscribeReminder = function () {
      return ApiRequest({
        method: 'GET',
        url: baseURL + '/subscribeReminder'
      });
    };

    var setSubscribeReminder = function (subscribe) {
      return ApiRequest({
        method: 'POST',
        url: baseURL + '/subscribeReminder?subscribe=' + subscribe
      });
    };

    // Public functions:
    return {
      createCoupon: createCoupon,
      getCompletedDrawDates: getCompletedDrawDates,
      getEurojackpotInfo: getEurojackpotInfo,
      getEurojackpotWinningNumbers: getEurojackpotWinningNumbers,
      getJokerWinningNumbers: getJokerWinningNumbers,
      getSystemWinningProbabilities: getSystemWinningProbabilities,
      getRandomNumbers: getRandomNumbers,
      getSystemKeys: getSystemKeys,
      getCoupon: getCoupon,
      getRandomJokerNumbers: getRandomJokerNumbers,
      getSubscribeReminder: getSubscribeReminder,
      setSubscribeReminder: setSubscribeReminder,
    };

  });
