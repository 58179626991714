defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/StatisticsModule/Graph',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render'
  ],
  function (m, Render) {

    // Template:
    var Graph = function (controller) {

      // Variables:
      var gameType = controller.selectedGameClient();
      var numbersType = controller.property('numbersType');
      var render = Render();
      var selectedPoints = controller.property('selectedPoints');
      var statisticsFeed = controller.statisticsNumbersFeed || [];

      // Functions:
      var pointSelected = function (pointIndex) {
        return function () {
          if (!selectedPoints[gameType]) {
            var gameObject = selectedPoints;

            gameObject[gameType] = { numbers: {}, extraNumbers: {} };
          }

          if (!selectedPoints[gameType][numbersType][pointIndex]) {
            var numberObject = selectedPoints[gameType][numbersType];

            numberObject[pointIndex] = statisticsFeed[pointIndex].number;
          } else {
            delete controller.property('selectedPoints')[gameType][numbersType][pointIndex];

            if (Object.keys(controller.property('selectedPoints')[gameType]['numbers']).length == 0 && Object.keys(controller.property('selectedPoints')[gameType]['extraNumbers']).length == 0) {
              delete controller.property('selectedPoints')[gameType];
            }
          }
        };
      };

      var pointHovered = function (pointIndex) {
        return function () {
          controller.property('hoveredPoint', pointIndex);
        };
      };

      var pointUnhovered = function () {
        return function () {
          controller.property('hoveredPoint', -1);
        };
      };

      // Render:
      render.ready.then(function (nodes) {
        var currentPosition = controller.property('currentPosition');

        controller.property('graphSlideToLeft', -currentPosition <= 0 ? false : true);
        controller.property('graphSlideToRight', -currentPosition >= controller.property('largeBarsWrapperWidth') - controller.windowWidth ? false : true);
        controller.property('largeBarWidth', nodes['large-bar'][0].$element.offsetWidth);
      });

      // Return:
      return m('div', { class: 'statistics-module-large-view', config: render.depend('statistics-module-large-view') }, [
        m('div', { class: 'statistics-module-large-internal-wrapper', config: controller.render().depend('large-bars-wrapper') }, [
          statisticsFeed.map(function (point, index) {
            if (point.drawnCount === 0 && controller.property('filterZeroToggle')) {
              return;
            }

            return [m('div', { class: 'statistics-large-bar-wrapper' + (selectedPoints[gameType] ? (selectedPoints[gameType][numbersType][index] ? ' selected' : '') : '') + (numbersType == 'extraNumbers' ? ' special' : ''), config: render.depend('large-bar'), onclick: pointSelected(index), onmouseover: pointHovered(index), onmouseout: pointUnhovered(index) }, [
              m('div', { class: 'bar' + (point.drawnCount === controller.mostDrawnCount ? ' most-drawn' : '') + (point.drawnCount === controller.leastDrawnCount ? ' least-drawn' : ''), style: (controller.isMobile() ? 'width:' : 'height:') + (point.drawnCount / (controller.mostDrawnCount / 100) || 0) + '%' }, [
                m('div', { class: 'amount' }, point.drawnCount),
                point.drawnCount === controller.mostDrawnCount && controller.mostDrawnCount > 0 ? [
                  m('svg', { class: 'icon-most-drawn' }, [
                    m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesStatisticsModuleIcons.svg#icon-highest-arrow' })
                  ])
                ] : null,
                point.drawnCount === controller.leastDrawnCount ? [
                  m('svg', { class: 'icon-least-drawn' }, [
                    m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesStatisticsModuleIcons.svg#icon-lowest-arrow' })
                  ])
                ] : null
              ]),
              m('div', { class: 'number' }, point.number)
            ])];
          })
        ]),
        m('div', { class: 'statistics-module-graph-slide-button right' + (controller.property('graphSlideToRight') ? ' active' : ''), onclick: controller.slideGraphSlide('right') }, [
          m('svg', { class: 'icon' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-right-arrow' })
          ])
        ]),
        m('div', { class: 'statistics-module-graph-slide-button left' + (controller.property('graphSlideToLeft') ? ' active' : ''), onclick: controller.slideGraphSlide('left') }, [
          m('svg', { class: 'icon' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-left-arrow' })
          ])
        ])
      ]);
    };

    // Public functions:
    return Graph;

  });
