defineDs('DanskeSpil/Framework/NumberGames/Scripts/Templates/SelectDropdown',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'Common/Framework/EventHandling/Scripts/OutsideClick',
    'Shared/Framework/Mithril/Scripts/Helpers/DOMUtils',
  ],
  function (m, Render, OutsideClick, DOMUtils) {

    // Variables:
    var dropdownActive = false;

    // View:
    var SelectDropdown = function (controller, options, selected, select) {

      // Elements:
      var $selectDropdown = m.prop();
      var $selectPlaceholder = m.prop();
      var $selectOptions = m.prop();
      var $selectOptionsList = m.prop();
      var $selectOptionsItem = m.prop();

      // Variables:
      var render = Render();

      // Functions:
      var selectOption = function (value) {
        return function () {
          select(value);
        };
      };

      const setScrollTop = (element, value) => {
        if (!element) return;
        element.scrollTop = value;
      };


      var toggleSelect = function () {
        var $wrapper = $selectDropdown();
        var $placeholder = $selectPlaceholder();
        var placeholderHeight = DOMUtils.calculateElementOuterHeight($placeholder);
        var $selectOptionsBox = $selectOptions();
        var $optionsList = $selectOptionsList();
        var $optionsItem = $selectOptionsItem();
        var dropdownListHeight = DOMUtils.calculateElementOuterHeight($optionsList);
        var dropdownHeight = dropdownListHeight + placeholderHeight;
        var itemHeight = $optionsItem ? DOMUtils.calculateElementOuterHeight($optionsItem) : 0;
        var selectPosition = DOMUtils.getElementOffset($placeholder).top - window.scrollY; // eslint-disable-line compat/compat
        var possibleMaxHeight = 0;
        var windowHeight = window.innerHeight;

        dropdownActive = !dropdownActive;

        if (dropdownActive) {
          $wrapper.classList.remove('reversed');
        }

        if (dropdownHeight > windowHeight - selectPosition && dropdownActive) {
          if (windowHeight - selectPosition - placeholderHeight > itemHeight * 3) {
            $selectOptionsBox.classList.add('overflowed');
            setScrollTop($selectOptionsBox, 0);
            $wrapper.classList.remove('reversed');

            possibleMaxHeight = windowHeight - selectPosition - placeholderHeight;
          } else {
            $wrapper.classList.add('reversed');

            if (dropdownListHeight > selectPosition) {
              possibleMaxHeight = selectPosition;
            } else {
              possibleMaxHeight = dropdownListHeight + 10;
            }

            if (possibleMaxHeight < dropdownListHeight) {
              $selectOptionsBox.classList.add('overflowed');
              setScrollTop($selectOptionsBox, dropdownListHeight - possibleMaxHeight);
            }
          }
        } else {
          possibleMaxHeight = dropdownListHeight + 10;

          $selectOptionsBox.classList.remove('overflowed');
        }

        $wrapper.classList.toggle('is-active', dropdownActive);

        $selectOptionsBox.style.maxHeight = (dropdownActive ? possibleMaxHeight - 10 : 0) + 'px';

      };
      // Renders:
      render.ready.then(function (nodes) {

        $selectDropdown((nodes['select-dropdown'].$element));
        $selectPlaceholder((nodes['select-placeholder'].$element));
        $selectOptions((nodes['select-options'].$element));
        $selectOptionsList((nodes['select-options-list'].$element));

        if (nodes['select-options-item']) {
          var $options = [];

          for (var i = 0; i < nodes['select-options-item'].length; i++) {
            $options.push(nodes['select-options-item'][i].$element); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          }

          $selectOptionsItem(($options));
        }

        OutsideClick(nodes['select-dropdown'].$element, nodes['select-dropdown'].context, function () {
          $selectDropdown().classList.toggle('is-active', false);
          $selectOptions().style.maxHeight = 0;

          dropdownActive = dropdownActive || false;
        });
      });

      // View:
      return m('div', { class: 'select-dropdown', config: render.depend('select-dropdown'), onclick: toggleSelect }, [
        m('span', { class: 'cs-placeholder', config: render.depend('select-placeholder') }, [
          selected() ? selected().name : controller.d('SelectDropdown/Placeholder'),
          m('svg', { class: 'select-dropdown-arrow' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-down-arrow' })
          ])
        ]),
        m('div', { class: 'select-options', config: render.depend('select-options') }, [
          m('ul', { config: render.depend('select-options-list') }, [
            options().map(function (option) {
              return m('li', { config: render.depend('select-options-item') }, [
                m('span', { onclick: selectOption(option) }, option.name)
              ]);
            })
          ])
        ])
      ]);
    };

    // Public functions:
    return SelectDropdown;

  });
