defineDs('DanskeSpil/Domain/NumberGames/Scripts/NonMithril/TopSpot',
  [
    'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo'
  ],
  function (JackpotInfo) {
    var $topSpots = document.querySelectorAll('.js-top-spot');

    var puljeFeedValueHasError = function (rawValue, replacedValue) {
      // if pulje is undefined, eg. {foobar}, JackpotInfo will return an empty string
      if (rawValue !== '' && replacedValue === '') {
        return true;
      }

      // if puljefeed is down, JackpotInfo will return the rawValue
      if ((replacedValue).match(/{.*?}/)) {
        return true;
      }

      // everything seems to be fine
      return false;
    };

    for (var i = 0; i < $topSpots.length; i++) {
      var $topSpot = $topSpots[i];

      this.$video = $topSpot.querySelector('.video'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

      this.$setVideoState = function () {
        this.$video.classList.remove('is-loading');
        this.$video.classList.add('can-playthrough');
      }.bind(this);

      // Context:
      if (this.$video) {
        this.$video.addEventListener('canplaythrough', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          this.$setVideoState();
        }.bind(this), false);

        // If the video is in the cache of the browser,
        // the 'canplaythrough' event might have been triggered
        // before we registered the event handler.
        if (this.$video.readyState > 3) {
          this.$setVideoState();
        }
      }

      if (!window.sitecoreEnv) {
        // Look for a handlebar in the text, if it contains a for example {lottosaturday} replace it with corresponding feed
        var $showFallbackAttr = $topSpot.getAttribute('data-show-fallback'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        var shouldShowFallBack = $showFallbackAttr && $showFallbackAttr.toLowerCase() === 'true'; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

        var h1 = $topSpot.querySelector('.js-top-spot-title'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        if (h1) {
          var h1RawValue = h1.getAttribute('data-replace-value').trim();
          var h1ReplacedValue = JackpotInfo.getDynamicFeed(h1RawValue).trim();
          var h1HasError = puljeFeedValueHasError(h1RawValue, h1ReplacedValue);
        }

        var h3 = $topSpot.querySelector('.js-top-spot-preface'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        if (h3) {
          var h3RawValue = h3.getAttribute('data-replace-value').trim();
          var h3ReplacedValue = JackpotInfo.getDynamicFeed(h3RawValue).trim();
          var h3HasError = puljeFeedValueHasError(h3RawValue, h3ReplacedValue);
        }

        if (h1 && h3) {
          if (shouldShowFallBack || h1HasError || h3HasError) {
            h1.innerHTML = h1.getAttribute('data-fallback-value');
            h3.innerHTML = h3.getAttribute('data-fallback-value');
          } else {
            h1.innerHTML = h1ReplacedValue;
            h3.innerHTML = h3ReplacedValue;
          }
        }
      }
    }
  }
);
