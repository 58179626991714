defineDs('DanskeSpil/Domain/NumberGames/Scripts/NonMithril/LottieTopSpot', [
  'Common/Framework/EventHandling/Scripts/CrossWindowEvents',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
  'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
], (CrossWindowEvents, Ensighten, JackpotInfo, EurojackpotInfo, LottoInfo, VikingLottoInfo) => {

  let prevDevice;

  const getDevice = () => {
    let device = 'mobile';
    if (window.matchMedia('(min-width: 1440px)').matches) {
      device = 'desktop';
    } else if (window.matchMedia('(min-width: 768px)').matches) {
      device = 'tablet';
    }
    return device;
  };

  const addLottie = async (options) => {
    const device = getDevice();
    if (options?.isInit && !options.isInit?.()) {
      options.isInit(true);
      prevDevice = null;
    }

    let src;
    const _$parent = options?.$parent?.querySelector('.lottie-top-spot__lottie'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    if (!_$parent?.dataset?.settings) {
      return;
    }
    const player = _$parent.querySelector('lottie-player');
    if (!player) {
      return;
    }
    const settings = _$parent.dataset.settings;
    const { lottieBackgroundSmall, lottieBackgroundMedium, lottieBackgroundLarge } = JSON.parse(settings);

    if (device === 'desktop') src = lottieBackgroundLarge;
    if (device === 'tablet') src = lottieBackgroundMedium;
    if (device === 'mobile') src = lottieBackgroundSmall;

    if (src) {
      try {
        const responseLottie = await fetch(src);
        if (!responseLottie.ok) {
          throw new Error(responseLottie.statusText);
        }
        const strLottie = JSON.stringify(await responseLottie.json());
        player.load(strLottie);
      } catch {
        player.load({});
      }
    } else {
      player.load({});
    }
  };

  const setupLottie = async (options) => {
    await addLottie(options);
    window.addEventListener('resize', () => {
      const device = getDevice();
      if (device === prevDevice) return;
      prevDevice = device;
      addLottie(options);
    });
  };

  const addTrackingToCta = (options) => {
    const _$parent = options?.$parent;
    _$parent?.querySelectorAll('.lottie-top-spot__cta')?.forEach(($cta) => {
      $cta.addEventListener('click', (event) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        event.preventDefault();
        Ensighten.pushGaEventOnPageRedirect('top_spot', 'cta_click', event.target.text);
        setTimeout(() => {
          window.location = event.target.href;
        }, 100);
      });
    });
  };

  const searchAndReplaceVariables = (dataMap, keySuffix) => {
    const $elements = document.querySelectorAll('.lottie-top-spot__header, .lottie-top-spot__description');
    if ($elements.length) {
      $elements.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        let elementText = $element.innerHTML.toString();

        Object.keys(dataMap).forEach(function (key) {
          elementText = elementText.replace(`{${key}${keySuffix}}`, dataMap[key]);
        });

        $element.innerHTML = elementText;
      });
    }
  };

  const initialize = () => {
    if (document.querySelector('.mode-edit')) return;

    JackpotInfo.ready.then(() => {
      // Helper function to format Joker jackpot
      const formatJoker = (feed) => {
        let feedText = feed;
        if (!feedText || feedText === '0') {
          feedText = '<span>mindst</span> 2'; // at least 2 mio in the jackpot
        }
        return feedText;
      };

      // Prepare data for replacement:
      const jackpotData = {
        vikinglotto: JackpotInfo.getByGame('vikinglotto').poolSizeFormatted || 'min. 19',
        lotto: JackpotInfo.getByGame('lotto').poolSizeFormatted || 'min. 7',
        eurojackpot: JackpotInfo.getByGame('eurojackpot').poolSizeFormatted || 'min. 63',
        jokerloerdag: formatJoker(JackpotInfo.getByGame('jokerloerdag').poolSizeFormatted),
        jokeronsdag: formatJoker(JackpotInfo.getByGame('jokeronsdag').poolSizeFormatted)
      };
      searchAndReplaceVariables(jackpotData, 'PoolSize');
    });

    Promise.all([
      LottoInfo.ready,
      VikingLottoInfo.ready,
      EurojackpotInfo.ready,
    ]).then(() => {
      const closingTimeData = {
        lotto: LottoInfo.data()?.lottoSaturday?.openDraw?.closingTime,
        vikinglotto: VikingLottoInfo.data()?.openDraw?.closingTime,
        eurojackpot: EurojackpotInfo.data()?.openDraw?.closingTime
      };

      const closingTimeDays = {};
      Object.keys(closingTimeData).forEach((key) => {
        if (closingTimeData[key]) {
          const _date = new Date(closingTimeData[key]);
          closingTimeDays[key] = _date.toLocaleString('da-dk', { weekday: 'long' });
        }
      });

      searchAndReplaceVariables(closingTimeDays, 'DrawDay');
    });

    const $spots = document.querySelectorAll(':not(.js-initialize-on-subscribe) > .lottie-top-spot');
    if ($spots.length) {
      $spots.forEach(($spot) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        const options = {
          $parent: $spot
        };
        setupLottie(options);
        addTrackingToCta(options);
      });
    }
  };

  CrossWindowEvents.subscribe('ds.event.lottietopspot:initialize', (options) => {
    if (!options) return;
    if (!options.$parent.hasChildNodes?.()) return; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

    setupLottie(options);
    addTrackingToCta(options);
  });

  initialize();

});
