defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoWinningNumbers/Drawing',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoWinningNumbers/Numbers',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoWinningNumbers/Results'
  ],
  function (m, Render, Numbers, Results) {

    // Template:
    var Drawing = function (controller, data, type) {

      var render = Render();

      var brand = {
        vikinglotto: {
          logo: function () {
            return m('svg', { class: 'vikinglotto-logo' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#icon-viking-lotto-logo' })
            ]);
          },
          class: 'vikinglotto-winning-numbers'
        },
        joker: {
          logo: function () {
            return [m('svg', { class: 'joker-hat' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#icon-joker-hat' })
            ]),
            m('svg', { class: 'joker-logo' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#icon-joker-text-logo' })
            ]),
            m('span', { class: 'joker-text' }, controller.d('WinningNumbers/WednesdayJokerText'))
            ];
          },
          class: 'joker-winning-numbers'
        }
      };

      render.ready.then(function (nodes) {
        var featuredWinners = nodes['featured-winners'];

        if (!featuredWinners.rendered) {
          var $element = featuredWinners.$element;

          $element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            $element.classList.toggle('hide');
          });
        }
      });

      var featuredWinners = function (winners, game) {
        if (!winners) return null;

        return m('div', { class: 'featured-winners hide', config: render.depend('featured-winners') }, [
          m('div', { class: 'header' }, [
            controller.d('WinningNumbers/FeaturedWinners/' + game),
            m('svg', { class: 'icon-arrow' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-up-arrow' })
            ])
          ]),
          m('div', { class: 'text' }, [
            m.trust(winners)
          ])
        ]);
      };

      // Return:
      return type == 'vikinglotto' ? // VIKINGLOTTO
        m('div', { class: [
          'winning-numbers-element',
          brand.vikinglotto.class,
          (data.checkViewport && typeof data.checkViewport === 'function') ? (data.checkViewport(brand.vikinglotto.class) ? 'winning-numbers-element--fade' : '') : ''
        ].join(' ')
        }, [
          m('div', { class: 'logo' }, [
            brand.vikinglotto.logo()
          ]),
          m('div', { class: 'numbers' }, [
            Numbers(controller, data.winningNumbers),
            Numbers(controller, data.bonusNumbers, 'bonus'),
          ]),
          m('div', { class: 'results' }, [
            Results(controller, data.prizes)
          ]),
          featuredWinners(data.winners, controller.game())
        ])
        : // JOKER
        m('div', { class: [
          'winning-numbers-element',
          brand.joker.class,
          (data.checkViewport && typeof data.checkViewport === 'function') ? (data.checkViewport(brand.joker.class) ? 'winning-numbers-element--fade' : '') : ''
        ].join(' ')
        }, [
          m('div', { class: 'logo' }, [
            brand.joker.logo()
          ]),
          m('div', { class: 'numbers' }, [
            Numbers(controller, data.winningNumbers, 'joker')
          ]),
          m('div', { class: 'results' }, [
            Results(controller, data.prizes, 'joker')
          ]),
          featuredWinners(data.winners, controller.jokerGame())
        ]);
    };

    // Public functions:
    return Drawing;

  });
