defineDs('DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils', [
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/DateUtils',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/NumberUtils',
], function (DateUtils, NumberUtils) {

  // Functions:
  var openCoupon = function () {
    window.location.replace(location.protocol + '//' + location.hostname + '/roed-konto/mine-spil#/1/365/aktive/alle-kanaler/');
  };

  var getQueryParam = function (key) {
    var params = location.search.slice(1).split('&');

    for (var i = 0; i < params.length; i++) {
      var args = params[i].split('=');
      if (args[0] == key) {
        return decodeURIComponent(args[1]);
      }
    }

    return null;
  };

  var prepareParamString = function (options) {
    options = options || {};
    var keys = Object.keys(options);
    var params = [];

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var val = options[key];

      if (val && val instanceof Array) {
        val = val.join(',');
      }

      params.push(key + '=' + val);
    }

    return params.length ? '?' + params.join('&') : '';
  };

  const isMobile = () => document.querySelector('body.is-mobile-detected') || false;

  const isTabletDevice = () => document.querySelector('body.is-tablet-detected') || false;

  var elementFullyScrolledInViewport = function (selector) {
    var elem = document.getElementsByClassName(selector);
    elem = elem.length > 0 ? elem[0] : null;
    if (!elem) return;

    var bounding = elem.getBoundingClientRect();

    return (bounding.left < 0 && Math.abs(bounding.left) > (bounding.width / 2)) ||
      (bounding.right > window.innerWidth && (bounding.right - window.innerWidth) > (bounding.width / 2));
  };

  var formatNaming = function (name, format, options) {
    if (options &&
      options.excludedNames &&
      options.excludedNames.indexOf(name.toLowerCase()) !== -1) return name;

    if (format === 'lowercase') return name.toLowerCase();
    if (format === 'capitalized') return name.charAt(0).toUpperCase() + name.slice(1);

    if (format === 'camelCase') {
      var camelCase = {
        vikinglotto: 'vikingLotto',
        eurojackpot: 'euroJackpot',
        allornothing: 'allOrNothing',
        lottosaturday: 'lottoSaturday'
      };
      if (camelCase[name.toLowerCase()]) return camelCase[name.toLowerCase()];
    }

    if (format === 'pascalCase') {
      var pascalCase = {
        lotto: 'Lotto',
        vikinglotto: 'VikingLotto',
        eurojackpot: 'EuroJackpot',
        keno: 'Keno',
        allornothing: 'AllOrNothing',
        lottosaturday: 'LottoSaturday'
      };
      if (pascalCase[name.toLowerCase()]) return pascalCase[name.toLowerCase()];
    }

    if (format === 'kebabCase') {
      var kebabCase = {
        vikinglotto: 'viking-lotto',
        allornothing: 'all-or-nothing',
        lottosaturday: 'lotto-saturday',
        eurojackpot: 'euro-jackpot'
      };
      if (kebabCase[name.toLowerCase()]) return kebabCase[name.toLowerCase()];
    }

    if (format === 'alternativeName') {
      var alternative = {
        lotto: 'lottosaturday'
      };
      if (options &&
        options.alternativeNameFormat &&
        alternative[name.toLowerCase()]) {
        return formatNaming(alternative[name.toLowerCase()], options.alternativeNameFormat);
      }
      if (alternative[name.toLowerCase()]) return alternative[name.toLowerCase()];
    }

    return name;
  };


  // Public functions:
  return {
    openCoupon,
    getQueryParam,
    prepareParamString,
    isMobile,
    isTabletDevice,
    elementFullyScrolledInViewport,
    formatNaming,
    ...DateUtils,
    ...NumberUtils,
  };

});
