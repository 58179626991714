defineDs('DanskeSpil/Framework/NumberGames/Scripts/Templates/SubscriptionValidationOverlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Context:
    var SubscriptionValidationOverlay = function (className, dictionary) {

      // Return:
      return {
        class: m.prop(className),

        render: function (dismiss) {
          return m('div', { class: this.class() }, [
            m('div', { class: 'pannel' }, [
              m('div', { class: 'icon-wrapper' }, [
                m('svg', { class: 'icon' }, m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-error' }))
              ]),
              m('h2', { class: 'headline' }, dictionary.header),
              m('p', { class: 'text' }, dictionary.body),
              m('div', { class: 'buttons' }, [
                m('button', { class: 'btn dogderblue button-confirm', onclick: dismiss, style: 'float: none;' }, dictionary.dismiss)
              ])
            ])
          ]);
        }
      };
    };

    // Public functions:
    return SubscriptionValidationOverlay;

  });
