defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/Client/Flow/Joker',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Common/Framework/EventHandling/Scripts/Event',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/UIHelpers',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/CheckButton',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/NotificationBox',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer',
  ],
  function (m, Event, Utils, UIHelpers, CheckButton, NotificationBox, DataLayer) {

    const Joker = {
      controller: function (args) {
        Object.assign(this, args.controller);

        this.property('stepGoToNext', function (goToNextFlowStep) {
          this.loading(true);
          if (this.game().numberOfDraws() === 0) {
            this.game().sendToSubscription();
            return;
          }
          if (this.verticalType?.() === 'jackpot') {
            this.game().status('add-to-basket');
            this.game().save();
            DataLayer.addToCart(this.game().createDataLayerProduct('plus'));
            this.loading(false);
            m.redraw();
            return;
          }
          this.game().prepareConfirm()
            .then(() => {
              goToNextFlowStep();
              this.loading(false);
              m.redraw();
            })
            .catch(() => {
              this.showError();
              this.loading(false);
              m.redraw();
            });
        }.bind(this));
        this.isStepValid(false);

        this.jokerOptions = ['both', 'wednesday', 'saturday', false];

        // if both jokers are false then we want to set `selectedJoker` to the right type
        // it can be `null` if the user has not interacted with the selector
        // and `false` if the user selected `no joker` option
        if (!this.game()['withJokerSaturday']() && !this.game()['withJokerWednesday']()) {
          this.selectedJoker(this.game()['withJokerSaturday']());
        }

        if (this.gameType === 'Eurojackpot' && this.game().gameVersionNo() === 2) {
          this.jokerOptions = ['both', false];
        }

        this.toggleWednesdayJoker = function (option) {
          const toggleState = typeof option === 'boolean' ? option : !this.game()['withJokerWednesday']();
          this.game()['withJokerWednesday'](toggleState);
          if (!toggleState) {
            this.game().generatedJokerRows('Wednesday', []);
          }
        }.bind(this);

        this.toggleSaturdayJoker = function (option) {
          const toggleState = typeof option === 'boolean' ? option : !this.game()['withJokerSaturday']();
          this.game()['withJokerSaturday'](toggleState);
          if (!toggleState) {
            this.game().generatedJokerRows('Saturday', []);
          }
        }.bind(this);

        if (this.currentFlowStep() === 'joker') {
          Event.fire('multi-client:joker-step-loaded', this);
        }
      },

      view: function (controller) {
        controller.isStepValid(controller.selectedJoker() !== null);
        const game = controller.game();
        const playAsSubscription = game.numberOfDraws() === 0;
        const jokerDrawDates = game.jokerDrawDates;
        const jokerCount = game.numberOfDraws();
        const jokerHatIcon = controller.jokerHatIcon?.() || '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#icon-joker-hat';
        const hasDrawInProgress = (gameName) => controller.settings.drawsInProgress?.includes(gameName);
        const jokerWednesdayInProgress = hasDrawInProgress('JokerWednesday');
        const jokerSaturdayInProgress = hasDrawInProgress('JokerSaturday');
        const jokerInProgress = jokerWednesdayInProgress || jokerSaturdayInProgress;
        const poolWednesday = Number(controller.settings.poolSizes?.jokeronsdag) || 0;
        const poolSaturday = Number(controller.settings.poolSizes?.jokerloerdag) || 0;

        if (game['withJokerWednesday']() || game['withJokerSaturday']()) {
          if (controller.gameType === 'Eurojackpot') {
            if (jokerInProgress && jokerCount > 1) {
              controller.selectedJoker(false);
              controller.toggleSaturdayJoker(false);
              controller.toggleWednesdayJoker(false);
              game.save();
            } else {
              controller.selectedJoker('both');
            }
          } else if ((game['withJokerWednesday']() && game['withJokerSaturday']())) {
            controller.selectedJoker('both');
          } else {
            if (game['withJokerWednesday']()) {
              controller.selectedJoker('wednesday');
            }
            if (game['withJokerSaturday']()) {
              controller.selectedJoker('saturday');
            }
          }
        }

        const onSelectToggle = function (value) {
          switch (value) {
          case 'saturday':
            controller.toggleSaturdayJoker(true);
            controller.toggleWednesdayJoker(false);
            break;

          case 'wednesday':
            controller.toggleSaturdayJoker(false);
            controller.toggleWednesdayJoker(true);
            break;

          case 'both':
            controller.toggleSaturdayJoker(true);
            controller.toggleWednesdayJoker(true);
            break;

          default:
            controller.toggleSaturdayJoker(false);
            controller.toggleWednesdayJoker(false);
            break;
          }
        };

        let isEurojackpotV2 = false;
        // we check the joker selection for Eurojackpot only if the game model has `verticalType` and the `verticalType`
        // is not `jackpot` that's why we do `!(game.verticalType && game.verticalType() === 'jackpot')`
        const isJackpot = game.verticalType && game.verticalType() === 'jackpot';
        if (controller.gameType === 'Eurojackpot' && game.gameVersionNo() === 2 && !isJackpot) {
          isEurojackpotV2 = true;
          const firstAvailableJokerDay = playAsSubscription ? 'wednesday' : game.jokerDrawDates()[0].dayName;

          // based on the numbers of draws we determine which Joker games are selected
          // if joker was selected when 1 draw was selected then when the draw count increase
          // we need to toggle the other joker on
          // if play as subscription is selected then the number of draws will be 0 and we want the same logic
          if (jokerCount > 1 || jokerCount === 0) {
            if ((game['withJokerWednesday']() || game['withJokerSaturday']()) &&
              !(game['withJokerWednesday']() && game['withJokerSaturday']())) {
              game['withJokerWednesday']() ? controller.toggleSaturdayJoker() : controller.toggleWednesdayJoker();
              game.save();
            }
          }

          // if joker was enabled when numberOfDraws was greater than 1 that means that both jokers were selected
          // now if numberOfDraws was changed to 1 we need to check which joker day is available next and toggle off the other joker
          if (jokerCount === 1) {
            // also if the available joker day has changed we want to disable the old one and switch to the other
            if (game['withJokerWednesday']() && firstAvailableJokerDay !== 'wednesday') {
              controller.toggleWednesdayJoker();
              if (!game['withJokerSaturday']()) {
                controller.toggleSaturdayJoker();
              }
              game.save();
            }
            if (game['withJokerSaturday']() && firstAvailableJokerDay !== 'saturday') {
              controller.toggleSaturdayJoker();
              if (!game['withJokerWednesday']()) {
                controller.toggleWednesdayJoker();
              }
              game.save();
            }
          }
        }

        const onSelect = function (option) {
          const value = typeof option === 'object' ? option.value : option;
          controller.selectedJoker(value);
          if (!value) {
            const activeSecondaryCta = document.querySelector('.secondary-cta.active');
            if (activeSecondaryCta) {
              activeSecondaryCta.click();
            }
          }
          onSelectToggle(value);
          game.save();
          controller.isStepValid(controller.selectedJoker() !== null);
        };

        const jokerDatesDetails = function (jokerDates) {
          const formattedDates = [];
          for (let index = 0; index < jokerDates.length; index++) {
            const date = new Date(jokerDates[index].closingTime);
            formattedDates.push(Utils.getLongDate(date, true));
          }
          return formattedDates;
        };

        const jokerOptionsItems = controller.jokerOptions.map(function (option) {
          const label = function () {
            if (controller.gameType === 'Eurojackpot' && game.gameVersionNo() === 2) {
              if (option && playAsSubscription) {
                return controller.mcDictionary('joker/bothJokerLabel');
              }
              return option ? controller.mcDictionary('joker/multipleJokerLabel', {
                count: jokerCount,
                price: game.jokerPrice() * jokerCount
              }) : controller.mcDictionary('joker/noJokerLabel');
            }

            let numberOfDraws = game.numberOfDraws() || 1;
            if (game.gameExtraDraws?.()?.length > 0 && !game.upsellExtra?.()) {
              numberOfDraws = numberOfDraws - game.gameExtraDraws().length;
            }
            switch (option) {
            case 'wednesday':
              return controller.mcDictionary('joker/wednesdayJokerLabel', { price: game.jokerPrice() * numberOfDraws });
            case 'saturday':
              return controller.mcDictionary('joker/saturdayJokerLabel', { price: game.jokerPrice() * numberOfDraws });
            case 'both':
              return controller.mcDictionary('joker/bothJokerLabel', { price: game.jokerPrice() * numberOfDraws * 2 });
            case false:
              return controller.mcDictionary('joker/noJokerLabel');
            }
          };
          const details = function () {
            if (controller.gameType !== 'Eurojackpot' ||
                !option ||
                playAsSubscription ||
                isJackpot) {
              return null;
            }

            return jokerDatesDetails(jokerDrawDates(option));
          };
          let isDisabled = false;
          let subLabel = option ? controller.mcDictionary('joker/jokerSublabel') : null;
          switch (option) {
          case 'wednesday':
            isDisabled = !playAsSubscription && !isJackpot && jokerWednesdayInProgress;
            if (poolWednesday > 0) {
              subLabel = controller.mcDictionary('joker/jokerSublabelPoolSize', { poolSize: poolWednesday });
            }
            break;
          case 'saturday':
            isDisabled = !playAsSubscription && !isJackpot && jokerSaturdayInProgress;
            if (poolSaturday > 0) {
              subLabel = controller.mcDictionary('joker/jokerSublabelPoolSize', { poolSize: poolSaturday });
            }
            break;
          case 'both':
            isDisabled = !playAsSubscription && !isJackpot && jokerInProgress;
            if (controller.gameType === 'Eurojackpot') {

              // If the user has selected a Eurojackpot high pool game, we want to show the minimum pool size,
              // not the current pool size.
              if (isJackpot) {
                subLabel = controller.mcDictionary('joker/jokerSublabel');
                break;
              }

              isDisabled = isDisabled && jokerCount > 1;
              const feed = controller.infoFeed();
              const jokerData = feed?.jokerData();
              const jokerDraw1 = jokerData?.jokerWednesday.openDraw?.drawingTime;
              const jokerDraw2 = jokerData?.jokerSaturday.openDraw?.drawingTime;
              const openDraw = feed.getOpenDraw()?.drawingTime;
              if (jokerDraw1 > openDraw && jokerDraw2 > openDraw && (poolWednesday || poolSaturday)) {
                subLabel = controller.mcDictionary('joker/jokerSublabelPoolSize',
                  { poolSize: poolWednesday || poolSaturday });
              }
            } else if (poolWednesday || poolSaturday) {
              const minPool = controller.mcDictionary('joker/minimumPoolSize');
              subLabel = controller.mcDictionary('joker/jokerSublabelPoolSizeBoth',
                {
                  poolWednesday: poolWednesday || minPool,
                  poolSaturday: poolSaturday || minPool,
                });
            }
            break;
          }
          const _option = {
            label: label(),
            sublabel: isDisabled ? controller.mcDictionary('joker/jokerSublabelDrawInProgress') : subLabel,
            value: option,
            isDisabled: isDisabled,
            details: details()
          };
          return CheckButton(_option, isDisabled ? () => {} : onSelect,
            Boolean(_option.value === controller.selectedJoker() && controller.currentFlowStep() === 'joker'));
        });

        return m('.multi-client__flow-step-inner', [
          m('.multi-client__flow-step-header', [
            m('svg.multi-client__joker-hat.mobile-only', {
              key: jokerHatIcon,
            }, [
              m('use', { href: jokerHatIcon })
            ]),
            m('.multi-client__flow-step-title', controller.mcDictionary('joker/title')),
            m('.multi-client__flow-step-subtitle', controller.mcDictionary('joker/subtitle')),
            m('svg.multi-client__joker-hat.mobile-hide', {
              key: jokerHatIcon,
            }, [
              m('use', { href: jokerHatIcon })
            ])
          ]),
          UIHelpers.columnDivider(jokerOptionsItems, {
            className: 'multi-client-joker-options'
          }),
          isEurojackpotV2 ? NotificationBox(jokerCount === 1 ? 'ejp_joker' : 'ejp_jokers', controller.settings.notifications, { replacements: { draws: jokerCount || 2 } }) : null
        ]);
      }

    };

    return Joker;
  });