defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoMosaik/Tile',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    var Tile = function (tile, controller) {

      this.vikinglottoLogoColorPairngs = m.prop({ 'dark-blue': 'white', pink: 'blue', yellow: 'blue', purple: 'white', babyblue: 'blue' });

      // Functions
      var svg = function () {
        return m('svg', {
          class: ['vikinglotto-mosaik__tile-svg']
        }, [
          m('use', { href: '/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#' + tile.state() + '_use' })
        ]);
      }.bind(this);

      var image = function (isStatic) {
        if (isStatic) {
          return m('a',
            {
              href: controller.cta.ctaLink,
              title: controller.cta.ctaTitle
            }, [
              m('img', {
                class: [
                  'vikinglotto-mosaik__tile-static-spot'
                ],
                src: '/Components/DanskeSpil/Domain/VikingLotto/Graphics/' + tile.state() + (tile.state() === 'mosaik-grafik' ? '_' + this.vikinglottoLogoColorPairngs()[tile.tileColor()] : '') + '.png'
              })
            ]
          );
        }

        return m('img', {
          class: [
            'vikinglotto-mosaik__tile-image'
          ],
          src: '/Components/DanskeSpil/Domain/VikingLotto/Graphics/' + tile.state() + (tile.state() === 'mosaik-grafik' ? '_' + this.vikinglottoLogoColorPairngs()[tile.tileColor()] : '') + '.png'
        });
      }.bind(this);

      // View:
      return m('div', {
        class:
          [
            'vikinglotto-mosaik__tile vikinglotto-mosaik__tile--' + tile.tileColor(),
            tile.isStatic() ? ' vikinglotto-mosaik__tile--grow2' : null,
            tile.showTileOnMobile() ? 'vikinglotto-mosaik__tile--mobile' : null
          ].join(' '),
        id: 'vikinglotto-mosaik-tile-' + tile.id()
      }, [
        m('div', {
          class: [
            'vikinglotto-mosaik__tile-graphics',
            tile.show() ? 'vikinglotto-mosaik__tile-graphics--show' : null,
            tile.isStatic() ? 'vikinglotto-mosaik__tile-graphics--show vikinglotto-mosaik__tile-graphics--mobile' : null
          ].join(' '),
          style: !tile.isStatic() && tile.fadeTimer() ? '--fade-timer: ' + tile.fadeTimer() + 'ms' : ''
        }, [
          tile.state() ? (controller.fileType[tile.state()] === 'svg' ? svg() : image(tile.isStatic())) : null
        ])
      ]);
    };

    // Public functions:
    return Tile;

  });
