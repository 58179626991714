defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/Client/RotarySelector',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersRotarySelector/RotarySelector'
  ],
  // eslint-disable-next-line no-unused-vars
  function (m, NumberGamesRotarySelector) {
    // Template:
    var RotarySelector = function (options, value, valuePostfix, onInput, game) {
      return (<NumberGamesRotarySelector
        classNames={`${game.toLowerCase()} multi-client-rotary-selector`}
        options={options()}
        value={value}
        valuePostfix={valuePostfix}
        onInput={onInput}
      />);
    };

    // Public functions:
    return RotarySelector;

  });
