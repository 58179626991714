defineDs('DanskeSpil/Domain/Tutorial/Scripts/Pages/TutorialSlidepage',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'Common/Framework/ScriptLibraries/Scripts/TinySlider',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialFooter',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialNavigation',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialHero'
  ], function (m, Render, TinySlider, TutorialFooter, Navigation, Hero) {
    var render = Render();

    return {
      controller: function (args) {
        window.scrollTo(0, 0);

        this.safeTutorialName = decodeURI(m.route.param('tutorial'));
        this.tutorial = args.allTutorials.filter(function (entry) {
          return entry.safeTitle === this.safeTutorialName;
        }.bind(this))[0];

        this.titleCssStyle = args.titleCssStyle;
        this.mostVisitedList = args.mostVisitedList;
        this.mostVisitedLabel = args.mostVisitedLabel;
        this.heroTitle = args.settings.heroTitle;
        this.heroText = args.settings.heroText;
        this.elementsCount = this.tutorial.elements.length;
        this.slidepageRelatedHelpTitle = args.settings.slidepageRelatedHelpTitle;
        this.relatedHelpTutorials = this.tutorial.relatedHelpTutorials;
        this.heroImage = this.tutorial.heroImage;
        this.actionButton = this.tutorial.actionButton;
        this.footerSettings = args.footerSettings;
        this.searchQuery = args.searchQuery;
        this.titleCssStyle = args.titleCssStyle;
        this.activeSlide = 0;
        this.showPrevArrow = m.prop(false);
        this.showNextArrow = m.prop(true);
        this.isSliderActive = false;
        this.desktopSlider = null;
        this.vimeoReady = false;
        this.vimeoPlayers = [];
        this.getTextWithHighligt = args.getTextWithHighligt;

        this.addPlayers = function () {
          document.querySelectorAll('.slidepage__slider--desktop iframe').forEach(function (iframe, i) {
            var player = new window.Vimeo.Player(iframe);
            if (i !== 0) {
              player.isPlaying = false;
              player.pause();
            }
            this.vimeoPlayers.push(player);
          }.bind(this));
        };

        this.onStepRendered = function (ignoredElement, isInitialized) {
          if (!isInitialized) {
            this.isSliderActive = true;
            this.desktopSlider = TinySlider({
              container: '.slidepage__slider--desktop',
              center: true,
              controlsContainer: '.slidepage__controls',
              prevButton: '.slidepage__arrow--prev',
              nextButton: '.slidepage__arrow--next',
              loop: false,
              nav: false,
              responsive: {
                768: {
                  gutter: 100,
                  fixedWidth: 730,
                },
                1024: {
                  gutter: 350,
                  fixedWidth: 730,
                },
                1440: {
                  gutter: 220,
                  fixedWidth: 900,
                }
              }
            });

            this.desktopSlider.events.on('indexChanged', function (event) {
              document.querySelector('.slidepage__current-slide').innerHTML = event.displayIndex > 9 ? event.displayIndex : '0' + event.displayIndex;
              var items = document.querySelectorAll('.slidepage__slider--desktop .slidepage__steps');
              var itemNumber = event.displayIndex - 1; // index er 1-baseret - itemNumber 0.

              items.forEach(function (item, index) {
                item.classList[index === itemNumber ? 'remove' : 'add']('slidepage__steps--fade');
              });

              this.vimeoPlayers.forEach(function (player, index) {
                if (index === itemNumber) {
                  if (!player.isPlaying) {
                    var interval = setInterval(function () {
                      player.play().then(function () {
                        player.isPlaying = true;
                      });
                      if (player.isPlaying) {
                        clearInterval(interval);
                      }
                    }, 500);
                  }
                } else {
                  player.pause().then(function () {
                    player.isPlaying = false;
                    player.setCurrentTime(0);
                  });
                }
              });

              if (itemNumber === 0) {
                // First slide
                this.showPrevArrow(false);
                this.showNextArrow(true);
              } else if (itemNumber === items.length - 1) {
                // Last slide
                this.showPrevArrow(true);
                this.showNextArrow(false);
              } else {
                // All other slides
                this.showPrevArrow(true);
                this.showNextArrow(true);
              }
              m.redraw(true);
            }.bind(this));

            m.redraw(true);

            var mobileSliders = document.querySelectorAll('.slidepage__slider--mobile');
            mobileSliders.forEach(function (slider) {
              var tns = TinySlider({
                container: slider.querySelector('.slidepage__steps'),
                navContainer: slider.querySelector('.slidepage__radio-buttons'),
                controls: false,
                loop: false,
                fixedWidth: 240,
                edgePadding: 40,
                mouseDrag: true,
              });

              tns.events.on('indexChanged', function (event) {
                var steps = slider.querySelectorAll('.slidepage__step');
                if (event.displayIndex === 1) {
                  steps[0].classList.add('slidepage__step--selected');
                  steps[1].classList.remove('slidepage__step--selected');
                } else {
                  steps[1].classList.add('slidepage__step--selected');
                  steps[0].classList.remove('slidepage__step--selected');
                }
              });
            });
          }
        }.bind(this);

        render.ready.then(function () {
          if (!this.vimeoReady) {
            include('https://player.vimeo.com/api/player.js', function () {
              this.vimeoReady = true;
              this.addPlayers();
            }.bind(this));
          }
        }.bind(this));
      },

      view: function (controller) {
        return m('.slidepage', { config: render.depend('container') }, [
          Navigation(controller), // eslint-disable-line compat/compat
          Hero({ title: controller.tutorial.title, style: controller.titleCssStyle }),
          m('.tutorial__content-box.slidepage__content.slidepage__content--mobile',
            controller.tutorial.elements.map(function (element, i) {
              return m('.slidepage__slider.slidepage__slider--mobile',
                {
                  config: render.depend('mobileContainer')
                },
                m('.slidepage__steps',
                  m('.slidepage__step.slidepage__step-description.slidepage__step--selected',
                    m('.slidepage__step-text-wrapper', [
                      m('.slidepage__step-header', { style: controller.titleCssStyle }, element.title),
                      m('.slidepage__step-text', m.trust(controller.getTextWithHighligt(element.text))),
                      m('.slidepage__step-number', ('0' + (i + 1)).slice(-2)),
                      i === 0 ? m('.slidepage__step-help-wrapper', [
                        m('.slidepage__step-help-circle'),
                        m('.slidepage__step-help-text', element.footerHelpText)
                      ]) : null,
                    ])
                  ),
                  element.mobileVideo ? m('iframe.slidepage__step.slidepage__step-illustration', {
                    src: element.mobileVideo,
                    allowFullScreen: true
                  }) : null
                ),
                m('.slidepage__radio-buttons', [
                  m('.slidepage__radio'),
                  m('.slidepage__radio')
                ])
              );
            }),
            controller.actionButton.Url && controller.actionButton.Title
              ? m('a.slidepage__action-button-wrapper', {
                href: controller.actionButton.Url
              }, m('.slidepage__action-button', controller.actionButton.Title))
              : null
          ),
          m('.tutorial__content-box.slidepage__content.slidepage__content--desktop',
            m('.slidepage__slider.slidepage__slider--desktop', {
              config: controller.onStepRendered,
              class: !controller.isSliderActive ? 'slidepage__slider--loading' : '',
            }, controller.tutorial.elements.map(function (element, i) {
              return m('.slidepage__steps',
                {
                  class: i !== 0 ? 'slidepage__steps--fade' : ''
                },
                m('.slidepage__step.slidepage__step-description',
                  m('.slidepage__step-text-wrapper', [
                    m('.slidepage__step-header', { style: controller.titleCssStyle }, element.title),
                    m('.slidepage__step-text', m.trust(controller.getTextWithHighligt(element.text))),
                    m('.slidepage__step-number', ('0' + (i + 1)).slice(-2))
                  ])
                ),
                element.desktopVideo ? m('iframe.slidepage__step.slidepage__step-illustration', {
                  src: element.desktopVideo
                }) : null
              );
            })),
            m('.slidepage__controls', [
              m('.slidepage__arrow.slidepage__arrow--prev', {
                class: !controller.showPrevArrow() ? 'slidepage__arrow--hide' : ''
              }),
              m('.slidepage__slide-counter',
                m('.slidepage__current-slide', '01'),
                m('.slidepage__separator', '/'),
                m('.slidepage__number-of-slides', controller.elementsCount < 10 ? '0' + controller.elementsCount : controller.elementsCount.toString())
              ),
              m('.slidepage__arrow.slidepage__arrow--next', {
                class: !controller.showNextArrow() ? 'slidepage__arrow--hide' : ''
              })
            ]),
            controller.actionButton.Url && controller.actionButton.Title
              ?
              m('a.slidepage__action-button-wrapper', {
                href: controller.actionButton.Url
              }, m('.slidepage__action-button', controller.actionButton.Title))
              : null
          ),
          controller.relatedHelpTutorials.length > 0 ?
            m('.slidepage__related-help',
              m('.slidepage__related-help-title', { style: controller.titleCssStyle }, controller.slidepageRelatedHelpTitle),
              m('.slidepage__related-help-wrapper',
                controller.relatedHelpTutorials.map(function (tutorial) {
                  return m('a.slidepage__related-help-wrapper-block', {
                    href: `/${tutorial.urlPrefix}/${tutorial.safeTitle}`,
                    config: m.route
                  }, [
                    m('.slidepage__related-help-image-wrapper',
                      m('img.slidepage__related-help-image', {
                        src: tutorial.image
                      })
                    ),
                    m('.slidepage__related-help-text-wrapper', [
                      m('.slidepage__related-help-element-title', { style: controller.titleCssStyle }, tutorial.title),
                      m('.slidepage__related-help-element-text', tutorial.text)
                    ]),
                    m('.slidepage__related-help-arrow')
                  ]);
                })
              )
            ) : null,
          controller.footerSettings ? TutorialFooter({ style: controller.titleCssStyle, ...controller.footerSettings }) : null
        ]);
      }
    };
  });
