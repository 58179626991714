defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/VikingLottoWinningNumbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/DatePicker',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoApi',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoDictionary',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoUtils',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoWinningNumbers/Drawing',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/FeaturedWinnersApi'
  ],
  function (Component, DatePicker, VikingLottoApi, VikingLottoDictionary, VikingLottoUtils, Drawing, FeaturedWinnersApi) {

    // Component:
    Component('vikinglotto-winning-numbers', [VikingLottoDictionary], function (m, route, settings, property) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.bonusNumbers = m.prop([]);
          this.d = VikingLottoDictionary.get;
          this.selectedDrawDate = m.prop();
          this.completedDrawsDates = m.prop([]);
          this.loading = m.prop(true);
          this.prizes = m.prop([{ amount: 0 }]);
          this.prizesJoker = m.prop([]);
          this.winningNumbers = m.prop([]);
          this.winningNumbersJoker = m.prop([]);
          this.featuredWinners = m.prop([]);
          this.featuredWinnersJoker = m.prop([]);
          this.game = m.prop('VikingLottoWednesday');
          this.jokerGame = m.prop('JokerWednesday');

          this.property = property;
          this.setings = settings;
          this.isDatepickerOpened = m.prop(false);

          // Functions:
          this.jokerFiltered = function () {
            var jokerArray = [];
            var prizesJoker = this.prizesJoker();
            var self = this;

            prizesJoker.forEach((value) => {
              value.name = self.d('WinningNumbers/CorrectJokerNumbers/Id' + value.id) + ' ' + self.d('WinningNumbers/CorrectJokerNumbers/CorrectNumber');
              jokerArray.push(value);
            });

            return (jokerArray.length > 0) ? jokerArray : this.prizesJoker();
          }.bind(this);

          this.prizesFiltered = function () {
            var d = this.d;
            var prizeArray = [];
            var prizes = this.prizes();
            var selectedWinningNumbers;

            if (settings.selectedWinningNumbers) {
              selectedWinningNumbers = settings.selectedWinningNumbers.split(',');
            } else {
              return this.prizes();
            }

            selectedWinningNumbers.forEach((id) => {
              prizes.forEach((value) => {
                if (value.id && value.id == id) {
                  value.name = d('WinningNumbers/CorrectNumbers/Id' + value.id) + ' ' + d('WinningNumbers/CorrectNumbers/CorrectNumber');
                  prizeArray.push(value);
                }
              });
            });

            return prizeArray.length > 0 ? prizeArray : this.prizes();
          }.bind(this);

          // Functions:
          this.getWinningNumbers = function () {
            var self = this;

            m.sync([
              VikingLottoApi.getWinningNumbers({ date: this.selectedDrawDate() }).then(function (data) {
                self.winningNumbers(data.vikinglotto.winningNumbers);
                self.bonusNumbers([data.vikinglotto.vikingNumber]);
                self.prizes(data.vikinglotto.prizes);
                self.winningNumbersJoker(data.jokerWednesday.winningNumbers);
                self.prizesJoker(data.jokerWednesday.prizes);

              })
            ]).then(function () {
              m.redraw();
            });
            FeaturedWinnersApi.getFeaturedWinners({ date: this.selectedDrawDate(), game: this.game() }).then(function (data) {
              self.featuredWinners(data);
              m.redraw();
            });
            FeaturedWinnersApi.getFeaturedWinners({ date: this.selectedDrawDate(), game: this.jokerGame() }).then(function (data) {
              self.featuredWinnersJoker(data);
              m.redraw();
            });

          }.bind(this);

          this.selectDate = function (date) {
            var self = this;

            self.selectedDrawDate(date);
            self.getWinningNumbers();
          }.bind(this);

          this.isWinningNumbersReady = function () {
            // Returns true if selected draw is loaded and have winningNumbers
            return !this.loading() && this.winningNumbers() && this.winningNumbers().length > 0;
          }.bind(this);

          // Context:
          VikingLottoApi.getCompletedDrawDates().then(function (dates) {
            dates.sort().reverse();

            var completedDraws = [];

            for (var i = 0; i < dates.length; i++) {
              var date = dates[i];
              completedDraws.push(date);
            }

            if (dates && dates.length > 0) {
              this.selectedDrawDate(dates[0]);
              this.completedDrawsDates(completedDraws);
              this.getWinningNumbers();
            }

            this.loading(false);

          }.bind(this));
        },


        view: function (controller) {
          var d = controller.d;
          return [
            m('section', { class: 'vikinglotto-section section-with-dropdown' }, [
              m('a', { class: 'anchor', name: 'vikinglottowinningnumbersspot1' }),
              m('div', { class: 'vikinglotto-content-wrapper' }, [
                m('h2', { class: 'headline' }, d('WinningNumbers/PrizeHeading')),
                m('p', { class: 'sub-headline' }, d('WinningNumbers/PrizeSubHeading')),
                controller.completedDrawsDates() && controller.completedDrawsDates().length > 0 ? m('div', { class: 'select-date-section' }, [
                  (controller.selectedDrawDate() && (controller.completedDrawsDates().length > 0)) ? DatePicker(controller, true) : null
                ]) : m('p', { class: 'disclaimer-info-text' }, d('WinningNumbers/EmptyDrawList')),

                (function () {
                  if (controller.loading()) {
                    return '';
                  }

                  if (!controller.isWinningNumbersReady()) {
                    return m('div', { class: 'disclaimer-info-text' }, controller.d('WinningNumbers/WinningNumbersNotReady'));
                  }

                  return [
                    m('div', { class: 'winning-numbers-list' }, [
                      Drawing(controller, {
                        winningNumbers: controller.winningNumbers(),
                        bonusNumbers: controller.bonusNumbers(),
                        prizes: controller.prizesFiltered(),
                        winners: controller.featuredWinners(),
                        checkViewport: VikingLottoUtils.elementFullyScrolledInViewport
                      }, 'vikinglotto'),
                      Drawing(controller, {
                        winningNumbers: controller.winningNumbersJoker(),
                        prizes: controller.jokerFiltered(),
                        winners: controller.featuredWinnersJoker(),
                        checkViewport: VikingLottoUtils.elementFullyScrolledInViewport
                      }, 'joker')
                    ])
                  ];

                })(),

              ])
            ])
          ];
        }
      };

      // Setup routes:
      route('/', root);

    });

  });
