defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/Shared/PackageCard',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {
    // Template:
    var PackageCard = function ({ ribbon, tagline, title, body, footer, onclick }) {
      return m('.multi-packages-package-card__outer', {
        onclick
      }, m('.multi-packages-package-card', [
        ribbon?.length ? m('.multi-packages-package-card__ribbon', ribbon) : null,
        m('.multi-packages-package-card__header', [
          tagline ? m('.multi-packages-package-card__tagline', tagline) : null,
          title ? m('.multi-packages-package-card__title', title) : null,
        ]),
        m('.multi-packages-package-card__body', body),
        m('.multi-packages-package-card__footer', footer)
      ]));
    };

    // Public functions:
    return PackageCard;
  });