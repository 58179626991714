defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/SelectorScreenOption', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Buttons/Button',
// eslint-disable-next-line no-unused-vars
], function (m, Svg, Button) {
  const SelectorScreenOption = {
    controller: function ({ controller, systemType }) {
      Object.assign(this, controller);
      this.headingText = null;
      this.descriptionText = null;
      this.buttonText = null;
      this.icon = null;

      switch (systemType) {
      case 'M':
        this.headingText = controller.labelDictionary('systemSelectorScreenMathematicalHeading');
        this.descriptionText = controller.labelDictionary('systemSelectorScreenMathematicalDescription');
        this.buttonText = controller.labelDictionary('systemSelectorScreenMathematicalButton');
        this.icon = '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#mathematical-system';
        break;
      case 'R':
        this.headingText = controller.labelDictionary('systemSelectorScreenReducedHeading');
        this.descriptionText = controller.labelDictionary('systemSelectorScreenReducedDescription');
        this.buttonText = controller.labelDictionary('systemSelectorScreenReducedButton');
        this.icon = '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#reduced-system';
        break;
      case 'C':
        this.headingText = controller.labelDictionary('systemSelectorScreenChanceHeading');
        this.descriptionText = controller.labelDictionary('systemSelectorScreenChanceDescription');
        this.buttonText = controller.labelDictionary('systemSelectorScreenChanceButton');
        this.icon = '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#chance-system';
        break;
      }
    },
    view: function (controller, { systemType }) {
      return (<div
        data-uitest-id={`system-type-selector-option-${systemType}`}
        className={`flex flex-col w-full max-w-288 py-32 px-24 ${controller.backgroundColor} rounded-24 text-white text-center`}
        onclick={() => controller.onSelectSystemType(systemType)}
      >
        <Svg
          src={controller.icon}
          width='36'
          height='36'
          classNames='mx-auto mb-20'
          queryAttributes={{
            '.abackground, .ebackground': { fill: `rgb(var(${controller.gameName === 'lotto' ? '--colors-lotto-red-1' : '--colors-vikinglotto-blue-1'}))` }
          }}
        />
        <div className='text-18 font-bold'>{controller.headingText}</div>
        <div className='text-14 font-semibold opacity-50 mt-8 mb-40'>{controller.descriptionText}</div>
        <div className='mt-auto'>
          <Button
            data-uitest-id='system-type-selector-option-button'
            variant='primary'
            onClick={() => controller.onSelectSystemType(systemType)}
          >{controller.buttonText}</Button>
        </div>
      </div>);
    }
  };

  return SelectorScreenOption;
});