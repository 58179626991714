defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotUtils',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (Utils) {

    // Public functions:
    return {
      formatCurrency: Utils.formatCurrency,
      formatNumber: Utils.formatNumber,
      parseISO8601: Utils.parseISO8601,
      formatISO8601: Utils.formatISO8601,
      getQueryParam: Utils.getQueryParam,
      prepareParamString: Utils.prepareParamString,
      getLongDate: Utils.getLongDate,
      isMobile: Utils.isMobile,
      isTabletDevice: Utils.isTabletDevice,
      openCoupon: Utils.openCoupon
    };

  });
