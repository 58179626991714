defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Templates/RowVikingNumber',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Template:
    var RowVikingNumber = function (vikingNumber, className, config) {

      // Variables:
      className = className || '';

      // Return:
      return m('div', { class: 'viking-box viking-box-' + vikingNumber + className, config: config }, [
        m('svg', { class: 'icon icon-viking white' }, [
          m('use', { href: '/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#icon-viking-helmet-white' })
        ]),
        m('svg', { class: 'icon icon-viking yellow' }, [
          m('use', { href: '/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#icon-viking-helmet-yellow' })
        ]),
        m('span', { class: 'number' }, vikingNumber)
      ]);

    };

    // Public functions:
    return RowVikingNumber;

  });
