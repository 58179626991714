defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/Shared/PopoverDrawer',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Common/Framework/EventHandling/Scripts/Event'
  ],
  function (m, Event) {
    const _PopoverDrawer = {
      controller: function ({ controller }) {
        Object.assign(this, controller);

        this.pagePopoverCount = document.querySelectorAll('.popover-drawer').length + 1;

        this.onAnimationEnd = (event) => {
          const animationName = event.animationName;

          if (animationName === 'popover-drawer-card-slide-in-animation') {
            this.actionClassName('popover-drawer--active');
            Event.fire('popover-drawer:shown', this);
          }

          if (animationName === 'popover-drawer-fade-out-animation') {
            this.remove();
            this.isRendered(false);
            this.actionClassName('');
            m.redraw();
          }
        };
      },
      view: function (controller, ignoreArgs, slots) {
        if (!controller.isRendered()) {
          return null;
        }

        // View:
        return m('.popover-drawer', {
          class: `popover-drawer--${controller.pagePopoverCount} ${controller.actionClassName()}`,
          onanimationend: controller.onAnimationEnd
        }, [
          m('.popover-drawer__card', [
            m('button.popover-drawer__close-button', {
              onclick: controller.close
            }, [
              m('svg.icon-x', [
                m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-x' })
              ]),
              m('svg.icon-down-arrow', [
                m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-down-arrow' })
              ])
            ]),
            m('.popover-drawer__card-content', slots())
          ])
        ]);
      }
    };

    const PopoverDrawer = function ({ $parent, className } = {}) {
      this.$container = document.createElement('div');
      this.$container.classList.add('popover-drawer__container');
      const slots = m.prop(null);
      let onCloseCallback = m.prop(null);
      this.isRendered = m.prop(false);
      this.isShown = m.prop(false);
      this.actionClassName = m.prop('');

      if (className) {
        if (className.length > 0) {
          const classNames = className.split(' ');
          classNames.forEach((_className) => {
            this.$container.classList.add(_className);
          });
        }
      }

      this.close = function (event) {
        if (event) {
          event.stopPropagation();
          event.preventDefault();
        }
        this.actionClassName('popover-drawer--active popover-drawer--fade-out');
      }.bind(this);

      this.remove = function () {
        this.isRendered(false);
        onCloseCallback();
        this.$container.remove(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        setTimeout(() => {
          this.isShown(false);
        }, 0);
      }.bind(this);

      this.onClose = function (callback) {
        onCloseCallback = callback;
      };

      this.setContent = function (content) {
        this.isRendered(true);
        slots(content);
      }.bind(this);

      this.show = () => {
        if (this.isShown()) {
          return;
        }
        const _$parent = $parent || document.body;
        this.actionClassName('popover-drawer--fade-in');
        _$parent.appendChild(this.$container);
        this.isShown(true);
      };

      m.mount(this.$container, m(_PopoverDrawer, { controller: this }, slots));
    };

    // Public functions:
    return PopoverDrawer;
  });
