defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/UpsaleSpot',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/NumberGamesDictionary',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/ViewportUtils',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Resize',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer'
  ],
  function (Component, NumberGamesDictionary, ViewportUtils, Resize, DataLayer) {

    // Component:
    Component('upsale-spot', [NumberGamesDictionary], function (m, route, settings) {
      var getPropertyValue = function (source, property) {
        if (!source) return;
        return source[property];
      };

      // Components:
      var root = {
        controller: function () {

          // Variables
          this.d = NumberGamesDictionary.get;
          this.hasImage = settings.MobileImage !== null || settings.DesktopImage !== null;
          this.hasCallToAction = settings.CallToAction !== null;
          this.mobileImageSource = settings.MobileImage !== null ? settings.MobileImage.Source : '';
          this.desktopImageSource = settings.HostContainer == 'onecolumnspotview' ? getPropertyValue(settings.FullWidthImage, 'Source') : getPropertyValue(settings.HalfWidthImage, 'Source');
          this.desktopImageAlt = settings.HostContainer == 'onecolumnspotview' ? getPropertyValue(settings.FullWidthImage, 'Alt') : getPropertyValue(settings.HalfWidthImage, 'Alt');
          this.brand = m.prop(window.location.pathname.substring(window.location.pathname.indexOf('/') + 1).replace('/', '_'));
          this.campain = m.prop(this.hasCallToAction ? settings.CallToAction.Url.substring(settings.CallToAction.Url.lastIndexOf('=') + 1, settings.CallToAction.Url.length) : '');
          this.promoTracked = m.prop(false);

          // Functions:
          this.trackPromoView = function (promoProduct) {
            if (!this.promoTracked()) {
              setTimeout(function () { // Timeout removes the possibility for 2 trackings to be registerd at the same time, which would break the tracking.
                this.promoTracked(true);
                DataLayer.promoView(promoProduct);
              }.bind(this), 500);
            }
          }.bind(this);

          this.trackPromoClick = function () {
            DataLayer.promoClick(this.createPromoProduct());
          }.bind(this);


          this.createPromoProduct = function () {
            var promotions = [];
            var promo = {};
            promo.name = this.brand();

            promo.creative = this.campain();
            promo.position = settings.HostContainer;

            promotions.push(promo);

            return promotions;
          }.bind(this);

          this.getUpsaleTitleStyle = function () {
            var fontSize = (settings.TitleMobileDecoration.FontSize / 5) + 'vw';
            var fontWeight = settings.TitleMobileDecoration.FontWeight;
            var titleColor = settings.TitleMobileDecoration.FontColorHex;
            var titlePosLeft = settings.Positioning.TitleMobileLeft;
            var titlePosTop = settings.Positioning.TitleMobileTop;
            var viewportWidth = ViewportUtils.viewportWidth();

            if (viewportWidth > 768) {
              fontSize = (settings.TitleDecoration.FontSize / 10) + 'rem';
              fontWeight = settings.TitleDecoration.FontWeight;
              titleColor = settings.TitleDecoration.FontColorHex;
              titlePosLeft = settings.Positioning.TitleDesktopLeft;
              titlePosTop = settings.Positioning.TitleDesktopTop;

              if (viewportWidth <= 960) {
                fontSize = (settings.TitleMobileDecoration.FontSize / 10) + 'vw';
              }
            }

            var inlineStyling = 'top: ' + titlePosTop + '%;' +
              'left: ' + titlePosLeft + '%;' +
              (fontSize ? 'font-size: ' + fontSize + ';' : '') +
              (titleColor ? 'color: ' + titleColor + ';' : '') +
              (fontWeight ? 'font-weight: ' + fontWeight + ';' : '');

            return inlineStyling;
          };

          this.getUpsaleTextStyle = function () {
            var fontSize = (settings.TextMobileDecoration.FontSize / 4) + 'vw';
            var fontWeight = settings.TextMobileDecoration.FontWeight;
            var textColor = settings.TextMobileDecoration.FontColorHex;
            var textPosLeft = settings.Positioning.TextMobileLeft;
            var textPosTop = settings.Positioning.TextMobileTop;
            var viewportWidth = ViewportUtils.viewportWidth();

            if (viewportWidth > 768) {
              fontSize = (settings.TextDecoration.FontSize / 10) + 'rem';
              fontWeight = settings.TextDecoration.FontWeight;
              textColor = settings.TextDecoration.FontColorHex;
              textPosLeft = settings.Positioning.TextDesktopLeft;
              textPosTop = settings.Positioning.TextDesktopTop;

              if (viewportWidth < 960) {
                fontSize = (settings.TextMobileDecoration.FontSize / 10) + 'vw';
              }
            }

            return 'top: ' + textPosTop + '%;' + 'left: ' + textPosLeft + '%;' + (fontSize ? 'font-size: ' + fontSize + ';' : '') + (textColor ? 'color: ' + textColor + ';' : '') + (fontWeight ? 'font-weight: ' + fontWeight + ';' : '');
          };

          this.onEnterViewportListener = ViewportUtils.onEnterViewportListener(function () {
            this.onEnterViewportListener.detach();
            this.trackPromoView(this.createPromoProduct());
          }.bind(this));

          this.onViewRendered = function (element, isInitialized) {
            if (!isInitialized) {
              this.onEnterViewportListener.attachTo(element, { withDelay: 500 });
            }
          }.bind(this);

          this.onunload = function () {
            this.onEnterViewportListener.detach();
          }.bind(this);

          // Resize listner
          Resize(function () {
            m.redraw();
          });
        },

        view: function (controller) {
          return m('div', {
            class: 'upsale-spot', config: controller.onViewRendered, onclick: controller.trackPromoClick }, [
            m('picture', { class: 'upsale-image-container' }, [
              controller.hasImage ? [
                m('source', { media: '(max-width: 768px)', srcset: controller.mobileImageSource }),
                m('img', { class: 'upsale-image', src: controller.desktopImageSource, alt: controller.desktopImageAlt })
              ] : ''
            ]),
            m('div', { class: 'upsale-content-container' }, [
              settings.Title !== null ? m('h3', { class: 'upsale-title', style: controller.getUpsaleTitleStyle() }, settings.Title) : null,
              settings.Text !== null ? m('h4', { class: 'upsale-text', style: controller.getUpsaleTextStyle() }, settings.Text) : null,
              controller.hasCallToAction ? [
                m('a', { class: 'btn cta upsale-cta', href: settings.CallToAction.Url }, settings.CallToAction.Text)
              ] : '',
              settings.Identity !== null ? [
                m('div', { class: 'upsale-logo-wrapper' }, [
                  m('img', { class: 'upsale-logo ' + settings.Identity.Name.toLowerCase(), src: settings.Identity.LogoUrl })
                ])
              ] : ''
            ])
          ]);
        }
      };
      // Setup routes:
      route('/', root);

    });
  });
