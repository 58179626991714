defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/GuaranteeContent/EurojackpotTabs',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
  ], function (m) {
    const EurojackpotTabs = {
      controller: function ({ controller, column }) {
        Object.assign(this, controller);
        this.tabsOptions = ['upper', 'lower'];
        this.winningsTab = m.prop(this.tabsOptions[0]);
        this.winningsRows = {};
        this.winningsRowsUpper = ['5 + 2', '5 + 1', '5 + 0', '4 + 2', '4 + 1', '4 + 0'];
        this.winningsRowsLower = ['3 + 2', '3 + 1', '2 + 2', '3 + 0', '1 + 2', '2 + 1'];

        for (let i = 0; i < column.rows.length; i++) {
          const row = column.rows[i];
          this.winningsRows[`${row.mainNumbers} + ${row.starNumbers}`] = row.value;
        }
      },
      view: function (controller) {
        const tableStructure = () => {
          const winningTableData = controller.winningsTab() === 'upper' ? controller.winningsRowsUpper : controller.winningsRowsLower;
          return <div className='py-12 px-20 text-white'>
            <div className='flex justify-between border-0 border-b border-solid border-white'>{winningTableData.map((row) => {
              return <div className='flex-1 py-12'>{row}</div>;
            })}</div>
            <div className='flex justify-between'>{winningTableData.map((row) => {
              return <div className='flex-1 py-12'>{controller.winningsRows[row] ?? '-'}</div>;
            })}</div>
          </div>;
        };
        return <div>
          <div className='flex'>
            {controller.tabsOptions.map((tab) => {
              const isActive = controller.winningsTab() === tab;
              return <div
                className={`flex-1 py-20 cursor-pointer text-center first:rounded-tl-16 last:rounded-tr-16 overflow-clip text-white ${isActive ? 'bg-eurojackpot-carbon-1' : 'bg-eurojackpot-carbon-2'}`}
                onclick={() => {
                  controller.winningsTab(tab);
                }}
              >
                {controller.labelDictionary('systemGuaranteeTabAccordionTabLabel', {
                  fromCombination: tab === 'upper' ? controller.winningsRowsUpper[0] : controller.winningsRowsLower[0],
                  toCombination: tab === 'upper' ? controller.winningsRowsUpper[controller.winningsRowsUpper.length - 1] : controller.winningsRowsLower[controller.winningsRowsLower.length - 1],
                })}
              </div>;
            }
            )}
          </div>
          <div className='bg-eurojackpot-carbon-1'>{tableStructure()}</div>
        </div>;
      }
    };

    return EurojackpotTabs;
  });