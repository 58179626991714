
defineDs('DanskeSpil/Domain/NumberGames/Scripts/Helpers/CampaignEngineApi', [
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
], (ApiRequest) => {

  let apiUrl = '/api/content/v1';
  if (DS.Config.ENV === 'dev') {
    apiUrl = `${DS.Config.LOGINPAGE_HOST}${apiUrl}`;
  }

  const getActiveCampaigns = () => {
    return ApiRequest({
      method: 'GET',
      url: `${apiUrl}/activecampaigns`,
      localCacheTTL: 60,
      unwrapSuccess: (response) => {
        return response;
      }
    });
  };

  const getAllCampaigns = () => {
    return ApiRequest({
      method: 'GET',
      url: `${apiUrl}/campaigns`,
      localCacheTTL: 60,
      unwrapSuccess: (response) => {
        return response;
      }
    });
  };

  const getActiveCampaignForGame = (gameType) => {
    return new Promise((resolve, reject) => {
      getActiveCampaigns()
        .then((response) => {
          const gameCampaign = response[gameType];
          const gameTemplateId = gameCampaign?.activeCampaign?.templateId;
          if (!gameTemplateId) return reject();

          getAllCampaigns()
            .then((allCampaignsResponse) => {
              const activeCampaignResponse = allCampaignsResponse.find((campaign) => {
                return campaign?.templateId === gameTemplateId;
              });

              return resolve({ ...gameCampaign, ...activeCampaignResponse });
            })
            .catch(() => reject());
        })
        .catch(() => reject());
    });
  };


  return {
    getActiveCampaigns,
    getAllCampaigns,
    getActiveCampaignForGame
  };

});
