defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/InformationSwitcherSelector',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/SelectDropdown',
    'Shared/Framework/Ensighten/Scripts/Ensighten',
  ],
  function (Component, SelectDropdown, Ensighten) {

    // Component:
    Component('informationswitcher-selector', [], function (m, route) {


      // Components:
      var root = {
        controller: function () {

          // Variables:
          const panels = [...document.querySelectorAll('.informationswitcherspotview .panel')]; // panels outside this component

          var panelSettings = panels.map(function ($panel) {
            var pSettings = JSON.parse($panel.getAttribute('data-settings')); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
            return { value: pSettings.value, name: pSettings.name };
          });

          this.options = m.prop(panelSettings || []);
          this.selected = m.prop(this.options()[0] || '');

          // Functions:
          this.selectQuestion = function (item) {
            this.selected(item);
            const selectedElements = panels.filter(($panel) => $panel.getAttribute('data-panelname') === item.value); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
            if (selectedElements.length > 0) {
              const selectedEl = selectedElements[0];
              // If option is a subpanel, then select parent
              if (!selectedEl.classList.contains('panel')) {
                const currentPos = window.scrollY;
                const $selectedParent =  selectedEl.closest('.panel');
                const scrollOffset = $selectedParent.getBoundingClientRect().top; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
                const bufferTolerance = 100;
                const newPosition = scrollOffset + currentPos - bufferTolerance;
                window.scroll({ top: newPosition, behavior: 'smooth' });
              }
              panels.forEach(($panel) => $panel.classList.remove('is-displayed'));
              selectedEl.classList.add('is-displayed');

              Ensighten.pushVirtualPage(item.name, item.name.replace(/ /g, '-').toLowerCase());
              pushSelectedToUrlParams(item.name);
            }
          }.bind(this);
          // test

          // Init
          if (this.selected()) {
            // grab the 'selected' url parameter
            var selectedInUrlParams = this.options().filter(function (option) {
              return urlFriendlyName(option.name) === getUrlParams().selected;
            })[0];
            // if 'selected' was found, use that one instead
            selectedInUrlParams ? this.selectQuestion(selectedInUrlParams) : this.selectQuestion(this.selected());
          }

          function pushSelectedToUrlParams(selectedVal) {
            if (history.pushState) {
              var urlParams = getUrlParams();
              urlParams.selected = urlFriendlyName(selectedVal);

              var queryString = Object.keys(urlParams).map(function (key) { return key + '=' + urlParams[key]; }).join('&');
              var newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + queryString;
              window.history.pushState({ path: newUrl }, '', newUrl);
            }
          }

          function urlFriendlyName(s) {
            return s.toLowerCase().replace(/[^a-z]/g, '');
          }

          function getUrlParams() {
            var queries = {};
            const queryParams = new URLSearchParams(document.location.search);
            queryParams.forEach((value, key) => {
              queries[key] = value;
            });
            return queries;
          }

        },

        view: function (controller) {
          return [
            // Dropdown:
            SelectDropdown(controller, controller.options, controller.selected, controller.selectQuestion)
          ];
        }

      };

      // Setup routes:
      route('/', root);

    });

  });
