defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/ConfirmBar',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'Shared/Framework/Mithril/Scripts/Helpers/Scroll'
  ],
  function (m, Render, Scroll) {

    // Template:
    var ConfirmBar = function (controller) {

      // Variables:
      var $bar = null;
      var $container = null;
      var $top = null;
      var $wrapper = $DS(window);
      var d = controller.d;
      var pack = controller.package();
      var render = Render();

      // Functions:
      var sticky = function () {
        var barHeight = $bar.outerHeight();
        var topTop = $top.length > 0 ? $top.offset().top : 0;
        var containerHeight = $container.outerHeight();
        var containerTop = $container.offset().top;
        var parentWidth = $bar.parent().width();
        var scrollTop = $wrapper.scrollTop();
        var windowHeight = window.innerHeight;

        // Viewport:
        var aboveViewport = scrollTop > (topTop === 0 ? containerTop : topTop) + containerHeight;
        var belowViewport = scrollTop + windowHeight < (topTop === 0 ? containerTop : topTop);
        var relativeViewport = scrollTop + windowHeight > containerTop + containerHeight;

        $bar.attr('style', '');
        $container.attr('style', '');

        // Sticky:
        if (!aboveViewport && !belowViewport && !relativeViewport) {
          $bar.css({ bottom: '0px', position: 'fixed', width: parentWidth });
          $container.css({ paddingBottom: barHeight + 'px' });
        }

        // Dropdown:
        var containerEnd = containerTop + containerHeight;
        var scrollBottom = scrollTop + windowHeight;

        controller.property('confirmBarMaxHeight', ((scrollBottom > containerEnd ? (windowHeight - barHeight) - (scrollBottom - containerEnd) : windowHeight - barHeight) - 20));
      };

      // Render:
      render.ready.then(function (nodes) {
        $container = $DS('.confirm-bar-container');
        $top = $DS('.confirm-bar-top');

        if (!nodes['confirm-bar'].rendered && $container.length > 0) {
          $bar = $DS(nodes['confirm-bar'].$element);

          sticky();

          Scroll(function () {
            sticky();
          });
        }
      });

      // View:
      return m('div', { class: 'confirm-bar-wrapper', config: render.depend('confirm-bar') }, [
        m('div', { class: 'confirm-bar' }, [
          m('div', { class: 'confirm-bar-content' }, [
            m('div', { class: 'confirm-bar-back' }, [
              m('a', { class: 'back', onclick: controller.back }, [
                m('div', { class: 'total-price' }, d('ConfirmBar/BackButton'))
              ])
            ]),
            m('div', { class: 'confirm-bar-cta' + (pack.status() === 'pending-confirmation' ? ' processing' : ''), onclick: controller.confirm }, [
              m('a', { class: 'confirm' }, [
                m('div', { class: 'purchase-button-loader-box' }, [
                  m('span', { class: 'loader-text' }, d('ConfirmBar/Processing')),
                  m('span', { class: 'loader-animation-box' }, [
                    m('span', { class: 'loader-dot' }),
                    m('span', { class: 'loader-dot' }),
                    m('span', { class: 'loader-dot' })
                  ])
                ]),
                m('div', { class: 'total-price' }, d('ConfirmBar/SubmitButton', { totalPrice: pack.totalPrice() }))
              ])
            ])
          ])
        ])
      ]);

    };

    // Public functions:
    return ConfirmBar;

  });
