defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/CheckButton',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render'
  ],
  function (m, Render) {
    // View:
    var CheckButton = function (option, onSelect, isSelected) {
      var render = Render();
      var value = typeof option === 'object' ? option.value : option;
      var _onSelect = function (option) {
        return function () {
          onSelect(option);
          m.redraw(true);
        };
      };

      var className = function () {
        var className = '';

        if (isSelected) {
          className += 'check-button--selected';
        }
        if (option.isDisabled) {
          className += 'check-button--disabled';
        }

        return className;
      };

      var renderDetails = function (details) {
        if (Array.isArray(details)) {
          return m('.details-wrapper', {
            config: render.depend('collapsible-wrapper')
          }, details.map(function (detail) {
            return m('.detail-item', detail);
          }));
        }
        return [];
      };

      var secondaryCta = function () {
        if (!option.details) {
          return;
        }
        var button = null;
        if (option.details) {
          button = m('svg', { class: 'icon arrow-down-icon' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#arrow-down-icon' })
          ]);
        }
        return button ? m('.secondary-cta', { config: render.depend('secondary-cta') }, button) : null;
      };

      const iconIndicator = () => {
        if (option.iconIndicator) {
          return m('.icon-indicator', typeof option.iconIndicator === 'function' ? option.iconIndicator() : option.iconIndicator);
        }
        return null;
      };

      var configCollapsible = function (wrapperNode, checkNode, buttonNode) {
        if (wrapperNode.rendered) {
          return;
        }

        var wrapperContext = wrapperNode.context;
        var $wrapper = wrapperNode.$element;
        wrapperContext.height = $wrapper.offsetHeight;
        wrapperContext.isCollapsed = true;
        $wrapper.style.maxHeight = 0;
        var $button = buttonNode.$element;

        if (option.showDetails) {
          $wrapper.setAttribute('data-show-details', option.showDetails); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        }

        var collapseAnyOtherSection = function () {
          var activeSecondaryCtas = document.querySelectorAll('.secondary-cta.active');
          if (!activeSecondaryCtas) return;
          for (var i = 0; i < activeSecondaryCtas.length; i++) {
            // we don't want to collapse the details if the other checkNode is the selected one or it has the show details attribute
            var $parent = activeSecondaryCtas[i].parentElement;
            if ($parent.classList.contains('check-button--selected') ||
              $parent.querySelector('.details-wrapper').getAttribute('data-show-details')) continue; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            if ($button !== activeSecondaryCtas[i]) activeSecondaryCtas[i].click();
          }
        };

        var toggleCollapsible = function (event) {
          collapseAnyOtherSection();
          // If you click o the checkNode we want to only expand the collapsable section
          // You can click on the secondaryCta (the arrow) to toggle the collapsing of the details section
          if (event) {
            if (event.target.classList.contains('check-button--selected') &&
                !wrapperContext.isCollapsed) {
              return;
            }

            // if you collapsed the details for a section that has show details attribute using the secondaryCta,
            // we want to remove it so next time when collapseAnyOtherSection is called this section will toggle as well
            if ($wrapper.getAttribute('data-show-details')) $wrapper.removeAttribute('data-show-details'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          }
          $button.classList.toggle('active');
          $wrapper.classList.toggle('active');
          var maxHeight = wrapperContext.isCollapsed ? wrapperContext.height + 'px' : '0px';

          $wrapper.style.maxHeight = maxHeight;
          wrapperContext.isCollapsed = !wrapperContext.isCollapsed;
        };

        if (isSelected || option.showDetails) toggleCollapsible();

        checkNode.$element.addEventListener('click', function (event) { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          if (option.isDisabled) return;
          toggleCollapsible(event);
        });

        $button.addEventListener('click', function (event) { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          event.stopPropagation();
          toggleCollapsible(event);
        });
      };

      // Render:
      render.ready.then(function (nodes) {
        if (nodes['check-button'] && nodes['check-button'].rendered) return;
        if (nodes['collapsible-wrapper']) {
          configCollapsible(nodes['collapsible-wrapper'], nodes['check-button'], nodes['secondary-cta']);
        }
      });

      var attrs = {
        class: className(),
        config: render.depend('check-button'),
        key: value + (option.extraKey ? option.extraKey : ''),
        onclick: _onSelect(option),
        'data-uitest-id': value
      };

      if (option.id) attrs.id = option.id;

      return m('.check-button', attrs, [
        m('.check-button__dot'),
        m('.check-button__body', [
          m('.check-button__labels', [
            m('.check-button__label', option.label || value),
            option.sublabel ? m('.check-button__sublabel', option.sublabel) : null
          ]),
          option.details ? renderDetails(option.details) : null
        ]),
        iconIndicator(),
        secondaryCta(),
      ]);
    };

    return CheckButton;
  });
