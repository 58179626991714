defineDs('DanskeSpil/Domain/NumberGamePackage/Scripts/Templates/MultiPackages/Shared/PackagesTabs', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Common/Framework/EventHandling/Scripts/Event'
], function (m, Event) {
  // Component:
  var PackagesTabs = {
    controller: function ({ controller }) {
      Object.assign(this, controller);

      this.selectedTabIndex = m.prop(0);
      Event.subscribe('multi-packages-tab:change', ({ index, key }) => {
        if (this.componentKey !== key) return;
        this.selectedTabIndex(index);
        Event.fire('multi-packages-tab:changed', { index, key });
      });

      this.activeIndicatorRender = ($element) => {
        const $activeTab = $element.querySelector('.multi-packages-tab--active'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        const $activeTabIndicator = $element.querySelector('.multi-packages-tab__active-indicator'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        if (!$activeTab || !$activeTabIndicator) return;
        $activeTabIndicator.style.width = `${$activeTab.clientWidth}px`;
        $activeTabIndicator.style.left = `${$activeTab.offsetLeft}px`;
      };
    },

    view: function (controller) {
      if (!controller.tabsOptions.length) {
        return m('div');
      }
      const indicatorExtraClassNames = controller.tabsOptions[controller.selectedTabIndex()]?.classModifier ? `multi-packages-tab__active-indicator--${controller.tabsOptions[controller.selectedTabIndex()].classModifier}` : '';

      return m('.multi-packages-tabs__wrapper',
        m('.multi-packages-tabs', {
          config: controller.activeIndicatorRender
        }, [
          m('.multi-packages-tab__active-indicator', {
            class: indicatorExtraClassNames
          }),
          controller.tabsOptions.map((option, index) => {
            return m('.multi-packages-tab', {
              class: controller.selectedTabIndex() === (index + (option.tabIndexOffset || 0)) ? 'multi-packages-tab--active' : '',
              onclick: () => {
                const targetTabIndex = index + (option.tabIndexOffset || 0);
                Event.fire('multi-packages-tab:change', { index: targetTabIndex, key: controller.componentKey });
              }
            }, option.label);
          })
        ])
      );
    }
  };

  return PackagesTabs;
});
