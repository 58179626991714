defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotApi',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage'
  ],
  function (m, EurojackpotApi, Storage) {

    // Variables:
    var data = m.prop({});
    var isFeedDown = m.prop(false);
    var jokerData = m.prop({});
    var noOpenDraw = m.prop(false);
    var ready = m.deferred();

    var playSystems = m.prop();

    // Functions:

    // Draw repeat Options:
    var getDrawRepeatOptions = function () {
      var options = data().drawRepeatOptions && data().drawRepeatOptions.options ? data().drawRepeatOptions.options : [];

      // Filter: Draw repeat value should not be higher than known amount of upcomming draws
      options = options.filter(function (v) {
        return v <= data().draws.length;
      });

      // Sort
      options.sort(function (a, b) { return a > b ? 1 : -1; });

      return options;
    };

    var getDrawRepeatMax = function () {
      return data().drawRepeatMax;
    };

    var getDrawRepeatDefault = function () {
      return data().drawRepeatOptions && data().drawRepeatOptions.default ? data().drawRepeatOptions.default : 1;
    };

    var getJokerPrice = function () {
      var dayData = jokerData().jokerWednesday || jokerData().jokerSaturday;

      if (dayData && dayData.openDraw && dayData.openDraw.rowPrice) {
        return dayData.openDraw.rowPrice;
      }

      return dayData && dayData.draws && dayData.draws[0] && dayData.draws[0].rowPrice ? dayData.draws[0].rowPrice : 0;
    };

    // Classic Options:
    const getClassicOptions = function () {
      return data().classicOptions?.options || [1, 2, 3, 4, 5, 10, 20, 50];
    };

    const getClassicDefaultRowCount = function () {
      return data().classicOptions?.default || 5;
    };

    // Lightning Options:
    var getLightningOptions = function () {
      return data().lightningOptions && data().lightningOptions.options ? data().lightningOptions.options : [];
    };

    var getLightningDefaultRowCount = function () {
      return data().lightningOptions && data().lightningOptions.default ? data().lightningOptions.default : 0;
    };

    // Lucky options:
    var getLuckyRowOptions = function () {
      return data().luckyOptions && data().luckyOptions.options ? data().luckyOptions.options : [10, 20, 30, 40, 50];
    };

    var getLuckyDefaultRowCount = function () {
      return data().luckyOptions && data().luckyOptions.default ? data().luckyOptions.default : 10;
    };

    // Play systems:
    var getPlaySystems = function () {
      if (playSystems()) {
      // Cache
        return playSystems();
      }

      var systemNames = data().playSystems;
      playSystems(systemNames.map(function (name) {
        var splitted = name.split(' ');
        var counts = splitted[1].split('-');
        return ({ name: name, type: splitted[0], selectedNumbersCount: counts[0], rowsCount: counts[1] });
      }));

      return playSystems();
    }.bind(this);

    var getSystemNames = function (type) {
      var systems = getPlaySystems();
      var names = [];

      for (var i = 0; i < systems.length; i++) {
        if (!type || systems[i].type === type) {
          names.push(systems[i].name);
        }
      }

      return names;
    }.bind(this);

    // Open draw:
    var getOpenDraw = function () {
      return data().openDraw;
    };

    var feedValid = function (str) {
      try {
        var json = JSON.parse(str);

        if (json && typeof json.eurojackpot.openDraw !== 'undefined') {
          return true;
        }
      } catch (e) { }

      return false;
    };

    var storeToCache = function (json) {
    // Store with TTL 2 min.
      Storage.set('EurojackpotInfoFeed', json ? JSON.stringify(json) : null, 120);
    };

    // Initialize:
    var init = function () {

      // 1) LOOK IN DOM
      var $eurojackpotFeedData = document.querySelector('#eurojackpot-info');
      if ($eurojackpotFeedData) {
        var domData = $eurojackpotFeedData.innerHTML;

        if (feedValid(domData)) {
          var domJson = JSON.parse(domData);

          data(domJson.eurojackpot);

          noOpenDraw(!data().openDraw);

          jokerData({
            jokerSaturday: domJson.jokerSaturday,
            jokerWednesday: domJson.jokerWednesday
          });

          // Store to cache
          storeToCache(domJson);
          ready.resolve();

          return;
        }
      }

      // 2) LOOK IN LOCALSTORAGE
      var cacheData = Storage.get('EurojackpotInfoFeed');

      if (!cacheData || !feedValid(cacheData)) {
      // Clear cache
        storeToCache(null);
      } else {
      // Use cache
        // data(JSON.parse(cacheData));

        // noOpenDraw(!data().openDraw);
        var d = JSON.parse(cacheData);

        data(d.eurojackpot);

        noOpenDraw(!data().openDraw);

        jokerData({
          jokerSaturday: d.jokerSaturday,
          jokerWednesday: d.jokerWednesday
        });

        ready.resolve();

        return;
      }

      // 3) LOAD INFO FROM FEED
      EurojackpotApi.getEurojackpotInfo().then(function (d) {
        data(d.eurojackpot);

        noOpenDraw(!data().openDraw);

        jokerData({
          jokerSaturday: d.jokerSaturday,
          jokerWednesday: d.jokerWednesday
        });

        // Store to cache
        storeToCache(d);

        ready.resolve();
      }, function () {
        isFeedDown(true);
        noOpenDraw(true);

        ready.resolve();

        console.error('### EUROJACKPOT FEED DOWN ###');
      });

    };

    init();

    // Public functions:
    return {
      data: data,
      getOpenDraw: getOpenDraw,
      jokerData: jokerData,
      isFeedDown: isFeedDown,
      noOpenDraw: noOpenDraw,
      ready: ready.promise,
      getDrawRepeatOptions: getDrawRepeatOptions,
      getDrawRepeatDefault: getDrawRepeatDefault,
      getDrawRepeatMax: getDrawRepeatMax,
      getJokerPrice: getJokerPrice,
      getSystemNames: getSystemNames,
      getClassicOptions: getClassicOptions,
      getClassicDefaultRowCount: getClassicDefaultRowCount,
      getLightningOptions: getLightningOptions,
      getLightningDefaultRowCount: getLightningDefaultRowCount,
      getLuckyRowOptions: getLuckyRowOptions,
      getLuckyDefaultRowCount: getLuckyDefaultRowCount
    };

  });
