defineDs('DanskeSpil/Domain/NumberGames/Scripts/Helpers/MultiClientUtils',
  [],
  function () {
    const hexToHSL = (hex) => {
      // Convert hex to RGB
      let r = parseInt(hex.substring(1, 3), 16) / 255;
      let g = parseInt(hex.substring(3, 5), 16) / 255;
      let b = parseInt(hex.substring(5, 7), 16) / 255;

      // Find the min and max values of RGB
      let cmin = Math.min(r, g, b);
      let cmax = Math.max(r, g, b);
      let delta = cmax - cmin;

      // Calculate HSL values
      let h = 0;
      let s = 0;
      let l = (cmax + cmin) / 2;

      if (delta !== 0) {
        switch (cmax) {
        case r:
          h = ((g - b) / delta) % 6;
          break;
        case g:
          h = ((b - r) / delta) + 2;
          break;
        case b:
          h = ((r - g) / delta) + 4;
          break;
        }
        h = Math.round(h * 60);
        s = Math.round(delta / (1 - Math.abs((2 * l) - 1)) * 100);
      }

      l = Math.round(l * 100);

      return [h, s, l];
    };

    const setCssHslVariables = ({ hexColor, variableName, rootElement }) => {
      if (!hexColor || !variableName) {
        return;
      }

      const hslLabels = ['h', 's', 'l'];
      const hslValues = hexToHSL(hexColor);
      const _rootElement = rootElement || document.documentElement;

      hslValues.forEach((value, index) => {
        _rootElement.style.setProperty(`--multiclient-${variableName}-color-${hslLabels[index]}`, value);
      });
    };

    /**
     * Checks for the presence of a multi-client anchor in the URL and performs a specific action based on the available play types.
     *
     * @param {Object} options - The options object.
     * @param {Array} options.availablePlayTypes - The available play types.
     * @param {Function} options.goToRoute - The function to navigate to a specific route.
     */
    const checkForMultiClientAnchor = ({ availablePlayTypes, goToRoute }) => {
      const locationHash = window.location.hash;
      if (!locationHash.includes('-multi-client') ||
          availablePlayTypes.length === 0) {
        return;
      }

      for (let i = 0; i < availablePlayTypes.length; i++) {
        const iterationHash = `${availablePlayTypes[i]}-multi-client`;
        if (locationHash.includes(iterationHash)) {
          window.location.hash = '';
          goToRoute(availablePlayTypes[i]);
          scrollWrapperToTop();
          break;
        }
      }
    };

    const scrollWrapperToTop = () => {
      const $multiClientWrapper = document.querySelector('.multi-client-wrapper__outer');
      if (!$multiClientWrapper) return;

      const $globalHeader = document.querySelector('header');
      const globalHeaderHeight = $globalHeader ? ($globalHeader.clientHeight + $globalHeader.getBoundingClientRect().y) : 0; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      const $timeNotificationBar = document.querySelector('.time-consuming-notification-wrapper');
      const timeNotificationBarHeight = $timeNotificationBar ? $timeNotificationBar.clientHeight : 0;
      const $realityCheck = document.querySelector('.reality-check-notification');
      const realityCheckHeight = $realityCheck ? $realityCheck.clientHeight : 0;

      const otherElementsOffset = globalHeaderHeight > 0 ? globalHeaderHeight : (timeNotificationBarHeight + realityCheckHeight);

      window.scrollTo({
        top: $multiClientWrapper.offsetTop - otherElementsOffset,
        behavior: 'smooth'
      });
    };

    return {
      hexToHSL,
      setCssHslVariables,
      checkForMultiClientAnchor,
      scrollWrapperToTop
    };
  });

