defineDs('DanskeSpil/Domain/Tutorial/Scripts/Pages/TutorialTipsSystemPage',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Templates/Overlay',
    'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/ModalDialog',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialFooter',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialNavigation',
    'DanskeSpil/Domain/Tutorial/Scripts/Components/TutorialHero'
  ], function (m, Overlay, ModalDialog, TutorialFooter, Navigation, Hero) {

    return {
      controller: function (args) {
        // Variables
        this.consts = {
          R: 'r',
          U: 'u',
          DESCRIPTION: 'description',
          GUARANTEE: 'guarantee',
          KEY: 'systemKey'
        };
        Object.freeze(this.consts);

        this.titleCssStyle = args.titleCssStyle;
        this.safeTitle = decodeURI(m.route.param('tutorial'));
        this.mostVisitedLabel = args.mostVisitedLabel;
        this.mostVisitedList = args.mostVisitedList;
        this.footerSettings = args.footerSettings;
        this.tutorial = args.tipsSystemTutorial[0];
        this.systems = {
          r: {},
          u: {}
        };
        this.selectedSystem = this.consts.R;
        this.selectedCombination = null;
        this.dropdownOpen = false;
        this.selectedTable = this.consts.DESCRIPTION;

        window.scrollTo(0, 0);

        this.tutorial.rSystemFolder.tipsSystems.forEach(function (system) {
          this.systems.r[system.name] = system;
        }.bind(this));
        this.tutorial.uSystemFolder.tipsSystems.forEach(function (system) {
          this.systems.u[system.name] = system;
        }.bind(this));

        this.showOverlay = function () {
          new Overlay(new ModalDialog([
            m('.tutorial-overlay', [
              m('.tutorial-overlay__title', { style: this.titleCssStyle }, this.tutorial.popupHeader),
              m('.tutorial-overlay__text', m.trust(this.tutorial.popupText)),
              m('img.tutorial-overlay__image', {
                src: this.tutorial.popupImage.Source,
                alt: this.tutorial.popupImage.AlternameText
              })
            ])
          ], {
            buttons: []
          })).show();
        }.bind(this);

      },

      view: function (controller) {
        return m('.tutorial-tips', [
          Navigation(controller), // eslint-disable-line compat/compat
          Hero({ title: controller.tutorial.title, style: controller.titleCssStyle }),
          m('.tutorial__content-box.tutorial-tips__content', [
            m('.tutorial-tips__content-inner', [
              m('.tutorial-tips__header', { style: controller.titleCssStyle }, controller.tutorial.firstHeader),
              m('.tutorial-tips__button-wrapper', [
                m('.tutorial-tips__button', {
                  class: controller.selectedSystem === controller.consts.R ? 'tutorial-tips__button--selected' : '',
                  onclick: function () {
                    controller.selectedSystem = controller.consts.R;
                    controller.selectedCombination = null;
                  }
                }, controller.tutorial.rSystemButtonText),
                m('.tutorial-tips__button', {
                  class: controller.selectedSystem === controller.consts.U ? 'tutorial-tips__button--selected' : '',
                  onclick: function () {
                    controller.selectedSystem = controller.consts.U;
                    controller.selectedCombination = null;
                  }
                }, controller.tutorial.uSystemButtonText)
              ]),
              m('.tutorial-tips__read-more', m.trust(controller.tutorial.subButtonsText)),
              m('.tutorial-tips__header', { style: controller.titleCssStyle }, [
                controller.tutorial.secondHeader,
                m('span.tutorial-tips__popup-trigger', {
                  onclick: controller.showOverlay
                }, '?')
              ]),
              m('.tutorial-tips__dropdown', {
                class: [
                  controller.dropdownOpen ? 'tutorial-tips__dropdown--open' : '',
                  controller.selectedCombination ? 'tutorial-tips__dropdown--selected' : ''
                ].join(' ').trim(),
                onclick: function () {
                  controller.dropdownOpen = !controller.dropdownOpen;
                }
              }, [
                m('.tutorial-tips__label', controller.selectedCombination || 'KOMBINATION'),
                m('.tutorial-tips__dropdown-content', Object.keys(controller.systems[controller.selectedSystem]).map(function (combinationKey) {
                  return m('.tutorial-tips__combination-key', {
                    id: combinationKey,
                    class: combinationKey === controller.selectedCombination ? 'tutorial-tips__combination-key--selected' : '',
                    onclick: function (event) {
                      if (controller.selectedCombination === event.currentTarget.id) {
                        controller.selectedCombination = null;
                      } else {
                        controller.selectedCombination = event.currentTarget.id;
                      }
                      window.scrollTo(0, 0);
                    }
                  }, combinationKey);
                }))
              ])
            ]),

            controller.selectedCombination ? m('.tutorial-tips__table-wrapper', [
              m('.tutorial-tips__table-selectors', [
                m('.tutorial-tips__table-selector', {
                  class: controller.selectedTable === controller.consts.DESCRIPTION ? 'tutorial-tips__table-selector--active' : '',
                  onclick: function () {
                    controller.selectedTable = controller.consts.DESCRIPTION;
                  }
                }, 'BESKRIVELSE'),
                m('.tutorial-tips__table-selector', {
                  class: controller.selectedTable === controller.consts.GUARANTEE ? 'tutorial-tips__table-selector--active' : '',
                  onclick: function () {
                    controller.selectedTable = controller.consts.GUARANTEE;
                  }
                }, 'GARANTI'),
                m('.tutorial-tips__table-selector', {
                  class: controller.selectedTable === controller.consts.KEY ? 'tutorial-tips__table-selector--active' : '',
                  onclick: function () {
                    controller.selectedTable = controller.consts.KEY;
                  }
                }, 'NØGLE')
              ]),
              m('.tutorial-tips__table', {
                class: 'tutorial-tips__table--' + controller.selectedTable
              }, [
                controller.selectedTable === controller.consts.DESCRIPTION ? m('.tutorial-tips__table-title', controller.selectedCombination) : null,
                m.trust(controller.systems[controller.selectedSystem][controller.selectedCombination][controller.selectedTable])
              ])
            ]) : null,
          ]),
          controller.footerSettings ? TutorialFooter({ style: controller.titleCssStyle, ...controller.footerSettings }) : null
        ]);
      }
    };
  });
