defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Header/Header', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
], function (m) {
  const Header = {
    controller: function ({ controller }) {
      Object.assign(this, controller);
    },
    view: function (ignoreController, { useCompactView, heading, subheading }) {
      if (!heading?.length && !subheading?.length) {
        return <div />;
      }

      let headlineColor = 'text-grey-700 group-[.christmas-theme]:text-white';
      let subheadingColor = 'text-gray-400 group-[.christmas-theme]:text-white';

      return (
        <div className={`js-multi-client__flow-step-header relative px-30 pt-10 pb-10 text-center ${useCompactView ? '' : 'md:pt-50'}`}>
          {heading?.length ? <div className={`${headlineColor} text-28 font-bold mt-0 mb-24 ${useCompactView ? '' : 'md:text-40'}`}>{heading}</div> : null}
          {subheading?.length ? <div className={`${subheadingColor} text-14 leading-22 font-semibold m-0`}>{m.trust(subheading)}</div> : null}
        </div>
      );
    }
  };
  return Header;
});