defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/StatisticsModule/GraphOverview',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render'
  ],
  function (m, Render) {

    // Template:
    var GraphOverview = function (controller) {

      // Variables:
      var barWidth = ((controller.property('smallBarsWrapperWidth') / controller.property('largeBarsWrapperWidth')) * controller.property('largeBarWidth')) + 'px';
      var d = controller.d;
      var gameType = controller.selectedGameClient();
      var numbersType = controller.property('numbersType');
      var render = Render();
      var selectedPoints = controller.property('selectedPoints');
      var statisticsFeed = controller.statisticsNumbersFeed || [];

      // Functions:
      var expandToggle = function () {
        return function () {
          controller.property('graphOverviewExpandToggle', !controller.property('graphOverviewExpandToggle'));
        };
      };

      // Render:
      render.ready.then(function (nodes) {
        controller.property('smallBarsWrapperWidth', nodes['small-bars-wrapper'].$element.offsetWidth);
      });

      // Return:
      return m('div', { class: 'statistics-module-small-view' + (controller.property('largeBarsWrapperWidth') > controller.windowWidth ? ' active' : '') + (controller.property('graphOverviewExpandToggle') ? ' minimized' : ''), config: render.depend('statistics-graph-overview-wrapper') }, [
        m('div', { class: 'statistics-module-graph-overview-inner-wrapper' }, [
          m('div', { class: 'statistics-module-graph-overview-expand-wrapper', onclick: expandToggle() }, [
            m('div', { class: 'graph-overview-button minimize' }, [
              m('svg', { class: 'icon' }, [
                m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesStatisticsModuleIcons.svg#icon-condensing-arrows' })
              ]),
              m('span', { class: 'text' }, d('GraphModule/GraphOverview/MinimizeOverview'))
            ]),
            m('div', { class: 'graph-overview-button maximize' }, [
              m('svg', { class: 'icon' }, [
                m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesStatisticsModuleIcons.svg#icon-expand-arrows' })
              ]),
              m('span', { class: 'text' }, d('GraphModule/GraphOverview/MaximizeOverview'))
            ])
          ]),
          m('div', { class: 'statistics-internal-wrapper', config: render.depend('small-bars-wrapper') }, [
            m('div', { class: 'statistics-bars-wrapper' }, [
              statisticsFeed.map(function (point, index) {
                if (point.drawnCount === 0 && controller.property('filterZeroToggle')) {
                  return;
                }

                return m('div', { class: 'statistics-small-bar-wrapper' + (controller.property('hoveredPoint') === index ? ' hovered' : ''), style: 'width: ' + barWidth }, [
                  m('div', { class: 'bar' + (selectedPoints[gameType] ? (selectedPoints[gameType][numbersType][index] ? ' selected' : '') : ''), style: 'height: ' + (point.drawnCount / (controller.mostDrawnCount / 100)) + '%' }, [
                    m('div', { class: 'number' }, point.number)
                  ])
                ]);
              })
            ]),
            m('div', { class: 'statistics-zoom-finder-wrapper' }, [
              m('div', { class: 'zoom-finder', config: controller.render().depend('graph-view-finder') })
            ])
          ])
        ])
      ]);
    };

    // Public functions:
    return GraphOverview;

  });
