defineDs('DanskeSpil/Domain/NumberGames/Scripts/Helpers/UIHelpers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Scroll',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Resize'
  ],
  function (m, Scroll, Utils, Resize) {
    // Functions:
    function getPositionRelativeToWindow($element) {
      let xPosition = 0;
      let yPosition = 0;

      while ($element) {
        xPosition += ($element.offsetLeft - $element.scrollLeft + $element.clientLeft);
        yPosition += ($element.offsetTop - $element.scrollTop + $element.clientTop);
        $element = $element.offsetParent;
      }

      return { x: xPosition, y: yPosition };
    }

    var columnDivider = function (items, options) {
      var columns = [];
      var columnsOptions = Utils.isMobile() ? ['single-column'] : ['left-column', 'right-column'];
      var className = options && options.className;
      columnsOptions.forEach(function (column, columnIndex) {
        var columnContent = items.filter(function (ignoreItem, itemIndex) {
          return itemIndex % columnsOptions.length === columnIndex % columnsOptions.length;
        });
        columns.push(m('.multi-columns-wrapper__column', { class: column }, columnContent));
      });

      return m('.multi-columns-wrapper', { class: className }, columns);
    };

    const stickyComponent = ({ $container, topLimit }) => {
      return ($element, ignoreIsRendered, context) => {
        const checkPosition = () => {
          if (!topLimit) return;
          const distanceFromWindowBottom = context.containerOffsetTop + $container.clientHeight;
          const distance = distanceFromWindowBottom - (window.innerHeight + window.scrollY);
          const topLimitValue = topLimit() || 0;
          const innerTopLimit = $container.clientHeight - $element.clientHeight - topLimitValue;
          if (distance > innerTopLimit) {
            let newPosition = distance - innerTopLimit;
            if (newPosition > topLimitValue) {
              newPosition = topLimitValue;
            }
            $element.style.transform = `translateY(${newPosition}px)`;
            return;
          }

          $element.style.transform = 'translateY(0)';
          $element.classList.toggle('sticky', distance > 0);
        };

        if (!context.isInitialized) {
          if (!$container?.contains($element)) return; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          setTimeout(() => {
            context.isInitialized = true;
            context.containerOffsetTop = getPositionRelativeToWindow($container).y;

            const resizeObserver = new ResizeObserver(() => {
              checkPosition();
            });
            resizeObserver.observe($container);

            Scroll(checkPosition);
            Resize(checkPosition);
            checkPosition();
          }, 0);
        }
      };
    };

    return {
      columnDivider,
      stickyComponent,
      getPositionRelativeToWindow,
    };
  });
