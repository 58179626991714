defineDs('DanskeSpil/Framework/NumberGames/Scripts/Helpers/Shake',
  function () {

    // Variables:
    var callbacks = [];
    var shakeCount = 0;
    var shakeState = 0;
    var shakeTolerance = 30;
    var shakeTimeout = 500;

    // Functions:
    var add = function (callback) {
      callbacks.push(callback);
    };

    // Init:
    window.ondevicemotion = function (event) {
      if (!event || !event.accelerationIncludingGravity) {
        return;
      }

      var xAxis = Math.abs(event.accelerationIncludingGravity.x);
      var yAxis = Math.abs(event.accelerationIncludingGravity.y);
      var zAxis = Math.abs(event.accelerationIncludingGravity.z);

      if ((xAxis + yAxis + zAxis) > shakeTolerance && !shakeState) {
        shakeCount++;

        if (shakeCount > 5) {
          shakeState = 1;

          var amount = callbacks.length;

          for (var i = 0; i < amount; i++) {
            callbacks[i]();
          }
        }

        setTimeout(function () {
          shakeState = 0;
          shakeCount = 0;
        }, shakeTimeout);
      }
    };

    // Public functions:
    return add;

  });
