defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/GuaranteeContent/ReducedAndChanceAccordions',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Accordions/Accordion',
  // eslint-disable-next-line no-unused-vars
  ], function (m, Accordion) {
    const ReducedAndChanceAccordions = {
      controller: function ({ controller, data }) {
        Object.assign(this, controller);
        this.accordionsData = [];

        switch (this.gameName) {
        case 'vikinglotto':
          this.oddTableRowColor = 'odd:bg-vikinglotto-blue-1/10';
          break;
        case 'eurojackpot':
          this.oddTableRowColor = 'odd:bg-eurojackpot-gold-1/10';
          break;
        default:
          this.oddTableRowColor = 'odd:bg-lotto-red-1/10';
          break;
        }

        data.rows.forEach((dataRow) => {
          const rowsLength = dataRow.frequency.length;
          const tableData = {
            header: [],
            rows: [],
          };

          for (let i = 0; i < rowsLength; i++) {
            const row = [];
            dataRow.columns.forEach((column, index) => {
              if (i === 0) {
                tableData.header.push(this.labelDictionary('systemGuaranteeTabAccordionReducedAndChanceTableCorrectNumbersHeading', {
                  number: column.correct
                }));
              }
              row.push(dataRow.columns[index].values[i]);
            });
            if (i === 0) {
              tableData.header.push(this.labelDictionary('systemGuaranteeTabAccordionReducedAndChanceTableFrequencyHeading'));
            }
            row.push(dataRow.frequency[i]);
            tableData.rows.push(row);
          }

          const tableStructure = <table className='w-full text-black'>
            <thead>
              <tr className='border-0 border-b border-solid border-grey-1'>
                {tableData.header.map((header) => {
                  return <th className='text-12 leading-18 text-center pt-6 pb-10'>{header}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData.rows.map((row) => {
                return <tr className={`${this.oddTableRowColor}`}>
                  {row.map((cell) => {
                    return <td className='text-12 leading-18 text-center py-6'>{cell}</td>;
                  })}
                </tr>;
              })}
            </tbody>
          </table>;
          this.accordionsData.push({
            heading: this.labelDictionary('systemGuaranteeTabAccordionReducedAndChanceTableCorrectNumbersHeading', {
              number: dataRow.number
            }),
            content: <div className='bg-white'>{tableStructure}</div>
          });
        });
      },
      view: function (controller) {
        return (<div data-uitest-id='accordions-list' className='w-full max-w-372 mx-auto flex flex-col gap-6 mb-24'>
          {controller.accordionsData.map((accordionData) => {
            return <Accordion
              allowOnlyOneOpen={true}
              heading={accordionData.heading}
              content={accordionData.content}
            />;
          })}
        </div>);
      }
    };

    return ReducedAndChanceAccordions;
  });