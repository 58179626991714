defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/StatisticsModule/NumbersTypeSwitch',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Template:
    var NumbersTypeSwitch = function (controller) {

      // Variables:
      var d = controller.d;
      var numbersType = controller.property('numbersType');
      var selectedGameClient = controller.selectedGameClient();

      // Functions:
      if (selectedGameClient === 'Keno') {
        return;
      }

      var setNumbersType = function (type) {
        return function () {
          controller.property('numbersType', type);
          controller.changeNumberType();
        };
      };

      // Return:
      return m('div', { class: 'statistics-module-numbers-type-switch-wrapper' }, [
        m('div', { class: 'numbers-type-switch-inner-wrapper' }, [
          m('div', { class: 'numbers-type-switch-button-wrapper' + (numbersType === 'numbers' ? ' active' : ''), onclick: setNumbersType('numbers') }, [
            m('span', { class: 'button-text' }, d('GraphModule/NumbersTypeToggleSwitch/' + selectedGameClient + '/NormalNumbers'))
          ]),
          m('div', { class: 'numbers-type-switch-button-wrapper' + (numbersType === 'extraNumbers' ? ' active' : ''), onclick: setNumbersType('extraNumbers') }, [
            m('span', { class: 'button-text' }, d('GraphModule/NumbersTypeToggleSwitch/' + selectedGameClient + '/SpecialNumbers'))
          ])
        ])
      ]);
    };

    // Public functions:
    return NumbersTypeSwitch;

  });
