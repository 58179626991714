defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/AccordionInfoOverlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Shared/Framework/Ensighten/Scripts/Ensighten',
    'Common/Framework/EventHandling/Scripts/Event',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/GenericOverlay'
  ],
  function (Component, Ensighten, Event, Overlay, GenericOverlay) {

    // Component:
    Component('accordion-info-overlay', [], function (m, route, settings, property) {

      // Components:
      const root = {
        controller: function () {
          this.property = property;
          this.selectedItemIndex = m.prop(null);
          this.openedFrom = m.prop('');

          this.key = settings.key;
          this.image = settings.image;
          this.settings = JSON.stringify(settings);
          for (const [key, value] of Object.entries(settings.variables || {})) {
            let dateVariable = new Date(value);
            let _value = value;
            if (dateVariable.toString() !== 'Invalid Date') {
              _value = dateVariable.toLocaleString('da-dk', {
                day: 'numeric',
                month: 'long'
              });
            }
            this.settings = this.settings.replace(`{${key}}`, _value);
          }
          this.settings = JSON.parse(this.settings);
          this.captionText = this.settings.captionText;
          this.headerText = this.settings.header;
          this.subheadingText = this.settings.subheading;
          this.bodyText = this.settings.body;
          this.accordionItems = this.settings.accordionItems?.length ? this.settings.accordionItems : null;
          this.confirmButton = settings.confirm;
          this.dismissButton = settings.dismiss;
          this.overlay = m.prop(null);

          const confirmAction = () => {
            Ensighten.pushGaEvent('faq_popup', 'closed', this.openedFrom());
          };
          const onClose = () => {
            Ensighten.pushGaEvent('faq_popup', 'closed', this.openedFrom());
          };

          const onListRender = ($element) => {
            const $items = $element.childNodes;
            const activeClassName = 'accordion-info-overlay__item--active';

            const toggleItem = ($item, isItemSelected) => {
              const $itemBody = $item.querySelector('.item-body'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
              const $itemBodyInner = $item.querySelector('.item-body__inner'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
              $itemBody.style.maxHeight = isItemSelected ? `${$itemBodyInner.offsetHeight}px` : '';
              $item.classList.toggle(activeClassName, isItemSelected);
            };

            const removeActiveClass = () => {
              $items.forEach(($item) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
                toggleItem($item, false);
              });
            };

            $items.forEach(($item, index) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
              $item.addEventListener('click', () => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
                const isItemSelected = this.selectedItemIndex() !== index;
                removeActiveClass();
                toggleItem($item, isItemSelected);
                this.selectedItemIndex(isItemSelected ? index : null);
                if (isItemSelected) {
                  Ensighten.pushGaEvent('faq', 'open_faq', $item.querySelector('.item-header__title').textContent); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
                }
              });
            });
          };

          const generateItem = (args) => {
            return m('.accordion-info-overlay__item', [
              m('.item-header', [
                m('.item-header__title', args.title),
                m('.item-header__button',
                  m('svg.item-header__button-icon', [
                    m('use', { href: '/Components/DanskeSpil/Domain/Subscriptions/Graphics/SpriteSheets/UiKit.svg#arrow_down' })
                  ])
                )
              ]),
              m('.item-body', [
                m('.item-body__inner', m.trust(args.body))
              ])
            ]);
          };

          const itemsList = () => {
            if (!this.accordionItems) return null;
            return m('.accordion-info-overlay__list', {
              config: onListRender
            }, this.accordionItems.map((item, index) => generateItem(item, index)));
          };

          Event.subscribe('ds.event.accordionInfoOverlay:show', (args) => {
            const $existingOverlay = document.querySelector(`.accordion-info-overlay__${this.key}`);
            if ($existingOverlay) return;
            if (args.key === this.key) {
              if (args.srcIdentifier) {
                this.openedFrom(args.srcIdentifier);
                Ensighten.pushGaEvent('faq_popup', 'shown', this.openedFrom());
              }

              let className = `accordion-info-overlay accordion-info-overlay__${this.key}`;
              if (args.className) {
                className += ` ${args.className}`;
              }

              const genericOverlayOptions = {
                className: className,
                imageCaption: this.captionText,
                iconUrl: this.image,
                header: this.headerText,
                subheading: this.subheadingText,
                bodyView: itemsList() || m('.accordion-info-overlay__body-text', m.trust(this.bodyText)),
                confirm: this.confirmButton?.text,
                dismiss: this.dismissButton?.text,
              };

              this.overlay(new Overlay(GenericOverlay(genericOverlayOptions)));
              this.overlay().show(args.onConfirm || confirmAction, args.onClose || onClose);
            }
          });
        }
      };

      // Setup routes:
      route('/', root);

    });

  });
