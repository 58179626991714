defineDs('DanskeSpil/Domain/NumberGames/Scripts/Helpers/StatisticsModuleApi',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest'
  ],
  function (ApiRequest) {

    // Variables:
    var baseURL = window.location.origin + '/dlo/scapi/danskespil/numbergames/';

    // Functions:
    var drawDates = function (gameType) {
      return ApiRequest({
        method: 'GET',
        url: baseURL + gameType + '/completedDrawDates'
      });
    };

    var statisticsFeed = function (gameType, dates) {
      return ApiRequest({
        data: dates,
        method: 'POST',
        url: baseURL + gameType + '/WinningNumbersStatisticsSearch'
      });
    };
    const biggestWins = function (gameType) {
      return ApiRequest({
        method: 'GET',
        url: baseURL + gameType + '/biggestWinners'
      });
    };

    const yearlyWinnings = (gameType) => {
      return ApiRequest({
        method: 'GET',
        url: baseURL + gameType + '/yearlyWinnings'
      });
    };

    // Public functions:
    return {
      drawDates: drawDates,
      statisticsFeed: statisticsFeed,
      yearlyWinnings: yearlyWinnings,
      biggestWins: biggestWins
    };

  });
