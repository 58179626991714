defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/WinningNumbers/DrawnBalls', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
], (m) => {
  const DrawnBalls = ({ gameType, numbers, label, bonusNumbers, bonusLabel, property, gameTypeSelected, selectedDrawDate }) => {

    if (!numbers || numbers.length === 0) {
      return;
    }

    // Initialize.
    if (!property('drawnBallsPreviousSelectedGameType')) property('drawnBallsPreviousSelectedGameType', gameType);
    if (!property('drawnBallsPreviousSelectedDrawDate')) property('drawnBallsPreviousSelectedDrawDate', selectedDrawDate());
    if (!property('drawnBallsPreviousNumbers')) property('drawnBallsPreviousNumbers', numbers.toString());
    if (!property('drawnBallsAnimateCssClass')) property('drawnBallsAnimateCssClass', 'animate');

    const didNumbersChange = () => {
      const dateHasChanged = property('drawnBallsPreviousSelectedDrawDate') !== selectedDrawDate();
      const gameTypeHasChanged = property('drawnBallsPreviousSelectedGameType') !== gameType;
      const NumbersHasChanged = property('drawnBallsPreviousNumbers') !== numbers.toString();
      return dateHasChanged || gameTypeHasChanged || NumbersHasChanged;
    };

    const reToggleAnimation = () => {
      property('drawnBallsPreviousSelectedDrawDate', selectedDrawDate());
      property('drawnBallsPreviousSelectedGameType', gameType);
      property('drawnBallsPreviousNumbers', numbers.toString());
      property('drawnBallsAnimateCssClass', 'no-animation');
      setTimeout(() => {
        property('drawnBallsAnimateCssClass', 'animate');
        m.redraw();
      }, 0);
    };

    if (didNumbersChange()) reToggleAnimation();

    const getDrawnBalls = (gameType, numbers, label, isBonusNumbers) => {
      if (!numbers?.length) return;

      return m('.winning-numbers-drawn-balls__section', [
        label ? m('.winning-numbers-drawn-balls__title', label) : '',
        m('.winning-numbers-drawn-balls__balls', {
          class: `winning-numbers-drawn-balls__balls--${gameType}${isBonusNumbers ? '-bonus' : ''}`,
        }, numbers.map((number) => m('.winning-numbers-drawn-balls__ball', number))),
      ]);
    };

    return m('.winning-numbers-drawn-balls', {
      class: `winning-numbers-drawn-balls--${gameTypeSelected()} winning-numbers-drawn-balls--${property('drawnBallsAnimateCssClass')}`
    }, [
      getDrawnBalls(gameType, numbers, label),
      getDrawnBalls(gameType, bonusNumbers, bonusLabel, true),
    ]);

  };

  return DrawnBalls;

});
